import React, { useState, useEffect } from "react";
import HttpRequest from "../../services/HttpRequest";

import { Table, Input, Space, InputNumber, DatePicker, Select } from "antd";
import SelectSearch from "../../components/select/select-search";

//Moverlo para la dataTable List
import moment from "moment";
import LocationForm from "../form/locationInput";

export const ShowFilters = (props) => {
    const [typeCompoment, setTypeCompoment] = useState([]);
    const [comparison, setComparison] = useState("");
    const [optionChoices, setOptionChoices] = useState([]);
    const [optionValue, setOptionValue] = useState(null);
    const [optionHumanRead, setOptionHumanRead] = useState(null);

    const setOptionCompare = (name, value) => {
        let filter = Object.values(props.filters).filter(
            (i) => i.name === name
        );
        let compare = Object.values(props.comparison).filter(
            (i) => i.name === value
        );

        let typeOp = compare[0].type.includes(filter[0].type)
            ? filter[0].type
            : compare[0].type[0];

        setComparison(value);
        setTypeCompoment(typeOp);
    };

    const setComponent = (value) => {
        switch (value) {
            case "int":
                return (
                    <InputNumber
                        name="number"
                        placeholder="Ingrese datos"
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        onBlur={e => onChange("int", Number(e.target.value))}
                    //onChange={(value) => onChange("int", value) }
                    />
                );
            case "float":
                return (
                    <InputNumber
                        name="number"
                        placeholder="Ingrese datos"
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        onChange={(value) => onChange("float", value)}
                    />
                );
            case "str":
                return (
                    <Input
                        name="dato"
                        placeholder="Ingrese datos"
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        onBlur={e => onChange("str", e.target.value)}
                    //onChange={(e) => onChange("str", e.target.value) }
                    />
                );
            case "datetime":
                return (
                    <DatePicker
                        format="DD/MM/YYYY HH:mm"
                        showTime={{
                            defaultValue: moment("00:00:00", "HH:mm"),
                        }}
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        onChange={(date, dateString) =>
                            onChange("datatime", dateString)
                        }
                    />
                );
            case "choices":
                return (
                    <SelectSearch
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        name={"compare"}
                        action={onChange}
                        dataSources={optionChoices}
                        placeholder={"Creador"}
                        optionValue={optionValue}
                        optionHumanRead={optionHumanRead}
                    />
                );
            case "bool":
                return (
                    <Select onChange={val => onChange('bool', val)} placeholder='Seleccione'>
                        <Select.Option value='True'>Sí</Select.Option>
                        <Select.Option value='False'>No</Select.Option>
                    </Select>
                )
            case "location":
                return (<LocationForm onChange={(value) => onChange("location", value)} />)
            default:
                break;
        }
    };

    const onChange = (name, value) => {
        props.function(
            props.name,
            props.filters[props.name].name,
            comparison,
            value
        );
    };

    async function getChoice() {
        /*let filter = Object.values(props.filters).filter(
            (i) => i.type_choices === "choices"
        );*/

        const filter = props.filters[props.name]
        if (filter.values.type === 'dinamic') {
            setOptionValue(filter.values.extra_data.read_data.value);
            setOptionHumanRead(filter.values.extra_data.read_data.human_readable);
            HttpRequest.endpoint = filter.values.extra_data.url;
            await HttpRequest.get().then((res) => {
                setOptionChoices(res);
            });
        } else {
            const options = Object.entries(filter.values.extra_data);
            setOptionValue(0);
            setOptionHumanRead(1);
            setOptionChoices(options)
        }
    }

    useEffect(() => {
        if (props.filters[props.name].type === "choices") {
            getChoice();
        }
    }, []);

    return (
        <div
            key={props.index}
            className="form-group"
            style={{ textAlign: "left" }}
        >
            <Space>
                <div>
                    <Input
                        name="filter"
                        style={{ border: "none" }}
                        value={props.name}
                        readOnly
                    />
                </div>
                <div>
                    <SelectSearch
                        style={{
                            width: 200,
                            textAlign: "left",
                            fontSize: 12,
                        }}
                        name={props.filters[props.name].name}
                        action={setOptionCompare}
                        dataSources={
                            props.typeOfComparison[
                            props.filters[props.name].type
                            ]
                        }
                        placeholder={"Condición"}
                    />
                </div>
                <div>{setComponent(typeCompoment)}</div>
            </Space>
        </div>
    );
};

export const DataTable = (props) => {
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            filters: [
                {
                    text: "Joe",
                    value: "Joe",
                },
                {
                    text: "Jim",
                    value: "Jim",
                },
                {
                    text: "Submenu",
                    value: "Submenu",
                    children: [
                        {
                            text: "Green",
                            value: "Green",
                        },
                        {
                            text: "Black",
                            value: "Black",
                        },
                    ],
                },
            ],
            // specify the condition of filtering result
            // here is that finding the name started with `value`
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            sortDirections: ["descend"],
        },
        {
            title: "Age",
            dataIndex: "age",
            defaultSortOrder: "descend",
            sorter: (a, b) => a.age - b.age,
        },
        {
            title: "Address",
            dataIndex: "address",
            filters: [
                {
                    text: "London",
                    value: "London",
                },
                {
                    text: "New York",
                    value: "New York",
                },
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.address.indexOf(value) === 0,
            sorter: (a, b) => a.address.length - b.address.length,
            sortDirections: ["descend", "ascend"],
        },
    ];

    const dataSource = [
        {
            key: "1",
            name: "John Brown",
            age: 32,
            address: "New York No. 1 Lake Park",
        },
        {
            key: "2",
            name: "Jim Green",
            age: 42,
            address: "London No. 1 Lake Park",
        },
        {
            key: "3",
            name: "Joe Black",
            age: 32,
            address: "Sidney No. 1 Lake Park",
        },
        {
            key: "4",
            name: "Jim Red",
            age: 32,
            address: "London No. 2 Lake Park",
        },
    ];

    function onChange(pagination, filters, sorter, extra) {
        props.onChange({ pagination, filters, sorter, extra });
    }
    return (
        <Table
            rowKey={(record, index) => index}
            responsive={"xs"}
            rowClassName={"DataTable"}
            columns={props.column ? props.column : columns}
            bordered
            dataSource={props.dataSource ? props.dataSource : []}
            onChange={onChange}
            size={props.size}
            pagination={props.pagination ? props.pagination : false}
            loading={props.loading ? props.loading : false}
            scroll={props.scroll}
        />
    );
};
