import { Button, Input, Row, Select, Form, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { Panel, PanelBody } from '../../components/panel/panel';
import HttpRequest from '../../services/HttpRequest';
import { connect } from 'react-redux';
import "./removeEquip.css"
import { useForm } from 'antd/lib/form/Form';
import autoCloseModal from './../../components/modal/AutoCloseModal';

function RemoveEquipEmailTemplate(props) {

    const [templateList, setTemplateList] = useState([]);
    const [templateContent, setTemplateContent] = useState();
    const [templateID, setTemplateID] = useState();
    const [loading, setLoading] = useState(false);

    const { Option } = Select;
    const [form] = useForm()

    const getTemplates = () => {
        var templates = [];
        var templates_content = [];
        console.log("Obteniendo plantillas");
        var params = { operator: props.operator }
        HttpRequest.endpoint = "communications/template_email/"
        HttpRequest.get(params).then((res) => {
            res.forEach(element => {
                templates_content[element["ID"]] = element["template_html"]
                templates.push(<Option key={element["ID"]} value={element["ID"]}>{element["name"]}</Option>)
            });
            setTemplateContent(templates_content);
            setTemplateList(templates);

        })
    }

    const getData = () => {
        HttpRequest.endpoint = "formerCustomers/remove_equipament_email/"
        var params = { operator: props.operator }
        HttpRequest.get(params).then((res) => {
            if (res.length > 0) {
                setTemplateID(res[0]['template_email'])
                form.setFieldsValue({ email: res[0]['sender'], template: { value: res[0]['template_email'] } })
            } else {
                setTemplateID(null)
                form.setFieldsValue({ email: null, template: { value: null } })
            }
            getTemplates();
        })

    }

    const updateEmail = (values) => {
        setLoading(true)
        console.log(values)
        const formData = new FormData();
        formData.append("template_email", templateID)
        formData.append("sender", values['email'])
        formData.append("operator", props.operator)
        HttpRequest.endpoint = "formerCustomers/remove_equipament_email/"
        HttpRequest.post(formData).then((res) => {
            if (res["ID"]) {
                autoCloseModal("Se ha actualizado el registro correctamente")
            } else {
                if (Object.keys(res).includes('Error same operator')) {
                    HttpRequest.patch(res['Error same operator'], formData).then((res2) => {
                        if (res2["ID"]) {
                            autoCloseModal("Se ha actualizado el registro correctamente")
                        }
                        else {
                            Modal.error({
                                title: "Error",
                                content: "Ha ocurrido un error al actualizar el registro"
                            })
                        }
                    })
                } else {
                    Modal.error({
                        title: "Error",
                        content: "Ha ocurrido un error al crear el registro"
                    })
                }
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData();
        getTemplates();
    }, [])

    useEffect(() => {
        getData();
    }, [props.operator])


    return (
        <Panel>
            <PanelBody>
                <div className="removeEquipTemplate">
                    <Form form={form} onFinish={updateEmail} style={{ width: "100%" }}>
                        <Row justify="space-between" style={{ width: "100%" }}>
                            <div style={{ width: "62%" }}>
                                <h5>Seleccione la plantilla de correo a enviar cuando se retire un equipo</h5>
                                <Form.Item name="template">
                                    <Select labelInValue value={{ value: templateID }} className="removeEquipTemplateSelect" onChange={(option) => { setTemplateID(option["value"]); console.log(option); }}>
                                        {templateList}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{ width: "36%" }}>
                                <h5>Email Emisor</h5>
                                <Row >
                                    <Form.Item name="email" rules={[{ type: 'email', message: 'Correo inválido' }]}>
                                        <Input style={{ width: "100%", marginTop: "20px" }} />
                                    </Form.Item>
                                    <Button type="primary" loading={loading} style={{ marginTop: "20px" }} htmlType="submit">Guardar</Button>
                                </Row>
                            </div>
                        </Row>
                    </Form>
                </div>
                <Input.TextArea rows={templateContent ? 10 : 3} style={{ backgroundColor: "white", color: "rgba(0,0,0,0.65)" }} value={templateID != null && templateContent != null ? templateContent[templateID] : null} disabled />
            </PanelBody>
        </Panel >
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator
    };
}

export default connect(mapStateToProps)(RemoveEquipEmailTemplate);
