import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { Tag } from 'antd';
import { endPointCreateEvent, endPointTriggerEventDataTable, endPointTriggerEventTableStruct } from "../../services/endpoints";
import EventCreateForm from "./EventCreateForm.jsx";
import { event } from '../../config/permissions';

function Event2() {
    const [tableRows, setTableRows] = useState([]);

    const endpoints = {
        columns: endPointTriggerEventTableStruct,
        rows: endPointTriggerEventDataTable,
        delete: endPointCreateEvent,
    }

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            channels: row.channels.map(channel => {
                switch (channel) {
                    case "text":
                        return <Tag color={"geekblue"} key={channel}>Mensaje de texto</Tag>
                        break;
                    case "call":
                        return <Tag color={"volcano"} key={channel}>Llamada</Tag>
                        break;
                    case "whatsapp":
                        return <Tag color={"green"} key={channel}>WhatsApp</Tag>
                        break;
                    case "email":
                        return <Tag color={"purple"} key={channel}>Correo</Tag>
                        break;
                    default:
                        break;
                }
            })
        })))
    }
    return (
        <>
            <FullTable permissions={event} modalContent={<EventCreateForm />} endpoints={endpoints} handleRows={handleRows} tableRows={tableRows} tableName='evento' />
        </>
    )
}

export default Event2;