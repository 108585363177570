import React from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import ACTIONS from '../../redux/creator';

const NotificationUI = styled.div`
	position: fixed;
	padding: 1rem;
	bottom: 1rem;
	right: 1rem;
	width: 100%;
	max-width: 400px;
	background-color: white;
	box-shadow: 0 0 5px rgba(0,0,0,0.2);
	border-radius: 4px;
	border-left: 6px solid var(--secondary-color);
	p {
		margin-bottom: 0;
		font-size: 1rem;
	}
	.close {
		position: absolute;
		top: -.5rem;
		right: .25rem;
		cursor: pointer;
		font-weight: 400;
	}
`

const Notification = ({notification, className, closeNotification}) => {
	const {type, message} = notification
	return (
		!message
		? null
		: (
			<NotificationUI className={`${className} type-${type}`}>
				<div className="row">
					<div className="col-xs-12">
						<div className="close" onClick={closeNotification}>x</div>
						<p>{message}</p>
					</div>
				</div>
			</NotificationUI>
		)
	)
};

const mapStateToProps = state => ({
	notification: state.notification
})
const mapDispatchToProps = dispatch => ({
	closeNotification() {
		dispatch(ACTIONS.closeNotification())
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)