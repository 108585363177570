import React, {Fragment} from "react";
import SearchTables from "../TypificationsTables/SearchTables";
import Webhooks from "./Webhooks";

function WebhooksSearchTables() {
    const endpoint = 'webhooks/webhook_tables/';

    return (
        <Fragment>
            <SearchTables component={<Webhooks />} endpoint={endpoint} />
        </Fragment>
    );
}

export default WebhooksSearchTables;
