import React from 'react'
import { PanelAccordion, PanelAccordionGroup, PanelAccordionItem } from './../../panel/panel-accordion';

export const GeneralTab = ({ props }) => (
	<PanelAccordion>
		<PanelAccordionGroup title="Información General">
			<PanelAccordionItem>

			</PanelAccordionItem>
		</PanelAccordionGroup>
	</PanelAccordion>
)