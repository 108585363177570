import React, { useState, useEffect } from 'react'
import {
    Select,
    Row,
    Button,
    Modal,
    Popover,
    Form,
    Input
} from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { connect } from 'react-redux';
import autoCloseModal from '../../components/modal/AutoCloseModal';
import RetentionCategories from '../../pages/retentions/RetentionCategories'

function FinanceEmails(props) {


    const [emails, setEmails] = useState([])
    const [emailOptions, setEmailOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [idCategories, setIdCategories] = useState([])
    const { Option } = Select;

    const handleIdCategori = (e) => {
        setIdCategories(
            e.map((idCat) => ({
                id: idCat.value
            }))
        )

    }

    useEffect(() => {
        getEmails()
    }, [])

    const getEmails = () => {
        let existent_emails = [];
        let initial_emails = [];
        setLoading(true)
        HttpRequest.endpoint = "escalation_finance/email/?operator=" + props.operator
        HttpRequest.get().then((res) => {
            res.forEach(email => {
                existent_emails.push(<Option key={email.email}>{email.email}</Option>)
                initial_emails.push(email.email)
            });
            setEmailOptions(existent_emails)
            setEmails(initial_emails)
            setLoading(false)
        })
    }

    const updateEmails = () => {
        setLoading(true)
        HttpRequest.endpoint = "escalation_finance/email/update_emails/"
        const formData = new FormData();
        formData.append("operator", props.operator);
        formData.append("emails", JSON.stringify(emails));
        formData.append("categories", JSON.stringify(idCategories))
        HttpRequest.post(formData).then((res) => {
            if (Array.isArray(res)) {
                autoCloseModal('Se han actualizado los tags con éxito')
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error al actualizar los correos'
                })
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <Row style={{ paddingTop: "30px" }} justify="center">
                <Select style={{ width: "60%" }} mode="tags" placeholder="Correos Finanzas" value={emails} onChange={(e) => setEmails(e)}>
                    {emailOptions}
                </Select>
                <Button loading={loading} onClick={updateEmails}>Guardar</Button>
                <p >
                    Escriba el correo y presione enter para añadirlo a la lista
                </p>
            </Row>


            <RetentionCategories handleIdCategori={handleIdCategori} />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(FinanceEmails);