import { Button, Select, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "../../components/panel/panel";
import HttpRequest from "../../services/HttpRequest";
import { connect } from "react-redux";
import { endPointSlackChannel } from "../../services/endpoints.jsx";
import "./removeEquip.css";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import store from "../../redux/store.js";

function RemoveEquipmentChannel(props) {
	const [loading, setLoading] = useState(false);
	const [channelList, setChannelList] = useState([]);
	const [channel, setChannel] = useState(null);

	const getChannel = () => {
		HttpRequest.endpoint = props.endpoint;
		var params = { operator: props.operator };
		HttpRequest.get(params).then((res) => {
			if (res.length >= 1) {
				setChannel(res[0]["channel"]);
			}
		});
	};

	const getSlackChannels = (nextCursor) => {
		HttpRequest.endpoint = endPointSlackChannel;
		const operator = store.getState().operator;
		const filters = { operator: operator };
		if (nextCursor) {
			filters.cursor = nextCursor;
		}
		HttpRequest.get(filters).then((res) => {
			if (res.ok) {
				setChannelList((prevChannels) =>
					prevChannels.concat(
						res.channels.map((chan) => (
							<Select.Option key={chan.id} value={chan.id}>
								{chan.name}
							</Select.Option>
						))
					)
				);
			}
			if (res.response_metadata && res.response_metadata.next_cursor) {
				getSlackChannels(res.response_metadata.next_cursor);
			}
		});
	};

	const submitChanges = () => {
		setLoading(true);
		const formData = new FormData();
		formData.append("channel", channel);
		formData.append("operator", props.operator);
		HttpRequest.endpoint = props.endpoint;
		HttpRequest.post(formData).then((res) => {
			if (res["ID"]) {
				autoCloseModal("Se ha actualizado el registro correctamente");
			} else {
				if (Object.keys(res).includes("Error same operator")) {
					HttpRequest.patch(
						res["Error same operator"],
						formData
					).then((res2) => {
						if (res2["ID"]) {
							autoCloseModal(
								"Se ha actualizado el registro correctamente"
							);
						} else {
							Modal.error({
								title: "Error",
								content:
									"Ha ocurrido un error al actualizar el registro",
							});
						}
					});
				} else {
					Modal.error({
						title: "Error",
						content: "Ha ocurrido un error al crear el registro",
					});
				}
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		getSlackChannels();
		getChannel();
	}, [props.operator]);

	// const submitChanges = () => {
	// 	setLoading(true)
	// 	const formData = new FormData();
	// 	formData.append("channel", channel)
	// 	formData.append("operator", props.operator)
	// 	HttpRequest.endpoint = "formerCustomers/remove_equipament_channel/"
	// 	HttpRequest.post(formData).then((res) => {
	// 		if (res["ID"]) {
	// 			autoCloseModal("Se ha actualizado el registro correctamente")
	// 		} else {
	// 			if (Object.keys(res).includes('Error same operator')) {
	// 				HttpRequest.patch(res['Error same operator'], formData).then((res2) => {
	// 					if (res2["ID"]) {
	// 						autoCloseModal("Se ha actualizado el registro correctamente")
	// 					}
	// 					else {
	// 						Modal.error({
	// 							title: "Error",
	// 							content: "Ha ocurrido un error al actualizar el registro"
	// 						})
	// 					}
	// 				})
	// 			} else {
	// 				Modal.error({
	// 					title: "Error",
	// 					content: "Ha ocurrido un error al crear el registro"
	// 				})
	// 			}
	// 		}
	// 		setLoading(false)
	// 	})
	// }

	useEffect(() => {
		getSlackChannels();
		getChannel()
	}, [props.operator])

	return (

		<Panel>
			<PanelBody>
				<h5>Seleccione el canal de Slack al que se enviará la notificación cuando se retire un equipo de red</h5>
				<div className="removeEquipTemplate">
					<Select placeholder="Seleccione el canal" labelInValue value={{ value: channel }}
						style={{ width: "100%", marginTop: "20px" }} onChange={(option) => setChannel(option.value)} showSearch optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{channelList}
					</Select>
					<Button type="primary" loading={loading} style={{ marginTop: "20px" }} onClick={submitChanges}>Guardar</Button>
				</div>
			</PanelBody>
		</Panel >
	)
}

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(RemoveEquipmentChannel);
