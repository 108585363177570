import React, { useState, useEffect } from "react";
import { Button, Input, Form } from "antd";

function EscalationUrl(props) {
	const [links, setLinks] = useState({});
	const [id, setId] = useState(1);

	const addLink = (initialVal = null) => {
		setId((id) => {
			setLinks((prevLinks) => {
				const linkCopy = { ...prevLinks };
				linkCopy[`link${id}`] = initialVal;
				return linkCopy;
			});
			return id + 1;
		});
	};
	const deleteLink = (linkKey) => {
		setLinks((prevLinks) => {
			const linkCopy = { ...prevLinks };
			delete linkCopy[linkKey];
			return linkCopy;
		});
	};

	useEffect(() => {
		if (props.initialData) {
			setLinks([]);
			props.initialData.map((url) => addLink(url));
		}
	}, [props.initialData]);

	return (
		<>
			<Button onClick={() => addLink()}>
				Agregar link de multimedia
			</Button>
			{Object.keys(links).map((link) => {
				return (
					<div key={link} className="ticket-flex">
						<Form.Item
							className="ticket-flex-input"
							name={link}
							initialValue={links[link]}
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input placeholder="Link" />
						</Form.Item>
						<Button
							type="primary"
							danger
							onClick={() => deleteLink(link)}
						>
							X
						</Button>
					</div>
				);
			})}
		</>
	);
}

export default EscalationUrl;
