import React from "react";
import TypificationsTable from "../TypificationsTables/TypificationsTables";

function IclassCustomTables() {
	const endpoints = {
		formEndpoints: [
			"iclass/service_orders/table/",
			"iclass/service_orders/table/definition/",
			"iclass/service_orders/table",
		],
		tableEndpoints: {
			columns: "iclass/service_orders/datatables_struct/",
			rows: "iclass/service_orders/datatables/",
			delete: "iclass/service_orders/table/",
		},
	};
	return (
		<TypificationsTable
			endpoints={endpoints}
			removeCreated={true}
			removeAsigned={true}
			addCreator
		/>
	);
}

export default IclassCustomTables;
