import React from 'react'
import { Avatar, Row, Col } from 'antd';
import styled from 'styled-components'
import { MailOutlined, WhatsAppOutlined, } from "@ant-design/icons";
import { useHistory } from 'react-router-dom'

export default function NotificationList({
  notifications,
  dropdown,

}) {



  let history = useHistory();

  const openNotification = (data, pk) => {
    const id = data?.conversation_ID

    if (data.type === "whatsapp") {
      history.replace(`/messages/whatsapp/${id}/${pk}`)
    } else {
      history.replace(`/messages/emails/${id}/${pk}`)
    }

  }
  return (
    <div>
      {
        notifications
          ? (

            <List width={dropdown ? '300px' : '100%'}>{
              notifications.map((item) =>
                <ItemCusttom >
                  <Row justify='center' align='middle' onClick={() => openNotification(item.data, item.pk)}>
                    <Col span={4}  >
                      <Avatar
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: item?.data?.type === "whatsapp" ? '#8ed1ab' : "#8eb3d1",
                        }}

                        icon={item?.data?.type === "whatsapp" ? <WhatsAppOutlined /> : <MailOutlined />}
                      />
                    </Col>
                    <Col span={18}>
                      {item?.message}
                    </Col>
                  </Row>
                </ItemCusttom>
              )
            }</List>
          ) : (<></>)
      }

    </div>
  )
}

const List = styled.div`
  width: ${({ width }) => width || '100%'};
  padding: 5px;
`

const ItemCusttom = styled.div`
    width: 100%;
		margin: 15px auto ;
    font-size: 15px;
    cursor: pointer;
    :hover{
        background-color: #f4f4f4;
    }
`

