import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { qc } from '../../config/permissions';
import InspeccionesForm from './InspeccionesForm';

function InspeccionesTable(props) {
    let endpoints = {
        columns: 'qc/inspection/' + props.tableID + "/datatables_struct/",
        rows: 'qc/inspection/' + props.tableID + "/datatables/",
        delete: 'qc/inspection/',
        download: 'qc/inspection/' + props.tableID + '/download_data/',
    }

    return (
        <>
            <FullTable permissions={qc.inspection} modalWidth={1100} modalContent={<InspeccionesForm />} endpoints={endpoints} tableName='inspección' />
        </>
    )
}

export default InspeccionesTable;