import React from "react";
import Moment from "react-moment";
import "moment/locale/es";

//Declare const to variables
export const column = [
	{
		title: "SLA",
		dataIndex: "sla",
		key: "sla",
		sortDirections: ["descend"],
		sorter: (a, b) => a.sla.length - b.sla.length,
		render: (text) => (
			<Moment fromNow locale="es">
				{text}
			</Moment>
		),
	},
	{
		title: "Fecha",
		dataIndex: "date",
		key: "date",
		sorter: true,
		render: (text) => <Moment format="DD/MM/YYYY">{text}</Moment>,
	},
	{
		title: "ID",
		dataIndex: "ID",
		key: "ID",
		sorter: (a, b) => a.ID - b.ID,
		sortDirections: ["ascend"],
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		render: (text) => <a href="#">{text}</a>,
	},
	{
		title: "Categoría",
		dataIndex: "category",
		key: "category",
		sorter: (a, b) => a.category.length - b.category.length,
	},
	{
		title: "Cliente",
		dataIndex: "client",
		key: "client",
	},
	{
		title: "Servicios",
		dataIndex: "service",
		key: "service",
	},
	{
		title: "Liveagent",
		dataIndex: "liveagent",
		key: "liveagent",
	},
	{
		title: "Canal",
		dataIndex: "channel",
		key: "channel",
	},
	{
		title: "Tipo",
		dataIndex: "type",
		key: "type",
	},
	{
		title: "Estado",
		dataIndex: "stated",
		key: "stated",
	},
	{
		title: "Urgencía",
		dataIndex: "priority",
		key: "priority",
	},
	{
		title: "Ciudad",
		dataIndex: "city",
		key: "city",

		responsive: ["md"],
	},
	{
		title: "Gestión",
		dataIndex: "management",
		key: "management",

		responsive: ["md"],
	},
];
