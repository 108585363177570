import React, { useState } from 'react';
import { Form, Button, Input, Select, Modal, Timeline } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { can, validOperator } from '../../helpers/helpers';
import Moment from "react-moment";
import { WhatsAppOutlined, MailOutlined, PhoneOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { contact } from '../../config/permissions';

function ContactForm(props) {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [communications, setCommunications] = useState(null);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState({})

    const getUsers = () => {
        HttpRequest.endpoint = 'user/data/information/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                const userList = {};
                res.forEach(user => {
                    userList[user.ID] = user.name
                })
                setUsers(userList)
                getContactHistory(userList)
            }
        })
    }

    const submitForm = e => {
        if (can(contact.create)) {
            if (validOperator()) {
                setButtonLoading(true);
                HttpRequest.endpoint = 'customers/leadlet_contact/';
                const formData = new FormData();
                Object.keys(e).forEach(key => {
                    if (e[key] !== undefined) {
                        formData.append(key, e[key])
                    }
                })
                formData.append('leadted_customer', props.id);
                HttpRequest.post(formData).then(res => {
                    setButtonLoading(false);
                    if (res.ID) {
                        Modal.success({
                            title: 'Éxito',
                            content: 'Se ha creado el contacto con éxito'
                        })
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al crear el contacto'
                        })
                    }
                })
            }
        }
    }
    const getContactHistory = (userList) => {
        if (can(contact.view)) {
            HttpRequest.endpoint = 'customers/leadlet_contact/?leadted_customer=' + props.id;
            HttpRequest.get().then(res => {
                if (res.length > 0) {
                    const messages = []
                    res.forEach(msg => {
                        messages.push(
                            <Timeline.Item
                                dot={msg.contact_type === 3 ? <MailOutlined className='timeline-icon' /> : msg.contact_type === 2 ? <PhoneOutlined className='timeline-icon' /> : msg.contact_type === 1 ? <WhatsAppOutlined className='timeline-icon' /> : msg.contact_type === 4 ? <FacebookOutlined className='timeline-icon' /> : <InstagramOutlined className='timeline-icon' />}
                                key={msg.ID}
                            >
                                <strong>{userList[msg.creator]} {<Moment format='DD/MM/YYYY' locale='es'>{msg.created}</Moment>}: </strong>{msg.message}.
                            </Timeline.Item>
                        )
                    })
                    setCommunications(messages);
                } else {
                    setError('Este cliente no posee comunicaciones previas.')
                }
            })
        }
    }

    return (
        <>
            {!communications &&
                <>
                    <Form name='contact-form' onFinish={submitForm}>
                        <Form.Item name='message' label='Mensaje' rules={[{ required: true, whitespace: true, message: 'Este campo es requerido' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='contact_type' label='Canal' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select>
                                <Select.Option value={1}>WhatsApp</Select.Option>
                                <Select.Option value={2}>Teléfono</Select.Option>
                                <Select.Option value={3}>Correo</Select.Option>
                                <Select.Option value={4}>Facebook</Select.Option>
                                <Select.Option value={5}>Instagram</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button htmlType='submit' type='primary' loading={buttonLoading} block size='large' >Crear</Button>
                    </Form>
                    <Button block size='large' className='center-btn' onClick={getUsers}>Ver historial de contacto</Button>
                </>
            }
            {communications && users && !error ?
                <Timeline mode='alternate'>
                    {communications}
                </Timeline>
                : error
            }

        </>
    )
}

export default ContactForm