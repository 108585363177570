import React, { useState, useEffect, useRef } from 'react'
import 'react-chat-elements/dist/main.css';
import { MessageBox } from 'react-chat-elements';
import HttpRequest from '../../services/HttpRequest';
import { Button, Col, Image, Input, Modal, Popover, Row, Select, Upload, Carousel } from 'antd';
import { UserOutlined, UserSwitchOutlined, SendOutlined, PaperClipOutlined, HighlightOutlined, SnippetsOutlined, EditOutlined } from '@ant-design/icons';
import CustomerInfoModal from '../removeEquipment/customerInfoModal';
import ServiceInfoModal from '../removeEquipment/serviceInfoModal';
import Typify from "../typify/typify";
import CustomerSelector from './CustomerSelector';
import TypifyComments from '../typify/typifyComments';
import { Link } from 'react-router-dom';
import { NIL } from 'uuid';
import { connect } from 'react-redux';
import TemplateSelector from './TemplateSelector';
import TagView from './TagView';
import styled from 'styled-components';


function Conversation(props) {

    const [messages, setMessages] = useState([])
    const { Option } = Select;
    const [agentList, setAgentList] = useState([])
    const [message, setMessage] = useState("")
    const [refresh, setRefresh] = useState(false)
    const [agent, setAgent] = useState()
    const [template, setTemplate] = useState(null)
    const [rut, setRut] = useState(null)
    const [serviceModal, setServiceModal] = useState(false)
    const [customerModal, setCustomerModal] = useState(false)
    const [customerSelector, setCustomerSelector] = useState(false)
    const [commentModal, setCommentModal] = useState(false)
    const [commentId, setCommentId] = useState(null)
    const [showInput, setShowInput] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [templateSelector, setTemplateSelector] = useState(false)
    const [subject, setSubject] = useState(null)
    const [templateMSG, setTemplateMSG] = useState(null)
    const [service, setService] = useState(null)
    const [tags, setTags] = useState([])
    const divRef = useRef(null)
    const [ticketModal, setTicketModal] = useState(false);
    const [channel, setChannel] = useState(1);
    const [typifyId, setTypifyId] = useState(null);
    const [imagenesList, setImagenesList] = useState([])

    const getMessages = () => {
        HttpRequest.endpoint = props.messagesEndpoint + props.id + "/get_messages/"
        HttpRequest.get().then((res) => {

            setMessages(res)
        })
    }
    const updateAgent = (value) => {
        HttpRequest.endpoint = "communications/conversation/"
        const formData = new FormData();
        formData.append("agent", value)
        setAgent(value)
        const user = JSON.parse(localStorage.getItem("userInfo"));
        let admin = user.groups.includes("Administrador Comunicaciones")
        HttpRequest.patch(props.id, formData).then((res) => {
            if (res.agent_username == JSON.parse(localStorage.getItem("userInfo")).username) {
                setShowInput(true)
            } else {
                setShowInput(false)
            }
        })
    }

    const getConversationData = () => {
        HttpRequest.endpoint = "communications/conversation/" + props.id + "/"
        HttpRequest.get().then((res) => {
            if (res.agent != null) setAgent(res.agent)
            if (res.services != null) {
                getRut(res.services)
                setService(res.services[0])
            }
            setChannel(res.channel)
            setTypifyId(res.typify);
            setCommentId(res.chat)
            setTags(res.tags)
            if (res.agent_username == JSON.parse(localStorage.getItem("userInfo")).username && res.status == 0) {
                setShowInput(true)
            }
            setCustomer(res.customer)
        })
    }

    const getRut = (service) => {
        HttpRequest.endpoint = "matrix/customer_filter/?criterion=service&search=" + service[0]
        HttpRequest.get().then((res) => {
            if (res.length > 0) setRut(res[0].id)
        })
    }

    const getAgents = () => {
        HttpRequest.endpoint = "user/data/information/"
        HttpRequest.get().then((res) => {
            let agents = []
            res.forEach(user => {
                agents.push(<Option key={user.ID} value={user.ID}>{user.name}</Option>);
            });
            setAgentList(agents)
        })
    }

    const sendTemplate = (template_html) => {
        const formData = new FormData();
        formData.append("kind", 1)
        formData.append("conversation", props.id)
        formData.append("message", message)
        formData.append("receiver", customer)
        formData.append("template", template_html)
        formData.append("service", service)
        HttpRequest.endpoint = props.messagesEndpoint
        HttpRequest.post(formData).then((res) => {
            setRefresh(!refresh)
            setMessage("")
            setSubject("")
        })
    }

    const sendTextMessage = () => {
        if (message.length > 0) {
            const formData = new FormData();
            formData.append("kind", 1)
            formData.append("conversation", props.id)
            formData.append("message", message)
            formData.append("receiver", customer)
            formData.append("subject", subject)
            HttpRequest.endpoint = props.messagesEndpoint
            HttpRequest.post(formData).then((res) => {
                setRefresh(!refresh)
                setMessage("")
                setSubject("")
            })
        }
    }

    const uploadPhoto = ({ file, onSuccess }) => {
        console.log(file, onSuccess)
        const formData = new FormData();
        formData.append("kind", 1)
        formData.append("conversation", props.id)
        formData.append("message", message)
        HttpRequest.endpoint = "communications/whatsapp_message/"
        formData.append('image', file)
        formData.append("receiver", customer)
        formData.append("message", message ?? " ")
        HttpRequest.post(formData).then(res => {
            console.log(res, 'sss')
            if (res && res.ID) {
                onSuccess("ok")
                setRefresh(!refresh)
                setMessage("")
                setSubject("")
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error enviando el archivo'
                })
            }
        })
    }

    const createConversation = () => {
        HttpRequest.endpoint = "communications/conversation/"
        const formData = new FormData();
        formData.append("channel", props.type == "email" ? 0 : 4)
        if (props.typifyInfo != null) {
            formData.append("typify", props.typifyInfo["ID"])
            formData.append("customer", props.typifyInfo["type"] == 3 ? props.typifyInfo["customer"] : props.typifyInfo["customer"][0])
            formData.append("services", props.typifyInfo["services"])
            formData.append("chat", props.typifyInfo["chat"])
        }
        const user = JSON.parse(localStorage.getItem('userInfo'))
        formData.append("agent", user.ID)
        HttpRequest.post(formData).then((res) => {
            getConversationData();
            HttpRequest.endpoint = "tickets/typify/";
            const formData = new FormData();
            formData.append(props.type == "email" ? "conversation_email" : "conversation_whatsapp", res["ID"])
            HttpRequest.patch(props.typifyInfo["ID"], formData).then((res) => console.log(res))
        })
    }

    function InsertInframe(template) {
        return (
            <>
                <InframeessageBox srcDoc={template} />

            </>
        )
    }



    useEffect(() => {
        getAgents()
        getConversationData()
    }, [])

    useEffect(() => {
        if (messages.length > 0) {
            setTimeout(() => {
                if (divRef.current) {
                    divRef.current.scrollIntoView({ behavior: 'smooth' });
                }

            }, 5000);
        }
    }, [messages])

    useEffect(() => {
        getMessages()
    }, [props.notifications])

    useEffect(() => {
        console.log("ACA", props.id, props.id != null)
        if (props.id != null) {
            getMessages()
        }

    }, [refresh, props.notifications])


    console.log(imagenesList, 'img list');


    return (

        <div style={{ paddingTop: "10px" }}>
            <Modal
                width={900}
                footer={null}
                visible={template != null}
                onCancel={() => {
                    setTemplate(null)
                    setImagenesList([])
                }}
            >
                <iframe width="850" height="350" srcDoc={template}></iframe>


                <ContainerCarousel >
                    <ul>
                        {imagenesList.map(url => <li><img alt='Error cargando' key={url} src={url} /></li>)}
                    </ul>
                </ContainerCarousel>



            </Modal>
            <TemplateSelector
                visible={templateSelector}
                setVisible={setTemplateSelector}
                sendTemplate={sendTemplate}
            />
            <CustomerInfoModal
                setVisible={setCustomerModal}
                rut={rut}
                visible={customerModal}
            />
            <ServiceInfoModal
                setVisible={setServiceModal}
                rut={rut}
                visible={serviceModal}
            />
            <Modal
                width={900}
                footer={null}
                visible={customerSelector}
                onCancel={() => setCustomerSelector(false)}
            >
                <CustomerSelector id={props.id} setCustomerSelector={() => setCustomerSelector(false)} />
            </Modal>
            <Modal
                width={1050}
                footer={null}
                visible={ticketModal}
                onCancel={() => setTicketModal(false)}
            >
                <Typify conversationChannel={channel} id={typifyId} refreshConversation={getConversationData} hideTabs={true} hideSmallButtons={true} hideButtons={typifyId != null} conversationID={props.id} closeModal={() => setTicketModal(false)} />
            </Modal>
            <Modal title="Comentarios" visible={commentModal} onCancel={() => setCommentModal(false)} footer={null}>
                <TypifyComments id={commentId} />
            </Modal>
            <div style={{ backgroundColor: "#4991a3", height: "50px", padding: "8px" }}>
                <Row align='middle'>
                    <Col flex={5} style={{ marginLeft: "15px" }}>
                        {rut != null && props.hidecustomer == null && <Button onClick={() => setCustomerModal(true)}>Cliente</Button>}
                        {rut != null && props.hidecustomer == null && <Button onClick={() => setServiceModal(true)}>Servicio</Button>}
                        {rut == null && props.hidecustomer == null && <Button icon={<UserOutlined style={{ fontSize: "20px" }} />} onClick={() => setCustomerSelector(true)} >Asignar Cliente</Button>}
                        <Button style={{ marginLeft: "8px" }} onClick={() => setCommentModal(true)}>Comentarios</Button>
                    </Col>
                    <Col flex={1}>
                        <Row justify="space-around">
                            {
                                !props.ticket
                                && (<>
                                    <UserSwitchOutlined style={{ fontSize: "30px", color: "white" }} />
                                    <Select value={agent} onChange={updateAgent} style={{ minWidth: "150px" }}>
                                        {agentList}
                                    </Select>
                                    <HighlightOutlined style={{ fontSize: "30px", color: "white" }} />
                                    <Select defaultValue={0} >
                                        <Option value={0}>Abierto</Option>
                                        <Option value={1}>Cerrado</Option>
                                        <Option value={2}>Pendiente</Option>
                                    </Select>
                                    <EditOutlined style={{ fontSize: "30px", color: "white" }} />
                                </>)
                            }

                            <Popover placement='rightBottom' content={<TagView tags={tags} id={props.id} />} title={null} trigger="click">
                                <Button>Tags</Button>
                            </Popover>
                            {props.hideTypify == null && <Button onClick={() => setTicketModal(true)}> {typifyId != null ? "Ver ticket" : "Crear ticket"}</Button>}
                        </Row>
                    </Col>
                </Row>
            </div>
            <div style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "10px", height: "400px", overflowY: "scroll" }}>
                {messages.length > 0 &&


                    messages.map((message) =>
                        <div>

                            {
                                message.kind === 1 &&
                                <div key={message.ID} style={{ display: "flex", justifyContent: "end", paddingRight: "20px", paddingTop: "10px" }}>
                                    <h6 style={{ marginBottom: 0, color: "#7d7d7d" }}>
                                        {message.creator}
                                    </h6>
                                </div>
                            }

                            {
                                !message.is_html ? (
                                    message.media_url.length == 0
                                        ? (
                                            <div key={message.ID}
                                                onClick={() => {
                                                    props.type === "email" &&
                                                        setTemplate(message.message)
                                                    setImagenesList(message.media_url)
                                                }}>
                                                <CusttomMessageBox
                                                    position={message.kind == 1 ? 'right' : 'left'}
                                                    type={message.media_url.length != 0
                                                        ? (
                                                            message.media_url[0].endsWith("jpg") || message.media_url[0].endsWith("jpeg") || message.media_url[0].endsWith("png") || message.media_url[0].includes("messagebird.com")
                                                                ? ('photo')
                                                                : ('file')
                                                        )
                                                        : ('text')}
                                                    title={props.type == "email" ? message.subject : null}
                                                    text={message.message}
                                                    status={message.kind == "1" ? message.status == 0 ? 'sent' : message.status == 1 ? 'read' : 'waiting' : null}
                                                    data={{
                                                        uri: message.media_url[0],
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            !message.media_url[0].endsWith("jpg") || message.media_url[0].endsWith("jpeg") || message.media_url[0].endsWith("png") || message.media_url[0].includes("messagebird.com")
                                            && (
                                                <div key={message.ID} style={{ display: "flex", justifyContent: message.kind == 1 ? "end" : "start", paddingRight: "20px", paddingTop: "10px" }}>
                                                    <div style={{ backgroundColor: "#95bfc9", borderRadius: "10px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "10px", paddingBottom: "10px" }}>
                                                        <Link style={{ color: "white" }} to={message.media_url[0]} target="_blank" download>{message.media_url[0].split('messagebird_files/')[1] ?? "Click para descargar"}</Link>
                                                    </div>
                                                </div>
                                            )

                                        )
                                ) : (
                                    <div key={message.ID}
                                        onClick={() => {
                                            props.type === "email" &&
                                                setTemplate(message.message)
                                            setImagenesList(message.media_url)
                                        }} >
                                        <CusttomMessageBox
                                            position={message.kind == 1 ? 'right' : 'left'}
                                            type={message.media_url.length != 0
                                                ? (
                                                    message.media_url[0].endsWith("jpg") || message.media_url[0].endsWith("jpeg") || message.media_url[0].endsWith("png") || message.media_url[0].includes("messagebird.com")
                                                        ? ('photo')
                                                        : ('file')
                                                )
                                                : ('text')
                                            }
                                            text={message.media_url.length < 0 ? message.message : InsertInframe(message.message)}
                                            title={message.subject}
                                            data={{
                                                uri: message.media_url[0],
                                            }}


                                        />


                                    </div>
                                )
                            }

                        </div>
                    )
                }
                <div ref={divRef} />
            </div>
            {
                showInput &&
                <>
                    {props.type == "email" ? (
                        <Row style={{ paddingTop: "10px", display: "flex", justifyContent: "end" }}>
                            <Input placeholder='Asunto' onChange={(input) => setSubject(input.target.value)} value={subject} />
                            <Input placeholder='Mensaje' onChange={(input) => setMessage(input.target.value)} value={message} />
                            <Button icon={<SnippetsOutlined size={50} />} onClick={() => setTemplateSelector(true)} > Plantilla </Button>
                            <Upload customRequest={uploadPhoto} >
                                <Button icon={<PaperClipOutlined size={50} />} > Adjuntar </Button>
                            </Upload>
                            <Button icon={<SendOutlined size={50} />} onClick={sendTextMessage} > Enviar </Button>
                        </Row>
                    ) : (
                        <Row style={{ paddingTop: "10px" }}>
                            <Input style={{ width: "90%" }} onChange={(input) => setMessage(input.target.value)} value={message} />
                            <Button icon={<SendOutlined size={50} />} onClick={sendTextMessage} />
                            <Upload customRequest={uploadPhoto} >
                                <Button icon={<PaperClipOutlined size={50} />} />
                            </Upload>
                        </Row>
                    )}
                </>
            }
            {
                props.ticket && props.id == null &&
                <>
                    <Button onClick={createConversation}>Iniciar Conversación</Button>
                </>
            }
        </div >
    )

}

const InframeessageBox = styled.iframe`
    width: 100%;
    height: auto;
`

const CusttomMessageBox = styled(MessageBox)`
    img{
        max-width: 300px;
    }
`



const ContainerCarousel = styled.div`
    display: flex;
    padding: 10px;
    justify-content: center;
    

    ul{
        flex-direction: row;
        list-style: none;
        display: flex;
        width: 90%;
        overflow: auto;
        padding: 0;
    }

    li{
        margin: 10px ;
    }

    img{
        max-width: 300px;
        max-height: 200px
    }
`





function mapStateToProps(state) {
    return {
        notifications: state.notifications
    };
}

export default connect(mapStateToProps)(Conversation);