import React, { useState, useEffect, useRef } from 'react';
import { Select, Form, Spin } from 'antd';
import HttpRequest from '../../services/HttpRequest';

function SolutionResultField(props) {
    const [categories, setCategories] = useState({
        category: [],
        subcategory: [],
    });
    const formRef = useRef()
    const [showField, setShowField] = useState(false);

    const getChildCategories = (classification, parent) => {
        HttpRequest.endpoint = 'escalation_ti/problems/';
        HttpRequest.get({ operator: props.operator, parent: parent }).then(res => {
            const categories = [];
            res.forEach(cat => {
                categories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                [classification]: categories
            }));
        })
    }
    const getRecursiveCategories = id => {
        HttpRequest.endpoint = 'escalation_ti/problems/' + id + "/";
        HttpRequest.get().then((res) => {
            switch (res["classification"]) {
                case 3:
                    getChildCategories("subcategory", res["parent"])
                    formRef.current.setFieldsValue({
                        subcategory: res["ID"]
                    })
                    getRecursiveCategories(res["parent"])
                    break;
                case 2:
                    getChildCategories("category", res["parent"])
                    formRef.current.setFieldsValue({
                        category: res["ID"]
                    })
                    getRecursiveCategories(res["parent"])
                    break;
                case 1:
                    formRef.current.setFieldsValue({
                        type: res["ID"]
                    })
                    setShowField(true)
                    break;
                default:
                    break;
            }
        })
    }
    useEffect(() => {
        if (props.initial) {
            getRecursiveCategories(props.initial)
            /*props.addProblem({
                id: props.initial,
                index: props.index
            })*/
        } else {
            setShowField(true);
        }
    }, [])

    return (
        <>
            <Form className='problem-category-field' name='problem' ref={formRef}>
                {showField ?
                    <>
                        <Form.Item name='type' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select className='problem-field' disabled={props.disabled} placeholder='Seleccionar tipo' onChange={(e) => { getChildCategories('category', e); formRef.current.setFieldsValue({ category: null, subcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {props.typeCategories}
                            </Select>
                        </Form.Item>
                        <Form.Item name='category' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select className='problem-field' disabled={props.disabled} placeholder='Seleccionar categoría' onChange={(e) => { getChildCategories('subcategory', e); formRef.current.setFieldsValue({ subcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {categories.category}
                            </Select>
                        </Form.Item>
                        <Form.Item name='subcategory' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select className='problem-field' disabled={props.disabled} placeholder='Seleccionar subcategoría' onChange={e => props.addProblem({
                                id: e,
                                index: props.index
                            })} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {categories.subcategory}
                            </Select>
                        </Form.Item>
                    </> : <Spin />}
            </Form>
        </>
    )
}

export default SolutionResultField;