import React, { useState, useEffect } from 'react';
import { Form, Button, Input, Spin, Row, Select, Checkbox } from 'antd';
import HttpRequest from './../../services/HttpRequest';
import autoCloseModal from './../../components/modal/AutoCloseModal';
import errorModal from './../../components/modal/errorModal';
import Moment from "react-moment"
import moment from 'moment';
import { qc } from '../../config/permissions';
import { can } from '../../helpers/helpers';

function MonitoreoForm(props) {
    const [initialValues, setInitialValues] = useState({});
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const submit = e => {
        setButtonLoading(true);
        HttpRequest.endpoint = 'qc/monitoring/';
        const formData = new FormData();
        formData.append('name', e.name);
        props.id && formData.append('status', e.status);
        if (props.id) {
            if (!can(qc.monitoreo.edit)) {
                return;
            }
            HttpRequest.patch(props.id, formData).then(res => {
                if (res && res.ID) {
                    autoCloseModal('Se ha editado el monitoreo exploratorio con éxito')
                    props.closeModal()
                } else {
                    setButtonLoading(false);
                    errorModal('Ha ocurrido un error editando el monitoreo exploratorio')
                }
            })
        } else {
            if (!can(qc.monitoreo.create)) {
                return;
            }
            HttpRequest.post(formData).then(res => {
                if (res && res.ID) {
                    autoCloseModal('Se ha creado un monitoreo exploratorio con éxito')
                    props.closeModal()
                } else {
                    errorModal('Ha ocurrido un error creando un monitoreo exploratorio')
                }
            })
        }
    }

    useEffect(() => {
        if (props.id) {
            HttpRequest.endpoint = 'qc/monitoring';
            HttpRequest.get(null, props.id).then(res => {
                if (res && res.ID) {
                    setInitialValues(res)
                    setLoading(false);
                } else {
                    errorModal('Ha ocurrido un error obteniendo la data de este monitoreo')
                }
            })
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <>
            {!loading ?
                <>
                    <Form onFinish={submit} initialValues={initialValues}>
                        {props.id && <p><strong>SLA: </strong>{<Moment fromNow ago locale='en'>{moment().add(-1 * initialValues.sla, 's')}</Moment>}</p>}
                        <Form.Item name='name' label='Nombre' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Input placeholder='Nombre' />
                        </Form.Item>
                        {props.id && <Form.Item name='status' label='Estado'>
                            <Select placeholder='Estado'>
                                <Select.Option value={0}>Abierto</Select.Option>
                                <Select.Option value={1}>Cerrado</Select.Option>
                            </Select>
                        </Form.Item>}
                        <Button block type='primary' htmlType='submit' loading={buttonLoading}>{props.id ? 'Guardar' : 'Crear'}</Button>
                    </Form>
                </>

                :
                <Spin size='large' className='center-spin' />
            }
        </>
    )
}

export default MonitoreoForm;