import React, { useState, useEffect } from "react";
import HttpRequest from "./../../services/HttpRequest";
import { Button, Input, Form, Select, Row } from "antd";
import errorModal from "./../../components/modal/errorModal";
import autoCloseModal from "./../../components/modal/AutoCloseModal";

function EmailForm(props) {
	const [templates, setTemplates] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [answer, setAnswer] = useState(null);

	const submit = (values) => {
		setButtonLoading(true);
		HttpRequest.endpoint =
			answer === "template"
				? `communications/email/send_email/`
				: `communications/email/`;
		const formData = new FormData();
		answer === "template" &&
			formData.append("service_number", values.service);
		answer === "template" && formData.append("template", values.template);
		answer === "normal" && formData.append("message", values.text);
		formData.append("sender", values.sender);
		formData.append("receiver", values.receiver);
		formData.append("subject", values.subject);
		HttpRequest.post(formData).then((res) => {
			if (res && (res.ID || res.success === "Email sent")) {
				autoCloseModal("Se ha enviado el email con éxito");
				props.closeModal();
			} else {
				errorModal("Ha ocurrido un error enviando el email");
				setButtonLoading(false);
			}
		});
	};

	useEffect(() => {
		HttpRequest.endpoint = `communications/template_email/`;
		HttpRequest.get().then((res) => {
			if (res && Array.isArray(res)) {
				setTemplates(res);
			}
		});
	}, []);

	return (
		<>
			<h1 className="center-text">Nuevo email</h1>
			<h5 className="center-text">
				Seleccione como desea enviar el email
			</h5>
			<Row justify="center">
				<Button onClick={() => setAnswer("normal")}>
					Directamente
				</Button>
				<Button onClick={() => setAnswer("template")}>
					Con plantilla
				</Button>
			</Row>
			<Form onFinish={submit}>
				<Form.Item
					name="sender"
					label="Emisor"
					rules={[
						{ required: true, message: "Este campo es requerido" },
						{ type: "email", message: "Formato de email inválido" },
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="receiver"
					label="Recipiente"
					rules={[
						{ required: true, message: "Este campo es requerido" },
						{ type: "email", message: "Formato de email inválido" },
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="subject"
					label="Asunto"
					rules={[
						{ required: true, message: "Este campo es requerido" },
					]}
				>
					<Input />
				</Form.Item>
				{answer === "normal" && (
					<Form.Item
						name="text"
						label="Contenido"
						rules={[
							{
								required: true,
								message: "Este campo es requerido",
							},
						]}
					>
						<Input.TextArea />
					</Form.Item>
				)}
				{answer === "template" && (
					<>
						<Form.Item
							name="template"
							label="Plantilla"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Select>
								{templates.map((temp) => (
									<Select.Option
										key={temp.ID}
										value={temp.ID}
									>
										{temp.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="service"
							label="Servicio"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input />
						</Form.Item>
					</>
				)}
				<Button
					disabled={!answer}
					loading={buttonLoading}
					htmlType="submit"
					type="primary"
					className="center-btn"
				>
					Enviar
				</Button>
			</Form>
		</>
	);
}

export default EmailForm;
