import React from 'react';
// import { removeEquipmentTables } from '../../config/permissions';
import TypificationsTable from '../TypificationsTables/TypificationsTables';

function LeadCustomTables() {
    const endpoints = {
        formEndpoints: ['customers/lead_tables/', 'customers/lead_tables/definition/', 'customers/lead_tables'],
        tableEndpoints: {
            columns: "customers/lead_tables/datatables_struct/",
            rows: "customers/lead_tables/datatables/",
            delete: "customers/lead_tables/",
        }
    }
    return (
        <TypificationsTable
            endpoints={endpoints}
            // permissions={removeEquipmentTables}
            removeCreated={true}
            removeAsigned={true}
        />
    )
}

export default LeadCustomTables;