import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import BandaAnchaPNG from './../../../assets/images/bandaancha.png'

class DropdownNotification extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
  
	render() {
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown" tag="li">
				<DropdownToggle className="f-s-14" tag="a">
					<i className="fa fa-bell"></i>
					<span className="label">1</span>
				</DropdownToggle>
				<DropdownMenu className="media-list dropdown-menu-right" tag="ul">
					<DropdownItem className="dropdown-header" tag="li" header>Notificaciones (1)</DropdownItem>
					<DropdownItem className="media">
						<div className="media-left">
							<img src={BandaAnchaPNG} className="media-object" alt="Banda Ancha" />
						</div>
						<div className="media-body">
							<h6 className="media-heading">Sistema</h6>
							<div className="text-muted f-s-11">El dispositivo XYZ presenta fallas.</div>
						</div>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default DropdownNotification;
