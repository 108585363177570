import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { typifications } from '../../config/permissions';
import CanalesForm from './CanalesForm';

function IndicadoresTable() {
    const endpoints = {
        delete: 'qc/qa_channel/',
        columns: 'qc/qa_channel/datatables_struct/',
        rows: 'qc/qa_channel/datatables/',
        download: 'qc/qa_channel/download_data/',
    };

    return (
        <>
            <FullTable permissions={typifications} modalContent={<CanalesForm />} endpoints={endpoints} tableName='canal' />
        </>
    )
}

export default IndicadoresTable;