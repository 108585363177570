import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import HttpRequest from "../../../services/HttpRequest";
import { Alert } from 'antd';


const FormCreateWebhookTable = (props) => {
  const [form] = Form.useForm();
  const indInit = {
    text: "Registrar tabla",
    loading: false
  };
  const alertInit = {
    show: false,
    type: '',
    message: ''
  };
  const [ind, setInd] = useState(indInit);
  const [alert, setAlert] = useState(alertInit);
  const [initValues, setInitValues] = useState({
    name: "",
    hooktitle: ""
  });

  useEffect(()=>{
    if (props.id) {
      setInd({ loading: true, text: 'Obteniendo datos' });
      HttpRequest.endpoint = "webhooks/webhook_tables";
      HttpRequest.get(null, props.id)
      .then((res)=> {
      setInd({ loading: false, text: 'Editar tabla' });
        if (res.ID) {
          form.setFieldsValue({
            name: res.name,
            hooktitle: res.hook_title
          })
        } else {
          setAlert({
            show: true,
            type: "error",
            message: "Ha ocurrido un error mientras se obtenian los datos del registro"
          });
        }
      })
    }
  }, []);

  const onFinish = (values) => {
    setInd({ loading: true, text: `${props.id ? 'Editando' : 'Registrando'} tabla` });

    let table = new FormData();
    table.append("name", values.name);
    table.append("hook_title", values.hooktitle);
    HttpRequest.endpoint = "webhooks/webhook_tables/";
    //Create
    if (props.id === undefined) {
      HttpRequest.post(table)
      .then(res => {
        setInd(indInit);
        if (res.ID) {
          form.resetFields();
          setAlert({
            show: true,
            type: "info",
            message: "¡Tabla registrada con exito!"
          });
        } else {
          setAlert({
            show: true,
            type: "error",
            message: "Ha ocurrido un error mientras se registraba la tabla"
          });
        }
      });
    } 
    // Update
    if (props.id) {
      HttpRequest.patch(props.id, table)
      .then(res => {
        setInd(indInit);
        if (res.ID) {
          props.closeModal();
        } else {
          setAlert({
            show: true,
            type: "error",
            message: "Ha ocurrido un error mientras se editaba el registro"
          });
        }
      });
    }
  };

  const onCloseAlert = (e) => {
    setAlert(alertInit);
  };

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      setfieldsvalue={initValues}
      onFinish={onFinish}>
      <Form.Item
        label="Nombre"
        name="name"
        rules={[{ required: true, message: 'El nombre de la tabla es requerido' }]}>
        <Input />
      </Form.Item> 
      <Form.Item
        label="Título del hook"
        name="hooktitle"
        rules={[{ required: true, message: 'El título del hook es requerido' }]}>
        <Input />
      </Form.Item> 

      {alert.show &&
        <Form.Item wrapperCol={{ offset: 8 }}>
          <Alert
            description={alert.message}
            onClose={onCloseAlert}
            type={alert.type}
            closable
          />
        </Form.Item>
      }
      <Form.Item wrapperCol={{ offset: 8 }}>
          <div className="site-button-ghost-wrapper">
              <Button type="primary" htmlType="submit" loading={ind.loading}>
                  {ind.text}
              </Button>
          </div>
      </Form.Item>
    </Form>
  );
};

export default FormCreateWebhookTable;





