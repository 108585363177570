import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { escalationSolutions } from '../../config/permissions';
import EscalationConfigForm from './EscalationConfigForm';

function EscalationResults() {
    const endpoints = {
        rows: 'escalation_ti/solution/datatables/',
        columns: 'escalation_ti/solution/datatables_struct/',
        delete: 'escalation_ti/solution/'
    }
    const formEndpoint = 'escalation_ti/solution';
    return (
        <>
            <FullTable
                modalContent={<EscalationConfigForm permissions={escalationSolutions} endpoint={formEndpoint} />}
                endpoints={endpoints}
                tableName='solución'
                permissions={escalationSolutions}
            />
        </>
    )
}

export default EscalationResults;