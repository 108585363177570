import React, { useState, useEffect } from "react";
import ServiceOrder from "./ServiceOrder";
import SOTimePicker from "./SOTimePicker";
import HttpRequest from "./../../services/HttpRequest";
import moment from "moment";
import errorModal from "./../../components/modal/errorModal";

function SOWizard(props) {
	const [step, setStep] = useState(1);
	const [info, setInfo] = useState(null);

	const steps = {
		1: <ServiceOrder setStep={setStep} setInfo={setInfo} />,
		2: (
			<SOTimePicker
				info={info}
				id={props.id}
				closeModal={props.closeModal}
			/>
		),
	};

	const searchOsTypes = (address, selectedSO) => {
		HttpRequest.endpoint = "iclass/service_orders/pulso_os/";
		HttpRequest.get().then((res) => {
			if (res && typeof res === "object") {
				const selectedOsIndex = Object.keys(res).indexOf(selectedSO);
				setInfo(
					Object.assign(
						{ osTypes: res },
						{ selectedOsIndex },
						{ location: address }
					)
				);
				setStep(2);
			}
		});
	};

	useEffect(() => {
		if (props.id) {
			HttpRequest.endpoint = "iclass/service_orders";
			HttpRequest.get(null, props.id).then((res) => {
				if (res && res.street_location && res.kind) {
					if (!moment().isAfter(res.end_date)) {
						searchOsTypes(res.street_location, res.kind);
					} else {
						errorModal(
							"Esta orden de servicio ya pasó y por lo tanto no puede ser editada"
						);
						props.closeModal();
					}
				}
			});
		}
	}, []);

	return <>{steps[step]}</>;
}

export default SOWizard;
