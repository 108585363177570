import React from 'react';
import { Form, Select, Spin, Button } from 'antd';

function ProcessSelector(props) {
    const submitForm = e => {
        props.receiveProcess(e.process);
    }

    return (
        <>
            {props.processes.length > 0 ?
                <Form name='process' onFinish={submitForm}>
                    <h1 className='center-text'>Seleccione el proceso a realizar</h1>
                    <Form.Item label='Proceso' name='process'>
                        <Select>
                            {props.processes}
                        </Select>
                    </Form.Item>
                    <Button type='primary' className='center-btn' htmlType='submit'>Siguiente</Button>
                </Form>
                :
                <Spin size='large' className='center-spin' />}
        </>
    )
}

export default ProcessSelector;