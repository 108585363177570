export default {
    SET_AUTHORIZATION_TOKEN: "SET_AUTHORIZATION_TOKEN",
    CLOSE_SESSION: "CLOSE_SESSION",
    SET_NOTIFICATION: "SET_NOTIFICATION",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    SET_USER: "SET_USER",
    SET_PREFERENCE: "SET_PREFERENCE",
    REMOVE_PREFERENCE: "REMOVE_PREFERENCE",
    SET_HTTP_ERROR: "HTTP_ERROR",
    SET_HTTP_ERROR_SYSTEM: "HTTP_ERROR_SYSTEM",
    TOGGLE_RIGHT_SIDEBAR: "TOGGLE_RIGHT_SIDEBAR",
    SET_OPERATOR: "SET_OPERATOR",
    SET_COMPANY: "SET_COMPANY",
    SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
    /* SET_TABS: "SET_TABS",
    REMOVE_TABS: "REMOVE_TABS", */
};
