import React, { useState, useEffect } from 'react';
import { Button, Select, Row } from 'antd';
import errorModal from './../../components/modal/errorModal';
import HttpRequest from './../../services/HttpRequest';
import { canGroup } from '../../helpers/helpers';

function Step2(props) {
    const [isResponsible, setIsResponsible] = useState(undefined);
    const [assignedSystem, setAssignedSystem] = useState(null);
    const [assignedMember, setAssignedMember] = useState(null);
    const [loading, setLoading] = useState(false);
    const [systems, setSystems] = useState([]);
    const [agents, setAgents] = useState([]);
    const [disabledFields, setDisabledFiels] = useState({})

    const takeTicket = () => {
        setLoading(true)
        HttpRequest.endpoint = "tech_support/ticket/";
        const formData = new FormData();
        const user = JSON.parse(localStorage.getItem('userInfo'))
        formData.append('agent', user.ID);
        HttpRequest.patch(props.ticket, formData).then(res => {
            if (res.ID) {
                props.updateRows && props.updateRows()
                props.setTicketInfo(res)
                props.setStep(3)
            } else {
                setLoading(false)
                errorModal('Ha ocurrido un error escalando el ticket a un miembro')
            }
        })
    }
    const assignMember = () => {
        if (assignedMember) {
            setLoading(true)
            HttpRequest.endpoint = "tech_support/ticket/";
            const formData = new FormData();
            formData.append('agent', assignedMember);
            HttpRequest.patch(props.ticket, formData).then(res => {
                if (res.ID) {
                    props.updateRows && props.updateRows()
                    props.setTicketInfo(res)
                    props.setStep(3)
                } else {
                    setLoading(false)
                    errorModal('Ha ocurrido un error escalando el ticket a un miembro')
                }
            })
        } else {
            errorModal('Debe seleccionar un integrante de su equipo para hacer uso de esta opción')
        }
    }
    const assignSystem = () => {
        if (assignedSystem) {
            setLoading(true)
            HttpRequest.endpoint = "tech_support/ticket/";
            const formData = new FormData();
            formData.append('assigned_team', assignedSystem)
            HttpRequest.patch(props.ticket, formData).then(res => {
                setLoading(false)
                if (res.ID) {
                    props.updateRows && props.updateRows()
                    props.setTicketInfo(res)
                } else {
                    errorModal('Ha ocurrido un error escalando el ticket a un sistema')
                }
            })
        } else {
            errorModal('Debe seleccionar un sistema para hacer uso de esta opción')
        }
    }

    useEffect(() => {
        HttpRequest.endpoint = 'tech_support/ticket/system_groups/';
        HttpRequest.get().then(res => {
            if (res && Array.isArray(res)) {
                setSystems(res)
            } else {
                errorModal('Ha ocurrido un error obteniendo la lista de sistemas')
            }
        })
    }, [])

    useEffect(() => {
        HttpRequest.endpoint = 'user/data/information_group/';
        HttpRequest.get({ group_name: props.ticketInfo.assigned_team_name }).then(res => {
            if (res && Array.isArray(res)) {
                setAgents(res)
            } else {
                errorModal('Ha ocurrido un error obteniendo la lista de agentes')
            }
        })
    }, [])

    useEffect(() => {
        const disabledItems = {
            system: !canGroup(props.ticketInfo.assigned_team_name)
        }
        setDisabledFiels(disabledItems);
    }, [props.ticketInfo])

    return (
        <>
            {isResponsible === undefined ? //pregunta inicial
                <>
                    <p className='center-text'><strong>Sistema asignado: </strong>{props.ticketInfo.assigned_team_name}</p>
                    <p className='center-text'>¿Es responsabilidad de su sistema?</p>
                    <Row justify='center'>
                        <Button disabled={disabledFields.system} type='primary' onClick={() => setIsResponsible(true)}>Sí lo es</Button>
                        <Button disabled={disabledFields.system} onClick={() => setIsResponsible(false)}>No lo es</Button>
                    </Row>
                    <Row justify='center'>
                        <Button type='link' disabled={disabledFields.system} onClick={() => props.setStep(1)}>Ir al paso anterior</Button>
                    </Row>
                </>
                :
                isResponsible ? //si es responsabilidad del sistema
                    <>
                        <p className='center-text'>¿Desea tomar el ticket o asignarlo a otro miembro del equipo?</p>
                        <Row justify='center'>
                            <Button disabled={disabledFields.system} loading={loading} type='primary' onClick={takeTicket}>Tomar ticket</Button>
                        </Row>
                        <Row justify='center'>
                            <Select placeholder='Miembro' onChange={val => setAssignedMember(val)} value={assignedMember}>
                                {agents.map(agnt => <Select.Option value={agnt.ID} key={agnt.ID}>{agnt.name}</Select.Option>)}
                            </Select>
                            <Button loading={loading} disabled={disabledFields.system} onClick={assignMember}>Asignar a otro miembro</Button>
                        </Row>
                    </>
                    : //no es responsabilidad del sistema
                    <>
                        <p className='center-text'>Seleccione el sistema al que escalar el ticket</p>
                        <Row justify='center'>
                            <Select disabled={disabledFields.system} placeholder='Seleccionar sistema' onChange={val => setAssignedSystem(val)} value={assignedSystem}>
                                {systems.map(sys => <Select.Option value={sys.ID} key={sys.ID}>{sys.name}</Select.Option>)}
                            </Select>
                            <Button loading={loading} disabled={disabledFields.system} onClick={assignSystem}>Escalar</Button>
                        </Row>
                    </>
            }
        </>
    )
}

export default Step2;