import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import ProtectedRoute from "./../../middleweare/ProtectedRoute";
import routes from "./../../config/page-route.jsx";
import { Tabs } from "antd";
import { RoutedTabs, NavTab } from "react-router-tabs";

const { TabPane } = Tabs;

class TabPanel extends Component {
	newTabIndex = 0;

	state = {
		activeKey: "/",
		panes: [
			{
				title: "Dashboard",
				component: routes[2].object,
				key: "/",
				closable: false,
			},
		],
		paths: ["/"],
	};

	onChange = (activeKey) => {
		console.log(activeKey);
		this.setState({ activeKey });
	};

	onEdit = (targetKey, action) => {
		this[action](targetKey);
	};

	add = () => {
		let selectRoute = routes.find((i) => i.path === this.props.path);
		const { panes, paths } = this.state;
		console.log(routes, panes);
		const activeKey = selectRoute.path ? selectRoute.path : "";
		const newPanes = [...panes];
		const path = [...paths];
		path.push(this.props.path);
		newPanes.push({
			title: selectRoute.title,
			component: selectRoute.object,
			key: selectRoute.path,
		});

		this.setState({ paths: path });
		this.setState({
			panes: newPanes,
			activeKey,
		});
	};

	remove = (targetKey) => {
		//Se debe enviar por medio de un empoind.
		/* let indexOf = this.state.newPath.findIndex((i) => i === parseInt(id));
		dataSource.splice(indexOf, 1); */

		const { panes, activeKey } = this.state;
		let newActiveKey = activeKey;
		let lastIndex;
		panes.forEach((pane, i) => {
			if (pane.key === targetKey) {
				lastIndex = i - 1;
			}
		});
		const newPanes = panes.filter((pane) => pane.key !== targetKey);
		if (newPanes.length && newActiveKey === targetKey) {
			if (lastIndex >= 0) {
				newActiveKey = newPanes[lastIndex].key;
			} else {
				newActiveKey = newPanes[0].key;
			}
		}
		this.setState({
			panes: newPanes,
			activeKey: newActiveKey,
		});
	};

	ifOnChange = () => {
		if (!this.state.paths.includes(this.props.path)) {
			this.add();
		}
	};

	render() {
		const { panes, activeKey } = this.state;
		this.ifOnChange();

		return (
			<Tabs
				type="editable-card"
				hideAdd
				onChange={this.onChange}
				activeKey={activeKey}
				onEdit={this.onEdit}
			>
				{panes.map((pane) => (
					<TabPane
						tab={pane.title}
						key={pane.key}
						closable={pane.closable}
						onTabClick={pane.key}
						style={{ height: 900 }}
					>
						{pane.component}
					</TabPane>
				))}
			</Tabs>
		);
	}
}

export default TabPanel;
