import React, { useState, useEffect } from 'react';
import { Collapse, Modal } from 'antd';
import SocialForm from './SocialForm';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import HttpRequest from "../../services/HttpRequest.js";

function AccordionForm(props) {
    const [loading, setLoading] = useState(true);
    const [fullCustomerInfo, setFullCustomerInfo] = useState(null);

    const getClientInfo = () => {
        if (props.id) {
            HttpRequest.endpoint = 'customers/customer';
            HttpRequest.get(null, props.id).then(res => {
                if (res.ID) {
                    setFullCustomerInfo(res);
                    setLoading(false)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo la información de este usuario. Por favor intente nuevamente.'
                    })
                }
            }).catch(err => {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo la información de este usuario. Por favor intente nuevamente.'
                })
            })
        } else {
            setLoading(false)
        }
    }

    useEffect(getClientInfo, [])

    return (
        <>
            {!loading &&
                <Collapse accordion>
                    <Collapse.Panel key='social' header='Social'>
                        <SocialForm updateRows={props.updateRows} customerInfo={fullCustomerInfo} setNewClient={props.setNewClient} />
                    </Collapse.Panel>
                    <Collapse.Panel key='email' header='Email'>
                        <EmailForm updateRows={props.updateRows} newClient={props.newClient} customerInfo={fullCustomerInfo} />
                    </Collapse.Panel>
                    <Collapse.Panel key='phone' header='Teléfono'>
                        <PhoneForm updateRows={props.updateRows} newClient={props.newClient} customerInfo={fullCustomerInfo} />
                    </Collapse.Panel>
                </Collapse>
            }
        </>
    )
}

export default AccordionForm;