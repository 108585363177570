import React from "react";
import { Form, Input, DatePicker, ConfigProvider, Button } from "antd";
import esES from "antd/lib/locale/es_ES";
import moment from "moment";

function Reminder(props) {
	if (props.closed) {
		return (
			<p className="center-text">
				Esta retención se encuentra cerrada y por lo tanto no puede
				agregar recordatorios
			</p>
		);
	}
	return (
		<Form
			onFinish={props.submit}
			initialValues={
				props.initialReminder
					? {
							message: props.initialReminder.message,
							date: moment(
								props.initialReminder.date,
								"YYYY-MM-DDTHH:mm"
							),
					  }
					: null
			}
		>
			<ConfigProvider locale={esES}>
				<Form.Item
					label="Fecha"
					name="date"
					rules={[
						{ required: true, message: "Este campo es requerido" },
					]}
				>
					<DatePicker
						style={{ width: "100%" }}
						format={"DD/MM/YYYY HH:mm"}
						showTime
					/>
				</Form.Item>
			</ConfigProvider>
			<Form.Item
				label="Mensaje"
				name="message"
				rules={[{ required: true, message: "Este campo es requerido" }]}
			>
				<Input />
			</Form.Item>
			<Button type="primary" htmlType="submit" className="center-btn">
				Enviar
			</Button>
		</Form>
	);
}

export default Reminder;
