import React, { useState } from 'react';
import { Modal, Row, Button } from 'antd';
import MatrixPaymentForm from './MatrixPaymentForm';
import store from '../../redux/store';
import HttpRequest from '../../services/HttpRequest';
import Config from "../../config";

function ActivationLatePaymentCreate(props) {
    const [step, setStep] = useState(1);
    const [matrixLoading, setMatrixLoading] = useState(false);

    const createClosedTipification = () => {
        const formData = new FormData();
        formData.append('customer_type', 3)
        formData.append('channel', props.channel);
        formData.append('status', 1);
        formData.append('category', props.categories.category);
        formData.append('rut', JSON.stringify([props.rut]));
        formData.append('services', JSON.stringify(props.services));
        formData.append('type', 0)
        formData.append('process', props.process)
        HttpRequest.endpoint = "tickets/typify/";
        return HttpRequest.post(formData)
    }

    const activateAllServices = () => {
        return Promise.all(props.services.map(serv => {
            return fetch(Config.API_URL + '/matrix/network_active_drop/' + serv + '/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ action: 'activar' })
            }).then(res => res.json())
        }))
    }
    const activateSomeServices = services => {
        return Promise.all(services.map(serv => {
            return fetch(Config.API_URL + '/matrix/network_active_drop/' + serv + '/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ action: 'activar' })
            }).then(res => res.json())
        }))
    }
    const liveAgent = () => {
        fetch(Config.API_URL + '/liveagent/departments/', {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            }
        })
            .then(res => res.json())
            .then(res => {
                let deptId;
                if (res && Array.isArray(res)) {
                    res.forEach(dept => {
                        if (dept.name === 'Finanzas') {
                            deptId = dept.department_id
                        }
                    })
                    if (deptId) {
                        const body = {
                            message: 'Activación de servicio por pago parcial',
                            departmentId: deptId,
                            subject: 'Activación de servicio por pago parcial',
                            recipient: 'maria.palencia@bandaancha.cl',
                            useridentifier: 'maria.palencia@bandaancha.cl',
                        }
                        fetch(Config.API_URL + '/liveagent/tickets/', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `JWT ${localStorage.getItem("authorization")}`,
                            },
                            body: JSON.stringify(body)
                        })
                            .then(res => res.json())
                            .then(res => {
                                if (/*valido aqui respuesa de liveagent*/false) {
                                    Modal.success({
                                        title: 'Éxito',
                                        content: 'Se han activado los servicios y se ha derivado el ticket a Liveagent con éxito',
                                        okText: 'Intentar nuevamente',
                                        onOk: () => liveAgent()
                                    })
                                } else {
                                    Modal.error({
                                        title: 'Error',
                                        content: 'Se activaron los servicios, pero ha ocurrido un error derivando el ticket a Liveagent',
                                        okText: 'Intentar nuevamente',
                                        onOk: () => liveAgent()
                                    })
                                }
                            })
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Se activaron los servicios, pero ha ocurrido un error derivando el ticket a Liveagent',
                            okText: 'Intentar nuevamente',
                            onOk: () => liveAgent()
                        })
                    }
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Se activaron los servicios, pero ha ocurrido un error derivando el ticket a Liveagent'
                    })
                }
            })
    }
    const yesTransfer = () => {
        let success = 0;
        activateAllServices().then(allRes => {
            allRes.forEach((res, i) => {
                if (res && res.mensaje === "Se ha activado el servicio.") {
                    success++
                } else {
                    Modal.error({
                        title: "Error",
                        content: `Ha ocurrido un error activando el servicio ${props.services[i]}. Por favor intente nuevamente`,
                    })
                }
            })
            if (success === allRes.length) {
                //liveAgent()
                createClosedTipification().then(res => {
                    if (res.ID) {
                        props.updateRows()
                        props.startAgain()
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error creando la tipificación.'
                        })
                    }
                })
            }
        })
    }
    const notTransfer = () => {
        Modal.info({
            title: 'Verificación',
            content: 'Por favor verifique el comprobante de pago proveniente de Servipag, BCI, Caja vecina'
        })
        setStep(2)
    }

    const receiveInfo = (e, invoices, paidBols, partialBols) => {
        setMatrixLoading(true)
        e.paid_on = e.paid_on.format('YYYY-MM-DD');
        e.deposited_on = e.deposited_on.format('YYYY-MM-DD');
        e.customer_rut = props.rut;
        e.operator = store.getState().operator;
        let initialRes;
        fetch(Config.API_URL + '/matrix/register_payments/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            },
            body: JSON.stringify(e)
        })
            .then(res => { initialRes = res; return res.json() })
            .then(res2 => {
                const errors = res2 && Object.keys(res2)
                if (res2 && errors.length === 0) {
                    /*Modal.success({
                        title: 'Éxito',
                        content: 'Se ha registrado el pago en Matrix con éxito'
                    })*/
                } else {
                    if (initialRes.ok) {
                        Modal.warning({
                            title: 'Mensaje',
                            content: 'Se ha registrado el pago en Matrix con éxito, sin embargo ocurrieron los siguientes errores: ' +
                                errors.map(key => errors[key]).join(' / ')
                        })
                        //IGUAL CONTINUO, SOLO QUE YA MOSTRE EL MENSAJE CON LOS CAMPOS FALTANTES
                    } else {
                        console.log(res2)
                        throw new Error('error')
                    }
                }
                const paidServs = [];
                const partialServs = [];
                function onlyUnique(value, index, self) {
                    return self.indexOf(value) === index;
                }
                paidBols.forEach(bol => {
                    bol.service.forEach(serv => {
                        paidServs.push(serv.number)
                    })
                })
                partialBols.forEach(bol => {
                    bol.boleta.service.forEach(serv => {
                        partialServs.push(serv.number)
                    })
                })
                const paidServices = paidServs.filter(onlyUnique);
                const partialServices = partialServs.filter(onlyUnique)
                const paidServsToActivate = [];
                const partialServsToActivate = [];
                paidServices.forEach(serv => {
                    let debt = 0;
                    invoices.forEach(inv => {
                        inv.service.forEach(invServ => {
                            if (invServ.number === serv) {
                                if (!e.boletas.includes(inv.id)) {
                                    debt++
                                }
                            }
                        })
                    })
                    if (debt < 2) {
                        paidServsToActivate.push(serv)
                    } else {
                        Modal.info({
                            title: 'Información',
                            content: 'El servicio ' + serv + ' no será activado ya que presenta más de 1 boleta impaga pendiente'
                        })
                    }
                })
                partialServices.forEach(serv => {
                    let debt = 0;
                    invoices.forEach(inv => {
                        inv.service.forEach(invServ => {
                            if (invServ.number === serv) {
                                if (!e.boletas.includes(inv.id)) {
                                    debt++
                                }
                            }
                        })
                    })
                    if (debt < 2) {
                        partialServsToActivate.push(serv)
                    } else {
                        Modal.info({
                            title: 'Información',
                            content: 'El servicio ' + serv + ' no será activando ya que presenta más de 1 boletas impagas pendientes'
                        })
                    }
                })
                if (paidServsToActivate.length > 0) {
                    activateSomeServices(paidServsToActivate).then(allRes => {
                        let paidSuccess = 0;
                        allRes.forEach((res, i) => {
                            if (res && res.mensaje === "Se ha activado el servicio.") {
                                paidSuccess++
                            } else {
                                Modal.error({
                                    title: "Error",
                                    content: `No se ha podido activar el servicio ${paidServsToActivate[i]}`
                                })
                            }
                        })
                        if (paidSuccess === allRes.length) {
                            createClosedTipification().then(res => {
                                if (res.ID) {
                                    props.updateRows()
                                    //Modal.success({ title: "Éxito", content: "Se han activado los servicios de las boletas pagadas completamente y se ha creado la tipificación cerrada con éxito" })
                                    if (partialServsToActivate.length === 0) {
                                        props.startAgain()
                                    }
                                } else {
                                    Modal.error({ title: "Error", content: "Se han activado los servicios de las boletas pagadas completamente, pero ha ocurrido un error creando la tipificación." })
                                }
                            })
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido error activando 1 o más servicios asociados a las boletas pagadas completamente (' +
                                    paidBols.map(bol => bol.id + ', ')
                                    + '). Por lo tanto, tampoco se ha creado la tipificación.'
                            })
                            props.startAgain()
                            props.updateRows()
                        }
                    }).catch(err => {
                        console.log(err);
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido error activando 1 o más servicios asociados a las boletas pagadas completamente (' +
                                paidBols.map(bol => bol.id + ', ')
                                + '). Por lo tanto, tampoco se ha creado la tipificación.'
                        })
                        props.startAgain()
                        props.updateRows()
                    })
                }
                if (partialServsToActivate.length > 0) {
                    activateSomeServices(partialServsToActivate).then(allRes2 => {
                        Modal.info({
                            title: 'Información',
                            content: 'La boleta ' + partialBols[0].boleta.id + ' es pagada parcialmente, ya que existe existe una diferencia de ' + partialBols[0].diferencia + ' $'
                        })
                        let partialSuccess = 0;
                        allRes2.forEach((res, i) => {
                            if (res && res.mensaje === "Se ha activado el servicio.") {
                                partialSuccess++
                            } else {
                                Modal.error({
                                    title: "Error",
                                    content: `No se ha podido activar el servicio ${partialServsToActivate[i]}`
                                })
                            }
                        })
                        if (partialSuccess === allRes2.length) {
                            createClosedTipification().then(res => {
                                if (res.ID) {
                                    //Modal.success({ title: "Éxito", content: "Se han activado los servicios de la boleta pagada parcialmente y se ha creado la tipificación cerrada con éxito" })
                                    props.startAgain()
                                    props.updateRows()
                                } else {
                                    Modal.error({ title: "Error", content: "Se han activado los servicios de la boleta pagada parcialmente, pero ha ocurrido un error creando la tipificación." })
                                }
                            })
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido error activando 1 o más servicios asociados a la boleta pagada parcialmente (' +
                                    partialBols[0].boleta.id
                                    + '). Por lo tanto, tampoco se ha creado la tipificación.'
                            })
                            props.startAgain()
                            props.updateRows()
                        }
                    }).catch(err => {
                        console.log(err);
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido error activando 1 o más servicios asociados a la boleta pagada parcialmente (' +
                                partialBols[0].boleta.id
                                + '). Por lo tanto, tampoco se ha creado la tipificación.'
                        })
                        props.startAgain()
                        props.updateRows()
                    })
                }
                setMatrixLoading(false)
            })
            .catch(err => {
                console.log(err);
                setMatrixLoading(false)
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error registrando el pago en matrix'
                })
            })
    }

    const steps = {
        1:
            <>
                <h1 className='center-text'>El comprobante es de una transferencia?</h1>
                <Row className='center-row'>
                    <Button onClick={yesTransfer} type='primary'>Sí es una transferencia</Button>
                    <Button onClick={notTransfer} type='secondary'>No es una transferencia</Button>
                </Row>
            </>,
        2:
            <>
                <MatrixPaymentForm latePayment receiveInfo={receiveInfo} loading={matrixLoading} rut={props.rut} services={props.services} requiredBoletas />
            </>
    }

    return (
        <>
            {steps[step]}
        </>
    )
}

export default ActivationLatePaymentCreate;