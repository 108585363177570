import React from "react";
import SearchTables from "../TypificationsTables/SearchTables";
import RetentionEscalationsTable from "./RetentionEscalations";

function RetentionEscalationSearchTables(props) {
	const endpoint = "escalation_ti/escalation_tables/";
	return (
		<>
			<SearchTables
				endpoint={endpoint}
				component={
					<RetentionEscalationsTable service={props.service} />
				}
			/>
		</>
	);
}

export default RetentionEscalationSearchTables;
