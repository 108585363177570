import React from 'react';
import FullTable from '../../components/datatable/FullTable';

function CallsTable(props) {
    let endpoints = {
        columns: "communications/text/" + props.tableID + "/datatables_struct/",
        rows: "communications/text/" + props.tableID + "/datatables/",
        delete: `communications/text/`,
        download: 'communications/text/' + props.tableID + '/download_data/',
    }
    return (
        <>
            <FullTable buttons={[]} endpoints={endpoints} tableName='llamadas' />
        </>
    )
}

export default CallsTable;