import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import LeadletTable2 from './LeadletTable2';

function LeadletSearchTables() {
    const endpoint = 'customers/leadlet_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<LeadletTable2 />} />
        </>
    )
}

export default LeadletSearchTables;