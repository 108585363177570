import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Select, Modal } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { connect } from 'react-redux';

function FactibilityConfiguration(props) {
    const [categories, setCategories] = useState({
        type: [],
        type2: [],
        type3: [],
        category: [],
        category2: [],
        category3: [],
        subcategory: [],
        subcategory2: [],
        subcategory3: [],
        secondSubcategory: [],
        secondSubcategory2: [],
        secondSubcategory3: [],
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const form = useRef();

    const getChildCategories = (classification, parent) => {
        HttpRequest.endpoint = "tickets/category/";
        HttpRequest.get({ operator: props.operator, parent: parent }).then(res => {
            const categories = [];
            res.forEach(cat => {
                categories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                [classification]: categories
            }));
        })
    }
    const getRecursiveCategories = (id, fact = '') => {
        HttpRequest.endpoint = "tickets/category/" + id + "/";
        HttpRequest.get().then((res) => {
            switch (res["classification"]) {
                case 4:
                    getChildCategories("secondSubcategory" + fact, res["parent"])
                    form.current.setFieldsValue({
                        ["secondSubcategory" + fact]: res["ID"]
                    })
                    getRecursiveCategories(res["parent"], fact)
                    break;
                case 3:
                    getChildCategories("subcategory" + fact, res["parent"])
                    form.current.setFieldsValue({
                        ["subcategory" + fact]: res["ID"]
                    })
                    getRecursiveCategories(res["parent"], fact)
                    break;
                case 2:
                    getChildCategories("category" + fact, res["parent"])
                    form.current.setFieldsValue({
                        ["category" + fact]: res["ID"]
                    })
                    getRecursiveCategories(res["parent"], fact)
                    break;
                case 1:
                    form.current.setFieldsValue({
                        ["type" + fact]: res["ID"]
                    })
                    break;
                default:
                    break;
            }
        })
    }
    const submitForm = e => {
        const formData = new FormData();
        formData.append('description', JSON.stringify(
            [
                {
                    name: 'Factibilidad',
                    category: e.secondSubcategory || e.subcategory
                },
                {
                    name: 'Factibilidad no venta',
                    category: e.secondSubcategory2 || e.subcategory2
                },
                {
                    name: 'Nuevo servicio',
                    category: e.secondSubcategory3 || e.subcategory3
                },
            ]
        ))
        HttpRequest.endpoint = 'tickets/process/'
        HttpRequest.patch(props.id, formData).then(res => {
            setButtonLoading(false);
            if (res.ID) {
                Modal.success({
                    title: 'Éxito',
                    content: 'Se ha editado la configuración con éxito'
                })
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error editando la configuración'
                })
            }
        })
    }

    useEffect(() => {
        setCategories(prevCats => ({
            ...prevCats,
            type: props.typeCats,
            type2: props.typeCats,
            type3: props.typeCats,
        }));
    }, [])

    useEffect(() => {
        if (props.processInfo.description.length > 0) {
            const positions = {
                'Factibilidad': '',
                'Factibilidad no venta': 2,
                'Nuevo servicio': 3
            }
            //getRecursiveCategories(props.processInfo.description['Factibilidad'].id)
            //getRecursiveCategories(props.processInfo.description['Factibilidad no venta'].id, 2)
            //getRecursiveCategories(props.processInfo.description['Factibilidad nuevo servicio'].id, 3)
            props.processInfo.description.forEach(proc => {
                getRecursiveCategories(proc.category, positions[proc.name])
            })
        }
    }, [])

    return (
        <>
            <Form ref={form} name='typification-slack' onFinish={submitForm}>
                <h4>Factibilidad</h4>
                <Form.Item label='Tipo' name='type' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('category', e); form.current.setFieldsValue({ category: null, subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.type}
                    </Select>
                </Form.Item>
                <Form.Item label='Categoría' name='category' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('subcategory', e); form.current.setFieldsValue({ subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.category}
                    </Select>
                </Form.Item>
                <Form.Item label='Subcategoría' name='subcategory' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('secondSubcategory', e); form.current.setFieldsValue({ secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.subcategory}
                    </Select>
                </Form.Item>
                <Form.Item label='Segunda subcategoría' name='secondSubcategory'>
                    <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.secondSubcategory}
                    </Select>
                </Form.Item>


                <h4>Factibilidad no venta</h4>
                <Form.Item label='Tipo' name='type2' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('category2', e); form.current.setFieldsValue({ category2: null, subcategory2: null, secondSubcategory2: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.type2}
                    </Select>
                </Form.Item>
                <Form.Item label='Categoría' name='category2' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('subcategory2', e); form.current.setFieldsValue({ subcategory2: null, secondSubcategory2: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.category2}
                    </Select>
                </Form.Item>
                <Form.Item label='Subcategoría' name='subcategory2' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('secondSubcategory2', e); form.current.setFieldsValue({ secondSubcategory2: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.subcategory2}
                    </Select>
                </Form.Item>
                <Form.Item label='Segunda subcategoría' name='secondSubcategory2'>
                    <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.secondSubcategory2}
                    </Select>
                </Form.Item>

                <h4>Nuevo servicio</h4>
                <Form.Item label='Tipo' name='type3' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('category3', e); form.current.setFieldsValue({ category3: null, subcategory3: null, secondSubcategory3: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.type3}
                    </Select>
                </Form.Item>
                <Form.Item label='Categoría' name='category3' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('subcategory3', e); form.current.setFieldsValue({ subcategory3: null, secondSubcategory3: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.category3}
                    </Select>
                </Form.Item>
                <Form.Item label='Subcategoría' name='subcategory3' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={(e) => { getChildCategories('secondSubcategory3', e); form.current.setFieldsValue({ secondSubcategory3: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.subcategory3}
                    </Select>
                </Form.Item>
                <Form.Item label='Segunda subcategoría' name='secondSubcategory3'>
                    <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {categories.secondSubcategory3}
                    </Select>
                </Form.Item>

                <Button block size='large' loading={buttonLoading} type='primary' htmlType='submit'>{props.id ? 'Guardar' : 'Crear'}</Button>
            </Form>
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(FactibilityConfiguration);