import React, { useState, useEffect } from 'react';
import Chat from './Chat';
import Step1 from './Step1';
import './TicketsWizard.css';
import Step2 from './Step2';
import Step3 from './Step3';
import HttpRequest from './../../services/HttpRequest';
import { Spin } from 'antd';

function TicketsWizard(props) {
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [ticketInfo, setTicketInfo] = useState(null);

    const steps = {
        1: <Step1 updateRows={props.updateRows} setStep={setStep} ticket={props.id} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} />,
        2: <Step2 updateRows={props.updateRows} setStep={setStep} ticket={props.id} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} />,
        3: <Step3 updateRows={props.updateRows} setStep={setStep} ticket={props.id} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} />
    }

    useEffect(() => {
        HttpRequest.endpoint = "tech_support/ticket";
        HttpRequest.get(null, props.id).then(res => {
            if (res.ID) {
                /*let editable = false;
                const user = JSON.parse(localStorage.getItem('userInfo'))
                if (canGroup(res.assigned_team_name)) { //valido quienes pueden editar el ticket
                    editable = res.agent_name ? res.agent_name === user.username : true
                }
                if (!editable) {
                    Modal.info({
                        title: 'Información',
                        content: 'Usted no pertenece al grupo asignado a este ticket u otro agente ya lo tomó. Solo tiene permiso de lectura'
                    })
                }*/
                setTicketInfo(res);
                if (res.agent) { //si hay un agente, significa que puedes ver los detalles
                    setStep(3)
                } else if (res.status === 1) { //si no hay agente, pero si es del equipo de desarollo (aceptado)
                    setStep(2)
                }
                setLoading(false)
            }
        })
    }, [props.id])

    return (
        <>
            {!loading ?
                <div className='ticket-container'>
                    <div className='step-container'>
                        {steps[step]}
                    </div>
                    <div className='chat-container'>
                        <Chat channel={ticketInfo.slack_channel} ts={ticketInfo.slack_thread} />
                    </div>
                </div>
                :
                <Spin className='center-spinner' size='large' />
            }
        </>
    )
}

export default TicketsWizard;