import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import { Button, Select, Form, Input, Popover } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import "../../pages/leads/leads.css";
import Config from "../../config";

const LocationForm = (props) => {
    const [result, setResult] = useState();
    const [tag, setTag] = useState(props.driveValue ? props.driveValue : "Ubicación");
    const [showForm, setShowForm] = useState(false);
    const [regionsList, setRegionsList] = useState();
    const [region, setRegion] = useState(null);
    const [communeList, setCommuneList] = useState();
    const [commune, setCommune] = useState();
    const [streetList, setStreetList] = useState();
    const [street, setStreet] = useState();
    const [visible, setVisible] = useState();
    const [streetLocationsList, setStreetLocationsList] = useState();
    const [streetLocation, setStreetLocation] = useState();
    const { Option } = Select;
    const [form] = Form.useForm();

    const getRegionData = (streetLocation = null) => {
        let regions = [];
        HttpRequest.endpoint = "pulso/search-region/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                if (element.id === 13 || element.id === 15) regions.push(<Option key={element["id"]} value={element["id"]}>{element["name"]}</Option>);
            });
            setRegionsList(regions);
        });
        if (typeof props.id === 'number') {
            HttpRequest.endpoint =
                "pulso/search-complete-location/" + streetLocation + "/";
            HttpRequest.get().then((res) => {
                getCommuneData(res[0]["region_id"]);
                getStreet(res[0]["commune_id"]);
                getStreetLocation(res[0]["street_id"]);
                setRegion(res[0]["region_id"]);
                setCommune(res[0]["commune_id"]);
                setStreet(res[0]["street_id"]);
                setShowForm(true);
            });
        } else {
            setShowForm(true);
        }
    };
    const getCommuneData = (region) => {
        let communes = [];
        HttpRequest.endpoint = "pulso/search-commune/" + region + "/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                communes.push(<Option key={element["id"]} value={element["id"]}>{element["name"]}</Option>);
            });
            setCommuneList(communes);
        });
    };
    const getStreet = (commune) => {
        let streets = [];
        HttpRequest.endpoint = "pulso/search-street/" + commune + "/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                streets.push(<Option key={element["id"]} value={element["id"]}>{element["name"]}</Option>);
            });
            setStreetList(streets);
        });
    };
    const getStreetLocation = (street) => {
        let streetLocations = [];
        HttpRequest.endpoint = "pulso/search-street-location/" + street + "/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                streetLocations.push(<Option key={element["id"]} value={element["id"]}>{element["street_no"]}</Option>);
            });
            setStreetLocationsList(streetLocations);
        });
    };
    useEffect(() => {
        getRegionData();
    }, []);

    useEffect(() => {
        if (props.drive && result && result.split(':')[0] === 'streetLocation') {
            fetch(Config.API_URL + '/pulso/factibility-iris/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ street_location: [result.split(':')[1]] })
            }).then(res => res.json()).then(res => {
                if (res && Array.isArray(res)) {
                    setTag(res[0].street_location)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }, [result])

    useEffect(() => {
        if (result) {
            !props.driveEdit && props.onChange(result);
        }
    }, [result])

    const saveDrive = () => {
        if (result) {
            props.onChange(result);
            setVisible(false)
        }
    }

    const locationForm = (<div style={{ width: "350px" }}>
        {showForm && (
            <div className="direction-form">
                <Form onFinish={saveDrive} form={form} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                    <Form.Item rules={props.drive && [{ required: true, message: 'Debe completar este campo' }]} name="region" label="Región" initialValue={{ value: region ?? null }}>
                        <Select
                            className="direction-select" placeholder="Región:  Ej:Metropolitana" labelInValue showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(option) => {
                                getCommuneData(option.key);
                                setResult("region:" + option.key)
                                setTag("Región: " + option.label)
                                form.setFieldsValue({ commune: undefined, street: undefined, streetLocation: undefined });
                                setStreetList([]);
                                setStreetLocationsList([]);
                            }}
                        >
                            {regionsList}
                        </Select>
                    </Form.Item>
                    <Form.Item rules={props.drive && [{ required: true, message: 'Debe completar este campo' }]} name="commune" label="Comuna" initialValue={{ value: commune ?? null }}>
                        <Select
                            className="direction-select" placeholder="Comuna:  Ej: Providencia" labelInValue showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(option) => {
                                setResult("commune:" + option.key)
                                setTag("Comuna: " + option.label)
                                getStreet(option.key);
                                form.setFieldsValue({ street: undefined, streetLocation: undefined });
                                setStreetLocation(null);
                                setStreetLocationsList([]);
                            }} >
                            {communeList}
                        </Select>
                    </Form.Item>
                    <Form.Item rules={props.drive && [{ required: true, message: 'Debe completar este campo' }]} name="street" label="Calle" initialValue={{ value: street ?? null }} >
                        <Select
                            className="direction-select" placeholder="Calle:  Ej: Santa Rita" labelInValue showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(option) => {
                                setResult("street:" + option.key)
                                setTag("Calle: " + option.label)
                                getStreetLocation(option.key);
                                form.setFieldsValue({ streetLocation: undefined });
                            }}
                        >
                            {streetList}
                        </Select>
                    </Form.Item>
                    <Form.Item rules={props.drive && [{ required: true, message: 'Debe completar este campo' }]} label="Número" name="streetLocation" initialValue={{ value: streetLocation ?? null }}>
                        <Select
                            placeholder="Número:  Ej: 694" labelInValue showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(option) => {
                                setResult("streetLocation:" + option.key)
                                setTag("Dirección: " + option.label)
                            }}
                        >
                            {streetLocationsList}
                        </Select>
                    </Form.Item>
                    {/*<Button onClick={() => console.log(result, tag)}>Prueba</Button>*/}
                    {props.drive && result && <Button htmlType='submit' className='center-btn' type='primary'>Guardar</Button>}
                </Form>
            </div>
        )
        }
    </div >);

    return (
        <Popover
            content={locationForm}
            trigger="click"
            visible={visible}
            onVisibleChange={(visible) => setVisible(visible)}
        >
            <Input disabled={props.disabled} value={tag} className={props.className ? props.className : props.drive ? 'column-item' : ''} />
        </Popover>
    )
}

export default LocationForm;