import React, { useState, useEffect } from "react";
import ServiceSearch from "./ServiceSearch";
import { Button, Form, Collapse, Select, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import HttpRequest from "./../../services/HttpRequest";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import errorModal from "./../../components/modal/errorModal";
import Reminder from "./Reminder";
import RetentionAgreements from "./RetentionsAgreements";
import RetentionStatus from "./RetentionStatus";
import { can } from "../../helpers/helpers";
import { retentions } from "../../config/permissions";
import RetentionContact from "./RetentionContact";
import CustomerInfoModal from "./../typify/customerInfoModal";
import ServiceInfoModal from "./../typify/servicesInfoModal";
import RetentionEscalations from "./RetentionEscalations";
import RetentionEscalationSearchTables from "./RetentionEscalationSearchTable";
import store from "../../redux/store";

function RetentionsForm(props) {
	const [form] = useForm();
	const [buttonLoading, setButtonLoading] = useState(false);
	const [data, setData] = useState(null);
	const [closed, setClosed] = useState(false);
	const [rut, setRut] = useState("");
	const [customerModal, setCustomerModal] = useState(false);
	const [serviceModal, setServiceModal] = useState(false);
	const [escalation, setEscalation] = useState(false);
	const [categoriesList, setCategoriesList] = useState([]);
	const [agreements, setAgreements] = useState([])
	const [agreementsList, setAgreementsList] = useState([])

	const submitRetention = (values) => {
		if (!can(retentions.create)) {
			return;
		}
		setButtonLoading(true);
		const formData = new FormData();
		formData.append("service", JSON.stringify(values.services[0]));
		formData.append("channel", values.channel);
		formData.append("categories", JSON.stringify(values.categories));
		HttpRequest.endpoint = "retentions/customer_retention/";
		HttpRequest.post(formData).then((res) => {
			setButtonLoading(false);
			if (res.ID) {
				autoCloseModal("Se ha creado la retención con éxito");
				props.closeModal();
			} else {
				errorModal("Ha ocurrido un error creando la retención");
			}
		});
	};

	const submitReminder = (values) => {
		if (!can(retentions.edit)) {
			return;
		}
		const formData = new FormData();
		formData.append("message", values.message);
		formData.append("date", values.date.format("YYYY-MM-DD HH:mm"));
		HttpRequest.endpoint = `retentions/customer_retention/${props.id}/add_reminder/`;
		HttpRequest.post(formData).then((res) => {
			if (res.ID) {
				autoCloseModal("Se ha creado el recordatorio con éxito");
			} else {
				errorModal("Ha ocurrido un error creando el recordatorio");
			}
		});
	};

	const submitAgreements = () => {
		if (!can(retentions.edit)) {
			return;
		}
		setButtonLoading(true);
		HttpRequest.endpoint = `retentions/customer_retention/`;
		const formData = new FormData();
		formData.append("agreements", JSON.stringify(agreements));
		HttpRequest.patch(props.id, formData).then((res) => {
			setButtonLoading(false);
			if (res.ID) {
				autoCloseModal("Se han actualizado los acuerdos con éxito");
			} else {
				errorModal("Ha ocurrido un error actualizando los acuerdos");
			}
		});

	};

	const submitFollowups = (followupArray) => {
		if (!can(retentions.edit)) {
			return;
		}
		if (followupArray.length === 0) {
			return errorModal("No hay nuevos seguimientos que agregar");
		}
		setButtonLoading(true);
		HttpRequest.endpoint = `retentions/customer_retention/${props.id}/add_followup/`;
		Promise.all(
			followupArray.map((flw) => {
				const formData = new FormData();
				formData.append("followup", flw);
				return HttpRequest.post(formData);
			})
		).then((allRes) => {
			if (allRes && Array.isArray(allRes)) {
				if (allRes[0].ID) {
					HttpRequest.endpoint = "retentions/customer_retention";
					HttpRequest.get(null, props.id).then((res) => {
						setButtonLoading(false);
						if (res.ID) {
							setData(res);
						} else {
							errorModal(
								"Ha ocurrido un error obteniendo los acuerdos actualizados"
							);
						}
					});
				} else {
					setButtonLoading(false);
					errorModal("Ha ocurrido un error creando el seguimiento");
				}
			} else {
				setButtonLoading(false);
				errorModal("Ha ocurrido un error creando el seguimiento");
			}
		});
	};

	const submitContact = (values) => {
		setButtonLoading(true);
		HttpRequest.endpoint = "retentions/customer_retention/";
		const formData = new FormData();
		formData.append(
			"contact_date",
			values.contact.format("YYYY-MM-DD HH:mm")
		);
		HttpRequest.patch(props.id, formData).then((res) => {
			setButtonLoading(false);
			if (res.ID) {
				autoCloseModal("Se ha editado la hora de contacto con éxito");
			} else {
				errorModal("Ha ocurrido un error editando la hora de contacto");
			}
		});
	};

	useEffect(() => {
		if (props.id) {
			HttpRequest.endpoint = "retentions/customer_retention";
			HttpRequest.get(null, props.id).then((res) => {
				if (res.ID) {
					if (res.status === 0) {
						setClosed(false);
					} else {
						setClosed(true);
					}
					setData(res);
					if (res.agreements.length > 0) setAgreements(res.agreements)
				} else {
					errorModal("Ha ocurrido un error obteniendo la retención");
				}
			});
		}
	}, []);

	useEffect(() => {
		if (props.id) {
			let agreement_list = [];
			HttpRequest.endpoint = "retentions/agreement/";
			HttpRequest.get().then((res) => {
				if (res && Array.isArray(res)) {
					res.forEach(element => {
						agreement_list.push(<Select.Option key={element.ID} value={element.ID}>{element.name}</Select.Option>)
					});
					setAgreementsList(agreement_list)
				} else {
					errorModal("Ha ocurrido un error obteniendo los acuerdos");
				}
			});
		}
	}, []);

	useEffect(() => {
		if (data) {
			HttpRequest.endpoint = "matrix/customer_filter/";
			const formData = { criterion: "service", search: data.service };
			HttpRequest.get(formData).then((res) => {
				if (res && Array.isArray(res)) {
					if (res[0]) {
						setRut(res[0].id);
					} else {
						throw new Error("not found");
					}
				} else {
					errorModal("Ha ocurrido un error consultando Matrix");
				}
			});
		}
	}, [data]);

	useEffect(() => {
		HttpRequest.endpoint = `retentions/customer_retention/get_retention_categories/`;
		HttpRequest.get({ operator: store.getState().operator }).then((res) => {
			if (res && Array.isArray(res)) {
				HttpRequest.endpoint = "tickets/typify/categories_names/";
				const formData = new FormData();
				formData.append("categories", JSON.stringify(res));
				HttpRequest.post(formData).then((res2) => {
					if (res2 && Array.isArray(res2)) {
						setCategoriesList(
							res2.map((cat) => ({
								value: cat.ID,
								label: cat.name,
							}))
						);
					} else {
						errorModal(
							"Ha ocurrido un error obteniendo las categorías"
						);
					}
				});
			} else {
				errorModal("Ha ocurrido un error obteniendo las categorías");
			}
		});
	}, []);

	return (
		<>
			{!props.id ? (
				<>
					{categoriesList.length > 0 && (
						<Form onFinish={submitRetention} form={form}>
							<ServiceSearch form={form} requiredService />
							<Form.Item
								name="channel"
								label="Canal"
								rules={[
									{
										required: true,
										message: "Este campo es requerido",
									},
								]}
							>
								<Select>
									<Select.Option key="0" value={0}>
										Correo (LA)
									</Select.Option>
									<Select.Option key="1" value={1}>
										Llamadas (LL)
									</Select.Option>
									<Select.Option key="2" value={5}>
										Facebook (FB)
									</Select.Option>
									<Select.Option key="3" value={6}>
										Instagram (IG)
									</Select.Option>
									<Select.Option key="4" value={4}>
										WhatsApp (WS)
									</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item
								name="categories"
								label="Categorías"
								rules={[
									{
										required: true,
										message: "Este campo es requerido",
									},
								]}
							>
								<Select mode="multiple">
									{categoriesList.map((cat) => (
										<Select.Option
											value={cat.value}
											key={cat.value}
										>
											{cat.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Button
								loading={buttonLoading}
								htmlType="submit"
								type="primary"
								className="center-btn"
							>
								Crear
							</Button>
						</Form>
					)}
				</>
			) : (
				data &&
				rut && (
					<>
						<div
							style={{ textAlign: "right", marginBottom: "25px" }}
						>
							<Button onClick={() => setEscalation(true)}>
								Escalamientos
							</Button>
							<Button onClick={() => setCustomerModal(true)}>
								Cliente
							</Button>
							<Button onClick={() => setServiceModal(true)}>
								Servicio
							</Button>
						</div>
						<Collapse accordion defaultActiveKey="1">
							<Collapse.Panel header="Retención" key="1">
								<RetentionStatus
									channel={data.channel}
									id={props.id}
									closed={closed}
									setClosed={setClosed}
								/>
							</Collapse.Panel>
							<Collapse.Panel header="Recordatorios" key="2">
								<Reminder
									submit={submitReminder}
									closed={closed}
									initialReminder={data && data.reminder}
								/>
							</Collapse.Panel>
							<Collapse.Panel header="Acuerdos" key="3">
								<Select
									mode="multiple"
									placeholder="Acuerdos"
									defaultValue={agreements}
									value={agreements}
									onChange={(e) => setAgreements(e)}
									style={{ width: "80%", marginRight: "10px" }}
								>
									{agreementsList}
								</Select>
								<Button onClick={submitAgreements}>Guardar</Button>
							</Collapse.Panel>
							<Collapse.Panel header="Seguimientos" key="4">
								<RetentionAgreements
									submit={submitFollowups}
									initialAgreements={data && data.followup}
									loading={buttonLoading}
									retentionId={props.id}
									followup
									closed={closed}
								/>
							</Collapse.Panel>
							<Collapse.Panel header="Contacto" key="5">
								<RetentionContact
									loading={buttonLoading}
									submitContact={submitContact}
									initial={data.contact_date}
								/>
							</Collapse.Panel>
						</Collapse>
						{customerModal && (
							<CustomerInfoModal
								visible={customerModal}
								setVisible={setCustomerModal}
								rut={rut}
							/>
						)}
						{serviceModal && (
							<ServiceInfoModal
								visible={serviceModal}
								setVisible={setServiceModal}
								rut={rut}
							/>
						)}
						{escalation && (
							<Modal
								visible={escalation}
								onCancel={() => setEscalation(false)}
								footer={null}
								width={800}
								title="Escalamientos"
							>
								<RetentionEscalationSearchTables
									service={data.service}
								/>
							</Modal>
						)}
					</>
				)
			)}
		</>
	);
}

export default RetentionsForm;
