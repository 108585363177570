import React, { useState, useEffect } from 'react'
import { Select, Row, Button, Modal } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { connect } from 'react-redux';
import autoCloseModal from '../../components/modal/AutoCloseModal';

function ConversationTags(props) {

    const [tags, setTags] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const { Option } = Select;

    useEffect(() => {
        getTags()
    }, [])

    const getTags = () => {
        let existent_tags = [];
        let initial_tags = [];
        setLoading(true)
        HttpRequest.endpoint = "communications/conversation_tag/?operator=" + props.operator
        const formData = new FormData();
        HttpRequest.get().then((res) => {
            res.forEach(tag => {
                existent_tags.push(<Option key={tag.name}>{tag.name}</Option>)
                initial_tags.push(tag.name)
            });
            setTagOptions(existent_tags)
            setTags(initial_tags)
            setLoading(false)
        })
    }

    const updateTags = () => {
        setLoading(true)
        HttpRequest.endpoint = "communications/conversation_tag/update_tags/"
        const formData = new FormData();
        formData.append("operator", props.operator);
        formData.append("tags", JSON.stringify(tags));
        HttpRequest.post(formData).then((res) => {
            if (Array.isArray(res)) {
                autoCloseModal('Se han actualizado los tags con éxito')
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error al actualizar los tags'
                })
            }
            setLoading(false)
        })
    }

    return (
        <div>
            <Row style={{ paddingTop: "30px" }} justify="center">
                <Select style={{ width: "60%" }} mode="tags" placeholder="Tags Convresaciones" value={tags} onChange={(e) => setTags(e)}>
                    {tagOptions}
                </Select>
                <Button loading={loading} onClick={updateTags}>Guardar</Button>
            </Row>
            <p className="center-text os-text">
                Escriba el nombre del tag y presione enter para añadirlo a la lista
            </p>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(ConversationTags);