import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Spin, Button } from 'antd';
import HttpRequest from './../../services/HttpRequest';
import { connect } from "react-redux";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import errorModal from './../../components/modal/errorModal';
import Config from "../../config";

function SacTestForm(props) {
    const [testList, setTestList] = useState([]);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const submit = e => {
        setButtonLoading(true)
        const body = {
            name: e.name,
            tests: e.tests,
            operator: props.operator
        }
        if (!props.id) {
            fetch(Config.API_URL + '/escalation_ti/test_group/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("authorization")}`,
                },
                body: JSON.stringify(body)
            }).then(res => res.json()).then(res => {
                setButtonLoading(false)
                if (res.ID) {
                    autoCloseModal('Se ha creado el grupo con éxito')
                    props.closeModal()
                } else {
                    errorModal('Ha ocurrido un error creando el grupo')
                }
            })
        } else {
            fetch(Config.API_URL + '/escalation_ti/test_group/' + props.id + '/', {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("authorization")}`,
                },
                body: JSON.stringify(body)
            }).then(res => res.json()).then(res => {
                setButtonLoading(false)
                if (res.ID) {
                    autoCloseModal('Se ha editado el grupo con éxito')
                    props.closeModal()
                } else {
                    errorModal('Ha ocurrido un error editando el grupo')
                }
            })
        }
    }

    const getData = () => {
        HttpRequest.endpoint = "escalation_ti/test_group";
        HttpRequest.get(null, props.id).then(res => {
            if (res && res.ID) {
                setData({ name: res.name, tests: res.tests.map(test => test.id) })
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        if (props.id) {
            getData()
        } else {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        HttpRequest.endpoint = 'escalation_ti/test/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                setTestList(res)
            }
        })
    }, [])

    return (
        <>
            {loading ?
                <Spin size='large' className='center-spin' />
                :
                <Form onFinish={submit} initialValues={data}>
                    <Form.Item name='name' label='Nombre'>
                        <Input placeholder='Nombre del grupo' />
                    </Form.Item>
                    <Form.Item name='tests' label='Pruebas'>
                        <Select mode='multiple' placeholder='Seleccionar'>
                            {testList.map(test => <Select.Option key={test.ID} value={test.ID}>{test.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Button loading={buttonLoading} type='primary' htmlType='submit' block>{props.id ? 'Guardar' : 'Crear'}</Button>
                </Form>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(SacTestForm);