import React from 'react'
import { Row, Menu, Dropdown } from 'antd';
import { MailOutlined, WhatsAppOutlined, DownOutlined } from "@ant-design/icons";
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import NotificationList from '../../notifications/NotificationList';


export default function Notifications(props) {
    let history = useHistory();
    const menu = (
        <Menu>
            <Menu.Item>
                <ButtomLabel
                    onClick={() => history.push("/messages/emails")}
                > {<MailOutlined />} Correos</ButtomLabel>
            </Menu.Item>
            <Menu.Item>
                <ButtomLabel
                    onClick={() => history.push("/messages/whatsapp")}
                > {<WhatsAppOutlined />} WhatsApp</ButtomLabel>
            </Menu.Item>
        </Menu>
    );

    const openNotification = (data, pk) => {
        const id = data?.conversation_ID

        if (data.type === "whatsapp") {
            history.replace(`/messages/whatsapp/${id}/${pk}`)
        } else {
            history.replace(`/messages/emails/${id}/${pk}`)
        }

    }

    return (
        <>
            <div style={{ height: "200px", overflowY: "scroll" }} >
                <NotificationList
                    notifications={props.notifications}
                    dropdown={true}
                />
            </div >

            <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    Ver todos <DownOutlined />
                </a>
            </Dropdown>

        </>

    )
}


const ButtomLabel = styled.label`
    font-size: 13spx;
    cursor: pointer;
    margin: 5px 10px;
    display: flex;
    width: 150px;
    

    span{
        margin: auto 5px;
    }

`

