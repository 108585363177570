import React, { useState } from "react";
import FullTable from "../../components/datatable/FullTable";
import { Modal, Button, Tag } from "antd";
import { retentions } from "../../config/permissions";
import Chat from "../tickets/Chat";
import RetentionsForm from "./RetentionsForm";

function RetentionsTable(props) {
	const [tableRows, setTableRows] = useState([]);
	const [slackInfo, setSlackInfo] = useState(null);

	const endpoints = {
		columns:
			"retentions/customer_retention/" +
			props.tableID +
			"/datatables_struct/",
		rows: "retentions/customer_retention/" + props.tableID + "/datatables/",
		delete: `retentions/customer_retention/`,
		download:
			"retentions/customer_retention/" +
			props.tableID +
			"/download_data/",
	};

	const handleRows = (res) => {
		setTableRows(
			res.map((row) => ({
				...row,
				slack_thread: (
					<Button
						disabled={!row.slack_thread}
						onClick={() =>
							setSlackInfo({
								channel: row.slack_channel,
								thread: row.slack_thread,
							})
						}
					>
						Ver
					</Button>
				),
				succesfull: (
					<>
						{row.status == "CERRADO" && (
							<Tag color={row.succesfull ? "green" : "red"}>{row.succesfull ? "Exitosa" : "Fallida"}</Tag>
						)}
					</>
				),
			}))
		);
	};

	return (
		<>
			<FullTable
				modalContent={<RetentionsForm />}
				endpoints={endpoints}
				handleRows={handleRows}
				tableRows={tableRows}
				tableName="retención"
				permissions={retentions}
				modalWidth={800}
			/>
			{slackInfo && (
				<Modal
					visible={slackInfo}
					footer={null}
					onCancel={() => setSlackInfo(null)}
				>
					<Chat channel={slackInfo.channel} ts={slackInfo.thread} />
				</Modal>
			)}
		</>
	);
}

export default RetentionsTable;
