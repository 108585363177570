import React, { useState, useEffect } from "react";
import { Form, Select, Button, Popover } from "antd";
import CategoryForm from "./../typify/categoryForm";
import errorModal from "./../../components/modal/errorModal";
import HttpRequest from "./../../services/HttpRequest";
import store from "../../redux/store";
import autoCloseModal from "./../../components/modal/AutoCloseModal";

function FinanceCategories() {
    const [categoryValues, setCategoriesValues] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);

    const submit = () => {
        if (categoryValues.length === 0) {
            return errorModal("Debe seleccionar al menos 1 categoría");
        }
        const formData = new FormData();
        formData.append("categories", JSON.stringify(categoryValues));
        HttpRequest.endpoint = `escalation_finance/escalation_category/add_escalation_categories/`;
        HttpRequest.post(formData).then((res) => {
            if (res && Array.isArray(res)) {
                autoCloseModal("Se han asociado las categorías con éxito");
            } else {
                errorModal("Ha ocurrido un error asociando las categorías");
            }
        });
    };

    useEffect(() => {
        HttpRequest.endpoint = `escalation_finance/escalation_category/get_escalation_categories/`;
        HttpRequest.get({ operator: store.getState().operator }).then((res) => {
            if (res && Array.isArray(res)) {
                setCategoriesValues(res);
                HttpRequest.endpoint = "tickets/typify/categories_names/";
                const formData = new FormData();
                formData.append("categories", JSON.stringify(res));
                HttpRequest.post(formData).then((res2) => {
                    if (res2 && Array.isArray(res2)) {
                        setCategoriesList(
                            res2.map((cat) => ({
                                value: cat.ID,
                                label: cat.name,
                            }))
                        );
                    }
                });
            } else {
                errorModal("Ha ocurrido un error obteniendo las categorías");
            }
        });
    }, []);

    return (
        <Form onFinish={submit} className="category-form-container">
            <p className="center-text">
                Seleccione las categorías de tipificaciones asociadas a
                finanzas
            </p>
            <Select
                mode="multiple"
                onChange={(vals) => setCategoriesValues(vals)}
                value={categoryValues}
                className="category-form-select"
            >
                {categoriesList.map((cat) => (
                    <Select.Option value={cat.value} key={cat.value}>
                        {cat.label}
                    </Select.Option>
                ))}
            </Select>
            <Popover
                content={
                    <CategoryForm
                        setCategoriesList={setCategoriesList}
                        currentValues={categoryValues}
                        setCurrentValues={setCategoriesValues}
                    />
                }
                title="Title"
                trigger="click"
            >
                <Button>+</Button>
            </Popover>
            <Button htmlType="submit" type="primary" className="center-btn">
                Guardar
            </Button>
        </Form>
    );
}

export default FinanceCategories;
