import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropType from 'prop-types'
import styled from 'styled-components'

import ACTIONS from './../../redux/creator'

import { Panel, PanelHeader, PanelBody } from '../panel/panel'
import Icon from '../icons/Icon'
import EmptyContent from './../content/empty'
import PanelRightHeader from './../sidebar-right/panel-right-header';
import { GeneralTab } from './../sidebar-right/sidebar-content/general';
import { device } from '../../helpers/helpers'

const BottomSheetUI = styled.div`
	position: fixed;
	top: calc(100% - 40px);
	width: 100%;
	overflow: scroll;
	z-index: 100;
	background: white;
	display: block;
	&.show {
		top: 101px
	}
	.panel {
		height: calc(100vh - 101px);
		margin-bottom: 0;
		.panel-heading {
			.panel-title {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
	@media screen and ${device.mobile} {
		display: none;
	}
`

const BottomSheet = props => {
	const [section, changeSection] = useState("general")

	const toggle = () => {
		console.log("Ok")
		const sidebar = props.sidebar
		const newState = {
			...props.sidebar,
			active: !sidebar.active,
			show: !sidebar.show
		}
		props.toggleSidebar(newState)
	}

	const handleSection = (event) => changeSection(event.target.value)

	return (
		<BottomSheetUI className={props.sidebar.show ? "show" : ""}>
			<Panel>
				<PanelHeader noButton>
					Sidebar
					<Icon icon={props.sidebar.show ? "angle-down" : "angle-up"} onClick={toggle} />
				</PanelHeader>
				<PanelBody className={(!props.sidebar || !props.sidebar.child) && 'full-height'}>
					{
						!props.sidebar || !props.sidebar.child
							? <EmptyContent />
							: (
								<>
									<PanelRightHeader handleSection={handleSection} />
									<hr />
									{section === "general" && <GeneralTab />}
								</>
							)
					}
				</PanelBody>
			</Panel>
		</BottomSheetUI>
	)
}

BottomSheet.propTypes = {
	isOpen: PropType.bool
}

BottomSheet.defaultProps = {
	isOpen: false
}

const mapStateToProps = state => ({
	sidebar: state.sidebar
})

const mapDispatchToProps = dispatch => ({
	toggleSidebar(payload) {
		dispatch(ACTIONS.toggleSidebar(payload))
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(BottomSheet)