import React, { useState } from "react";
import { connect } from "react-redux";

import DrawerRight from "./drawer";
//import Event from "../../pages/event/Event";
import DrawerConfig from './DrawerConfig';
import './drawer.css';
import { canWithoutMessage } from "../../helpers/helpers";
import { typifications } from "../../config/permissions";
/**
 *
 * @function  Returns right panel mapping props.list creating a floating button
 * and a Drawer for every item.
 *
 * @param {[object]} props a list of dictionaries with an icon for the floating button,
 * the content, width and title of the drawer. Icons must be different for each other or the drawer will no render
 *
 */
const PanelRight = (props) => {
    // const contenido = props.contenido;

    const contenido = [
        /*{
            icon: "calendar",
            content: <p>Content</p>,
            width: "20%",
            title: "Calendario",
        },
        { icon: "cog", content: <p>Content</p>, width: "30%" },
        {
            icon: "code",
            content: <p>Content</p>,
            width: "20%",
            title: "Código",
        },
        {
            icon: "envelope",
            content: <iframe className='drawer-iframe' title='Messagebird' src='https://optic.ladesk.com/agent/index.php#Conversations'></iframe>,
            width: "80%",
            title: "Mensajes",
        },
        {
            icon: "bullhorn",
            content: <p>Content</p>,
            width: "20%",
            title: "Anuncios",
        },
        //{ icon: "envelope", content: <Event />, width: "80%" },
        { icon: "cog", content: <p>pntent</p>, width: "30%" },*/
        {
            icon: "cog",
            content: <DrawerConfig />,
            title: "Configuraciones",
            width: "80%",
        },
        {
            icon: "key",
            content: canWithoutMessage(typifications.view) && <iframe className='drawer-iframe' title='Messagebird' src='https://inbox.messagebird.com/'></iframe>,
            width: "80%",
            title: "MessageBird",
        },
    ];

    var icons = [];
    var skippedItems = 0;

    /**
     * @function sets de height of the floating buttons starting from the middle of the screen
     * towards the top and bottom ends.
     * @param {[number]} index
     */
    const setHeight = (index) => {
        var height;
        var odd = index % 2 == 0;
        odd
            ? (height = 90 / 2 + (7 * index) / 2 + 3)
            : (height = 90 / 2 - (7 * index) / 2);
        return height;
    };

    return (
        <div>
            {contenido.map((item, index) => {
                if (index - skippedItems < 11 && !icons.includes(item.icon)) {
                    icons.push(item.icon);
                    var height = setHeight(index - skippedItems);
                    return (
                        <DrawerRight
                            key={index}
                            height={height}
                            title={item.title}
                            content={item.content}
                            icon={item.icon}
                            width={item.width}
                        />
                    );
                } else {
                    skippedItems = skippedItems + 1;
                }
            })}
        </div>
    );
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
});

export default connect(mapStateToProps)(PanelRight);
