import React from "react";
import SearchTables from "../TypificationsTables/SearchTables";
import IclassTable from "./../iclass/IclassTable";

function IclassSearchTables(props) {
	const endpoint = "iclass/service_orders/table/";
	return (
		<>
			<SearchTables
				endpoint={endpoint}
				component={<IclassTable initialParams={props.initialParams} />}
			/>
		</>
	);
}

export default IclassSearchTables;
