import { Button, Spin, Tabs, Drawer, Modal } from "antd";
import React, { useState, useEffect } from "react";
import EscalationForm from "./FinanceEscalationForm";
import Typify from "../typify/typify";
import HttpRequest from "../../services/HttpRequest";
import CustomerInfoModal from "../typify/customerInfoModal";
import ServiceInfoModal from "../typify/servicesInfoModal";
import PhotoList from "../removeEquipment/PhotoList";
import FinanceEscalationHistory from "./FinanceEscalationHistory";
import FinanceEscalationMultimedia from "./FinanceEscalationMultimedia";
import Conversation from "../conversations/Conversation";

function FinanceEscalationFull(props) {
	const [escalation, setEscalation] = useState(null);
	const [loading, setLoading] = useState(true);
	const [customerModal, setCustomerModal] = useState(true);
	const [serviceModal, setServiceModal] = useState(false);
	const [showHistory, setShowHistory] = useState(false);
	const [showPhotos, setShowPhotos] = useState(false);
	const [showMultimedia, setShowMultimedia] = useState(false);

	const getEscalation = () => {
		HttpRequest.endpoint = "escalation_finance/escalation";
		HttpRequest.get(null, props.id).then((res) => {
			setEscalation(res);
			setLoading(false);
		});
	};
	const handleTabClick = (e) => {
		if (Number(e) === 1) {
			setCustomerModal(true);
			setServiceModal(false);
		} else {
			setServiceModal(true);
			setCustomerModal(false);
		}
	};

	useEffect(getEscalation, []);

	return (
		<>
			{!loading ? (
				<>
					<Tabs
						centered
						tabBarExtraContent={[
							<Button
								className="escalation-top-button"
								onClick={() => setShowMultimedia(true)}
								type="primary"
							>
								Multimedia
							</Button>,
							<Button
								className="escalation-top-button"
								onClick={() => setShowPhotos(true)}
								type="primary"
							>
								Fotos
							</Button>,
							<Button
								className="escalation-top-button"
								onClick={() => setShowHistory(true)}
								type="primary"
							>
								Historial
							</Button>,
							<Button onClick={() => props.closeTab(props.tabID)}>
								Salir
							</Button>,
						]}
					>
						<Tabs.TabPane tab="Escalamiento" key="escalation">
							<EscalationForm id={props.id} />
						</Tabs.TabPane>
						{escalation && escalation.typify && (
							<Tabs.TabPane tab="Tipificación" key="typify">
								<Typify
									id={escalation.typify}
									hideButtons
									hideTabs
									hideSmallButtons
									finanzasView={true}
								/>
							</Tabs.TabPane>
						)}
						{escalation && escalation.rut && (
							<Tabs.TabPane tab="Cliente" key="Client">
								<Tabs centered onTabClick={handleTabClick}>
									<Tabs.TabPane tab="Cliente" key="1">
										<CustomerInfoModal
											notModal
											visible={customerModal}
											setVisible={setCustomerModal}
											rut={escalation.rut}
										/>
									</Tabs.TabPane>
									<Tabs.TabPane tab="Servicios" key="2">
										<ServiceInfoModal
											notModal
											visible={serviceModal}
											setVisible={setServiceModal}
											rut={escalation.rut}
										/>
									</Tabs.TabPane>
								</Tabs>
							</Tabs.TabPane>
						)}
						{escalation && (
							<Tabs.TabPane tab="Conversaciones" key="conversations">
								<Tabs centered>
									<Tabs.TabPane tab="Whatsapp" key="whatsapp">
										<Conversation hidecustomer={true} hideTypify={true} messagesEndpoint="communications/whatsapp_message/" type="whatsapp" id={escalation.whatsapp_conversation} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="Correo" key="email">
										<Conversation hidecustomer={true} hideTypify={true} messagesEndpoint="communications/email_message/" type="email" id={escalation.email_conversation} />
									</Tabs.TabPane>
								</Tabs>
							</Tabs.TabPane>
						)}
					</Tabs>
					<Modal
						title="Fotos"
						width={800}
						onCancel={() => setShowPhotos(false)}
						visible={showPhotos}
						footer={null}
					>
						<PhotoList
							endpoint="escalation_finance/escalation_photo/"
							photoFieldName="escalation"
							removeID={props.id}
						/>
					</Modal>
					<Modal
						title="Multimedia"
						onCancel={() => setShowMultimedia(false)}
						visible={showMultimedia}
						footer={null}
					>
						<FinanceEscalationMultimedia
							escalationData={escalation}
							escalationID={props.id}
						/>
					</Modal>
					<Drawer
						title="Historial"
						placement="right"
						width="600px"
						closable={false}
						onClose={() => setShowHistory(false)}
						visible={showHistory}
					>
						<FinanceEscalationHistory escalationID={props.id} />
					</Drawer>
				</>
			) : (
				<Spin />
			)}
		</>
	);
}

export default FinanceEscalationFull;
