import React, { useEffect, useState } from "react";
import { Form, Comment, Card, Button, Timeline, Tooltip, Modal, Row } from "antd";
import moment from "moment";
import { CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import HttpRequest from "../../services/HttpRequest";
import Moment from "react-moment";

const TypifyHistory = (props) => {

    const [first, setFirst] = useState();
    const [changes, setChanges] = useState([])
    const [agentList, setAgentList] = useState();
    const [dates, setDates] = useState([]);
    const [firstDate, setFirstDate] = useState();
    const [technicianList, setTechnicianList] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);

    let channelLabel = { 0: "Correo", 1: "Llamada", 2: "Chat", 3: "Redes", 4: "WhatsApp", 5: "Facebook", 6: "Instagram" }
    let statusLabel = { 0: "Abierto", 1: "Cerrado", 2: "Esperando respuesta", 3: "Dar seguimiento", 4: "Llamar luego" }
    let urgencyLabel = { 0: "Normal", 1: "Crítico", 2: "Reclamo" }
    let cityLabel = { 0: "Arica", 1: "Santiago", 2: "Otra" }
    let managementLabel = { 0: "Ninguna", 1: "Visita técnica", 2: "Instalación", 3: "Retiro de equipos", 4: "Configuración online" }
    let keysLabel = {
        "city": "Ciudad", "agent": "Agente", "channel": "Canal", "customer_type": "Tipo de cliente", "discount_porcentage": "Porcentaje descuento",
        "discount_reason": "Razón del descuento", "discount_responsable_area": "Area", "liveagent": "Liveagent", "management": "Gestión", "technician": "Técnico",
        "status": "Estado", "type": "Tipo", "urgency": "Urgencia", "category": "Categoría", "services": "Servicios"
    }


    const getHistory = (id) => {
        let changeList = [];
        HttpRequest.endpoint = "tickets/typify/" + id + "/history/";
        HttpRequest.get().then((res) => {
            setFirst(res["first"]);
            getCategoryName(res["first"]["category"])
            setFirstDate(
                <div>
                    <Moment format="DD/MM/YYYY hh:mm">
                        {res["first"]["created"]}
                    </Moment>
                    <p>{agentList[res["first"]["creator"]]}</p>
                </div>)
            res["changes"].forEach(element => {
                if (Object.keys(element).includes("created") || element.length <= 2) {
                    return;
                };
                let label = (
                    <div>
                        <Moment format="DD/MM/YYYY hh:mm">
                            {element["updated"]["new_value"]}
                        </Moment>
                        <p>{agentList[element["updater"]["new_value"]]}</p>
                    </div>);

                let list = [];
                Object.keys(element).forEach(key => {
                    if (key == "updater" || key == "updated") {
                        return;
                    }
                    list.push(<div>
                        <h5>{keysLabel[key]}</h5>
                        Valor nuevo:
                        {{
                            channel: " " + channelLabel[element[key]["new_value"]],
                            status: " " + statusLabel[element[key]["new_value"]],
                            urgency: " " + urgencyLabel[element[key]["new_value"]],
                            city: " " + cityLabel[element[key]["new_value"]],
                            management: " " + managementLabel[element[key]["new_value"]],
                            agent: " " + agentList[element[key]["new_value"]]
                        }[key]}
                        <br />
                        <br />
                    </div>)
                });
                if (list.length > 0) {
                    changeList.push(
                        < Timeline.Item label={label}>
                            <Card bordered={true} >
                                {list}
                            </Card>
                        </Timeline.Item>);
                }

            });
            setChanges(changeList)
        })
    }

    const getCategoryName = (id) => {
        let list = [];
        let formData = {};
        HttpRequest.endpoint = "tickets/category/" + id + "/";
        HttpRequest.get(formData).then((res) => {
            list.push(res["name"])
            console.log(list);
            setCategoryNames(list)
        })

    }

    const getAgents = () => {
        let list = {};
        HttpRequest.endpoint = "user/data/information/";
        HttpRequest.get().then((res) => {
            res.forEach(element => {
                list[element["ID"]] = element["name"]
            });
            setAgentList(list);
        })
    }

    const getTechnicians = () => {
        let list = {};
        HttpRequest.endpoint = "matrix/technicians/";
        HttpRequest.get().then((res) => {
            res.forEach(element => {
                list[element["id"]] = element["name"]
            });
            setTechnicianList(list);
        })
    }

    useEffect(() => {
        getAgents();
        getTechnicians();
    }, [])

    useEffect(() => {
        if (agentList && technicianList && props.id) getHistory(props.id);
    }, [agentList, technicianList])

    return (
        <div>
            {first && categoryNames.length > 0 && (
                <Timeline mode="alternate">
                    <Timeline.Item label={firstDate}>
                        <Card title="Creado" bordered={true} style={{ width: 300 }} >
                            <p>Servicios: {first["services"]}<br />
                            Liveagent: {first["liveagent"]}<br />
                            Canal: {channelLabel[first["channel"]]}<br />
                            Tipo: {first["type"] == "0" ? "IN" : "OUT"}<br />
                            Estado: {statusLabel[first["status"]]}<br />
                            Categoría: {categoryNames[0]}<br />
                            Agente asignado: {agentList[first["agent"]]}</p>
                        </Card>
                    </Timeline.Item>
                    {changes}
                </Timeline >
            )}


            {/* <Timeline.Item>Solve initial network problems 2015-09-01</Timeline.Item>
            <Timeline.Item>Technical testing 2015-09-01</Timeline.Item>
            <Timeline.Item>Network problems being solved 2015-09-01</Timeline.Item> */}
        </div>
    );


}

export default TypifyHistory;