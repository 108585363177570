import React, { useState, useEffect, useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Modal, Button, Carousel, Row, Spin } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import styled from 'styled-components';

function PhotoList(props) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentPhotos, setCurrentPhotos] = useState([]);
    const [photoList, setPhotoList] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const photoRef = useRef()

    const uploadPhoto = ({ file, onSuccess }) => {
        HttpRequest.endpoint = props.endpoint || "formerCustomers/remove_equipament_photo/";
        const formData = new FormData();
        formData.append(props.photoFieldName || 'remove_equipament', props.removeID);
        formData.append('image', file)
        HttpRequest.post(formData).then(res => {
            if (res && res.ID) {
                onSuccess("ok")
                Modal.success({
                    title: 'Éxito',
                    content: 'Se ha guardado la imagen con éxito'
                })
                getPhotos()
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error guardando la foto'
                })
            }
        })
    }

    const deletePhoto = () => {
        setButtonLoading(true);
        let photoToDelete;
        currentPhotos.forEach((photo, i) => {
            if (i === currentSlide) {
                photoToDelete = photo.ID;
            }
        })
        HttpRequest.endpoint = props.endpoint || "formerCustomers/remove_equipament_photo/";
        HttpRequest.delete(photoToDelete).then(res => {

            console.log(res, 'respuesta delete');
            getPhotos()
            setButtonLoading(false);

        })
    }
    const getPhotos = () => {
        setLoading(true)
        HttpRequest.endpoint = props.endpoint || "formerCustomers/remove_equipament_photo/";
        const filters = {}
        if (props.photoFieldName) {
            filters[props.photoFieldName] = props.removeID
        } else {
            filters["remove_equipament"] = props.removeID
        }
        HttpRequest.get(filters).then(res => {
            console.log(res, 'respuesta')
            if (res && Array.isArray(res)) {
                if (res) {
                    newListPhotos(res)
                } else {
                    setCurrentPhotos([])
                }
                setLoading(false)

            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo las fotos actuales'
                })
            }
        })
    }

    function newListPhotos(imgArray) {
        var newList = imgArray.map((e) => {
            var nameImg = e.image.split('/media/')
            return (
                {
                    ID: e.ID,
                    image_url: process.env.REACT_APP_IRIS_BACKEND + '/media/' + nameImg[1],
                    created: e.created,
                    creator: e.creator,
                    deleted: e.delete,
                    operator: e.operator,
                    remove_equipament: e.remove_equipament,
                    updated: e.updated,
                    updater: e.updater,
                }
            )
        })
        setCurrentPhotos(newList)
    }


    useEffect(() => {
        getPhotos()
    }, [])

    return (
        <>
            {
                loading ?
                    <Spin size='large' className='center-spin' />
                    :
                    <>
                        <Row justify='center'>
                            <Upload
                                customRequest={uploadPhoto}
                            >
                                <Button className='center-btn' icon={<UploadOutlined />}>Agregar foto</Button>
                            </Upload>
                        </Row>
                        <CusttomCarousel afterChange={setCurrentSlide} ref={photoRef} dots={false} >
                            {
                                currentPhotos.map(photo =>
                                    <ConterImage>
                                        <Image alt='Error cargando' key={photo.ID} src={photo.image_url} />
                                    </ConterImage>)
                            }
                        </CusttomCarousel>
                        {
                            currentPhotos.length > 0 &&
                            <Row justify='center'>
                                <Button type='primary' onClick={() => photoRef.current.prev()}>Anterior</Button>
                                <Button danger type='primary' onClick={deletePhoto} loading={buttonLoading}>Eliminar foto actual</Button>
                                <Button type='primary' onClick={() => photoRef.current.next()}>Siguiente</Button>
                            </Row>
                        }
                    </>
            }
        </>
    )
}

export default PhotoList;


const CusttomCarousel = styled(Carousel)`
    background-color: #f5f2f2;
    margin: 10px auto;
`
const Image = styled.img`
    width: 70%; 
    min-width: 350px;
    height: 300px;
`
const ConterImage = styled.section`&&&{
    height: auto;
    display: flex !important;
    justify-content: center;
    padding: 10px;
    
  }`