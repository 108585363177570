import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import Avatar from "./../user/Avatar";

class SidebarProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileActive: 0,
        };
    }

    render() {
        const { user } = this.props;

        return (
            <PageSettings.Consumer>
                {({ pageSidebarMinify }) => (
                    <ul className="nav">
                        {/*<li
							className={
								"nav-profile " + (this.state.profileActive ? "expand " : "")
							}
						>
							<Link to="/">
								<div className="cover with-shadow"></div>
								<img
									className="logo-company"
									src="./../assets/img/logo/logo-banda-ancha-1.png"
									alt=""
								/>
								<div className="info">{user ? user.username : null}</div>
							</Link>
						</li>*/}
                        <li>
                            <ul
                                className={
                                    "nav nav-profile " +
                                    (this.state.profileActive && !pageSidebarMinify
                                        ? "d-block "
                                        : "")
                                }
                            >
                                <li>
                                    <Link to="/">
                                        <i className="fa fa-cog"></i> Settings
									</Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i className="fa fa-pencil-alt"></i> Send Feedback
									</Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i className="fa fa-question-circle"></i> Helps
									</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                )}
            </PageSettings.Consumer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProfile);
