import React, { useState, useEffect } from 'react';
import HttpRequest from "../../services/HttpRequest.js";
import { Form, Input, Button, Modal } from 'antd';
import { can, validOperator } from '../../helpers/helpers.js';
import { client } from '../../config/permissions.js';
import autoCloseModal from './../../components/modal/AutoCloseModal';

function EmailForm(props) {
    const [formItemNewEmailList, setFormItemNewEmailList] = useState([]);
    const [formItemSavedEmailList, setFormItemSavedEmailList] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);

    const validateNewClient = () => {
        if (!props.customerInfo && !props.newClient) {
            Modal.warning({
                title: "Advertencia",
                content: 'Debe crear un usuario antes de poder agregarle información de contacto'
            })
        }
    }
    const submitForm = formData => {
        if (validOperator()) {
            const keys = Object.keys(formData);
            if (keys.length > 0) {
                setButtonLoading(true)
                if (props.newClient) {
                    if (can(client.create)) {
                        HttpRequest.endpoint = `customers/customer/${props.newClient.ID}/add_email/`
                    } else {
                        return
                    }
                } else if (props.customerInfo) {
                    if (can(client.edit)) {
                        HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/add_email/`
                    } else {
                        return
                    }
                }
                Promise.all(
                    keys.map(key => {
                        const data = new FormData();
                        data.set('email', formData[key])
                        //{ email: formData[key] }
                        return HttpRequest.post(data)
                    })
                ).then(allRes => {
                    setButtonLoading(false)
                    let failedEmails = 0;
                    let repeated = false;
                    allRes.forEach(res => {
                        if (res['Error email']) {
                            failedEmails++
                        } else if (res['Error email repeat']) {
                            Modal.error({
                                title: 'Error',
                                content: 'Este email ya se encuentra registrado.'
                            })
                            repeated = true;
                        }
                    })
                    if (failedEmails) {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al agregar el email.'
                        })
                    } else {
                        if (repeated === false) {
                            autoCloseModal('Se han agregado los emails con éxito')
                            props.updateRows();
                        }
                    }
                }).catch(err => {
                    console.log('error catch al agregar emails', err);
                    setButtonLoading(false)
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error al agregar emails'
                    })
                })
            }
        }
    }
    const deleteEmail = (index, isNew, email = null) => {
        if (!isNew) {
            if (can(client.edit)) {
                HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/delete_email/`
                const data = new FormData()
                data.set('email', email)
                HttpRequest.post(data).then(res => {
                    console.log(res)
                    if (res['Error email']) {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al eliminar el email.'
                        })
                    } else {
                        setFormItemSavedEmailList(prevState => {
                            let emailIndexToDelete = 0;
                            prevState.forEach((emailDiv, i) => {
                                if (Number(emailDiv.key) === index) {
                                    emailIndexToDelete = i;
                                }
                            })
                            return prevState.slice(0, emailIndexToDelete).concat(prevState.slice(emailIndexToDelete + 1))
                        });
                        autoCloseModal('Se ha eliminado el email con éxito')
                        props.updateRows();
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        } else {
            setFormItemNewEmailList(prevState => {
                let emailIndexToDelete = 0;
                prevState.forEach((emailDiv, i) => {
                    if (Number(emailDiv.key) === index) {
                        emailIndexToDelete = i;
                    }
                })
                return prevState.slice(0, emailIndexToDelete).concat(prevState.slice(emailIndexToDelete + 1))
            });
        }
    }
    const editEmail = (e, i) => {
        if (can(client.edit)) {
            const newEmail = e.target.value;
            const oldEmail = props.customerInfo.emails[i];
            if (oldEmail !== newEmail) {
                HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/add_email/`;
                const data = new FormData()
                data.set('email', newEmail)
                HttpRequest.post(data).then(res => {
                    if (res['Error email']) {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al editar el email.'
                        })
                    } else if (res['Error email repeat']) {
                        Modal.error({
                            title: 'Error',
                            content: 'Este email ya se encuentra registrado.'
                        })
                    } else {
                        HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/delete_email/`;
                        const data = new FormData();
                        data.set('email', oldEmail);
                        HttpRequest.post(data).then(res => {
                            if (res['Error email']) {
                                Modal.error({
                                    title: 'Error',
                                    content: 'Ha ocurrido un error al editar el email.'
                                })
                            } else {
                                autoCloseModal('Se he editado el email con éxito')
                                props.updateRows();
                            }
                        })
                    }
                })
            }
        }
    }

    const generateNewEmailField = () => {
        if (props.customerInfo) {
            if (!can(client.edit)) {
                return
            }
        } else if (props.newClient) {
            if (!can(client.create)) {
                return
            }
        }
        const actualEmailFields = [...formItemNewEmailList];
        const newEmailNumber = formItemNewEmailList.length + formItemSavedEmailList.length + 1;
        actualEmailFields.push(
            <div key={newEmailNumber} className='client-phone-field'>
                <Form.Item name={'email' + newEmailNumber} rules={[{ required: true, whitespace: true, message: 'Este campo es obligatorio' }, { type: 'email', message: 'Formato de email inválido' }]}>
                    <Input placeholder={'Email ' + newEmailNumber} />
                </Form.Item >
                <Button danger type='primary' onClick={() => deleteEmail(newEmailNumber, true)}>X</Button>
            </div>
        )
        setFormItemNewEmailList(actualEmailFields)
    }
    const generateSavedEmailFields = () => {
        if (props.customerInfo) {
            const prevEmails = [];
            props.customerInfo.emails.forEach((email, i) => {
                prevEmails.push(
                    <div key={i + 1} className='client-phone-field'>
                        <Form.Item initialValue={email} name={'email' + i} rules={[{ required: true, whitespace: true, message: 'Este campo es obligatorio' }, { type: 'email', message: 'Formato de email inválido' }]}>
                            <Input placeholder={'Email ' + i} onBlur={e => editEmail(e, i)} />
                        </Form.Item >
                        <Button danger type='primary' onClick={() => deleteEmail(i + 1, false, email)}>X</Button>
                    </div>
                )
            })
            return setFormItemSavedEmailList(prevEmails);
        }
    }

    useEffect(validateNewClient, [])
    useEffect(generateSavedEmailFields, [])

    return (
        <>
            {(props.newClient || props.customerInfo) &&
                <>
                    <Form>
                        {formItemSavedEmailList}
                    </Form>
                    <Form name='email-form' onFinish={submitForm}>
                        <Button type='primary' onClick={generateNewEmailField} style={{ display: 'block', margin: '0 auto 15px auto' }}>Agregar nuevo email</Button>
                        {formItemNewEmailList}
                        <Button disabled={formItemNewEmailList.length === 0 && formItemSavedEmailList.length === 0} type='primary' htmlType='submit' style={{ display: 'block', margin: '0 auto' }} loading={buttonLoading}>Guardar</Button>
                    </Form>
                </>
            }
        </>
    )
}

export default EmailForm;