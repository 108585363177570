import React, { useState, useEffect } from "react";
import FullTable from "../../components/datatable/FullTable";
import { Tag, Modal, Form, Input, Button } from "antd";
import { client } from "../../config/permissions";
import SOWizard from "./SOWizard";
import HttpRequest from "./../../services/HttpRequest";
import Moment from "react-moment";
import moment from "moment";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import errorModal from "./../../components/modal/errorModal";
import { connect } from "react-redux";
import Chat from "../tickets/Chat";

function IclassTable(props) {
	const [tableRows, setTableRows] = useState([]);
	const [forceReload, setForceReload] = useState(false);
	const [agendamientoData, setAgendamientoData] = useState(null);
	const [colors, setColors] = useState(null);
	const [slackInfo, setSlackInfo] = useState(null);

	const endpoints = {
		columns:
			"iclass/service_orders/table/" +
			props.tableID +
			"/datatables_struct/",
		rows: "iclass/service_orders/table/" + props.tableID + "/datatables/",
		delete: `iclass/service_orders/table/`,
		download: "iclass/service_orders/download_data/",
	};

	const findColor = (osName) => {
		let color = "white";
		colors.forEach((osObj) => {
			if (osObj.type === osName) {
				color = osObj.color;
			}
		});
		return color;
	};

	const closeOS = (id) => {
		const formData = new FormData();
		formData.append(
			"agent",
			JSON.parse(localStorage.getItem("userInfo")).username
		);
		HttpRequest.endpoint = `iclass/service_orders/${id}/close_order/`;
		HttpRequest.post(formData).then((res) => {
			if (res && res.id) {
				autoCloseModal("Se ha cerrado la orden de servicio con éxito");
				setForceReload(!forceReload);
			} else {
				errorModal(
					"Ha ocurrido un error cerrando esta orden de servicio"
				);
			}
		});
	};

	const handleRows = (res) => {
		setTableRows(
			res.map((row) => ({
				...row,
				custom_cancel: (
					<Button
						danger
						type="primary"
						onClick={() =>
							moment().isAfter(row.end_date)
								? errorModal(
									"Esta orden de servicio ya pasó y no puede ser cancelada"
								)
								: setAgendamientoData({ id: row.ID })
						}
					>
						Cancelar
					</Button>
				),
				start_date: (
					<Moment locale="es" format="DD/MM/YYYY HH:mm">
						{row.start_date}
					</Moment>
				),
				end_date: (
					<Moment locale="es" format="DD/MM/YYYY HH:mm">
						{row.end_date}
					</Moment>
				),
				status: row.status && row.status.value,
				kind: (
					<p
						style={{
							backgroundColor: findColor(row.kind),
							padding: "5px",
							borderRadius: "5px",
							textAlign: "center",
						}}
					>
						{row.kind}
					</p>
				),
				slack_thread: (
					<Button
						disabled={!row.slack_thread}
						onClick={() =>
							setSlackInfo({
								channel: row.slack_channel,
								thread: row.slack_thread,
							})
						}
					>
						Ver
					</Button>
				),
				custom_close: (
					<Button
						disabled={row.status?.code !== "0"}
						onClick={() => closeOS(row.ID)}
						danger
						type="primary"
					>
						Cerrar
					</Button>
				),
				sla: (
					<Moment fromNow ago locale="en">
						{moment().add(-1 * row.sla, "s")}
					</Moment>
				)
			}))
		);
	};

	const cancelAgendamiento = (values) => {
		const formData = new FormData();
		formData.append("motive", values.reason);
		formData.append("agent", JSON.parse(localStorage.getItem("userInfo")).username);
		HttpRequest.endpoint =
			"iclass/service_orders/" + agendamientoData.id + "/delete_order/";
		HttpRequest.post(formData).then((res) => {
			if (res && res.detail === "Service order deleted") {
				autoCloseModal(
					"Se ha cancelado la orden de servicio con éxito"
				);
				setAgendamientoData(null);
				setForceReload(!forceReload);
			} else {
				errorModal(
					"Ha ocurrido un error cancelando esta orden de servicio"
				);
			}
		});
	};

	useEffect(() => {
		setColors(null);
		HttpRequest.endpoint = "iclass/service_orders/so_color/";
		HttpRequest.get({ operator: props.operator }).then((res) => {
			if (res && Array.isArray(res)) {
				setColors(res);
			} else {
				errorModal(
					"Ha ocurrido un error obteniendo los colores de las ordenes de servicio"
				);
			}
		});
	}, [props.operator]);

	return (
		<>
			{colors && (
				<FullTable
					forceReload={forceReload}
					modalContent={<SOWizard />}
					endpoints={endpoints}
					handleRows={handleRows}
					tableRows={tableRows}
					tableName="OS"
					permissions={client}
					modalWidth={800}
					initialParams={props.initialParams}
				/>
			)}
			<Modal
				visible={agendamientoData}
				footer={null}
				onCancel={() => setAgendamientoData(null)}
			>
				<Form onFinish={cancelAgendamiento}>
					<h1 className="center-text">Cancelar agendamiento</h1>
					<Form.Item
						label="Motivo"
						name="reason"
						rules={[
							{
								required: true,
								message: "Este campo es requerido",
							},
						]}
					>
						<Input.TextArea />
					</Form.Item>
					<Button type="primary" block htmlType="submit">
						Cancelar
					</Button>
				</Form>
			</Modal>
			{slackInfo && (
				<Modal
					visible={slackInfo}
					footer={null}
					onCancel={() => setSlackInfo(null)}
				>
					<Chat channel={slackInfo.channel} ts={slackInfo.thread} />
				</Modal>
			)}
		</>
	);
}

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(IclassTable);
