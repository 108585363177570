import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import TicketsWizard from './TicketsWizard';
import Moment from "react-moment";
import { Tag } from 'antd';
import { tech_support } from '../../config/permissions';

function TicketsTable(props) {
    const [tableRows, setTableRows] = useState([]);

    let endpoints = {
        columns: "tech_support/ticket/" + props.tableID + "/datatables_struct/",
        rows: "tech_support/ticket/" + props.tableID + "/datatables/",
        delete: `tech_support/ticket/`,
        download: 'tech_support/ticket/' + props.tableID + '/download_data/',
    }

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            commits: row.commits.map(commit => <Tag key={commit}>{commit}</Tag>),
            trello_urls: row.trello_urls.map(url => <Tag key={url}>{url.split(/https:\/\/trello.com\/c\/[A-Za-z0-9]+\/[0-9]+-/)[1]}</Tag>),
            updated: <Moment fromNow locale="es">{row.updated}</Moment>
        })))
    }
    return (
        <>
            <FullTable permissions={tech_support} handleRows={handleRows} tableRows={tableRows} modalContent={<TicketsWizard />} modalWidth={800} buttons={[]} endpoints={endpoints} tableName='ticket' />
        </>
    )
}

export default TicketsTable;