import React, { Fragment } from "react";
import FullTable from '../../components/datatable/FullTable';

const Webhooks = (props) => {
    let endpoints = {
        columns: "hooks_send/datatables_struct/",
        rows: "hooks_send/datatables_content/",
    };
    
    return (
        <Fragment>
            <FullTable buttons={[]} endpoints={endpoints} tableName='llamadas' hookTitle={props.hookTitle} />
        </Fragment>
    )
};

export default Webhooks;
