import React, { useState, useEffect } from 'react';
import { Button, Row, Modal } from 'antd';
import HttpRequest from './../../services/HttpRequest';
import errorModal from './../../components/modal/errorModal';
import { canGroup } from '../../helpers/helpers';

function Step1(props) {
    const [loading, setLoading] = useState(false);
    const [disabledFields, setDisabledFiels] = useState({})

    const handleResponsible = isResponsible => {
        setLoading(true);
        HttpRequest.endpoint = "tech_support/ticket/";
        const formData = new FormData();
        formData.append('status', isResponsible ? 1 : 2); //1 aceptado, 2 rechazado
        HttpRequest.patch(props.ticket, formData).then(res => {
            if (res.ID) {
                props.updateRows && props.updateRows()
                props.setTicketInfo(res)
                if (isResponsible) {
                    props.setStep(2)
                } else {
                    setLoading(false)
                    Modal.info({
                        title: 'Información',
                        content: 'Se ha rechazado el ticket. Por favor indique en el hilo de Slack la razón'
                    })
                }
            } else {
                setLoading(false)
                errorModal('Ha ocurrido un error')
            }
        })
    }

    useEffect(() => {
        const disabledItems = {
            button: !canGroup(props.ticketInfo.assigned_team_name)
        }
        setDisabledFiels(disabledItems);
    }, [props.ticketInfo])

    return (
        <>
            {props.ticketInfo.status === 2 && <p className='center-text'>Este ticket fue rechazado</p>}
            <p className='center-text'><strong>Sistema asignado: </strong>{props.ticketInfo.assigned_team_name}</p>
            <p className='center-text'>¿Es responsabilidad del equipo de desarrollo?</p>
            <Row justify='center'>
                <Button loading={loading} disabled={disabledFields.button} type='primary' onClick={() => handleResponsible(true)}>Sí lo es</Button>
                <Button loading={loading} disabled={disabledFields.button} onClick={() => handleResponsible(false)}>No lo es</Button>
            </Row>
        </>
    )
}

export default Step1;