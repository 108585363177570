import React from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import HttpRequest from "../../services/AuthenticationService";

class CustomerInformation extends React.Component {
	state = {
		navTitleTabs: ["Datos Personales", "Servicios"],
		activeTab: "0",
		endpoint: "matrix/customers/",
		data: {},
		customer__rut: "21.903.155-6",
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	async componentDidMount(event) {
		let end = HttpRequest;
		end.endpoint = this.state.endpoint;
		await end.get({ rut: "21.903.155-6" }, null, false).then((res) => {
			this.setState({ data: res[0] });
		});
	}

	render() {
		let custom = this.state.data;
		return (
			<div>
				<Breadcrumb />
				<div className="row">
					<div className="col-md-12">
						<Panel>
							<PanelHeader>
								Información del cliente:{" "}
								{this.state.customer__rut}{" "}
							</PanelHeader>
							<PanelBody>
								<div className="row">
									<div className="col-md-12">
										<Nav tabs>
											{this.state.navTitleTabs.map(
												(i, k) => (
													<NavItem key={i}>
														<NavLink
															className={classnames(
																{
																	active:
																		this
																			.state
																			.activeTab ===
																		`${k}`,
																}
															)}
															onClick={() => {
																this.toggleTab(
																	`${k}`
																);
															}}
														>
															<span className="d-sm-none">
																{
																	this.state
																		.navTitleTabs[
																		k
																	]
																}
															</span>
															<span className="d-sm-block d-none">
																{
																	this.state
																		.navTitleTabs[
																		k
																	]
																}
															</span>
														</NavLink>
													</NavItem>
												)
											)}
										</Nav>
										<TabContent
											activeTab={this.state.activeTab}
										>
											<TabPane tabId="0">
												<table
													cellPadding="1"
													cellSpacing="1"
													className="table table-bordered table-striped"
												>
													<tbody>
														<tr>
															<td> Nombre </td>
															<td> Tipo </td>
															<td> Correo </td>
															<td> Teléfono </td>
															<td>
																{" "}
																Día de Pago
															</td>
														</tr>
														<tr>
															<td>
																{custom.name}
															</td>
															<td>{}</td>
															<td>
																{custom.email}
															</td>
															<td>
																{custom.phone}
															</td>
															<td>
																{
																	custom.default_due_day
																}
															</td>
														</tr>
														<tr>
															<td colSpan="2">
																{" "}
																Dirección{" "}
															</td>
															<td> Comuna </td>
															<td> Nota </td>
															<td> Balance </td>
														</tr>
														<tr>
															<td colSpan="2">
																{
																	custom.composite_address
																}
															</td>
															<td>
																{custom.commune}
															</td>
															<td>
																{custom.notes}
															</td>
															<td>{}</td>
														</tr>
													</tbody>
												</table>
											</TabPane>
											<TabPane tabId="1">
												<table
													cellPadding="1"
													cellSpacing="1"
													className="table table-bordered table-striped"
												>
													<tbody>
														<tr>
															<td> IP </td>
															<td>
																{" "}
																Tipo de
																Documento{" "}
															</td>
															<td>
																{" "}
																Día de Pago{" "}
															</td>
															<td> Estado </td>
														</tr>
														<tr>
															<td>
																{" "}
																{
																	custom.name
																}{" "}
															</td>
															<td> {} </td>
															<td>
																{" "}
																{
																	custom.email
																}{" "}
															</td>
															<td>
																{" "}
																{
																	custom.phone
																}{" "}
															</td>
														</tr>
														<tr>
															<td> SSID </td>
															<td>
																{" "}
																Tecnología{" "}
															</td>
															<td>
																{" "}
																¿Auto corte?{" "}
															</td>
															<td>
																{" "}
																¿Visto
																conectado?{" "}
															</td>
														</tr>
														<tr>
															<td>
																{" "}
																{
																	custom.composite_address
																}{" "}
															</td>
															<td>
																{" "}
																{
																	custom.commune
																}{" "}
															</td>
															<td> si </td>
															<td> no </td>
														</tr>
														<tr>
															<td> Dirección </td>
															<td> Comuna </td>
															<td>
																{" "}
																¿Auto corte?{" "}
															</td>
															<td>
																{" "}
																¿Visto
																conectado?{" "}
															</td>
														</tr>
														<tr>
															<td>
																{" "}
																{
																	custom.composite_address
																}{" "}
															</td>
															<td>
																{" "}
																{
																	custom.commune
																}{" "}
															</td>
															<td> </td>
															<td> {}</td>
														</tr>
													</tbody>
												</table>
											</TabPane>
										</TabContent>
									</div>
								</div>
							</PanelBody>
						</Panel>
					</div>
				</div>
			</div>
		);
	}
}

export default CustomerInformation;
