import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { typifications } from '../../config/permissions';
import AgreementsForm from './AgreementsForm';
import CanalesForm from './AgreementsForm';

function AgreementsTable() {
    const endpoints = {
        delete: 'retentions/agreement/',
        columns: 'retentions/agreement/datatables_struct/',
        rows: 'retentions/agreement/datatables/',
        download: 'retentions/agreement/download_data/',
    };

    return (
        <>
            <FullTable permissions={typifications} modalContent={<AgreementsForm />} endpoints={endpoints} tableName='canal' />
        </>
    )
}

export default AgreementsTable;