import { Button, Form } from "antd";
import React, { useState } from "react";
import EscalationUrl from "../escalations/EscalationUrl";
import HttpRequest from "../../services/HttpRequest";
import autoCloseModal from "../../components/modal/AutoCloseModal";
import errorModal from "../../components/modal/errorModal";

function FinanceEscalationMultimedia(props) {
	const [edit, setEdit] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const editUrl = (values) => {
		setButtonLoading(true);
		const urls = [];
		for (let key in values) {
			if (key.includes("link")) {
				urls.push(values[key]);
			}
		}
		const formData = new FormData();
		formData.append("media_urls", JSON.stringify(urls));
		HttpRequest.endpoint = "escalation_finance/escalation/";
		HttpRequest.patch(props.escalationData.ID, formData).then((res) => {
			setButtonLoading(false);
			if (res && res.ID) {
				autoCloseModal("Se han editado los links con éxito");
			} else {
				errorModal("Ha ocurrido un error editando los links");
			}
		});
	};
	return (
		<>
			{!edit ? (
				<>
					<p className="center-text">
						A continuación el listado de links multimedia de este
						escalamiento
					</p>
					<ul>
						{props.escalationData &&
							props.escalationData.media_urls.map((link) => {
								return (
									<li key={link}>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={link}
										>
											{link}
										</a>
									</li>
								);
							})}
					</ul>
					<Button onClick={() => setEdit(true)}>Editar</Button>
				</>
			) : (
				<Form onFinish={editUrl}>
					<EscalationUrl
						initialData={props.escalationData.media_urls}
					/>
					<Button
						htmlType="submit"
						type="primary"
						loading={buttonLoading}
					>
						Guardar
					</Button>
				</Form>
			)}
		</>
	);
}

export default FinanceEscalationMultimedia;
