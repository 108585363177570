import React, { useEffect, useState } from "react";
import { Tabs, Table, Modal } from "antd";
import HttpRequest from "../../services/HttpRequest";
import Moment from "react-moment";

const ServiceInfoModal = (props) => {

    const [serviceInfo, setServiceInfo] = useState({});
    const [nodeInfo, setNodeInfo] = useState({});
    const [planInfo, setPlanInfo] = useState({});
    const [serviceTabs, setServiceTabs] = useState([]);
    const { TabPane } = Tabs;

    const serviceFields = {
        ip: 'Ip', document_type: 'Tipo de documento', due_day: 'Día de pago', status: 'Estado', ssid: 'SSID', technology_kind: 'Tecnología', composite_address: 'Dirección',
        commune: 'Comuna', allow_auto_cut: '¿Autocorte?', seen_connected: '¿Visto conectado?', mac_onu: 'MAC de ONU', network_status: 'Estado de red', activated_on: 'Fecha de Activación',
        installed_on: 'Fecha de Instalación', uninstalled_on: 'Fecha de desinstalación', expired_on: 'Fecha de expiración', billing_notes: 'Nota facturación', internal_notes: 'Nota Interna'
    }
    const serviceConstants = {
        status: {
            1: 'Activo',
            2: 'Pendiente',
            3: 'Inactivo',
            4: 'No instalado',
            5: 'Libre',
            6: 'Instalación rechazada',
            7: 'Plan no en venta',
            8: 'Moroso',
            9: 'A reprogramar',
            10: 'Cambio de titular',
            11: 'Descartado',
            12: 'En espera',
            13: 'Perdido'
        },
        technology_kind: {
            1: 'UTP',
            2: 'GPON',
            3: 'GEPON',
            4: 'Antena',
        }
    }

    const nodeFields = {
        code: "Código", status: "Estado", address: "Dirección", commune: "Comuna", is_building: "¿Es edificio?", is_optical_fiber: "¿Es fibra?",
        gpon: "GPON", gepon: "GEPON"
    }
    const nodeConstants = {
        status: {
            1: 'Cableado',
            2: 'Pendiente',
            3: 'En progreso',
            4: 'Sin etiqueta',
            5: 'Descartado'
        }
    }

    const planFields = { plan: "Plan", price: "Precio", category: "Categoría", uf: "UF", active: "¿Activo?" }
    const planConstants = {
        category: {
            1: 'Internet',
            2: 'Duo',
            3: 'Telefonía',
            4: 'Red',
            5: 'Televisión'
        }
    }

    const infoColumns = [
        { title: 'Campo', dataIndex: 'field', key: 'field' },
        { title: 'Valor', dataIndex: 'value', key: 'value' },
    ]

    const getServices = (rut) => {
        let serviceData = [];
        HttpRequest.endpoint = "matrix/services/"
        let filter = { customer__rut: rut }
        HttpRequest.get(filter).then((res) => {
            res.forEach((element) => {
                getTab(element)
            });
        })

    }

    const getTab = (res) => {
        let serviceData = [];
        Object.keys(res).map((key) => {
            if (Object.keys(serviceFields).includes(key)) {
                serviceData.push({ key: key, field: serviceFields[key], value: typeof res[key] == "boolean" ? res[key] ? "Si" : "No" : Array.isArray(res[key]) ? res[key][1] : (key == "activated_on" || key == "installed_on") ? <Moment fromNow locale="es">{res[key]}</Moment> : typeof res[key] === 'number' && Object.keys(serviceConstants).includes(key) ? serviceConstants[key][res[key]] : res[key] })
            }
        })
        setServiceInfo({ ...serviceInfo, [res["number"]]: serviceData });
        getNode(res["node"], res["number"])
        getPlan(res["plan"], res["number"])
    }

    const getNode = (link, number) => {
        let nodeData = [];
        let nodeEndpoint = "matrix/nodes" + link.split("nodes")[1]
        HttpRequest.endpoint = nodeEndpoint
        HttpRequest.get().then((res) => {
            Object.keys(res).map((key) => {
                if (Object.keys(nodeFields).includes(key)) {
                    nodeData.push({ key: key, field: nodeFields[key], value: typeof res[key] == "boolean" ? res[key] ? "Si" : "No" : typeof res[key] === 'number' && Object.keys(nodeConstants).includes(key) ? nodeConstants[key][res[key]] : res[key] })
                }
            })
            setNodeInfo({ ...nodeInfo, [number]: nodeData });
        })

    }

    const getPlan = (link, number) => {
        let planData = [];
        let planEndpoint = "matrix/" + link.split("/v1/")[1]
        HttpRequest.endpoint = planEndpoint;
        HttpRequest.get().then((res) => {
            Object.keys(res).map((key) => {
                if (Object.keys(planFields).includes(key)) {
                    planData.push({ key: key, field: planFields[key], value: typeof res[key] == "boolean" ? res[key] ? "Si" : "No" : typeof res[key] === 'number' && Object.keys(planConstants).includes(key) ? planConstants[key][res[key]] : res[key] })
                }
            })
            setPlanInfo({ ...planInfo, [number]: planData });
        })
    }

    useEffect(() => {
        if (props.rut != null) {
            getServices(props.rut)
        }
    }, [props.rut])
    return (
        <>
            {props.notModal ?
                <Tabs type="card">
                    {Object.keys(serviceInfo).map((service) => {
                        return (
                            <TabPane key={service} tab={service}>
                                <Tabs type="card">
                                    <TabPane tab="Servicio" key="1">
                                        <Table columns={infoColumns} dataSource={serviceInfo[service]} pagination={false} size="small" />
                                    </TabPane>
                                    <TabPane tab="Nodo" key="2">
                                        <Table columns={infoColumns} dataSource={nodeInfo[service]} pagination={false} size="small" />
                                    </TabPane>
                                    <TabPane tab="Plan" key="3">
                                        <Table columns={infoColumns} dataSource={planInfo[service]} pagination={false} size="small" />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                        )
                    })}
                </Tabs>
                :
                <Modal footer={null} visible={props.visible} width="65%" onCancel={() => props.setVisible(false)} >
                    <Tabs type="card">
                        {Object.keys(serviceInfo).map((service) => {
                            return (
                                <TabPane key={service} tab={service}>
                                    <Tabs type="card">
                                        <TabPane tab="Servicio" key="1">
                                            <Table columns={infoColumns} dataSource={serviceInfo[service]} pagination={false} size="small" />
                                        </TabPane>
                                        <TabPane tab="Nodo" key="2">
                                            <Table columns={infoColumns} dataSource={nodeInfo[service]} pagination={false} size="small" />
                                        </TabPane>
                                        <TabPane tab="Plan" key="3">
                                            <Table columns={infoColumns} dataSource={planInfo[service]} pagination={false} size="small" />
                                        </TabPane>
                                    </Tabs>
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </Modal>}
        </>
    )
}


export default ServiceInfoModal;