import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Select, Modal, Input } from 'antd';
import { useForm } from "antd/lib/form/Form";
import HttpRequest from '../../services/HttpRequest';
import { connect } from 'react-redux';
import FactibilityConfiguration from './FactibilityConfiguration';
import autoCloseModal from './../../components/modal/AutoCloseModal';

function WizardConfigurations(props) {
    const [formLoading, setFormLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(null);
    const [categories, setCategories] = useState({
        type: [],
        category: [],
        subcategory: [],
        secondSubcategory: []
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const [processInfo, setProcessInfo] = useState(null);
    const [subcategories, setSubcategories] = useState([]);
    const [requiredEmail, setRequiredEmail] = useState(false)
    const [emailTemplateList, setEmailTemplateList] = useState([])
    const [form] = useForm();

    const getInitialData = () => {
        if (props.id) {
            HttpRequest.endpoint = 'tickets/process'
            HttpRequest.get(null, props.id).then(res => {
                if (res) {
                    setProcessInfo(res)
                }
                if (res.description.sender != undefined) {
                    form.setFieldsValue({
                        'email': res.description.sender,
                        'template': res.description.template
                    })
                    setRequiredEmail(true)
                }
                if (res.description && res.name !== 'Factibilidad') {
                    if (res.description.category) {
                        getRecursiveCategories(res.description.category)
                    } else {
                        setFormLoading(false);
                    }
                } else {
                    setFormLoading(false);
                }
            })
        } else {
            setFormLoading(false);
        }
    }
    const getTypeCategories = () => {
        HttpRequest.endpoint = "tickets/category/";
        HttpRequest.get({ operator: props.operator, classification: 1 }).then(res => {
            const typeCategories = [];
            res.forEach(cat => {
                typeCategories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                type: typeCategories
            }));
        })
    }
    const getTemplateList = () => {
        HttpRequest.endpoint = "communications/template_email/";
        HttpRequest.get({ operator: props.operator }).then(res => {
            const templateList = [];
            templateList.push(<Select.Option key={null} value={null}>Ninguna</Select.Option>)
            res.forEach(template => {
                templateList.push(<Select.Option key={template.ID} value={template.ID}>{template.name}</Select.Option>)
            })
            setEmailTemplateList(templateList)
        })
    }
    const getChildCategories = (classification, parent) => {
        HttpRequest.endpoint = "tickets/category/";
        HttpRequest.get({ operator: props.operator, parent: parent }).then(res => {
            const categories = [];
            res.forEach(cat => {
                categories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                [classification]: categories
            }));
            if (classification === 'subcategory') {
                setSubcategories(res);
            }
        })
    }
    const getRecursiveCategories = id => {
        HttpRequest.endpoint = "tickets/category/" + id + "/";
        HttpRequest.get().then((res) => {
            switch (res["classification"]) {
                case 4:
                    getChildCategories("secondSubcategory", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        secondSubcategory: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 3:
                    getChildCategories("subcategory", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        subcategory: res["ID"]
                    }))
                    setSubcategories(res);
                    getRecursiveCategories(res["parent"])
                    break;
                case 2:
                    getChildCategories("category", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        category: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 1:
                    //getTypeCategories();
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        type: res["ID"]
                    }))
                    setFormLoading(false);
                    break;
                default:
                    break;
            }
        })
    }
    const submitForm = e => {
        setButtonLoading(true);
        const formData = new FormData();
        if (e.template != undefined) {
            formData.set("description", JSON.stringify({
                "category": e.secondSubcategory || e.subcategory,
                "template": e.template,
                "sender": e.email
            }));
        } else {
            formData.set("description", JSON.stringify({
                "category": e.secondSubcategory || e.subcategory
            }));
        }
        HttpRequest.endpoint = 'tickets/process/'
        HttpRequest.patch(props.id, formData).then(res => {
            setButtonLoading(false);
            if (res.ID) {
                autoCloseModal('Se ha editado la configuración con éxito')
                props.closeModal()
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error editando la configuración'
                })
            }
        })
    }
    useEffect(() => {
        getTypeCategories();
        getTemplateList();
    }, [props.operator])
    useEffect(getInitialData, [])


    return (
        <>
            {!formLoading &&
                <>
                    {processInfo.name === 'Factibilidad' ?
                        categories.type.length > 0 && <FactibilityConfiguration id={props.id} typeCats={categories.type} processInfo={processInfo} />
                        :
                        <Form form={form} name='typification-slack' initialValues={initialValues} onFinish={submitForm}>
                            <Form.Item label='Tipo' name='type' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                <Select onChange={(e) => { getChildCategories('category', e); form.setFieldsValue({ category: null, subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.type}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Categoría' name='category' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                <Select onChange={(e) => { getChildCategories('subcategory', e); form.setFieldsValue({ subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.category}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Subcategoría' name='subcategory' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                <Select onChange={(e) => { getChildCategories('secondSubcategory', e); form.setFieldsValue({ secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.subcategory}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Segunda subcategoría' name='secondSubcategory'>
                                <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.secondSubcategory}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Plantilla Correo' name='template'>
                                <Select onChange={(e) => { e != null ? setRequiredEmail(true) : setRequiredEmail(false) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {emailTemplateList}
                                </Select>
                            </Form.Item>
                            {requiredEmail && <Form.Item label='Correo Emisor' name='email' rules={[{ required: requiredEmail, message: 'Este campo es requerido' }, { type: "email", message: "Por favor ingrese un email válido" }]}>
                                <Input type="email" />
                            </Form.Item>}
                            <Button block size='large' loading={buttonLoading} type='primary' htmlType='submit'>{props.id ? 'Guardar' : 'Crear'}</Button>
                        </Form>}
                </>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(WizardConfigurations);