import React, { useState } from "react";
import CycleCreateForm from "./CycleCreateForm.jsx";
import { endPointCreateCycle, endPointCycleDataTable, endPointCycleTableStruct } from "../../services/endpoints";
import { Switch, Modal } from "antd";
import HttpRequest from "../../services/HttpRequest";
import FullTable from '../../components/datatable/FullTable';
import { can } from "../../helpers/helpers.js";
import { cycle } from "../../config/permissions.js";

function Cycle2() {
    const [tableRows, setTableRows] = useState([]);
    const [forceReload, setForceReload] = useState(false);

    const endpoints = {
        columns: endPointCycleTableStruct,
        rows: endPointCycleDataTable,
        delete: endPointCreateCycle,
    }
    const errors = {
        "Cycle active": "Error. El ciclo ya está activo o ya está ejecutando un test",
        "Datetime error": "Error. La fecha de inicio de este ciclo ya pasó",
    };

    const handleCheckedChange = (checked, id, isTest) => {
        if (can(cycle.edit)) {
            if (checked) {
                HttpRequest.endpoint = endPointCreateCycle + id + (isTest ? "/test/" : "/activate/");
                const formData = new FormData();
                formData.append("id", id)
                HttpRequest.post(formData).then(res => {
                    Object.keys(res).forEach(element => {
                        if (element in errors) {
                            Modal.error({
                                title: 'Error',
                                content: errors[element]
                            })
                        }
                    });
                    Object.values(res).forEach((element) => {
                        Object.keys(element).forEach(element2 => {
                            if (element2 in errors) {
                                Modal.error({
                                    title: 'Error',
                                    content: errors[element]
                                })
                            }
                        });
                    });
                    setForceReload(!forceReload)
                });
            } else {
                HttpRequest.endpoint = endPointCreateCycle + id + "/inactive/";
                HttpRequest.post().then((res) => {
                    setForceReload(!forceReload)
                });
            }
        }
    }

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            test: <Switch checked={row.test} onChange={checked => handleCheckedChange(checked, row.ID, true)} />,
            active: <Switch checked={row.active} onChange={checked => handleCheckedChange(checked, row.ID, false)} />
        })))
    }

    return (
        <>
            <FullTable permissions={cycle} forceReload={forceReload} modalContent={<CycleCreateForm />} endpoints={endpoints} handleRows={handleRows} tableRows={tableRows} tableName='ciclo' />
        </>
    )
}

export default Cycle2;