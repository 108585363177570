import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import TicketsTable from './TicketsTable';

function TicketSearchTables() {
    const endpoint = 'tech_support/ticket_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<TicketsTable />} />
        </>
    )
}

export default TicketSearchTables;