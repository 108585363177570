import React, { useState, useEffect } from "react";
import HttpRequest from "../../services/HttpRequest";

import { Input, Space, InputNumber, DatePicker, Select } from "antd";
import SelectSearch from "../../components/select/select-search";

//Moverlo para la dataTable List
import moment from "moment";

export const FilterComp = (props) => {
    const [typeCompoment, setTypeCompoment] = useState([]);
    const [comparison, setComparison] = useState("");
    const [optionChoices, setOptionChoices] = useState([]);
    const [optionValue, setOptionValue] = useState(null);
    const [optionHumanRead, setOptionHumanRead] = useState(null);
    const [name, setName] = useState(null);
    const [selectVal, setSelectVal] = useState(undefined);

    const setOptionCompare = (name, value) => {
        let filter = Object.values(props.filters).filter(
            (i) => i.name === name
        );
        let compare = Object.values(props.comparison).filter(
            (i) => i.name === value
        );

        let typeOp = compare[0].type.includes(filter[0].type)
            ? filter[0].type
            : compare[0].type[0];

        setComparison(value);
        setTypeCompoment(typeOp);
    };

    const setComponent = (value) => {
        switch (value) {
            case "int":
                return (
                    <InputNumber
                        name="number"
                        placeholder="Ingrese datos"
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        onChange={(value) => onChange("int", value)}
                        defaultValue={props.initialVals && props.initialVals[2]}
                    />
                );
            case "str":
                return (
                    <Input
                        name="dato"
                        placeholder="Ingrese datos"
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        onChange={(e) => onChange("str", e.target.value)}
                        initialValue={props.initialVals && props.initialVals[2]}
                    />
                );
            case "datetime":
                return (
                    <DatePicker
                        format="DD/MM/YYYY HH:mm"
                        showTime={{
                            defaultValue: moment("00:00:00", "HH:mm"),
                        }}
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        onChange={(date, dateString) =>
                            onChange("datatime", dateString)
                        }
                        defaultValue={props.initialVals && moment(props.initialVals[2], "DD/MM/YYYY HH:mm")}
                    />
                );
            case "choices":
                return (
                    <SelectSearch
                        style={{
                            width: 200,
                            fontSize: 12,
                        }}
                        name={"compare"}
                        action={onChange}
                        dataSources={optionChoices}
                        placeholder={"Creador"}
                        optionValue={optionValue}
                        optionHumanRead={optionHumanRead}
                        defaultValue={props.initialVals && props.initialVals[2]}
                    />
                );
            case "bool":
                return (
                    <Select onChange={val => onChange('bool', val)} placeholder='Seleccione' initialValue={props.initialVals && props.initialVals[2] ? 'True' : 'False'}>
                        <Select.Option value='True'>Sí</Select.Option>
                        <Select.Option value='False'>No</Select.Option>
                    </Select>
                )
            default:
                break;
        }
    };

    const onChange = (espName, value) => {
        props.function(
            name,
            props.filters[name].name,
            comparison,
            value
        );
    };

    async function getChoice() {
        /*let filter = Object.values(props.filters).filter(
            (i) => i.type_choices === "choices"
        );*/

        const filter = props.filters[name]
        if (filter.values.type === 'dinamic') {
            setOptionValue(filter.values.extra_data.read_data.value);
            setOptionHumanRead(filter.values.extra_data.read_data.human_readable);
            HttpRequest.endpoint = filter.values.extra_data.url;
            await HttpRequest.get().then((res) => {
                setOptionChoices(res);
            });
        } else {
            const options = Object.entries(filter.values.extra_data);
            setOptionValue(0);
            setOptionHumanRead(1);
            setOptionChoices(options)
        }
    }
    const handleSelectChange = e => {
        Object.keys(props.filters).forEach(espName => {
            if (e === props.filters[espName].name) {
                setName(espName);
            }
        })
        setSelectVal(e)
    }
    const getSelectValue = () => {
        if (props.initialVals) {
            props.nameOptions.forEach(opt => {
                if (opt.props.value === props.initialVals[0]) {
                    handleSelectChange(props.initialVals[0])
                    setOptionCompare(props.initialVals[0], props.initialVals[1])
                    props.function(opt.props.children, props.initialVals[0], props.initialVals[1], props.initialVals[2])
                }
            })
        }
    }

    useEffect(() => {
        if (name && props.filters[name].type === "choices") {
            getChoice();
        }
    }, [name]);

    useEffect(getSelectValue, [])

    return (
        <div
            key={props.index}
            className="align-filter"
            style={{ textAlign: "left", width: '80%' }}
        >
            <Space>
                <div>
                    <Select value={selectVal} placeholder='Seleccionar filtro' onChange={handleSelectChange}>
                        {props.nameOptions}
                    </Select>
                </div>
                {name && <div>
                    <SelectSearch
                        style={{
                            width: 200,
                            textAlign: "left",
                            fontSize: 12,
                        }}
                        name={props.filters[name].name}
                        action={setOptionCompare}
                        dataSources={props.typeOfComparison[props.filters[name].type]}
                        placeholder={"Condición"}
                        defaultValue={props.initialVals && props.initialVals[1]}
                    />
                </div>}
                <div>{setComponent(typeCompoment)}</div>
            </Space>
        </div>
    );
};

export default FilterComp;