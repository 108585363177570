import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, Spin } from 'antd';
import ColumnItem from './ColumnItem';
import './drives.css'
import { connect } from 'react-redux';
import HttpRequest from '../../services/HttpRequest';
import Config from "../../config";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import { drives } from '../../config/permissions';
import { can } from '../../helpers/helpers';

function TableForm(props) {
    const [formLoading, setFormLoading] = useState(true);
    const [defaultData, setDefaultData] = useState(null);
    const [columns, setColumns] = useState([]);
    const [initialColumns, setInitialColumns] = useState([]);
    const [columnsSubmit, setColumnsSubmit] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [id, setId] = useState(0);

    const submitForm = e => {
        if (!can(drives.definition.create)) {
            return;
        }
        let valid = true;
        if (Object.keys(columnsSubmit).length === 0) {
            return Modal.error({
                title: 'Error',
                content: 'Debe definir al menos 1 columna'
            })
        }
        Object.keys(columnsSubmit).forEach(key => {
            if (!columnsSubmit[key].name) {
                valid = false;
                Modal.error({
                    title: 'Error',
                    content: 'Debe definir el nombre de todas las columnas'
                })
            }
            if (!/^[A-Za-z0-9_]+$/i.test(columnsSubmit[key].name)) {
                valid = false;
                Modal.error({
                    title: 'Error',
                    content: 'El campo de nombre solo puede contener caracteres alfanuméricos (sin tildes) y guión bajo (_)'
                })
            }
            ['ID', 'operator', 'created', 'creator'].forEach(word => {
                if (word === columnsSubmit[key].name) {
                    valid = false;
                    Modal.error({
                        title: 'Error',
                        content: 'El campo de nombre no puede llamarse ' + word
                    })
                }
            })
        })
        if (!valid) {
            return
        }
        e.definition = Object.values(columnsSubmit);
        setButtonLoading(true)
        const formData = new FormData();
        formData.append('name', e.name);
        e.description && formData.append('description', e.description)
        formData.append('definition', JSON.stringify(e.definition))
        HttpRequest.endpoint = 'drives/definition/'
        HttpRequest.post(formData).then(res => {
            setButtonLoading(false)
            const errors = {
                'Description repeat error': 'No pueden haber columnas con el mismo nombre',
                'Error default_value format: phone format': 'El formato del número teléfonico es incorrecto',
                'Error dynamic_services value': 'El servicio especificado no existe',
                'Error float value': 'El número decimal debe contener decimal',
                'Error int value': 'El número entero no debe contener decimales',
                'Description default_value error: static selects': 'No se pueden tener opciones iguales en el campo de tipo opciones',
                'Error default_value value: email': 'El formato del email es inválido',
                "Error address value": 'La dirección especificada no existe',
                'Description error: static selects': 'Debe especificar al menos 1 opción en el campo de tipo opciones'
            }
            let error = 0;
            Object.keys(errors).forEach(err => {
                if (Object.keys(res).length > 0 && Object.keys(res)[0].includes(err)) {
                    error++
                    Modal.error({
                        title: 'Error',
                        content: errors[err]
                    })
                }
            })
            if (error === 0) {
                autoCloseModal('Se ha creado la definición con éxito')
                setButtonLoading(false)
                props.updateRows()
            }
        }).catch(err => {
            console.log(err);
            Modal.error({
                title: 'Error',
                content: 'Ha ocurrido un error creando la definición'
            })
        })
    }
    const editForm = e => {
        if (!can(drives.definition.edit)) {
            return;
        }
        let valid = true;
        Object.keys(columnsSubmit).forEach(key => {
            if (!columnsSubmit[key].name) {
                valid = false;
                Modal.error({
                    title: 'Error',
                    content: 'Debe definir el nombre de todas las columnas'
                })
            }
            if (!/^[A-Za-z0-9_]+$/i.test(columnsSubmit[key].name)) {
                valid = false;
                Modal.error({
                    title: 'Error',
                    content: 'El campo de nombre solo puede contener caracteres alfanuméricos (sin tildes) y guión bajo (_)'
                })
            }
            ['ID', 'operator', 'created', 'creator'].forEach(word => {
                if (word === columnsSubmit[key].name) {
                    valid = false;
                    Modal.error({
                        title: 'Error',
                        content: 'El campo de nombre no puede llamarse ' + word
                    })
                }
            })
        })
        if (!valid) {
            return
        }
        setButtonLoading(true)
        const formData = new FormData();
        formData.append('name', e.name);
        e.description && formData.append('description', e.description);
        HttpRequest.endpoint = 'drives/definition/'
        HttpRequest.patch(props.id, formData).then(res => {
            if (res.name) {
                if (columns.length > 0) {
                    const definition = Object.values(columnsSubmit)[0];
                    fetch(Config.API_URL + '/drives/definition/' + props.id + '/create_column/', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${localStorage.getItem("authorization")}`,
                        },
                        body: JSON.stringify(definition)
                    }).then(res => res.json()).then(res => {
                        setButtonLoading(false)
                        const errors = {
                            'Description repeat error': 'No pueden haber columnas con el mismo nombre',
                            'Error default_value format: phone format': 'El formato del número teléfonico es incorrecto',
                            'Error dynamic_services value': 'El servicio especificado no existe',
                            'Error float value': 'El número decimal debe contener decimal',
                            'Error int value': 'El número entero no debe contener decimales',
                            'Description default_value error: static selects': 'No se pueden tener opciones iguales en el campo de tipo opciones',
                            'Error default_value value: email': 'El formato del email es inválido',
                            "Error address value": 'La dirección especificada no existe',
                            'Description error: static selects': 'Debe especificar al menos 1 opción en el campo de tipo opciones'
                        }
                        let error = 0;
                        Object.keys(errors).forEach(err => {
                            if (Object.keys(res).length > 0 && Object.keys(res)[0].includes(err)) {
                                error++
                                Modal.error({
                                    title: 'Error',
                                    content: errors[err]
                                })
                            }
                        })
                        if (error === 0) {
                            autoCloseModal('Se ha editado la definición con éxito')
                            props.updateRows()
                            setButtonLoading(false)
                        }
                    }).catch(err => {
                        console.log(err);
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error editando las columnas'
                        })
                    })
                } else {
                    autoCloseModal('Se ha editado el drive con éxito')
                    props.updateRows()
                    setButtonLoading(false)
                }
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error editando el drive'
                })
            }
        })
    }

    const getDefaultData = () => {
        if (props.id) {
            HttpRequest.endpoint = 'drives/definition';
            HttpRequest.get(null, props.id).then(res => {
                if (res.name) {
                    setDefaultData(res);
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo la data de este drive'
                    })
                }
            })
        } else {
            setFormLoading(false);
        }
    }
    const deleteColumn = key => {
        setColumns(prevState => prevState.filter(col => Number(col.key) !== key));
        setColumnsSubmit(prevState => {
            delete prevState[key]
            return prevState
        })
    }
    const deleteEditColumn = (frontID, backID, colName) => {
        if (!can(drives.definition.edit)) {
            return;
        }
        fetch(Config.API_URL + '/drives/definition/' + backID + '/delete_column/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            },
            body: JSON.stringify({ column_name: colName })
        }).then(res => {
            if (res.ok) {
                setInitialColumns(prevState => prevState.filter(col => Number(col.key) !== frontID))
                autoCloseModal('Se ha eliminado la columna ' + colName + ' con éxito')
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error eliminando la columna ' + colName
                })
            }
        }).catch(err => {
            console.log(err)
            Modal.error({
                title: 'Error',
                content: 'Ha ocurrido un error eliminando la columna ' + colName
            })
        })
    }
    const generateInitialColumns = () => {
        if (defaultData && defaultData.definition && defaultData.definition.length > 0) {
            const defaultColumns = []
            defaultData.definition.forEach(col => {
                setId(prevState => {
                    defaultColumns.push(
                        <div key={prevState}>
                            <ColumnItem data={col} driveID={defaultData.ID} id={prevState} deleteColumn={() => deleteEditColumn(prevState, defaultData.ID, col.name)} />
                        </div>
                    )
                    return prevState + 1
                })
            })
            setInitialColumns(defaultColumns)
            setFormLoading(false);
        } else {
            if (defaultData) {
                setFormLoading(false);
            }
        }
    }
    const addNewColumn = () => {
        const actualColumns = [...columns];
        actualColumns.push(
            <div key={id}>
                <ColumnItem id={id} setColumnsSubmit={setColumnsSubmit} deleteColumn={deleteColumn} />
            </div>
        )
        setColumns(actualColumns);
        setId(prevState => prevState + 1);
    }

    const initialValues = {
        name: defaultData ? defaultData.name : null,
        description: defaultData ? defaultData.description : null
    }

    useEffect(getDefaultData, [])
    useEffect(generateInitialColumns, [defaultData])

    return (
        <>
            {!formLoading ?
                <>
                    <Form name='tables-form' initialValues={initialValues} onFinish={props.id ? editForm : submitForm}>
                        <Form.Item name='name' label='Nombre' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='description' label='Descripción'>
                            <Input />
                        </Form.Item>
                        <Button onClick={addNewColumn} disabled={props.id && columns.length === 1} type='primary' className='center-btn add-filter-btn'>Agregar columna</Button>
                        {initialColumns}
                        {columns}
                        <Button size='large' htmlType='submit' block type='primary' loading={buttonLoading}>{props.id ? 'Guardar' : 'Crear'}</Button>
                    </Form>
                </>
                :
                <Spin size='large' className='center-spin' />
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(TableForm);