import ACTIONS from "./actions";

export default {
    token: (state = localStorage.getItem("authorization"), action) => {
        if (state && state.length > 5)
            localStorage.setItem("authorization", state);
        switch (action.type) {
            case ACTIONS.SET_AUTHORIZATION_TOKEN:
                localStorage.setItem("authorization", action.payload);
                return action.payload;
            case ACTIONS.CLOSE_SESSION:
                return null;
            default:
                return state;
        }
    },
    notification: (state = { type: "default", message: null }, action) => {
        switch (action.type) {
            case ACTIONS.SET_NOTIFICATION:
                return action.payload;
            case ACTIONS.CLOSE_NOTIFICATION:
                return { type: "default", message: null };
            default:
                return state;
        }
    },
    user: (
        state = localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo"))
            : JSON.parse(localStorage.getItem("userInfo")),
        action
    ) => {
        switch (action.type) {
            case ACTIONS.SET_USER:
                return action.payload;
            case ACTIONS.CLOSE_SESSION:
                return null;
            default:
                return state;
        }
    },
    preferences: (
        state = localStorage.getItem("preferences")
            ? JSON.parse(localStorage.getItem("preferences"))
            : [],
        action
    ) => {
        switch (action.type) {
            case ACTIONS.SET_PREFERENCE:
                return state
                    .filter((item) => item.section !== action.payload.section)
                    .concat(action.payload.content);
            case ACTIONS.REMOVE_PREFERENCE:
                return state.filter(
                    (item) => item.section !== action.payload.section
                );
            default:
                return state;
        }
    },
    httpError: (state = false, action) => {
        switch (action.type) {
            case ACTIONS.SET_HTTP_ERROR:
                return action.payload;
            case ACTIONS.CLOSE_SESSION:
                return false;
            default:
                return state;
        }
    },
    httpErrorSystem: (state = '', action) => {
        switch (action.type) {
            case ACTIONS.SET_HTTP_ERROR_SYSTEM:
                return action.payload;
            default:
                return state;
        }
    },
    sidebar: (
        state = localStorage.getItem("sidebar")
            ? JSON.parse(localStorage.getItem("sidebar"))
            : { show: false, active: false, child: null },
        action
    ) => {
        switch (action.type) {
            case ACTIONS.TOGGLE_RIGHT_SIDEBAR:
                localStorage.setItem("sidebar", JSON.stringify(action.payload));
                return action.payload;
            default:
                return state;
        }
    },
    operator: (state = Number(localStorage.getItem('operator')) || 2, action) => {
        switch (action.type) {
            case ACTIONS.SET_OPERATOR:
                return action.payload;
            default:
                return state;
        }
    },
    company: (state = Number(localStorage.getItem('company')) || 1, action) => {
        switch (action.type) {
            case ACTIONS.SET_COMPANY:
                return action.payload;
            default:
                return state;
        }
    },
    notifications: (state = localStorage.getItem('notifications') || [], action) => {
        switch (action.type) {
            case ACTIONS.SET_NOTIFICATIONS:
                return action.payload;
            default:
                return state;
        }
    },
};
