import React from 'react'
import { Row, Col } from 'reactstrap';
import Image from './../images/Image'
import BoxPNG from './../../assets/images/box.png'
import styled from 'styled-components'

const EmptyContentUI = styled.div`
	height: 100%;
	img {
		max-width: 150px;
	}
`


const EmptyContent = props => (
	<EmptyContentUI className="full-height">
		<Row className="justify-content-center align-content-center full-height">
			<Col sm={12} className="row mb-3 justify-content-center">
				<Image src={BoxPNG} />
			</Col>
			<Col sm={12}>
				<p className="text-center">En este momento no hay datos cargados.</p>
			</Col>
		</Row>
	</EmptyContentUI>
);

export default EmptyContent