import { Spin, Table, Row, Button, Modal, Tag } from 'antd';
import React, { useState, useEffect } from 'react';
import HttpRequest from '../../services/HttpRequest';
import Moment from 'react-moment'

function PaymentAmount(props) {
    const [customerData, setCustomerData] = useState(null)
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false)
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);
    const [empty, setEmpty] = useState(false);

    const saveTipification = () => {
        setButtonLoading(true)
        const formData = new FormData();
        formData.append('customer_type', 3)
        formData.append('channel', props.channel);
        formData.append('status', 1);
        formData.append('category', props.categories.category);
        formData.append('rut', JSON.stringify([props.rut]));
        formData.append('services', JSON.stringify(props.services));
        formData.append('type', 0);
        formData.append('process', props.process)
        HttpRequest.endpoint = "tickets/typify/";
        HttpRequest.post(formData).then((res) => {
            setButtonLoading(false)
            if (res["ID"]) {
                //Modal.success({ title: "Éxito", content: "Se ha creado la tipificación exitosamente" })
                props.startAgain()
                props.updateRows()
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al crear la tipificación" })
            }
        })
    }

    useEffect(() => {
        HttpRequest.endpoint = 'matrix/invoices/';
        HttpRequest.get({ customer__rut: props.rut, paid_on__isnull: 'True' }).then(res => {
            if (Array.isArray(res) && res.length > 0) {
                const debts = [];
                const tableRows = [];
                res.forEach(invoice => {
                    let foundService = false;
                    invoice.service.forEach(serv => {
                        if (props.services.includes(serv.number)) {
                            foundService = true
                        }
                    })
                    if (foundService && invoice.paid_on === null && (invoice.kind === 'Factura' || invoice.kind === 'Boleta' || invoice.kind === 'Nota de cobro' || invoice.kind === 'Nota de venta')) {
                        debts.push(invoice)
                        setTotal(prevTotal => prevTotal + invoice.total)
                        tableRows.push({
                            date: <Moment format='DD/MM/YYYY'>{invoice.due_date}</Moment>,
                            comment: invoice.comment,
                            amount: invoice.total + ' $',
                            services: invoice.service.map(serv => <Tag key={serv.number}>{serv.number}</Tag>),
                            key: invoice.due_date
                        })
                    }
                })
                if (tableRows.length === 0) {
                    setEmpty(true)
                }
                setRows(tableRows)
                HttpRequest.endpoint = 'matrix/customers/' + res[0].customer.split('customers/')[1];
                HttpRequest.get().then(res => {
                    setCustomerData(res)
                    setLoading(false)
                })
            } else {
                Modal.info({
                    title: 'Información',
                    content: 'Este cliente no posee boletas impagas.'
                })
                if (props.delayWizard) {
                    props.startAgain()
                } else {
                    saveTipification()
                }

            }
        })
    }, [props.rut])

    const columns = [
        {
            key: 'date',
            dataIndex: 'date',
            title: 'Fecha'
        },
        {
            key: 'comment',
            dataIndex: 'comment',
            title: 'Comentario'
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            title: 'Monto'
        },
        {
            key: 'services',
            dataIndex: 'services',
            title: 'Servicios'
        },
    ]

    return (
        <>
            {!loading ?
                <>
                    <h1 className='form-title'>Nombre del cliente: {customerData.name}</h1>
                    <h3 className='form-subtitle'>RUT: {customerData.rut}</h3>
                    <h4 className='form-subtitle'>{!empty ? "Este cliente tiene las siguientes boletas impagas con los servicios seleccionado:" : "Este cliente no tiene boletas impagas con los servicios seleccionados"}</h4>
                    <Table pagination={false} columns={columns} dataSource={rows} />
                    {!empty && <h4 className='total-payment'>El total adeudado es de: {total} $</h4>}
                    {/*props.delayWizard &&
                        <Form name='boleta'>
                            <h6>Seleccione la boleta a la cual desea prorrogar pago</h6>
                            <Form.Item name='boleta' rules={[{required: true, mensaje: 'Este campo es requerido'}]}>
                                <Select>

                                </Select>
                            </Form.Item>
                        </Form>*/
                    }
                    <Row className='center-row'>
                        {
                            props.delayWizard ?
                                <Button onClick={props.delayPayment} type='primary' loading={props.loading}>Prorrogar pago</Button>
                                :
                                <Button onClick={saveTipification} type='primary' loading={buttonLoading}>Guardar tipificación</Button>
                        }
                        {
                            props.delayWizard &&
                            <Button onClick={props.dontDelayPayment} type='secondary' loading={props.loading}>No prorrogar pago</Button>
                        }
                    </Row>
                </>
                :
                <Spin className='center-spin' />
            }
        </>
    )
}

export default PaymentAmount;