import React from 'react'
import styled from 'styled-components'

const ButtonUI = styled.button`
	&.btn-simple {
		padding: .5rem .75rem;	
		border: 1px solid #ddd;
		outline: none !important;
		&:focus {
			outline: none !important;
		}
		&:hover {
			background-color: #f7f7f7;
		}
	}
`

const Button = ({children, value, className, style, color = "primary", size, type = "button", onClick, title}) => (
	<ButtonUI type={type} value={value} className={`btn ${className} btn-${size} btn-${color}`} onClick={onClick} style={style} title={title}>{children}</ButtonUI>
);

export default Button