// Packages
import React from "react";
import { connect } from "react-redux";

// Components
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { PageTitle } from "../../components/typography/Typography";

const Preferences = () => {
	return (
		<>
			<Breadcrumb />
			<PageTitle title="Preferencias" />
			<div className="row">
				<div className="col-xs-12">
					<Panel>
						<PanelHeader noButton>
							Preferencias de usuario
						</PanelHeader>
						<PanelBody></PanelBody>
					</Panel>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
