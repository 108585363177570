import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

const Animated = ({animation, duration, delay, className="", children, ...rest}) => {
	const [_duration, setDuration] = useState("1s")
	const [_delay, setDelay] = useState("1ms")

	const AnimatedUI = styled.div`
		position: relative;
		animation-duration: ${_duration} !important;
		animation-delay: ${_delay} !important;
		animation-iteration-count: 1 !important;
	`

	useEffect(() => {
		if(duration) setDuration(duration)
		if(delay) setDelay(delay)
	})

	return (
		<AnimatedUI className={`${animation} ${className}`} {...rest}>
			{children}
		</AnimatedUI>
	);
}

export default Animated