import React, { useState, useEffect } from 'react';
import HttpRequest from './../../services/HttpRequest';
import { Timeline, Card, Spin } from 'antd';
import Moment from "react-moment";
import Modal from 'antd/lib/modal/Modal';

function EscalationHistory(props) {
    const [history, setHistory] = useState(null);
    const [changes, setChanges] = useState([]);
    const [agents, setAgents] = useState([]);

    const findAgent = agentID => {
        let agentName = 'Desconocido'
        agents.forEach(ag => {
            if (ag.ID === agentID) {
                agentName = ag.name
            }
        })
        return agentName
    }

    useEffect(() => {
        if (agents.length > 0) {
            HttpRequest.endpoint = "escalation_ti/escalation/" + props.escalationID + "/history/";
            HttpRequest.get().then(res => {
                if (res && res.first) {
                    setHistory(res)
                    if (res.changes) {
                        const changeList = []
                        res.changes.forEach(change => {
                            if (change.escalation_level || change.status || change.agent_level_one || change.agent_level_two || change.agent_level_tree) {
                                changeList.push(
                                    <Timeline.Item key={change.updated.new_value} label={<Moment format="DD/MM/YYYY hh:mm">{change.updated.new_value}</Moment>}>
                                        <Card title="Editado" bordered={true}>
                                            {change.escalation_level && <p><strong>Nivel de escalamiento:</strong> {change.escalation_level.new_value === 0 ? 'Primero' : change.escalation_level.new_value === 1 ? 'Segundo' : 'Tercero'}</p>}
                                            {change.status && <p><strong>Estatus:</strong> {change.status === 0 ? 'Abierto' : change.status === 1 ? 'En SAC' : change.status === 2 ? 'Cerrado' : 'Cancelado'}</p>}
                                            {change.agent_level_one && <p><strong>Agente nivel 1:</strong> {change.agent_level_one.new_value === null ? 'N/A' : findAgent(change.agent_level_one.new_value)}</p>}
                                            {change.agent_level_two && <p><strong>Agente nivel 2:</strong> {change.agent_level_two.new_value === null ? 'N/A' : findAgent(change.agent_level_two.new_value)}</p>}
                                            {change.agent_level_tree && <p><strong>Agente nivel 3:</strong> {change.agent_level_tree.new_value === null ? 'N/A' : findAgent(change.agent_level_tree.new_value)}</p>}
                                        </Card>
                                    </Timeline.Item>
                                )
                            }
                        })
                        setChanges(changeList)
                    }
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo los cambios'
                    })
                }
            })
        }
    }, [props.escalationID, agents])

    useEffect(() => {
        HttpRequest.endpoint = 'user/data/information_group/';
        HttpRequest.get().then(res => {
            if (res && Array.isArray(res)) {
                setAgents(res)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo la lista de agentes'
                })
            }
        })
    }, [])

    return (

        <>
            {history ? <Timeline mode='alternate'>
                <Timeline.Item label={<Moment format="DD/MM/YYYY hh:mm">{history.first.created}</Moment>}>
                    <Card title="Creado" bordered={true} >
                        <p><strong>Nivel de escalamiento:</strong> {history.first.escalation_level === 0 ? 'Primero' : history.first.escalation_level === 1 ? 'Segundo' : 'Tercero'}</p>
                        <p><strong>Estatus:</strong> {history.first.status === 0 ? 'Abierto' : history.first.status === 1 ? 'En SAC' : 'Cerrado'}</p>
                        <p><strong>Agente nivel 1:</strong> {history.first.agent_level_one === null ? 'N/A' : findAgent(history.first.agent_level_one)}</p>
                        <p><strong>Agente nivel 2:</strong> {history.first.agent_level_two === null ? 'N/A' : findAgent(history.first.agent_level_two)}</p>
                        <p><strong>Agente nivel 3:</strong> {history.first.agent_level_tree === null ? 'N/A' : findAgent(history.first.agent_level_tree)}</p>
                    </Card>
                </Timeline.Item>
                {changes}
            </Timeline >
                :
                <Spin size='large' className='center-spin' />
            }
        </>
    );

}

export default EscalationHistory;