import React, { useEffect } from 'react';
import { Select } from 'antd';

function SolutionResultField(props) {
    /*useEffect(() => {
        if (props.initial) {
            props.addSolution({
                id: props.initial,
                index: props.index
            })
        }
    }, [])
    console.log(props)*/
    return (
        <>
            <Select className='dynamic-field-item' placeholder='Seleccionar prueba' disabled={props.disabled} defaultValue={props.initial} onChange={e => props.addSolution({ id: e, index: props.index })} placeholder='Seleccionar solución'>
                {props.solutions.map(sol => <Select.Option key={sol.ID} value={sol.ID}>{sol.name}</Select.Option>)}
            </Select>
        </>
    )
}

export default SolutionResultField;