import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import React, { useState, useEffect, useRef } from "react";
import { Button, Select, Modal } from "antd";
import { connect } from "react-redux"
import HttpRequest from "../../services/HttpRequest.js";
import { endPointCreateMassives } from "../../services/endpoints.jsx";
import FormComp from "../../components/form/Form.jsx";
import { can } from "../../helpers/helpers.js";
import autoCloseModal from "../../components/modal/AutoCloseModal.jsx";

const ChannelForm = (props) => {
    const [showForm, setShowForm] = useState(true);
    const [number, setNumber] = useState();
    const [phoneParsed, setPhoneParsed] = useState(["CL", null]);
    const [template, setTemplate] = useState();
    const [templateList, setTemplateList] = useState();
    const formRefMedios = useRef();
    const { Option } = Select;

    let mediosForm = {
        size: [6, 16],
        title: "mediosForm",
        items: [
            {
                custom: true,
                label: "Numero Prueba",
                name: "number",
                required: true,
                initialValue: number,
                component: (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <PhoneInput
                            defaultCountry={phoneParsed[0]}
                            style={{
                                width: "100%",
                            }}
                            value={phoneParsed[1]}
                            onChange={setNumber}
                        />
                    </div>
                ),
            },
            {
                custom: true,
                label: "Plantilla",
                name: "template",
                required: true,
                rules: [{ required: true, message: 'Este campo es requerido' }],
                component: (
                    <Select placeholder="Seleccione la plantilla" labelInValue defaultValue={{ value: template, }}
                        style={{ width: "100%", }} placeholder="Plantillas" onChange={(option) => setTemplate(option["key"])}  >
                        {templateList}
                    </Select>
                ),
            },
        ],
        multipart: false,
        submitButton: false,
    };

    const deleteChannel = () => {
        const formData = new FormData();
        formData.append("channel", props.type);
        HttpRequest.endpoint = endPointCreateMassives + props.id + "/remove_channel/";
        HttpRequest.post(formData).then((res) => {
            if (Object.keys(res).length === 0) {
                autoCloseModal("Se ha eliminado el medio exitosamente")
                props.setResetChannel(props.reset + 1)
                props.updateRows()
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al eliminar este medio" })
            }
        });
    };

    const getData = () => {
        if (props.event) {
            HttpRequest.endpoint = props.event.split("v1/").pop();
            HttpRequest.get().then((res) => {
                let phoneNumber = parsePhoneNumber(res["phone_number_test"]);
                setPhoneParsed([phoneNumber.country, phoneNumber.nationalNumber]);
                setNumber(res["phone_number_test"]);
                setTemplate(res["template"]);
                createOptions();
            });
        } else {
            createOptions();
        }
    }
    const createOptions = () => {
        let templatesArray = [];
        HttpRequest.endpoint = props.endpoint;
        let filters = { operator: props.operator }
        HttpRequest.get(filters)
            .then((res) => {
                for (let index = 0; index < res.length; index++) {
                    templatesArray.push(<Option key={res[index]["ID"]} value={res[index]["ID"]}>  {res[index]["name"]} </Option>);
                }
                setTemplateList(templatesArray);
            })
            .then(() => {
                setShowForm(true);
            });
    };

    const addMedios = () => {
        let permission = true;
        if (props.editing) {
            permission = can(props.permissions.edit)
        } else {
            permission = can(props.permissions.create)
        }
        if (permission) {
            let values = formRefMedios.current.values();
            const formData = new FormData();
            formData.append("channel", props.type);
            formData.append("phone_number_test", number);
            formData.append("template", template);
            HttpRequest.endpoint = endPointCreateMassives + props.id + "/add_channel/";
            HttpRequest.post(formData).then((res) => {
                if (Object.keys(res).length === 0) {
                    autoCloseModal("Se ha añadido el medio exitosamente")
                    props.updateRows()
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al agregar este medio" })
                }
            });
        }
    };

    useEffect(() => {
        setShowForm(false);
        props.id ? getData() :
            Modal.warning({ title: "Error", content: "Debe crear el masivo primero" })
    }, [props.id])

    useEffect(() => {
        console.log(props)
        if (props.reset) {
            setPhoneParsed(['CL', null])
            setTemplate(null)
            setNumber(null)
            setShowForm(false);
            setTimeout(() => {
                setShowForm(true)
            }, 100)
        }
    }, [props.reset])

    return (
        <div>
            {props.id && showForm && (
                <div>
                    <FormComp info={mediosForm} ref={formRefMedios} customSubmit={addMedios} />
                    <Button type="primary" onClick={() => formRefMedios.current.submit()} style={{ marginRight: "10px", }} >
                        Agregar
			        </Button>
                    <Button style={{ alignSelf: "right" }} type="primary" danger onClick={() => props.permissions ? can(props.permissions.delete) ? deleteChannel() : null : deleteChannel()} >
                        Eliminar
                    </Button>
                </div>
            )}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator
    }
}


export default connect(mapStateToProps)(ChannelForm);