import React, { useState } from "react";
import { Calendar, Modal, ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import IclassSearchTables from "./IclassSearchTable";

function IclassTableCalendar() {
	const [selectedDate, setSelectedDate] = useState(null);

	const getDay = (e) => {
		const date = e._d;
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		setSelectedDate({
			filters: [
				["start_date", ">", `${day}/${month}/${year} 00:01`],
				["end_date", "<", `${day}/${month}/${year} 23:59`],
			],
		});
	};
	return (
		<>
			<ConfigProvider locale={esES}>
				<Calendar onSelect={getDay} />
			</ConfigProvider>
			{selectedDate && (
				<Modal
					visible={selectedDate}
					footer={null}
					onCancel={() => setSelectedDate(null)}
					width={800}
				>
					<IclassSearchTables initialParams={selectedDate} />
				</Modal>
			)}
		</>
	);
}

export default IclassTableCalendar;
