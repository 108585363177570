import React, { useEffect, useState } from "react";
import { Tabs, Table, Modal } from "antd";
import HttpRequest from "../../services/HttpRequest";
import Moment from "react-moment";

const ServiceInfoModal = (props) => {

    const [serviceInfo, setServiceInfo] = useState({});
    const [nodeInfo, setNodeInfo] = useState({});
    const [planInfo, setPlanInfo] = useState({});
    const [serviceTabs, setServiceTabs] = useState([]);
    const { TabPane } = Tabs;

    const serviceFields = {
        ip: 'Ip', document_type: 'Tipo de documento', due_day: 'Día de pago', status: 'Estado', ssid: 'SSID', technology_kind: 'Tecnología', composite_address: 'Dirección',
        commune: 'Comuna', allow_auto_cut: '¿Autocorte?', seen_connected: '¿Visto conectado?', mac_onu: 'MAC de ONU', network_status: 'Estado de red', activated_on: 'Fecha de Activación',
        installed_on: 'Fecha de Instalación', uninstalled_on: 'Fecha de desinstalación', expired_on: 'Fecha de expiración', billing_notes: 'Nota facturación', internal_notes: 'Nota Interna'
    }

    const nodeFields = {
        code: "Código", status: "Estado", address: "Dirección", commune: "Comuna", is_building: "¿Es edificio?", is_optical_fiber: "¿Es fibra?",
        gpon: "GPON", gepon: "GEPON"
    }

    const planFields = { plan: "Plan", price: "Precio", category: "Categoría", uf: "UF", active: "¿Activo?" }

    const infoColumns = [
        { title: 'Campo', dataIndex: 'field', key: 'field' },
        { title: 'Valor', dataIndex: 'value', key: 'value' },
    ]

    const getServices = (rut) => {
        let serviceData = [];
        HttpRequest.endpoint = "matrix/services/"
        let filter = { customer__rut: rut }
        HttpRequest.get(filter).then((res) => {
            res.forEach((element) => {
                getTab(element)
            });
        })

    }

    const getTab = (res) => {
        let serviceData = [];
        Object.keys(res).map((key) => {
            if (Object.keys(serviceFields).includes(key)) {
                serviceData.push({ key: key, field: serviceFields[key], value: typeof res[key] == "boolean" ? res[key] ? "Si" : "No" : Array.isArray(res[key]) ? res[key][1] : (key == "activated_on" || key == "installed_on") ? <Moment fromNow locale="es">{res[key]}</Moment> : res[key] })
            }
        })
        setServiceInfo({ ...serviceInfo, [res["number"]]: serviceData });
    }

    useEffect(() => {
        if (props.rut != null) {
            getServices(props.rut)
        }
    }, [props.rut])

    return (
        < Modal footer={null} visible={props.visible} width="65%" onCancel={() => props.setVisible(false)} >
            <Tabs type="card">
                {Object.keys(serviceInfo).map((service) => {
                    return (
                        <TabPane key={service} tab={service}>
                            <Table columns={infoColumns} dataSource={serviceInfo[service]} pagination={false} size="small" />
                        </TabPane>
                    )
                })}
            </Tabs>
        </Modal >
    )
}


export default ServiceInfoModal;