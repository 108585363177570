import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import CallsTable from './CallsTable';

function CallsSearchTables() {
    const endpoint = 'communications/voice_call_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<CallsTable />} />
        </>
    )
}

export default CallsSearchTables;