import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, Select, InputNumber, Checkbox, DatePicker, Spin } from 'antd';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import HttpRequest from '../../services/HttpRequest';
import { connect } from 'react-redux';
import LocationForm from '../../components/form/locationInput';
import DynamicSelect from './DynamicSelect';
import Config from "../../config";
import RutField from './RutField';
import moment from 'moment';

function ColumnItem(props) {
    const [name, setName] = useState(props.data ? props.data.name : null);
    const [type, setType] = useState(props.data ? props.data.type : 'string');
    const [required, setRequired] = useState(props.data ? props.data.required : false);
    const [defaultValue, setDefaultValue] = useState(props.data ? props.data.default_value : null);
    const [users, setUsers] = useState([]); //users Iris
    const [plans, setPlans] = useState([]); //plans
    const [values, setValues] = useState(props.data ? props.data.values : []);
    const [visible, setVisible] = useState(false);
    const [edited, setEdited] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveDynamic, setSaveDynamic] = useState(false);
    const [showRut, setShowRut] = useState(false);

    const saveColumn = () => {
        if (!props.data) {
            props.setColumnsSubmit(prevState => {
                let object;
                if (type === 'select_static') {
                    object = {
                        name: name,
                        type: type,
                        required: required,
                        default_value: defaultValue,
                        values: values
                    }
                } else {
                    object = {
                        name: name,
                        type: type,
                        required: required,
                        default_value: defaultValue
                    }
                }
                prevState[props.id] = object;
                return prevState;
            })
        }
    }
    const deleteColumn = () => {
        if (!edited) {
            Modal.confirm({
                title: 'Confirmación',
                content: '¿Está seguro que desea eliminar esta columna? Todos los datos asociados a ella se eliminarán',
                okText: 'Sí, estoy seguro',
                cancelText: 'No, cancelar',
                onOk: () => props.deleteColumn(props.id)
            })
        } else {
            Modal.error({
                title: 'Error',
                content: 'Esta columna fue editada previamente. Si desea eliminarla, por favor cierre y abra nuevamente'
            })
        }

    }
    const getPlans = () => {
        HttpRequest.endpoint = 'matrix/plans/';
        HttpRequest.get({ operator: props.operator, fields: 'id,name' }).then(res => {
            if (res && Array.isArray(res)) {
                const planList = [];
                res.forEach(plan => {
                    planList.push(<Select.Option key={plan.id} value={plan.id}>{plan.name}</Select.Option>)
                })
                setPlans(planList)
            }
        })
    }
    const getUsers = () => {
        HttpRequest.endpoint = 'user/data/information';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                const userList = [];
                res.forEach(user => {
                    userList.push(<Select.Option key={user.ID} value={user.ID}>{user.name}</Select.Option>)
                })
                setUsers(userList)
            }
        })
    }

    const handleRutClick = () => {
        if (props.data) {
            if (visible) {
                setShowRut(true)
            } else {
                handleEditClick()
            }
        } else {
            setShowRut(true)
        }
    }
    const handleEditClick = () => {
        if (props.data) {
            setVisible(true)
            /*if (!edited) {
                
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Esta columna ya fue editada. Si desea editarla nuevamente, debe cerrar el modal y abrirlo de nuevo'
                })
            }*/
        }
    }
    const editColumn = () => {
        console.log(name, type, defaultValue, required, values)
        setButtonLoading(true);
        fetch(Config.API_URL + '/drives/definition/' + props.driveID + '/update_column/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            },
            body: JSON.stringify({ current_name: props.data.name, new_name: name, new_default_value: defaultValue, new_required: required, values })
        }).then(res => res.json())
            .then(res => {
                setButtonLoading(false)
                const errors = {
                    'Description repeat error': 'No pueden haber columnas con el mismo nombre',
                    'phone value format': 'El formato del número teléfonico es incorrecto',
                    'Error dynamic_services value': 'El servicio especificado no existe',
                    'Error float value': 'El número decimal debe contener decimal',
                    'Error int value': 'El número entero no debe contener decimales',
                    'Description default_value error: static selects': 'No se pueden tener opciones iguales en el campo de tipo opciones',
                    'Error default_value value: email': 'El formato del email es inválido',
                    "Error address value": 'La dirección especificada no existe',
                    'Description error: static selects': 'Debe especificar al menos 1 opción en el campo de tipo opciones'
                }
                let error = 0;
                Object.keys(errors).forEach(err => {
                    if (Object.keys(res).length > 0 && Object.keys(res)[0].includes(err)) {
                        error++
                        Modal.error({
                            title: 'Error',
                            content: errors[err]
                        })
                    }
                })
                if (error === 0) {
                    setEdited(true)
                    Modal.success({
                        title: 'Éxito',
                        content: 'Se ha editado la definición con éxito'
                    })
                }
            }).catch(err => {
                setButtonLoading(false)
                console.log(err);
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error editando las columnas'
                })
            })
    }
    const onKeyPress = (e) => {
        const specialCharRegex = new RegExp("^[A-Za-z0-9_]*$");
        const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!specialCharRegex.test(pressedKey)) {
            e.preventDefault();
            return false;
        }
    }
    const typesComponent = {
        'string': <Input disabled={props.data && !visible} defaultValue={defaultValue} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={e => setDefaultValue(e.target.value)} />,
        'int': <InputNumber disabled={props.data && !visible} defaultValue={defaultValue} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={val => setDefaultValue(val)} />,
        'float': <InputNumber disabled={props.data && !visible} defaultValue={defaultValue} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={val => setDefaultValue(val)} />,
        'phone': <PhoneInput disabled={props.data && !visible} value={type === "phone" && defaultValue} placeholder='Valor por defecto' defaultCountry={type === "phone" && defaultValue && parsePhoneNumber(defaultValue) ? parsePhoneNumber(defaultValue).country : 'CL'} className='column-item phone-fix' onChange={val => setDefaultValue(val)} />,
        'email': <div className={visible ? 'column-item2' : 'column-item'}><Form className><Form.Item initialValue={defaultValue} name='email' rules={[{ type: 'email', message: 'Formato inválido' }]}><Input disabled={props.data && !visible} placeholder='Valor por defecto' onChange={e => setDefaultValue(e.target.value)} /></Form.Item></Form></div>,
        'datetime': <DatePicker disabled={props.data && !visible} format={"DD/MM/YYYY HH:MM:ss"} defaultValue={defaultValue && moment(defaultValue, "YYYY/MM/DD HH:MM:ss")} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={val => val && setDefaultValue(val.format('YYYY/MM/DD HH:MM:ss'))} />,
        'logic': <Select disabled={props.data && !visible} defaultValue={defaultValue} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={val => setDefaultValue(val)}>
            <Select.Option value={true} key={'1'}>Verdadero</Select.Option>
            <Select.Option value={false} key={'2'}>Falso</Select.Option>
        </Select>,
        'select_dynamic_plans': <Select disabled={props.data && !visible} defaultValue={defaultValue} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={val => setDefaultValue(val)}>
            {plans}
        </Select>,
        'select_dynamic_users_iris': <Select disabled={props.data && !visible} defaultValue={defaultValue} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={val => setDefaultValue(val)}>
            {users}
        </Select>,
        'select_dynamic_services': <InputNumber disabled={props.data && !visible} defaultValue={defaultValue} placeholder='Valor por defecto' className={visible ? 'column-item2' : 'column-item'} onChange={val => setDefaultValue(val)} />,
        'select_static': <DynamicSelect className={visible ? 'column-item2' : 'column-item'} disabled={props.data && !visible} editing={props.driveID} setSaveDynamic={setSaveDynamic} placeholder='Valor por defecto' defaultValues={values} setValues={setValues} setDefaultValue={setDefaultValue} />,
        'select_dynamic_location': <LocationForm driveEdit disabled={props.data && !visible} placeholder='Valor por defecto' onChange={val => setDefaultValue(val.split(':')[1].concat(`:${props.operator}`))} drive />,
        'select_dynamic_rut': <div className={visible ? 'column-item2' : 'column-item'}>
            <Input onClick={handleRutClick} disabled={props.data && !visible} placeholder='Buscar RUT' value={defaultValue} />
            {showRut && <Modal footer={null} visible={showRut} onCancel={() => setShowRut(false)} >
                <RutField defaultValue={defaultValue} placeholder='Valor por defecto' onChange={val => setDefaultValue(val)} />
                <Button onClick={() => setShowRut(false)} className='center-btn' type='primary'>Cerrar</Button>
            </Modal>}
        </div>
    }
    const types = [{
        name: 'Texto',
        value: 'string'
    },
    {
        name: 'Número entero',
        value: 'int'
    },
    {
        name: 'Número decimal',
        value: 'float'
    },
    {
        name: 'Teléfono',
        value: 'phone'
    },
    {
        name: 'Email',
        value: 'email'
    },
    {
        name: 'Fecha',
        value: 'datetime'
    },
    {
        name: 'Lógico',
        value: 'logic'
    },
    {
        name: 'Plan',
        value: 'select_dynamic_plans'
    },
    {
        name: 'Opciones',
        value: 'select_static'
    },
    {
        name: 'Usuario',
        value: 'select_dynamic_users_iris'
    },
    {
        name: 'Localización',
        value: 'select_dynamic_location'
    },
    {
        name: 'RUT',
        value: 'select_dynamic_rut'
    },
    {
        name: 'Servicio',
        value: 'select_dynamic_services'
    }
    ]

    useEffect(saveColumn, [name, type, required, defaultValue, values])
    useEffect(() => {
        if (type === 'select_dynamic_users_iris') {
            getUsers()
        } else if (type === 'select_dynamic_plans') {
            getPlans()
        }
    }, [type])

    useEffect(() => {
        if (saveDynamic) {
            editColumn()
        }
    }, [saveDynamic])

    return (
        <>
            <div className='column-item-container'>
                <Input onKeyPress={onKeyPress} disabled={props.data} value={name} placeholder='Nombre' onChange={e => setName(e.target.value)} className='column-item' />
                <Select disabled={props.data} defaultValue={props.data && props.data.type} onChange={val => { setDefaultValue(null); setType(val) }} className='column-item' placeholder='Tipo'>
                    {types.map(type => <Select.Option value={type.value} key={type.value}>{type.name}</Select.Option>)}
                </Select>
                {!visible && typesComponent[type]}
                {props.data ? <Button onClick={handleEditClick}>Editar</Button> : <Checkbox defaultChecked={props.data && props.data.required} onChange={e => setRequired(e.target.checked)}>Requerido</Checkbox>}
                <Button danger type='primary' onClick={deleteColumn}>X</Button>
                <Modal footer={null} title="Editar columna" visible={visible} onCancel={() => setVisible(false)}>
                    <h4 className='center-text'>Editar columna</h4>
                    <Input onKeyPress={onKeyPress} onChange={e => setName(e.target.value)} placeholder='Nombre' className='column-item2' defaultValue={name} />
                    {typesComponent[props.data && props.data.type]}
                    <Checkbox onChange={e => setRequired(e.target.checked)} defaultChecked={required}>Requerido</Checkbox>
                    <Button type='primary' loading={buttonLoading} onClick={editColumn} className='center-btn edit-column-drive'>Guardar</Button>
                </Modal>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(ColumnItem);