import React from 'react';
import TypificationsTable from '../TypificationsTables/TypificationsTables';

function WhatsappCustomTables() {
    const endpoints = {
        formEndpoints: ['communications/whatsapp_conversation_tables/', 'communications/whatsapp_conversation_tables/definition/', 'communications/whatsapp_conversation_tables'],
        tableEndpoints: {
            columns: "communications/whatsapp_conversation_tables/datatables_struct/",
            rows: "communications/whatsapp_conversation_tables/datatables/",
            delete: "communications/conversation_tables/",
        }
    }
    return (
        <TypificationsTable
            endpoints={endpoints}
            removeCreated={true}
            removeAsigned={true}
        />
    )
}

export default WhatsappCustomTables;