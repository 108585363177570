import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import InspeccionesTable from './InspeccionesTable';

function InspeccionesSearchTables() {
    const endpoint = 'qc/inspection_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<InspeccionesTable />} />
        </>
    )
}

export default InspeccionesSearchTables;