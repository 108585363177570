import React, { useState } from 'react';
import ExtraTipifyInfo from './ExtraTipifyInfo';
import ActivationLatePayment from './ActivationLatePayment';

function ActivationLatePaymentWiz(props) {
    const [step, setStep] = useState(1);
    const [rut, setRut] = useState(null);
    const [services, setServices] = useState(null);
    const [channel, setChannel] = useState(null);

    const receiveExtraData = data => {
        setChannel(data.channel)
        setRut(data.customer_rut.value);
        setServices(data.services)
        setStep(2)
    }

    const steps = {
        1: <ExtraTipifyInfo nextStep={receiveExtraData} requiredRut requiredServices />,
        2: <ActivationLatePayment process={props.process} updateRows={props.updateRows} channel={channel} categories={props.categories} rut={rut} services={services} startAgain={props.startAgain} />
    }

    return (
        <>
            {steps[step]}
        </>
    )
}

export default ActivationLatePaymentWiz;