import { Form, InputNumber, Button } from "antd";
import React, { useState, useEffect } from "react";
import HttpRequest from "./../../services/HttpRequest";
import { connect } from "react-redux";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import errorModal from "./../../components/modal/errorModal";

function IclassVT(props) {
	const [loading, setLoading] = useState(false);
	const [initial, setInitial] = useState(null);

	const submit = (e) => {
		setLoading(true);
		console.log(e);
		setLoading(false);
		HttpRequest.endpoint = "iclass/service_orders/orders_max/";
		const formData = new FormData();
		formData.append("os_max", e.quantity);
		formData.append(
			"creator",
			JSON.parse(localStorage.getItem("userInfo")).username
		);
		HttpRequest.post(formData).then((res) => {
			if (res && res.id) {
				autoCloseModal(
					"Se ha registrado el número máximo de órdenes de servicio con éxito"
				);
			} else {
				errorModal(
					"Ha ocurrido un error registrando el numéro máximo de ordenes de servicio"
				);
			}
		});
	};

	useEffect(() => {
		HttpRequest.endpoint = "iclass/service_orders/orders_max/";
		HttpRequest.get({ operator: props.operator }).then((res) => {
			if (res && res.id) {
				setInitial({ quantity: res.os_max });
			} else if (res && Array.isArray(res)) {
				setInitial({ quantity: undefined });
			} else {
				setInitial({});
				errorModal(
					"Ha ocurrido un error obteniendo el numéro máximo de ordenes de servicio"
				);
			}
		});
	}, [props.operator]);

	if (initial === null) {
		return null;
	}

	return (
		<>
			<Form
				onFinish={submit}
				initialValues={initial}
				style={{
					margin: "0 auto",
					width: "400px",
				}}
			>
				<h3>
					Seleccione la cantidad de visitas técnicas disponibles por
					técnico
				</h3>
				<Form.Item
					label="Visitas técnicas"
					name="quantity"
					rules={[
						{ required: true, message: "Este campo es requerido" },
						{
							type: "number",
							min: 1,
							message: "Debe ser mínimo 1",
						},
					]}
				>
					<InputNumber />
				</Form.Item>
				<Button loading={loading} htmlType="submit" type="primary">
					Guardar
				</Button>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(IclassVT);
