import React, { useEffect, useState, Fragment } from "react";
import { Form, Button, Modal, Row, Input, Select, Comment, List, Tooltip } from "antd";
import { SendOutlined, MailOutlined, PhoneOutlined, MessageOutlined, ShareAltOutlined, WhatsAppOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import HttpRequest from "../../services/HttpRequest";
import { useForm } from "antd/lib/form/Form";
import { connect } from "react-redux"
import Moment from "react-moment";
import moment from "moment";
import { can } from "../../helpers/helpers";
import { recontact } from "../../config/permissions";
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';

const TypifyCommunication = (props) => {

    const [value, setValue] = useState("");
    const [channel, setChannel] = useState(0);
    const [channelType, setChannelType] = useState(0);
    const [messages, setMessages] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [editing, setEditing] = useState(false);
    const [editId, setEditId] = useState();
    const { Option } = Select;
    const [form] = useForm();

    let channelOpc = [<Option value={0}>Correo</Option>, <Option value={1}>Llamada</Option>, <Option value={2}>Chat</Option>,
    <Option value={3}>Redes</Option>, <Option value={4}>WhatsApp</Option>,]

    let channelTypeOpc = [<Option value={0}>In</Option>, <Option value={1}>Out</Option>]
    const iconStyle = {
        
    }
    let channelLabel = {
        0: (<Tooltip title="Correo" placement="left"><MailOutlined /> - </Tooltip>), 1: (<Tooltip title="Llamada" placement="left"><PhoneOutlined /> - </Tooltip>), 2: (<Tooltip title="Chat" placement="left"><MessageOutlined /> - </Tooltip>),
        3: (<Tooltip title="Redes" placement="left"><ShareAltOutlined /> - </Tooltip>), 4: (<Tooltip title="WhatsApp" placement="left"><WhatsAppOutlined /> - </Tooltip>)
    }

    const createMessage = () => {
        if (can(recontact.create)) {
            let formData = new FormData();
            formData.append("commentary", value)
            formData.append("typify_id", props.id)
            formData.append("channel", channel)
            formData.append("type", channelType)
            formData.append("operator", props.operator);
            
            for(var pair of formData.entries()) {
                console.log(pair[0]+ ', '+ pair[1]);
             }

            HttpRequest.endpoint = "tickets/communications/";
            HttpRequest.post(formData).then((res) => { getMessage(); setValue(""); })
        }
    }

    const editMessage = (id, message, channel, channelType) => {
        if (can(recontact.edit)) {
            HttpRequest.endpoint = "tickets/communications/";
            let formData = new FormData();
            formData.append("commentary", message);
            formData.append("typify_id", props.id)
            formData.append("channel", channel)
            formData.append("type", channelType)
            HttpRequest.patch(id, formData).then((res) => {
                setEditing(false);
                setEditId(null);
            })
        }
    }
    const deleteMessage = (id) => {
        if (can(recontact.delete)) {
            HttpRequest.endpoint = "tickets/communications/";
            HttpRequest.delete(id).then((res) => {
                getMessage()
            })
        }
    }

    const getAgents = () => {
        let list = {};
        HttpRequest.endpoint = "user/data/information/";
        HttpRequest.get().then((res) => {
            res.forEach(element => {
                list[element["ID"]] = element["name"]
            });
            setAgentList(list);
        })
    }

    const getMessage = () => {
        let list = [];
        HttpRequest.endpoint = "tickets/communications/?typify=" + props.id;
        HttpRequest.get().then((res) => {
            res.forEach(element => {
                if (editing && element["ID"] == editId) {
                    list.push({
                        key: element["ID"],
                        author: agentList[element["creator"]],
                        content: <div className="typify-comments">
                            <Row>
                                <div>
                                    <Form form={form}>
                                        <Form.Item name="editValue" initialValue={element["commentary"]}>
                                            <Input defaultValue={element["commentary"]} style={{ width: "200px" }} />
                                        </Form.Item>
                                        <Form.Item name="editChannel" initialValue={element["channel"]}>
                                            <Select placeholder={element["channel"]}>
                                                {channelOpc}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="editChannelType" initialValue={element["type"]}>
                                            <Select placeholder={element["type"]}>
                                                {channelTypeOpc}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <Button onClick={() => editMessage(
                                    element["ID"], 
                                    form.getFieldValue("editValue"), 
                                    form.getFieldValue("editChannel"),
                                    form.getFieldValue("editChannelType"),
                                )}>
                                    <CheckOutlined />
                                </Button>
                                <Button onClick={() => { setEditing(false); setEditId(null) }}>
                                    <CloseOutlined />
                                </Button>
                            </Row>
                        </div>,
                        datetime: <Moment fromNow locale="es">{element["created"]}</Moment>,
                    })
                } else {
                    list.push({
                        key: element["ID"],
                        author: agentList[element["updater"]],
                        content: <div className="typify-comments">
                            {channelLabel[element["channel"]]}
                            
                            {" " + element["commentary"]}

                            {
                                element["type"] === 0 ?
                                <Fragment>
                                    <span style={{margin: "0 .5em", opacity: ".3"}}>|</span>
                                    <LoginOutlined style={{marginRight: ".5em", display: "flex", alignItems: "center"}} /> 
                                    <span>In</span>
                                </Fragment>
                                :
                                <Fragment>
                                    <span style={{margin: "0 .5em", opacity: ".3"}}>|</span>
                                    <LogoutOutlined style={{marginRight: ".5em", display: "flex", alignItems: "center"}} /> 
                                    <span>Out</span>
                                </Fragment>
                                
                            }
                        </div>,
                        datetime: <Moment fromNow locale="es">{element["created"]}</Moment>,
                        actions: !props.hideButtons && [
                            <span>
                                <Tooltip key="delete-comment" title="Eliminar">
                                    <CloseOutlined onClick={() => deleteMessage(element["ID"])} />
                                </Tooltip>
                                <Tooltip key="edit-comment" title="Editar">
                                    <EditOutlined onClick={() => {
                                        setEditing(true);
                                        setEditId(element["ID"]);
                                    }} />
                                </Tooltip>
                            </span>
                        ]
                    })
                }
            });
            setMessages(list)
        })
    }

    useEffect(() => {
        getAgents();
    }, [props.id, props.operator])

    useEffect(() => {
        if (agentList.length != 0) {
            getMessage();
        }

    }, [agentList, editing])

    useEffect(() => {
        !props.id ? Modal.error({ title: "Error", content: "La tipificación no ha sido registrada" }) : getAgents();
    }, [])

    return (
        <div>
            {messages.length > 0 &&
                <List
                    dataSource={messages}
                    itemLayout="horizontal"
                    renderItem={props => <Comment {...props} />}
                />
            }
            {!props.hideButtons &&
                <>
                    <div className="typify-reconnect-message">
                        <Row style={{ width: "100%" }} align="top">
                            <Form.Item className="typify-reconnect-input">
                                <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder="Ingrese el mensaje" />
                            </Form.Item>
                            <Button onClick={createMessage} type="primary">
                                <SendOutlined />
                            </Button>
                        </Row>
                    </div>
                    <Select 
                        style={{ width: "100%" }} 
                        defaultValue={0} 
                        onChange={(option) => setChannel(option)} placeholder="Seleccione el canal">
                        {channelOpc}
                    </Select>
                    <Select 
                        style={{ width: "100%" }} 
                        defaultValue={0}
                        onChange={(option) => setChannelType(option)} placeholder="Seleccione el status">
                        {channelTypeOpc}
                    </Select>
                </>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator
    }
}

export default connect(mapStateToProps)(TypifyCommunication);