import React, { useState, useEffect } from 'react'
import { Tag, Button, Select } from "antd"
import HttpRequest from '../../services/HttpRequest'
import { connect } from 'react-redux';

function TagView(props) {

    const [edit, setEdit] = useState(false)
    const [tags, setTags] = useState(props.tags);
    const [tagOptions, setTagOptions] = useState([])

    const { Option } = Select;

    useEffect(() => {
        getTags()
    }, [])

    const getTags = () => {
        let existent_tags = [];
        let initial_tags = [];
        HttpRequest.endpoint = "communications/conversation_tag/?operator=" + props.operator
        HttpRequest.get().then((res) => {
            res.forEach(tag => {
                existent_tags.push(<Option key={tag.name}>{tag.name}</Option>)
                initial_tags.push(tag.name)
            });
            setTagOptions(existent_tags)
        })
    }

    function patchTags(e) {
        setEdit(false)
        const formData = new FormData();
        formData.append('tags', JSON.stringify(e))
        HttpRequest.endpoint = "communications/conversation/"
        HttpRequest.patch(props.id, formData).then((res) => {
            setTags(res.tags)
        })
    }

    return (
        <div>
            {edit ?
                <>
                    <Select style={{ minWidth: "150px" }} mode="multiple" placeholder="Tags Convresaciones" value={tags} onChange={(e) => setTags(e)}>
                        {tagOptions}
                    </Select>
                    <Button onClick={() => patchTags(tags)}>Listo</Button>
                </>
                :
                <>
                    {tags?.map((tag, index) =>
                        <Tag key={index} color="blue">{tag}</Tag>
                    )}
                    <Button onClick={() => setEdit(true)}>+</Button>
                </>
            }

        </div>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(TagView);