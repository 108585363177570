import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { qc } from '../../config/permissions';
import MonitoreoRegisterForm from './MonitoreoRegisterForm';
import Moment from "react-moment";
import HttpRequest from './../../services/HttpRequest';
import { Modal, Button } from "antd";
import ButtonWithModal from "../../components/buttons/ButtonWithModal"
import { can } from "../../helpers/helpers.js";
import { ExclamationCircleOutlined } from '@ant-design/icons';

function MonitoreoRegisterTable(props) {
    const [tableRows, setTableRows] = useState([]);
    const [forceReload, setForceReload] = useState(false);

    let endpoints = {
        columns: 'qc/monitoring_record/datatables_struct/',
        rows: 'qc/monitoring_record/' + props.monitoreoID + "/datatables/",
        delete: 'qc/monitoring_record/',
        download: 'qc/monitoring_record/' + props.monitoreoID + '/download_data/',
    }
    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            contact_date: <Moment fromNow>{row.created}</Moment>
        })))
    }
    const deleteRow = id => {
        if (can(qc.monitoreo.delete)) {
            if (props.closed) {
                return Modal.error({
                    title: 'Error',
                    content: 'Este monitoreo se encuentra cerrado, por lo que no puede eliminar registros del mismo'
                })
            }
            Modal.confirm({
                title: '¿Está seguro que desea eliminar este registro?',
                icon: <ExclamationCircleOutlined />,
                cancelText: 'Cancelar',
                onOk() {
                    HttpRequest.endpoint = 'qc/monitoring_record/';
                    HttpRequest.delete(id).then(res => {
                        if (res.status === 400) {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error eliminando este item'
                            })
                        } else {
                            Modal.success({
                                title: 'Éxito',
                                content: 'Se ha eliminado el item'
                            })
                            setForceReload(prevState => !prevState)
                        }
                    })
                },
            });
        }
    }
    const customRendering = (text, value, id) => {
        switch (value) {
            case "created":
                return <Moment fromNow locale="es">{text}</Moment>;
            case "custom_change":
                return <ButtonWithModal
                    name='Editar'
                    title='Editar registro'
                    onCancel={reload => reload && setForceReload(prevState => !prevState)}
                    content={<MonitoreoRegisterForm id={id} monitoreoID={props.monitoreoID} closed={props.closed} />}
                    width='90%'
                />
            case "custom_delete":
                return <Button
                    type='primary'
                    className="form-control"
                    danger
                    onClick={() => deleteRow(id)}
                >
                    Borrar
                </Button>
            default:
                break;
        }
    }

    return (
        <FullTable
            permissions={qc.monitoreo}
            modalContent={<MonitoreoRegisterForm monitoreoID={props.monitoreoID} closed={props.closed} />}
            endpoints={endpoints}
            tableName='registro'
            modalWidth='90%'
            handleRows={handleRows}
            tableRows={tableRows}
            buttons={props.closed && []}
            customRendering={{ renderFunction: customRendering }}
            forceReload={forceReload}
        />
    )
}

export default MonitoreoRegisterTable;