import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
import ColorPicker from "./ColorPicker";
import HttpRequest from "./../../services/HttpRequest";
import store from "../../redux/store.js";
import errorModal from "./../../components/modal/errorModal";
import autoCloseModal from "./../../components/modal/AutoCloseModal";

function ColorForm() {
	const [osList, setOsList] = useState([]);

	const submit = (osName, color) => {
		const formData = new FormData();
		formData.append("color", color);
		formData.append("so_type", osName);
		HttpRequest.endpoint = "iclass/service_orders/so_color/";
		HttpRequest.post(formData).then((res) => {
			if (res && res.id) {
				autoCloseModal("Se ha actualizado el color con éxito");
			} else {
				errorModal("Ha ocurrido un error actualizando el color");
			}
		});
	};

	useEffect(() => {
		HttpRequest.endpoint = "iclass/service_orders/so_color/";
		HttpRequest.get({ operator: store.getState().operator }).then((res) => {
			if (res && Array.isArray(res)) {
				setOsList(res);
			} else {
				errorModal("Ha ocurrido un error obteniendo los colores");
			}
		});
	}, []);

	return (
		<>
			<Form onFinish={submit}>
				{osList.map((os) => (
					<Form.Item
						name={os.type}
						label={os.type}
						initialValue={os.color}
						key={os.type}
						rules={[
							{
								required: true,
								message: "Este campo es requerido",
							},
						]}
					>
						<ColorPicker saveColor={submit} />
					</Form.Item>
				))}
			</Form>
		</>
	);
}

export default ColorForm;
