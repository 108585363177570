import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import React, { useState, useEffect, useRef } from "react";
import { Button, Select, Modal, Collapse, Form, Input, Checkbox, InputNumber, DatePicker, Row } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import { endPointMassiveFilters, endPointCreateMassives, endPointSlackChannel } from "../../services/endpoints.jsx";
import store from "../../redux/store.js";
import { endPointTemplateEmail } from "../../services/endpoints.jsx";
import FormComp from "../../components/form/Form.jsx";
import { connect } from "react-redux";
import { can } from "../../helpers/helpers.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';

const EmailForm = (props) => {
    const [showForm, setShowForm] = useState(true);
    const [template, setTemplate] = useState();
    const [templateList, setTemplateList] = useState();
    const [responseEmail, setResponseEmail] = useState();
    const [testEmail, setTestEmail] = useState();
    const [copyEmail, setCopyEmail] = useState();
    const [originEmail, setOriginEmail] = useState()
    const formRefMediosCorreo = useRef();
    const { Option } = Select;

    let mediosFormCorreo = {
        size: [6, 16],
        title: "mediosForm",
        items: [
            {
                custom: false,
                label: "Correo Prueba",
                name: "testEmail",
                type: "input",
                input: "email",
                required: true,
                initialValue: props.id ? testEmail : null,
            },
            {
                custom: false,
                label: "Correo Respuesta",
                name: "responseEmail",
                type: "input",
                input: "email",
                required: true,
                initialValue: props.id ? responseEmail : null,
            },
            {
                custom: false,
                label: "Correo Copia",
                name: "CopyEmail",
                type: "input",
                input: "email",
                required: false,
                initialValue: props.id ? copyEmail : null,

            },
            {
                custom: false,
                label: "Correo Origen",
                name: "email_origin",
                type: "input",
                input: "email",
                required: true,
                initialValue: props.id ? originEmail : null,
            },
            {
                custom: true,
                label: "Plantilla",
                name: "template",
                required: true,
                rules: [{ required: true, message: 'Este campo es requerido' }],
                component: (
                    <Select placeholder="Seleccione la plantilla" labelInValue defaultValue={{ value: template, }}
                        style={{ width: "100%", }} placeholder="Plantillas" onChange={(option) => setTemplate(option["key"])}  >
                        {templateList}
                    </Select>
                ),
            },
        ],
        multipart: false,
        submitButton: false,
    };

    const deleteChannel = () => {
        const formData = new FormData();
        formData.append("channel", "email");
        HttpRequest.endpoint = endPointCreateMassives + props.id + "/remove_channel/";
        HttpRequest.post(formData).then((res) => {
            if (Object.keys(res).length === 0) {
                props.updateRows()
                props.setResetChannel(props.reset + 1)
                autoCloseModal("Se ha eliminado el medio exitosamente")
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al eliminar este medio" })
            }
        });
    };

    const getData = () => {
        if (props.event) {
            HttpRequest.endpoint = props.event.split("v1/").pop();
            HttpRequest.get().then((res) => {
                setTestEmail(res["email_test"]);
                setResponseEmail(res["email_response"]);
                setCopyEmail(res["email_copy"]);
                setTemplate(res["template"]);
                setOriginEmail(res["email_origin"])
                createOptions();
            });
        } else {
            createOptions();
        }
    }
    const createOptions = () => {
        let templatesArray = [];
        HttpRequest.endpoint = endPointTemplateEmail;
        let filters = { operator: props.operator }
        HttpRequest.get(filters)
            .then((res) => {
                for (let index = 0; index < res.length; index++) {
                    templatesArray.push(<Option key={res[index]["ID"]} value={res[index]["ID"]}>  {res[index]["name"]} </Option>);
                }
                setTemplateList(templatesArray);
            })
            .then(() => {
                setShowForm(true);
            });
    };

    const addMedios = () => {
        let permission = true;
        if (props.editing) {
            permission = can(props.permissions.edit)
        } else {
            permission = can(props.permissions.create)
        }
        if (permission) {
            let values = formRefMediosCorreo.current.values()
            const formData = new FormData();
            formData.append("channel", "email");
            formData.append("email_test", values["testEmail"]);
            formData.append("email_response", values["responseEmail"]);
            values["CopyEmail"] && formData.append("email_copy", values["CopyEmail"]);
            formData.append("template", template);
            formData.append("email_origin", values["email_origin"]);
            HttpRequest.endpoint = endPointCreateMassives + props.id + "/add_channel/";
            HttpRequest.post(formData).then((res) => {
                if (Object.keys(res).length === 0) {
                    props.updateRows()
                    autoCloseModal("Se ha añadido el medio exitosamente")
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al agregar este medio" })
                }
            });
        }
    };

    useEffect(() => {
        setShowForm(false);
        props.id ? getData() :
            Modal.warning({ title: "Error", content: "Debe crear el masivo primero" })
    }, [props.id])

    useEffect(() => {
        if (props.reset) {
            setTemplate(null)
            setOriginEmail(null)
            setCopyEmail(null);
            setResponseEmail(null);
            setTestEmail(null);
            setShowForm(false);
            setTimeout(() => {
                setShowForm(true)
            }, 100)
        }
    }, [props.reset])

    return (
        <div>
            {props.id && showForm && (
                <div>
                    <FormComp info={mediosFormCorreo} ref={formRefMediosCorreo} customSubmit={addMedios} />
                    <Button type="primary" onClick={() => formRefMediosCorreo.current.submit()} style={{ marginRight: "10px", }} >
                        Agregar
			        </Button>
                    <Button style={{ alignSelf: "right" }} type="primary" danger onClick={() => props.permissions ? can(props.permissions.delete) ? deleteChannel() : null : deleteChannel()} >
                        Eliminar
                    </Button>
                </div>
            )}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator
    }
}

export default connect(mapStateToProps)(EmailForm);