import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { typifications } from '../../config/permissions';
import IndicadoresForm from './IndicadoresForm';

function IndicadoresTable() {
    const endpoints = {
        delete: 'qc/indicator/',
        columns: 'qc/indicator/datatables_struct/',
        rows: 'qc/indicator/datatables/',
        download: 'qc/indicator/download_data/',
    };

    return (
        <>
            <FullTable permissions={typifications} modalContent={<IndicadoresForm />} endpoints={endpoints} tableName='indicador' />
        </>
    )
}

export default IndicadoresTable;