import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Modal, InputNumber, DatePicker, Upload, ConfigProvider, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import esES from 'antd/lib/locale/es_ES';
import HttpRequest from '../../services/HttpRequest';
import store from '../../redux/store';

function MatrixPaymentForm(props) {
    const [bankAccounts, setBankAccounts] = useState([]);
    //const [invoices, setInvoices] = useState([]);
    const [invoiceInfo, setInvoiceInfo] = useState([]);

    const getInvoices = () => {
        HttpRequest.endpoint = 'matrix/invoices/';
        HttpRequest.get({ customer__rut: props.rut, paid_on__isnull: 'True' }).then(res => {
            if (Array.isArray(res) && res.length > 0) {
                const invoiceList = [];
                const invoiceArr = [];
                res.forEach(invoice => {
                    let foundService = false;
                    invoice.service.forEach(serv => {
                        if (props.services.includes(serv.number)) {
                            foundService = true
                        }
                    })
                    if (foundService && invoice.paid_on === null && (invoice.kind === 'Factura' || invoice.kind === 'Boleta' || invoice.kind === 'Nota de cobro' || invoice.kind === 'Nota de venta')) {
                        //invoiceList.push(<Select.Option value={invoice.id} key={invoice.id}>{invoice.due_date} - {invoice.comment} - {invoice.total} $</Select.Option>)
                        invoiceArr.push(invoice)
                    }
                })
                setInvoiceInfo(invoiceArr)
                //setInvoices(invoiceList)
            } else {
                Modal.info({
                    title: 'Información',
                    content: 'Este cliente no posee ninguna factura impaga, por lo que no se puede registrar ningun pago.'
                })
                if (props.delayPayment) {
                    props.hideModal()
                }
            }
        })
    }
    const getBankAccounts = () => {
        HttpRequest.endpoint = 'matrix/bankaccount/'
        HttpRequest.get({ operator: store.getState().operator }).then(res => {
            if (Array.isArray(res)) {
                const accounts = []
                res.forEach(bank => {
                    accounts.push(<Select.Option value={bank.id} key={bank.id}>{bank.tag} - {bank.account_number}</Select.Option>)
                })
                setBankAccounts(accounts)
            }
        })
    }
    const submitForm = async e => {
        /*if (e.boletas && e.boletas.length > 0) {
            const orderedBols = [];
            invoiceInfo.forEach(inv => {
                if (e.boletas.includes(inv.id)) {
                    orderedBols.push(inv.id)
                }
            })
            for (let i = 0; i < orderedBols.length; i++) {
                if (invoiceInfo[i].id !== orderedBols[i]) {
                    return Modal.error({
                        title: 'Error',
                        content: 'Debe seleccionar las boletas mas viejas a las más nuevas'
                    })

                }
            }
            e.boletas = orderedBols;
        }*/
        let paid = e.amount;
        let paidBols = [];
        let partialBols = [];
        for (let i = 0; i < invoiceInfo.length; i++) {
            if (paid >= invoiceInfo[i].total) {
                paidBols.push(invoiceInfo[i])
                paid -= invoiceInfo[i].total
            } else {
                if (paid < 0) {
                    break;
                } else if (invoiceInfo[i].total - paid <= 2000) {
                    paidBols.push(invoiceInfo[i])
                    paid -= invoiceInfo[i].total
                } else {
                    partialBols.push({
                        boleta: invoiceInfo[i],
                        diferencia: invoiceInfo[i].total - paid
                    })
                    paid -= invoiceInfo[i].total
                }
            }
        }
        const allBols = [];
        paidBols.forEach(bol => {
            allBols.push(bol.id)
        })
        partialBols.forEach(bol => {
            allBols.push(bol.boleta.id)
        })
        const comprobantes = [];
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            })
        }
        if (e.proof1 && e.proof1.file && e.proof1.file.status !== 'removed') {
            const comp1 = await getBase64(e.proof1.file);
            comprobantes.push(comp1.split('base64,')[1]);
            e.proof1 = null;
        }
        if (e.proof2 && e.proof2.file && e.proof2.file.status !== 'removed') {
            const comp2 = await getBase64(e.proof2.file);
            comprobantes.push(comp2.split('base64,')[1]);
            e.proof2 = null;
        }
        if (e.proof3 && e.proof3.file && e.proof3.file.status !== 'removed') {
            const comp3 = await getBase64(e.proof3.file);
            comprobantes.push(comp3.split('base64,')[1]);
            e.proof3 = null;
        }
        if (e.proof4 && e.proof4.file && e.proof4.file.status !== 'removed') {
            const comp4 = await getBase64(e.proof4.file);
            comprobantes.push(comp4.split('base64,')[1]);
            e.proof4 = null;
        }
        if (comprobantes.length > 0) {
            e.comprobante = comprobantes;
        }

        if (allBols.length > 0) {
            e.boletas = allBols;
        }
        const services = [];
        if (e.boletas) {
            e.boletas.forEach(bolId => {
                invoiceInfo.forEach(inv => {
                    if (inv.id === bolId) {
                        inv.service.forEach(serv => {
                            services.push(serv.number)
                        })
                    }
                })
            })
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            const filteredServices = services.filter(onlyUnique)
            e.service = filteredServices;
        }

        if (allBols.length !== invoiceInfo.length) {
            if (partialBols.length > 0) {
                Modal.confirm({
                    title: 'Información',
                    content: 'El monto definido no alcanza para pagar todas las boletas. Solo se registrarán las siguientes boletas: ' +
                        allBols.map(id => `${id}, `) + 'de las cuales las siguientes son pagadas parcialmente (diferencia mayor a 2000 $): ' +
                        partialBols.map(bol => bol.boleta.id + ', '),
                    okText: 'Entiendo',
                    onOk: () => {
                        if (props.latePayment) {
                            props.receiveInfo(e, invoiceInfo, paidBols, partialBols)
                        } else {
                            props.receiveInfo(e)
                        }
                    },
                    cancelText: 'Cancelar',
                })
            } else {
                Modal.confirm({
                    title: 'Información',
                    content: 'El monto definido no alcanza para pagar todas las boletas. Solo se registrarán las siguientes boletas: ' +
                        allBols.map(id => id + ', '),
                    okText: 'Entiendo',
                    onOk: () => {
                        if (props.latePayment) {
                            props.receiveInfo(e, invoiceInfo, paidBols, partialBols)
                        } else {
                            props.receiveInfo(e)
                        }
                    },
                    cancelText: 'Cancelar',
                })
            }
        } else {
            if (props.latePayment) {
                props.receiveInfo(e, invoiceInfo, paidBols, partialBols)
            } else {
                props.receiveInfo(e)
            }
        }
    }

    useEffect(getBankAccounts, [])
    useEffect(getInvoices, [])

    return (
        <>
            {bankAccounts.length > 0 && invoiceInfo.length > 0 ?
                <Form name='matrix-payment' onFinish={submitForm}>
                    <h1 className='form-title'>Registrar pago</h1>
                    <ConfigProvider locale={esES}>
                        <Form.Item name='paid_on' label='Fecha de pago' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <DatePicker format='DD/MM/YYYY' placeholder='Ejemplo 20-05-1950' />
                        </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider locale={esES}>
                        <Form.Item name='deposited_on' label='Fecha de depósito' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <DatePicker format='DD/MM/YYYY' placeholder='Ejemplo 20-05-1950' className='datepicker-wizard' />
                        </Form.Item>
                    </ConfigProvider>
                    <Form.Item name='amount' label='Monto' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item name='kind' label='Tipo' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select>
                            <Select.Option value={1}>Transbank</Select.Option>
                            <Select.Option value={2}>Cash</Select.Option>
                            <Select.Option value={3}>Cheque</Select.Option>
                            <Select.Option value={5}>Servipag</Select.Option>
                            <Select.Option value={6}>BCI deposit</Select.Option>
                            <Select.Option value={7}>Manual transfer</Select.Option>
                            <Select.Option value={8}>Webpay</Select.Option>
                            <Select.Option value={9}>Otro</Select.Option>
                            <Select.Option value={10}>No registrado en banco</Select.Option>
                            <Select.Option value={11}>Condonación</Select.Option>
                            <Select.Option value={12}>Pago masivo BCI</Select.Option>
                            <Select.Option value={13}>Paypal</Select.Option>
                            <Select.Option value={14}>Multicaja</Select.Option>
                            <Select.Option value={15}>Caja vecina</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='operation_number' label='Número de operación'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='proof1' label='Comprobante de pago'>
                        <Upload beforeUpload={() => false}>
                            <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name='proof2' label='Comprobante de pago 2'>
                        <Upload beforeUpload={() => false}>
                            <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name='proof3' label='Comprobante de pago 3'>
                        <Upload beforeUpload={() => false}>
                            <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name='proof4' label='Comprobante de pago 4'>
                        <Upload beforeUpload={() => false}>
                            <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name='comment' label='Comentario' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='bank_account' label='Cuenta' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select>
                            {bankAccounts}
                        </Select>
                    </Form.Item>
                    <Button type='primary' htmlType='submit' loading={props.loading}>Registrar pago</Button>
                </Form>
                :
                <Spin size='large' className='center-spin' />}
        </>
    )
}

export default MatrixPaymentForm;