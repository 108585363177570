import React from 'react';
import { PageSettings } from './../../config/page-settings.js';

class SidebarRight extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			collapse: [
				{	id: 1, collapse: true },
				{	id: 2, collapse: false },
				{	id: 3, collapse: false },
				{	id: 4, collapse: false },
				{	id: 5, collapse: false },
				{	id: 6, collapse: false },
				{	id: 7, collapse: false },
			]
		};
		this.toggleCollapse = this.toggleCollapse.bind(this);
	}

	toggleCollapse(index) {
		var newArray = [];
		for (let collapseObj of this.state.collapse) {
			if (collapseObj.id === index) {
				collapseObj.collapse = !collapseObj.collapse;
			} else {
				collapseObj.collapse = false;
			}
			newArray.push(collapseObj);
		}

		this.setState({
			collapse: newArray
		});
	}
  
	render() {
		return (
			<PageSettings.Consumer>
				{({pageTwoSidebar}) => (
					<React.Fragment>
						{pageTwoSidebar && (
							<React.Fragment>
								<div id="sidebar-right sidebar-right--float" className="sidebar sidebar-right">
									<div className="overflow-scroll height-full">
									</div>
								</div>
								<div className="sidebar-bg sidebar-right"></div>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
			</PageSettings.Consumer>
		)
	}
}


export default SidebarRight;
