import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	Modal,
	Row,
	Tooltip,
	Collapse,
	Drawer,
	Checkbox,
	Divider,
	InputNumber,
	Col,
	Spin
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import HttpRequest from "../../services/HttpRequest";
import { connect } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import CustomerInfoModal from "./customerInfoModal";
import ServiceInfoModal from "./serviceInfoModal";
import TimePicker from "../iclass/TimePicker";
import PhotoList from "./PhotoList";
import RemoveEquipHistory from "./removeEquipmentHistory";
import moment from "moment";


const RemoveEquipForm = (props) => {
	const [data, setData] = useState(null);
	const [cancelMotive, setCancelMotive] = useState("");
	const [customerList, setCustomerList] = useState([]);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [criterion, setCriterion] = useState("service");
	const { Option } = Select;
	const [serviceList, setServiceList] = useState([]);
	const [seriales, setSerials] = useState([]);
	const [customer, setCustomer] = useState();
	const [customerModal, setCustomerModal] = useState(false);
	const [serviceModal, setServiceModal] = useState(false);
	const [id, setId] = useState(props.id);
	const [rut, setRut] = useState();
	const [services, setServices] = useState(null);
	const [form] = useForm();
	const [loading, setLoading] = useState(false);
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const [iframeLoad, setIframeLoad] = useState(
		"https://fs2.iclass.com.br/iclassfs/restrict/ativo_search.seam"
	);
	const [iclassId, setIclassId] = useState(null);
	const [rappi, setRappi] = useState(false);
	const [rappiCost, setRappiCost] = useState(1);
	const [sacStep, setSacStep] = useState('');
	const [terrenoStep, setTerrenoStep] = useState(null);
	const [tiStep, setTiStep] = useState(null);
	const [historial, setHistorial] = useState(false);
	const [canceled, setCanceled] = useState(false);
	const [photos, setPhotos] = useState(false);
	const [previsualizeInfo, setPrevisualizeInfo] = useState(null);
	const [previsualizeModal, setPrevisualizeModal] = useState(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(null)
	const [model, setModel] = useState(null)
	const [mac, setMac] = useState(null)


	useEffect(() => {
		if (data) {
			setLoading(false)
		} else {
			setLoading(true)
		}
	}, [data])

	const searchUser = (value, rut = false) => {
		if (value != null && value.length > 0) {
			setCustomerList([]);
			setLoadingUsers(true);
			HttpRequest.endpoint = "matrix/customer_filter/";
			const formData = {
				criterion: rut ? "rut" : criterion,
				search: value,
			};
			HttpRequest.get(formData).then((res) => {
				setCustomerList(res);
				if (rut) setCustomer(res["text"]);
			});
		}
	};
	const getData = () => {
		HttpRequest.endpoint = "formerCustomers/remove_equipament";
		HttpRequest.get(null, props.id).then((res) => {
			if (res["status"] == 2) setCanceled(true);
			if (res["status_agendamiento"] != 2) {
				res["status_agendamiento"] == null
					? res["serial"] == null
						? setSacStep(0)
						: setSacStep(1)
					: setSacStep(res["status_agendamiento"] + 2);
			} else {
				setSacStep(null);
			}
			if (!res["removed_from_net"] && res.status_agendamiento === 2) {
				setTiStep(0);
			} else {
				setTiStep(null);
			}
			if (res["status_storage"] != null && res["status_storage"] != 3) {
				setTerrenoStep(res["status_storage"] + 1);
			} else {
				if (res.status_storage == 3 && !res.email_sended) {
					setTerrenoStep(4);
				} else {
					setTerrenoStep(null);
				}
				if (
					res["status_storage"] == null &&
					res["status_agendamiento"] != null
				) {
					setTerrenoStep(0);
				}
			}
			searchUser(res["rut"], true);
			getServices(res["rut"]);
			setRut(res["rut"]);
			setServices(res["service"]);
			setSerials(res["serial"])
			setCurrentStatus(res["equip_status"])
			setModel(res["model"])
			setMac(res["mac"])
			form.setFieldsValue({
				customer: res["rut"],
				services: res["service"],
				seriales: res["serial"],
			});
			if (res) {
				setData(res);
			}
			console.log(terrenoStep);
		});
	};
	const getServices = (rut) => {
		let list = [];
		const formData = { customer__rut: rut, operator: props.operator };
		if (props.operator == 0 || props.operator == null) {
			Modal.error({
				title: "Error",
				content: "Por favor seleccione un operador válido",
			});
			return;
		}
		HttpRequest.endpoint = "matrix/services/";
		HttpRequest.get(formData).then((res) => {
			res.forEach((element) => {
				list.push(
					<Option key={element["number"]} value={element["number"]}>
						{element["number"]}
					</Option>
				);
			});
			setServiceList(list);
			if (list.length === 1) {
				form.setFieldsValue({ services: [res[0].number] });
			}
		});
	};
	const onKeyPress = (e) => {
		const specialCharRegex = new RegExp("^[0-9]*$");
		const pressedKey = String.fromCharCode(
			!e.charCode ? e.which : e.charCode
		);
		if (!specialCharRegex.test(pressedKey)) {
			e.preventDefault();
			return false;
		}
	};
	const cancelOrder = () => {
		HttpRequest.endpoint = "formerCustomers/remove_equipament/";
		const formData = new FormData();
		formData.append("status", "2");
		HttpRequest.patch(id, formData).then((res) => {
			setLoading(false);
			if (res["ID"]) {
				props.updateRows();
				setData(res);
				setCanceled(true);
			} else {
				Modal.error({
					title: "Error",
					content:
						"Ha ocurrido un error al cancelar el retiro de equipo",
				});
			}
		});
	};
	const updateStatus = (
		section,
		status,
		iclass_id = null,
		order_start,
		order_end,
		back = false
	) => {
		setLoading(true);
		const formData = new FormData();
		switch (section) {
			case 0:
				formData.append("status", status);
				break;
			case 1:
				formData.append("status_agendamiento", status);
				break;
			case 2:
				formData.append("status_storage", status);
				break;
			case 3: //quitar
				formData.append("status_ti", status);
				break;
			default:
				break;
		}
		if (status == 1 && section == 1) {
			formData.append("rappi", rappi);
			if (rappi) formData.append("rappi_cost", rappiCost)
			formData.append("iclass_id", iclass_id);
			formData.append("order_start", order_start);
			formData.append("order_end", order_end);
		}
		HttpRequest.endpoint = "formerCustomers/remove_equipament/";
		HttpRequest.patch(id, formData).then((res) => {
			setLoading(false);
			if (res["ID"]) {
				setData(res);
				props.updateRows();
				switch (section) {
					case 1:
						if (status != 2) {
							if (back) {
								setSacStep(sacStep - 1);
							} else {
								setSacStep(sacStep + 1);
							}
						} else {
							setSacStep(null);
							setTerrenoStep(0);
							setTiStep(0);
						}
						break;
					case 2:
						status != 3
							? setTerrenoStep(terrenoStep + 1)
							: setTerrenoStep(null);
						break;
					default:
						break;
				}
			} else {
				if (Object.values(res).includes("Invalid Email Template")) {
					Modal.error({
						title: "Error",
						content:
							"No se ha seleccionado una plantilla de correo válida",
					});
				}
				Modal.error({
					title: "Error",
					content:
						"Ha ocurrido un error al actualizar el estado. Por favor verifique que cuente con los permisos necesarios",
				});
			}
		});
	};
	const handleSubmit = (values) => {
		setLoading(true);
		const formData = new FormData();
		//formData.append("serial", values["seriales"]);
		HttpRequest.endpoint = "formerCustomers/remove_equipament/";
		HttpRequest.patch(id, formData).then((res) => {

			setLoading(false);
			if (res["ID"]) {
				setData(res);
				props.updateRows();
				setSacStep(0);
				setId(res["ID"]);
			} else {
				Modal.error({
					title: "Error",
					content:
						"Ha ocurrido un error al crear el registro. Por favor verifique que cuente con los permisos necesarios.",
				});
			}
		});
	};
	const handleLoad = (props) => {
		if (iframeLoaded) {
			setIframeLoad(
				"https://fs2.iclass.com.br/iclassfs/restrict/ativo_search.seam"
			);
		} else {
			setIframeLoaded(true);
		}
	};

	const previewEmailTemplate = () => {
		HttpRequest.endpoint = "formerCustomers/remove_equipament_email/"
		HttpRequest.get({ operator: props.operator }).then(res => {
			if (res && res[0].template_email) {
				const emailTemplateID = res[0].template_email;
				HttpRequest.endpoint = 'communications/template_email/'
				HttpRequest.get({ ID: emailTemplateID }).then(res => {
					if (res && res[0].template_html) {
						setPrevisualizeInfo(res[0].template_html)
						setPrevisualizeModal(true)
					} else {
						Modal.error({
							title: 'Error',
							content: 'Ha ocurrido un error consultando la información de la plantilla de email'
						})
					}
				})
			} else {
				Modal.error({
					title: 'Error',
					content: '1-Ha ocurrido un error consultando la información de la plantilla'
				})
			}
		})
	}

	const updateSerial = (e) => {
		setLoading(true);
		const formData = new FormData();
		formData.append("serial", e["seriales"]);
		formData.append("model", e["model"])
		formData.append("mac", e["mac"])
		formData.append("status_storage", "3");
		formData.append("current_status", e.currentStatus);
		formData.append("equip_status", e.currentStatus);
		formData.append("person", rut);
		HttpRequest.endpoint = "formerCustomers/remove_equipament/";
		HttpRequest.patch(id, formData).then((res) => {
			setLoading(false);
			if (res["ID"]) {
				setData(res);
				setSerials(res["serial"])
				setCurrentStatus(e.currentStatus)
				setModel(res["model"])
				setMac(res["mac"])
				props.updateRows();
				setId(res["ID"]);
				setSacStep(null);
				if (res.email_sended) {
					setTerrenoStep(null);
				} else {
					setTerrenoStep(4);
				}
			} else {
				Modal.error({
					title: "Error",
					content: "Ha ocurrido un error al actualizar",
				});
			}
		});
	};
	const retryEmail = (e) => {
		setLoading(true);
		const formData = new FormData();
		formData.append("current_status", e.currentStatus);
		formData.append("person", rut);
		HttpRequest.endpoint =
			"formerCustomers/remove_equipament/" + props.id + "/retry_email/";
		HttpRequest.post(formData).then((res) => {
			setLoading(false);
			if (res != {}) {
				Modal.error({
					title: "Error",
					content:
						"Ha ocurrido un error al reintentar el envío de correo",
				});
			} else {
				props.updateRows();
			}
		});
	};

	const cancelAgendamiento = () => {
		const currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
		const agendDate = moment(data.order_end)
			.utc()
			.format("YYYY-MM-DD HH:mm:ss");
		if (!cancelMotive) {
			return Modal.error({
				title: "Error",
				content: "Debe especificar un motivo de cancelación",
			});
		}
		const cancelRequest = () => {
			setLoading(true);
			HttpRequest.endpoint =
				"iclass/service_orders/" + data.iclass_id + "/delete_order/";
			const formData = new FormData();
			formData.append("motive", cancelMotive);
			HttpRequest.post(formData).then((res) => {
				if (res && res.detail === "Service order deleted") {
					updateStatus(1, "0", null, null, null, true);
					setLoading(false)
				}
			});
		};
		if (currentDate > agendDate) {
			Modal.confirm({
				title: "Confirmación",
				content:
					"El agendamiento anterior ya se ejecutó. ¿Está seguro que desea reagendar?",
				okText: "Sí, estoy seguro",
				cancelText: "Cerrar",
				onOk: () => cancelRequest(),
			});
		} else {
			cancelRequest();
		}
	};
	const removeFromNet = () => {
		setLoading(true);
		const formData = new FormData();
		formData.append("removed_from_net", true);
		HttpRequest.endpoint = "formerCustomers/remove_equipament/";
		HttpRequest.patch(id, formData).then((res) => {
			setLoading(false);
			if (res["ID"]) {
				setData(res);
				props.updateRows();
				setTiStep(null);
				setId(res["ID"]);
			} else {
				Modal.error({
					title: "Error",
					content: "Ha ocurrido un error al actualizar",
				});
			}
		});
	};



	useEffect(() => {
		if (props.id) {
			getData();
		}
	}, []);

	const sacSteps = [
		{
			description: "Tomar retiro",
			content: (
				<div
					style={{
						width: "100%",
						justifyContent: "flex-end",
						display: "flex",
					}}
				>
					<Button
						htmlType="submit"
						loading={loading}
						type="primary"
						onClick={handleSubmit}
					>
						Tomar retiro y continuar
					</Button>
				</div>
			),
		},
		{
			description: "Registro en Iclass",
			content: (
				<div>
					<iframe
						className="remove-equip-iframe"
						onLoad={handleLoad}
						src={iframeLoad}
						title="Creación de servicio en Matrix"
					></iframe>
					<Row justify="space-between">

						<Col><h5>Realizó el registró en Iclass</h5>{" "}</Col>
						<Col>
							<Button

								type="link"
								href={"https://fs2.iclass.com.br/iclassfs/login/login.seam"}
								target="_blank"

							>
								Ir a Iclass
							</Button>
							<Button
								loading={loading}
								type="primary"
								onClick={() => {
									updateStatus(1, "0");
								}}
							>
								Listo
							</Button>
						</Col>
					</Row>
				</div >
			),
		},
		{
			description: "Crear Orden",
			content: (
				<div>
					{services && (
						<div>
							<Row justify="space-between">
								<Checkbox onChange={(e) => setRappi(e.target.checked)}><h4>El retiro será realizado por Rappi</h4></Checkbox>
								<Row align="middle">
									<h4>Costo del Retiro</h4>
									<InputNumber style={{ marginLeft: "8px" }} min={0.5} disabled={!rappi} defaultValue={1} onChange={(value) => setRappiCost(value)} />
								</Row>
							</Row>
							<>
								{
									!rappi
										? (<>
											<TimePicker
												remove={true}
												service={services}
												updateIclass={setIclassId}
												updateFunction={(iclass_id, order_start, order_end) => {
													updateStatus(
														1,
														"1",
														iclass_id,
														order_start,
														order_end
													);
													setSacStep(sacStep + 1);
												}}
											/>
										</>)
										: (<>
											<br />
											<Row justify="space-between">
												<h4>Crear la orden</h4>{" "}
												<Button
													loading={loading}
													type="primary"
													onClick={(iclass_id = 0) => {
														updateStatus(
															1,
															"1",
															iclass_id,

														);
														setSacStep(sacStep + 1)
													}}

												>
													Listo
												</Button>
											</Row>
										</>)
								}
							</>



						</div>
					)}
				</div>
			),
		},
		{
			description: "Agendamiento",
			content: (
				<div>
					<Input
						placeholder="Motivo de cancelación"
						onChange={(e) => setCancelMotive(e.target.value)}
					/>
					<Row justify="end">
						<Button
							type="primary"
							danger
							loading={loading}
							onClick={() => cancelAgendamiento()}
						>
							Reagendar
						</Button>
						<Button
							type="primary"
							loading={loading}
							onClick={() => {
								updateStatus(1, "2");
							}}
						>
							Finalizar
						</Button>
					</Row>
				</div>
			),
		},
	];
	const terrenoSteps = [
		{
			description: "En Almacen",
			content: (
				<div>
					<Row justify="space-between">
						<h5>El equipo está en almacen</h5>
						<Row>
							<Button
								type="primary"
								loading={loading}
								onClick={() => {
									updateStatus(2, "0");
								}}
							>
								Tomar retiro y continuar
							</Button>
						</Row>
					</Row>
				</div>
			),
		},
		{
			description: "En Bodega",
			content: (
				<div>
					<Row justify="space-between">
						<h5>El equipo está en bodega</h5>
						<Row>
							<Button
								type="primary"
								loading={loading}
								onClick={() => {
									updateStatus(2, "1");
								}}
							>
								Listo
							</Button>
						</Row>
					</Row>
				</div>
			),
		},
		{
			description: "Procesar en Iclass",
			content: (
				<div>
					<Row justify="space-between">
						<h5>Se ha procesado el equipo en Iclass</h5>
						<Row>
							<Button
								type="primary"
								loading={loading}
								onClick={() => {
									updateStatus(2, "2");
								}}
							>
								Listo
							</Button>
						</Row>
					</Row>
				</div>
			),
		},
		{
			description: "Seriales",
			content: (
				<div>
					<Modal width={900} title='Previsualización' footer={null} onCancel={() => { setPrevisualizeInfo(null); setPrevisualizeModal(false) }} visible={previsualizeModal}>
						{previsualizeInfo && <iframe width='850' height='350' srcDoc={previsualizeInfo}></iframe>}
						<Divider />
						<Row justify='center'>
							<Button loading={buttonLoading} type='primary' onClick={() => form.submit()}>Continuar</Button>
							<Button onClick={() => setPrevisualizeInfo(null)}>Cancelar</Button>
						</Row>
					</Modal>
					<Form
						form={form}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 20 }}
						onFinish={updateSerial}
					>
						<Form.Item
							name="seriales"
							initialValue={seriales}
							label="Seriales"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input placeholder="Ingrese el serial" />
						</Form.Item>

						<Form.Item
							name="model"
							label="Modelo"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>

							<Input placeholder="Ingrese el modelo" />
						</Form.Item>

						<Form.Item
							name="mac"
							label="Mac"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>

							<Input placeholder="Ingrese la direccion Mac" />
						</Form.Item>



						<Form.Item
							name="currentStatus"
							label="Estado actual"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input placeholder="Por favor indique el estado en el que se encuentra" />
						</Form.Item>

						<div
							style={{
								width: "100%",
								justifyContent: "flex-end",
								display: "flex",
							}}
						>
							<Button
								onClick={previewEmailTemplate}
								loading={loading}
								type="primary"
							>
								Guardar y continuar
							</Button>
						</div>
					</Form>
				</div>
			),
		},
		{
			description: "Reintentar envío de correo",
			content: (
				<div>
					<Form
						form={form}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 20 }}
						onFinish={retryEmail}
					>
						<Form.Item
							name="currentStatus"
							label="Estado actual"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input placeholder="Por favor indique el estado en el que se encuentra" />
						</Form.Item>
						<div
							style={{
								width: "100%",
								justifyContent: "flex-end",
								display: "flex",
							}}
						>
							<Button
								htmlType="submit"
								loading={loading}
								type="primary"
							>
								Reintentar
							</Button>
						</div>
					</Form>
				</div>
			),
		},
	];
	const tiSteps = [
		{
			description: "Retirado de Red",
			content: (
				<div>
					<Row justify="space-around">
						<div><h6>Número de servicio</h6>{services}</div>
						<div><h6>RUT del cliente</h6>{rut}</div>
					</Row>
					<Row justify="space-around" style={{ marginBottom: "35px" }}>
						<div><h6>Serial</h6>{seriales}</div>
						<div><h6>Estado del Equipo</h6>{currentStatus}</div>
						<div><h6>Modelo</h6>{model}</div>
					</Row>
					<Row justify="space-between">
						<h5>Retiró el equipo de la red</h5>
						<Button
							loading={loading}
							type="primary"
							onClick={
								() => removeFromNet() /*updateStatus(3, "2")*/
							}
						>
							Retirar
						</Button>
					</Row>
				</div>
			),
		},
	];

	return (
		<>
			<Row style={{ width: "100%" }} justify="space-between">
				<Button
					type="primary"
					danger
					onClick={() => {
						cancelOrder();
					}}
				>
					Cancelar Orden
				</Button>
				<Row justify="center" className="remove-equip-top-buttons">
					<Tooltip title="Información del Cliente">
						<Button onClick={() => setCustomerModal(true)}>
							Cliente
						</Button>
					</Tooltip>
					<Tooltip title="Información del Servicio">
						<Button onClick={() => setServiceModal(true)}>
							Servicio
						</Button>
					</Tooltip>
					<Tooltip title="Historial">
						<Button onClick={() => setHistorial(true)}>
							Historial
						</Button>
					</Tooltip>
					<Tooltip title="Fotos">
						<Button onClick={() => setPhotos(true)}>Fotos</Button>
					</Tooltip>
				</Row>
			</Row>

			{canceled ? (
				<h5>Se ha cancelado el retiro de equipo</h5>
			) : (
				<Collapse
					defaultActiveKey={[
						sacStep !== null
							? "sac"
							: terrenoStep !== null
								? "terreno"
								: "ti",
					]}
					accordion
				>
					{
						loading
							? <>

								<ContainerSpin>
									<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />

								</ContainerSpin>
							</>
							: <>
								<Collapse.Panel header="SAC" key="sac">
									{sacStep !== null ? (
										<>
											<h1 className="center-text remove-equip-title">
												Paso {sacStep + 1} de {sacSteps.length}:{" "}
												{sacSteps[sacStep]?.description}
											</h1>
											{
												data?.agent_sac
													? <>
														{sacSteps[sacStep + 1]?.content}
													</>
													: <>
														{sacSteps[sacStep]?.content}
													</>
											}

										</>
									) : (
										"Esta sección ya fue completada. Por favor complete la sección de Terreno y TI"
									)}
								</Collapse.Panel>
								<Collapse.Panel header="Terreno" key="terreno">
									{terrenoStep !== null ? (
										<>
											<h1 className="center-text remove-equip-title">
												Paso {terrenoStep + 1} de{" "}
												{terrenoSteps.length}:{" "}
												{terrenoSteps[terrenoStep].description}
											</h1>
											{terrenoSteps[terrenoStep].content}
										</>
									) : sacStep !== null ? (
										"Está sección requiere la completación de la sección de SAC"
									) : (
										"Está sección ya fue completada. Por favor complete la sección de TI"
									)}
								</Collapse.Panel>
								<Collapse.Panel header="TI" key="ti">
									{tiStep !== null ? (
										<>
											<h1 className="center-text remove-equip-title">
												Paso {tiStep + 1} de {tiSteps.length}:{" "}
												{tiSteps[tiStep].description}
											</h1>
											{tiSteps[tiStep].content}
										</>
									) : sacStep === null ? (
										"Esta sección ya fue completada"
									) : (
										"Esta sección requiere la completación de la sección de SAC"
									)}
								</Collapse.Panel>

							</>
					}

				</Collapse>
			)}
			<Drawer
				title="Historial"
				width={670}
				onClose={() => setHistorial(false)}
				visible={historial}
			>
				<RemoveEquipHistory id={props.id} />
			</Drawer>
			<Modal
				title="Fotos"
				width={800}
				onCancel={() => setPhotos(false)}
				visible={photos}
				footer={null}
			>
				<PhotoList removeID={props.id} />
			</Modal>
			{customerModal && (
				<CustomerInfoModal
					setVisible={setCustomerModal}
					rut={rut}
					visible={customerModal}
				/>
			)}
			{serviceModal && (
				<ServiceInfoModal
					setVisible={setServiceModal}
					rut={rut}
					visible={serviceModal}
				/>
			)}
		</>
	);
};


const ContainerSpin = styled.div`
	background-color: #f3f3f3;
	height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`


function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(RemoveEquipForm);
