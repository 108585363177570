import React, { useState, useEffect } from "react";
import {
	Calendar,
	ConfigProvider,
	Spin,
	Modal,
	Form,
	Input,
	Button,
	Checkbox,
} from "antd";
import esES from "antd/lib/locale/es_ES";
import { format } from "date-fns";
import HttpRequest from "../../services/HttpRequest";
import { connect } from "react-redux";
import autoCloseModal from "../../components/modal/AutoCloseModal.jsx";
import errorModal from "./../../components/modal/errorModal";

function IclassHolidayCreator(props) {
	const [hollidayList, setHollidayList] = useState(null);
	const [hollidayNames, setHollidayNames] = useState({ });
	const [day, setDay] = useState("");
	const [form] = Form.useForm();

	useEffect(() => {
		if (props.id) {
			getHolidayDate(props.id)
		} else {
			getHolidays();
		}
	}, []);

	const getHolidays = () => {
		let hollidays = [];
		HttpRequest.endpoint = "iclass/service_orders/so_holidays/";
		HttpRequest.get({ operator: props.operator }).then((res) => {
			if (res && Array.isArray(res)) {
				const hollidayNames = { };
				res.map((holliday) => {
					const date = format(new Date(holliday.day), "yyyy/MM/dd");
					hollidays.push(date);
					hollidayNames[date] = holliday.name;
				});
				setHollidayList(hollidays);
				setHollidayNames(hollidayNames);
			} else {
				errorModal(
					"Ha ocurrido un error obteniendo el listado de feriados"
				);
			}
		});
	};

	const getHolidayDate = (id) => {
		HttpRequest.endpoint = "iclass/service_orders/so_holidays/" + id + "/";
		HttpRequest.get({ operator: props.operator }).then((res) => {
			if (res) {
				setDay(format(new Date(res.day), "yyyy-MM-dd"))
				form.setFieldsValue({
					name: res.name,
					active: !res.active
				})
			} else {
				errorModal(
					"Ha ocurrido un error obteniendo la información del feriado"
				);
			}
		});
	};

	const cellRender = (value) => {
		return hollidayList.includes(value.format("YYYY/MM/DD")) ? (
			<div style={{ width: "100%", height: "100%" }}>
				<div
					style={{
						width: "100%",
						height: "100%",
						backgroundColor: "#e0cbad",
					}}
				>
					{hollidayNames[value.format("YYYY/MM/DD")]}
				</div>
			</div>
		) : (
			<div></div>
		);
	};

	const getDay = (e) => {
		let formData = new FormData();
		formData.append("day", day);
		formData.append("name", e.name);
		formData.append("active", !e.active);
		formData.append(
			"creator",
			JSON.parse(localStorage.getItem("userInfo")).username
		);
		HttpRequest.endpoint = "iclass/service_orders/so_holidays/";
		if (props.id) {
			HttpRequest.patch(props.id, formData).then((res) => {
				if (res.id) {
					autoCloseModal("Se ha actualizado el día feriado con éxito");
					props.updateRows();
				} else {
					errorModal(
						"Ha ocurrido un error actualizado el feriado"
					);
				}
			});
		} else {
			HttpRequest.post(formData).then((res) => {
				if (res.id) {
					autoCloseModal("Se ha agregado el día feriado con éxito");
					props.updateRows();
				} else {
					errorModal(
						"Ha ocurrido un error agregando el feriado o este ya estaba registrado"
					);
				}
			});
		}
	};

	return (
		<>
			{hollidayList ? (
				<ConfigProvider locale={esES}>
					<Calendar
						dateCellRender={cellRender}
						onSelect={(e) => setDay(e.format("YYYY-MM-DD"))}
					/>
				</ConfigProvider>
			) : (
				<Spin className="center-spin" />
			)}
			{day && (
				<Modal visible={day} footer={null} onCancel={() => setDay("")}>
					<Form form={form} onFinish={getDay}>
						<h1 className="center-text">
							{props.id ? "Actualizar feriado" : "Defina el nombre del feriado"}
						</h1>
						<Form.Item
							label="Nombre"
							name="name"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Inhabilitado"
							name="active"
							valuePropName="checked"
						>
							<Checkbox />
						</Form.Item>
						<Button type="primary" block htmlType="submit">
							Guardar
						</Button>
					</Form>
				</Modal>
			)}
		</>
	);
}

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(IclassHolidayCreator);
