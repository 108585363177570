import React, { useState, useEffect } from "react";
import { Form, Button, Input } from "antd";
import HttpRequest from "./../../services/HttpRequest";
import autoCloseModal from "./../../components/modal/AutoCloseModal";

function RetentionAgreements(props) {
	const [agreements, setAgreements] = useState({ });
	const [id, setId] = useState(1);

	const [form] = Form.useForm();

	const submit = (e) => {
		const agreementList = [];
		for (let key in e) {
			if (!key.includes("old-")) {
				agreementList.push(e[key]);
			}
		}
		props.submit(agreementList);
	};

	const addAgreement = (initialVal = null) => {
		setId((id) => {
			setAgreements((prevAgreements) => {
				const agreementsCopy = { ...prevAgreements };
				if (initialVal) {
					agreementsCopy[`old-agreement${id}`] = initialVal;
				} else {
					agreementsCopy[`agreement${id}`] = initialVal;
				}
				return agreementsCopy;
			});
			return id + 1;
		});
	};
	const deleteAgreement = (agreementKey, newAgr = true) => {
		if (newAgr) {
			setAgreements((prevAgreements) => {
				const agreementsCopy = { ...prevAgreements };
				delete agreementsCopy[agreementKey];
				return agreementsCopy;
			});
		} else {
			const agreementTextToDelete = form.getFieldValue(agreementKey);
			const formData = new FormData();
			if (props.agreement) {
				formData.append("agreement", agreementTextToDelete);
				HttpRequest.endpoint = `retentions/customer_retention/${props.retentionId}/remove_agreement/`;
			} else if (props.followup) {
				formData.append("followup", agreementTextToDelete);
				HttpRequest.endpoint = `retentions/customer_retention/${props.retentionId}/remove_followup/`;
			}
			HttpRequest.post(formData).then((res) => {
				if (res && res.ID) {
					autoCloseModal("Se ha eliminado con éxito");
					setAgreements((prevAgreements) => {
						const agreementsCopy = { ...prevAgreements };
						delete agreementsCopy[agreementKey];
						return agreementsCopy;
					});
				}
			});
		}
	};

	useEffect(() => {
		if (props.initialAgreements) {
			setAgreements({ });
			props.initialAgreements.forEach((agr) => addAgreement(agr));
		}
	}, [props.initialAgreements]);

	return (
		<Form onFinish={submit} form={form}>
			{!props.closed && (
				<Button onClick={() => addAgreement()} className="center-btn">
					Agregar
				</Button>
			)}
			{Object.keys(agreements).map((agreement) => {
				return (
					<div key={agreement} className="ticket-flex">
						<Form.Item
							className="ticket-flex-input"
							name={agreement}
							initialValue={agreements[agreement]}
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input disabled={agreements[agreement]} />
						</Form.Item>
						{!props.closed && (
							<Button
								type="primary"
								danger
								onClick={() =>
									deleteAgreement(
										agreement,
										agreements[agreement] ? false : true
									)
								}
							>
								X
							</Button>
						)}
					</div>
				);
			})}
			{!props.closed && (
				<Button
					loading={props.loading}
					htmlType="submit"
					type="primary"
					className="center-btn"
				>
					Guardar
				</Button>
			)}
		</Form>
	);
}

export default RetentionAgreements;
