import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, Modal } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { can, validOperator } from '../../helpers/helpers';
import { connect } from 'react-redux';
import store from "../../redux/store.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';

function TypificationSlackForm(props) {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(null);
    const [categories, setCategories] = useState({
        type: [],
        category: [],
        subcategory: [],
        secondSubcategory: []
    });
    const [channels, setChannels] = useState([]);
    const [fields, setFields] = useState([]);
    const form = useRef();

    const getInitialData = () => {
        if (props.id) {
            HttpRequest.endpoint = props.endpoints.message
            HttpRequest.get(null, props.id).then(res => {
                setInitialValues({
                    name: res.name,
                    fields: res.fields,
                    channel: res.channel,
                })
                getRecursiveCategories(res.category)
                //setFormLoading(false);
            })
        } else {
            setFormLoading(false);
        }
    }
    const getTypeCategories = () => {
        HttpRequest.endpoint = props.endpoints.categories;
        if (!props.operator || props.operator === 0) {
            Modal.error({
                title: "Error",
                content: "Por favor seleccione un operador válido",
            });
        } else {
            HttpRequest.get({ operator: props.operator, classification: 1 }).then(res => {
                const typeCategories = [];
                res.forEach(cat => {
                    typeCategories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
                })
                setCategories(prevCats => ({
                    ...prevCats,
                    type: typeCategories
                }));
                setChannels(prevChannels => {
                    prevChannels.length === 0 && getChannels()
                    return prevChannels
                })
            })
        }
    }
    const getChildCategories = (classification, parent) => {
        HttpRequest.endpoint = props.endpoints.categories;
        HttpRequest.get({ operator: props.operator, parent: parent }).then(res => {
            const categories = [];
            res.forEach(cat => {
                categories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                [classification]: categories
            }));
        })
    }
    const getRecursiveCategories = id => {
        HttpRequest.endpoint = props.endpoints.categories + id + "/";
        HttpRequest.get().then((res) => {
            switch (res["classification"]) {
                case 4:
                    getChildCategories("secondSubcategory", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        secondSubcategory: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 3:
                    getChildCategories("subcategory", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        subcategory: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 2:
                    getChildCategories("category", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        category: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 1:
                    getTypeCategories(false);
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        type: res["ID"]
                    }))
                    setFormLoading(false);
                    break;
                default:
                    break;
            }
        })
    }
    const getSlackChannels = nextCursor => {
        HttpRequest.endpoint = props.endpoints.slackChannels;
        const operator = store.getState().operator;
        const filters = { operator: operator };
        if (nextCursor) {
            filters.cursor = nextCursor
        }
        HttpRequest.get(filters).then(res => {
            if (res.ok) {
                setChannels(prevChannels => prevChannels.concat(res.channels.map(chan => <Select.Option key={chan.id} value={chan.id}>{chan.name}</Select.Option>)))
            }
            if (res.response_metadata && res.response_metadata.next_cursor) {
                getSlackChannels(res.response_metadata.next_cursor)
            }
        })
    }
    const getChannels = () => {
        getSlackChannels()
        getFields()
    }
    const getFields = () => {
        HttpRequest.endpoint = props.endpoints.fieldOptions;
        HttpRequest.get().then(res => {
            setFields(res.map(field => <Select.Option key={field.value} value={field.value}>{field.name}</Select.Option>))
            getInitialData()
        })
    }
    const submitForm = e => {
        if (validOperator()) {
            setButtonLoading(true);
            let category = e.secondSubcategory || e.subcategory;
            const formData = new FormData();
            formData.set('name', e.name)
            formData.set('fields', JSON.stringify(e.fields))
            formData.set('channel', e.channel)
            formData.set('category', category);
            HttpRequest.endpoint = props.endpoints.message + '/'
            if (props.id) {
                if (can(props.permissions.edit)) {
                    HttpRequest.patch(props.id, formData).then(res => {
                        setButtonLoading(false);
                        if (res.ID) {
                            autoCloseModal('Se ha editado el mensaje con éxito')
                            props.closeModal()
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error editando el mensaje'
                            })
                        }
                    })
                }
            } else {
                if (can(props.permissions.create)) {
                    HttpRequest.post(formData).then(res => {
                        setButtonLoading(false);
                        if (res.ID) {
                            autoCloseModal('Se ha creado el mensaje con éxito')
                            props.closeModal()
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error creando el mensaje'
                            })
                        }
                    })
                }
            }
        }
    }
    useEffect(getTypeCategories, [props.operator])

    return (
        <>
            {!formLoading &&
                <Form ref={form} name='typification-slack' initialValues={initialValues} onFinish={submitForm}>
                    <Form.Item label='Nombre' name='name' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Campos' name='fields' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select allowClear mode="multiple">
                            {fields}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Canal de slack' name='channel' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {channels}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Tipo' name='type' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select onChange={(e) => { getChildCategories('category', e); form.current.setFieldsValue({ category: null, subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {categories.type}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Categoría' name='category' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select onChange={(e) => { getChildCategories('subcategory', e); form.current.setFieldsValue({ subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {categories.category}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Subcategoría' name='subcategory' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select onChange={(e) => { getChildCategories('secondSubcategory', e); form.current.setFieldsValue({ secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {categories.subcategory}
                        </Select>
                    </Form.Item>
                    {props.typify && <Form.Item label='Segunda subcategoría' name='secondSubcategory'>
                        <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {categories.secondSubcategory}
                        </Select>
                    </Form.Item>}
                    <Button block size='large' loading={buttonLoading} type='primary' htmlType='submit'>{props.id ? 'Guardar' : 'Crear'}</Button>
                </Form>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(TypificationSlackForm);