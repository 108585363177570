import React, { useState, useEffect, useRef } from 'react';
import HttpRequest from "../../services/HttpRequest.js";
import { Form, Button, Modal } from 'antd';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { can, validOperator } from '../../helpers/helpers.js';
import { client } from '../../config/permissions.js';
import autoCloseModal from './../../components/modal/AutoCloseModal';

function PhoneForm(props) {
    const [formItemNewPhoneList, setFormItemNewPhoneList] = useState([]);
    const [formItemSavedPhoneList, setFormItemSavedPhoneList] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const newPhonesFormRef = useRef();
    const phoneRef = useRef();
    const [phone, setPhone] = useState(null);

    const validateNewClient = () => {
        if (!props.customerInfo && !props.newClient) {
            Modal.warning({
                title: "Advertencia",
                content: 'Debe crear un usuario antes de poder agregarle información de contacto'
            })
        }
    }
    const submitForm = formData => {
        if (validOperator()) {
            const keys = Object.keys(formData);
            if (keys.length > 0) {
                setButtonLoading(true)
                if (props.newClient) {
                    if (can(client.create)) {
                        HttpRequest.endpoint = `customers/customer/${props.newClient.ID}/add_phone/`
                    } else {
                        return
                    }
                } else if (props.customerInfo) {
                    if (can(client.edit)) {
                        HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/add_phone/`
                    } else {
                        return
                    }
                }
                Promise.all(
                    keys.map(key => {
                        const data = new FormData();
                        data.set('phone', formData[key])
                        //{ phone: formData[key] }
                        return HttpRequest.post(data)
                    })
                ).then(allRes => {
                    setButtonLoading(false)
                    let failedPhones = 0;
                    let repeated = false;
                    allRes.forEach(res => {
                        if (res['Error phone']) {
                            failedPhones++
                        } else if (res['Error phone repeat']) {
                            Modal.error({
                                title: 'Error',
                                content: 'Este telefono ya se encuentra registrado.'
                            })
                            repeated = true;
                        }
                    })
                    if (failedPhones) {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al agregar el teléfono.'
                        })
                    } else {
                        if (repeated === false) {
                            autoCloseModal('Se han agregado los teléfonos con éxito')
                            props.updateRows();
                        }
                    }
                }).catch(err => {
                    console.log('error catch al agregar telefonos', err);
                    setButtonLoading(false)
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error al agregar teléfonos'
                    })
                })
            }
        }
    }
    const deletePhone = (index, isNew, phone = null) => {
        if (!isNew) {
            if (can(client.edit)) {
                HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/delete_phone/`
                const data = new FormData()
                data.set('phone', phone)
                HttpRequest.post(data).then(res => {
                    console.log(res);
                    if (res['Error phone']) {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al eliminar el teléfono.'
                        })
                    } else {
                        setFormItemSavedPhoneList(prevState => {
                            let phoneIndexToDelete = 0;
                            prevState.forEach((phoneDiv, i) => {
                                if (Number(phoneDiv.key) === index) {
                                    phoneIndexToDelete = i;
                                }
                            })
                            return prevState.slice(0, phoneIndexToDelete).concat(prevState.slice(phoneIndexToDelete + 1))
                        });
                        autoCloseModal('Se ha eliminado el teléfono con éxito')
                        props.updateRows();
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        } else {
            setFormItemNewPhoneList(prevState => {
                let phoneIndexToDelete = 0;
                prevState.forEach((phoneDiv, i) => {
                    if (Number(phoneDiv.key) === index) {
                        phoneIndexToDelete = i;
                    }
                })
                return prevState.slice(0, phoneIndexToDelete).concat(prevState.slice(phoneIndexToDelete + 1))
            });
        }
    }
    phoneRef.current = phone;
    const editPhone = (e, i) => {
        if (can(client.edit)) {
            const newPhone = phoneRef.current;
            const oldPhone = props.customerInfo.phones[i];
            if (newPhone && oldPhone !== newPhone) {
                HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/add_phone/`;
                const data = new FormData()
                data.set('phone', newPhone)
                HttpRequest.post(data).then(res => {
                    if (res['Error phone']) {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al editar el teléfono.'
                        })
                    } else if (res['Error phone repeat']) {
                        Modal.error({
                            title: 'Error',
                            content: 'Este telefono ya se encuentra registrado.'
                        })
                    } else {
                        HttpRequest.endpoint = `customers/customer/${props.customerInfo.ID}/delete_phone/`;
                        const data = new FormData();
                        data.set('phone', oldPhone);
                        HttpRequest.post(data).then(res => {
                            if (res['Error phone']) {
                                Modal.error({
                                    title: 'Error',
                                    content: 'Ha ocurrido un error al editar el teléfono.'
                                })
                            } else {
                                autoCloseModal('Se he editado el teléfono con éxito')
                                props.updateRows();
                            }
                        })
                    }
                })
            }
            setPhone(null);
        }
    }
    const updateNewPhonesValues = (formItemName, e) => {
        const currentValues = newPhonesFormRef.current.getFieldsValue();
        console.log(currentValues, e);
        newPhonesFormRef.current.setFieldsValue({
            ...currentValues,
            [formItemName]: e
        })
    }
    const generateNewPhoneField = () => {
        if (props.customerInfo) {
            if (!can(client.edit)) {
                return
            }
        } else if (props.newClient) {
            if (!can(client.create)) {
                return
            }
        }
        const actualPhoneFields = [...formItemNewPhoneList];
        const newPhoneNumber = formItemNewPhoneList.length + formItemSavedPhoneList.length + 1;
        actualPhoneFields.push(
            <div key={newPhoneNumber} className='client-phone-field'>
                <PhoneInput className='phone-select-margin' placeholder={'Teléfono ' + newPhoneNumber} onChange={e => { const formItemName = 'phone' + newPhoneNumber; updateNewPhonesValues(formItemName, e) }} defaultCountry={'CL'} />
                <Form.Item className='abs-pos-error' name={'phone' + newPhoneNumber} rules={[{ required: true, whitespace: true, message: 'Este campo es obligatorio' }]} />
                <Button danger type='primary' onClick={() => deletePhone(newPhoneNumber, true)}>X</Button>
            </div>
        )
        setFormItemNewPhoneList(actualPhoneFields)

    }
    const generateSavedPhoneFields = () => {
        if (props.customerInfo) {
            const prevPhones = [];
            props.customerInfo.phones.forEach((phone, i) => {
                const phoneNumber = parsePhoneNumber(phone);
                prevPhones.push(
                    <div key={i + 1} className='client-phone-field' onBlur={e => editPhone(e, i)}>
                        <PhoneInput defaultCountry={phoneNumber.country} value={phoneNumber.nationalNumber} onChange={e => setPhone(e)} />
                        <Button danger type='primary' onClick={() => deletePhone(i + 1, false, phone)}>X</Button>
                    </div>
                )
            })
            return setFormItemSavedPhoneList(prevPhones);
        }
    }

    useEffect(validateNewClient, [])
    useEffect(generateSavedPhoneFields, [])

    return (
        <>
            {(props.newClient || props.customerInfo) &&
                <>
                    <Form id="clientPhoneFormList" >
                        {formItemSavedPhoneList}
                    </Form>
                    <Form name='phone-form' onFinish={submitForm} ref={newPhonesFormRef}>
                        <Button type='primary' onClick={generateNewPhoneField} style={{ display: 'block', margin: '0 auto 15px auto' }}>Agregar nuevo teléfono</Button>
                        {formItemNewPhoneList}
                        <Button loading={buttonLoading} disabled={formItemNewPhoneList.length === 0 && formItemSavedPhoneList.length === 0} type='primary' htmlType='submit' style={{ display: 'block', margin: '0 auto' }}>Guardar</Button>
                    </Form>
                </>
            }
        </>
    )
}

export default PhoneForm;