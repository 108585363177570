import React from "react";
import { Form, DatePicker, Button } from "antd";
import moment from "moment";

function RetentionContact(props) {
	return (
		<Form
			onFinish={props.submitContact}
			initialValues={{
				contact:
					props.initial && moment(props.initial, "YYYY-MM-DD HH:mm"),
			}}
		>
			<Form.Item
				name="contact"
				label="Fecha y hora de contacto"
				rules={[{ required: true, message: "Este campo es requerido" }]}
			>
				<DatePicker
					format="DD/MM/YYYY HH:mm"
					showTime
					style={{ width: "100%" }}
				/>
			</Form.Item>
			<Button
				className="center-btn"
				type="primary"
				htmlType="submit"
				loading={props.buttonLoading}
			>
				Guardar
			</Button>
		</Form>
	);
}

export default RetentionContact;
