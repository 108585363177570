import React, { useEffect, useState } from "react";
import { Tabs, Table, Modal } from "antd";
import HttpRequest from "../../services/HttpRequest";
import Moment from "react-moment";

const CustomerInfoModal = (props) => {

    const [personalInfo, setPersonalInfo] = useState();
    const { TabPane } = Tabs;

    const personalFields = {
        name: "Nombre", kind_name: "Tipo", email: "Correo", phone: "Teléfono",
        composite_address: "Dirección", commune: "Comuna", notes: "Nota"
    }

    const infoColumns = [
        { title: 'Campo', dataIndex: 'field', key: 'field' },
        { title: 'Valor', dataIndex: 'value', key: 'value' },
    ]


    useEffect(() => {
        if (props.rut != null) {
            getCustomerData(props.rut)
        }
    }, [props.rut])

    const getCustomerData = rut => {
        HttpRequest.endpoint = "matrix/customers/"
        let param = { rut: rut }
        let personalData = [];
        HttpRequest.get(param).then((res) => {
            Object.keys(res[0]).map((key) => {

                if (Object.keys(personalFields).includes(key)) {
                    personalData.push({ key: key, field: personalFields[key], value: res[0][key] })
                }
            })
            setPersonalInfo(personalData);
        })
    }

    return (
        <Modal footer={null} visible={props.visible} width="65%" onCancel={() => props.setVisible(false)}>
            <Tabs type="card">
                <TabPane tab="Personal" key="1">
                    <Table columns={infoColumns} dataSource={personalInfo} pagination={false} size="middle" />
                </TabPane>
            </Tabs>
        </Modal>
    )

}

export default CustomerInfoModal;