import React from 'react';
import { Input, InputNumber, DatePicker, Row } from 'antd';
import './tags.css';

function TagField(props) {
    return (
        <div className='tag-container'>
            <Input disabled value={props.tag.label} className='tag-name' />
            {props.tag.type === 'str' || props.tag.type === 'phone' ?
                <Input className='tag-value' onChange={e => props.onChange(props.tag.name, e.target.value)} />
                :
                props.tag.type === 'int' ?
                    <InputNumber className='tag-value' onChange={val => !isNaN(Number(val)) && props.onChange(props.tag.name, Number(val))} />
                    :
                    props.tag.type === 'email' ?
                        <Input className='tag-value' onChange={e => props.onChange(props.tag.name, e.target.value)} />
                        :
                        <DatePicker placeholder='Fecha' format={'DD/MM/YYYY'} className='tag-value' onChange={val => val && props.onChange(props.tag.name, val.format('DD/MM/YYYY'))} />
            }
        </div>
    )
}

export default TagField;