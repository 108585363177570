import { DatePicker, Form, ConfigProvider, Modal, Button, Input } from 'antd';
import React, { useState } from 'react';
import ExtraTipifyInfo from './ExtraTipifyInfo';
import PaymentDelay from './PaymentDelay';
import esES from 'antd/lib/locale/es_ES';
import HttpRequest from '../../services/HttpRequest';

function PaymentAmountWiz(props) {
    //props.startAgain y props.categories
    const [step, setStep] = useState(1);
    const [rut, setRut] = useState(null);
    const [services, setServices] = useState(null);
    const [channel, setChannel] = useState(null);
    const [tipifyID, setTipifyID] = useState(null);
    const [loading, setLoading] = useState(false)

    const receiveExtraData = data => {
        setChannel(data.channel)
        setRut(data.customer_rut.value);
        setServices(data.services)
        setStep(2)
    }
    const saveReminder = e => {
        setLoading(true)
        HttpRequest.endpoint = 'tickets/typify/' + tipifyID + '/create_reminder/';
        const formData = new FormData();
        formData.append('start', e.birth_date.format('YYYY-MM-DD'))
        formData.append('message', e.message);
        HttpRequest.post(formData).then(res => {
            if (res && Object.keys(res).length === 0) {
                props.updateRows()
                props.startAgain();
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false);
            Modal.error({
                title: 'Error',
                content: 'Ha ocurrido un error guardando el recordatorio'
            })
        })
    }

    const steps = {
        1: <ExtraTipifyInfo nextStep={receiveExtraData} requiredRut requiredServices />,
        2: <PaymentDelay process={props.process} setTipifyID={setTipifyID} setStep={setStep} channel={channel} categories={props.categories} rut={rut} services={services} startAgain={props.startAgain} />,
        3: <Modal title='Añadir recordatorio' visible={step === 3} onCancel={props.startAgain} footer={null}>
            <h4 className='center-text'>Añadir un recordatorio</h4>
            <Form name='reminder' onFinish={saveReminder}>
                <ConfigProvider locale={esES}>
                    <Form.Item label='Fecha de recordatorio' name='birth_date' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <DatePicker format='DD/MM/YYYY' placeholder='Ejemplo 20-05-1950' />
                    </Form.Item>
                </ConfigProvider>
                <Form.Item label='Mensaje' name='message' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Button type='primary' block htmlType='submit' loading={loading}>Guardar</Button>
            </Form>
        </Modal>
    }

    return (
        <>
            {steps[step]}
        </>
    )
}

export default PaymentAmountWiz;