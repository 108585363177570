import React from "react";
import { connect } from "react-redux";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import Avatar from "../../user/Avatar";
import { Modal } from "antd";
import HttpRequest from "../../../services/HttpRequest";
import AuthenticationService from "./../../../services/AuthenticationService";
import {
    endPointUserImage,
    endPointUserInformation,
} from "../../../services/endpoints";
import axios from "axios";

class DropdownProfile extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.openConfigurationModal = this.openConfigurationModal.bind(this);
        this.closeConfigurationModal = this.closeConfigurationModal.bind(this);
        this.state = {
            dropdownOpen: false,
            modalVisible: false,
            selectedFile: null,
            profilePicture: null,
        };
    }

    componentDidMount() {
        /*this.getProfilePic().then((res) => {
            this.setState({ profilePicture: res });
        });*/
    }

    toggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    openConfigurationModal() {
        this.setState(() => ({
            modalVisible: true,
        }));
    }

    closeConfigurationModal() {
        this.setState(() => ({
            modalVisible: false,
        }));
    }

    async getProfilePic() {
        try {
            HttpRequest.endpoint = endPointUserInformation;
            const res = await HttpRequest.get(
                { fields: "id,name" },
                null,
                false
            );
            HttpRequest.endpoint = `${endPointUserImage}${res["ID"]}/`;
            const res2 = await HttpRequest.get(
                ({ fields: "profile_image" }, null, false)
            );

            return res2["profile_image"];
        } catch (error) {
            return null;
        }
    }

    onFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
        this.getProfilePic();
    };

    onFileUpload = () => {
        console.log("Uploading ");

        // Create an object of formData 
        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "profile_image",
            this.state.selectedFile
        );

        // Details of the uploaded file 
        console.log(this.state.selectedFile);


        //New request using axios directly

        console.log("uploading 2");
        const url = 'http://iris.devel7.cl/api/v1/user/user_image/'
        axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            }
        }).then((res) => {
            console.log(res)
            console.log("Done!");
        });

        //Old request using httprequest. This worked before

        // HttpRequest.endpoint = endPointUserImage;
        // HttpRequest.post(formData, true, "multipart/form-data"
        // ).then((res) => {
        // 	this.setState({
        // 		profilePicture: this.getProfilePic(),
        // 	});
        // });
    };

    render() {
        const { user } = this.props;
        return (
            <div>
                <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                    className="dropdown navbar-user m-r-30"
                    tag="li"
                >
                    <DropdownToggle tag="a">
                        {/*this.state.profilePicture ? (
                            <Avatar src={this.state.profilePicture} />
                        ) : (
                                <Avatar />
                        )*/}
                        <span className="d-none d-md-inline m-l-15">
                            {user ? user.username : null}
                        </span>{" "}
                        <b className="caret"></b>
                    </DropdownToggle>
                    <DropdownMenu
                        className="dropdown-menu dropdown-menu-right"
                        tag="ul"
                    >
                        {/*<DropdownItem onClick={this.openConfigurationModal}>
							Configuración
						</DropdownItem>
                        <div className="dropdown-divider"></div>*/}
                        <DropdownItem
                            onClick={() =>
                                AuthenticationService.logout()
                                    ? window.location.reload()
                                    : null
                            }
                        >
                            Salir
						</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Modal
                    title="Configuración"
                    visible={this.state.modalVisible}
                    onCancel={this.closeConfigurationModal}
                    onOk={this.handleOk}
                >
                    <div>
                        <div>
                            <input type="file" onChange={this.onFileChange} />
                            <button onClick={this.onFileUpload}>Upload!</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default DropdownProfile;
