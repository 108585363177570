export const defaultValues = {
	draw: 1,
	"columns[0][data]": 0,
	"columns[0][name]": "created",
	"columns[0][searchable]": "true",
	"columns[0][orderable]": "true",
	"columns[0][search][value]": "",
	"columns[0][search][regex]": false,
	"columns[1][data]": 1,
	"columns[1][name]": "created",
	"columns[1][searchable]": true,
	"columns[1][orderable]": true,
	"columns[1][search][value]": "",
	"columns[1][search][regex]": false,
	"columns[2][data]": 2,
	"columns[2][name]": "ID",
	"columns[2][searchable]": true,
	"columns[2][orderable]": true,
	"columns[2][search][value]": "",
	"columns[2][search][regex]": false,
	"columns[3][data]": 3,
	"columns[3][name]": "category",
	"columns[3][searchable]": true,
	"columns[3][orderable]": true,
	"columns[3][search][value]": "",
	"columns[3][search][regex]": false,
	"columns[4][data]": 4,
	"columns[4][name]": "customer_type",
	"columns[4][searchable]": true,
	"columns[4][orderable]": true,
	"columns[4][search][value]": "",
	"columns[4][search][regex]": false,
	"columns[5][data]": 5,
	"columns[5][name]": "services",
	"columns[5][searchable]": true,
	"columns[5][orderable]": true,
	"columns[5][search][value]": "",
	"columns[5][search][regex]": false,
	"columns[6][data]": 6,
	"columns[6][name]": "liveagent",
	"columns[6][searchable]": true,
	"columns[6][orderable]": true,
	"columns[6][search][value]": "",
	"columns[6][search][regex]": false,
	"columns[7][data]": 7,
	"columns[7][name]": "channel",
	"columns[7][searchable]": true,
	"columns[7][orderable]": true,
	"columns[7][search][value]": "",
	"columns[7][search][regex]": false,
	"columns[8][data]": 8,
	"columns[8][name]": "type",
	"columns[8][searchable]": true,
	"columns[8][orderable]": true,
	"columns[8][search][value]": "",
	"columns[8][search][regex]": false,
	"columns[9][data]": 9,
	"columns[9][name]": "status",
	"columns[9][searchable]": true,
	"columns[9][orderable]": true,
	"columns[9][search][value]": "",
	"columns[9][search][regex]": false,
	"columns[10][data]": 10,
	"columns[10][name]": "urgency",
	"columns[10][searchable]": true,
	"columns[10][orderable]": true,
	"columns[10][search][value]": "",
	"columns[10][search][regex]": false,
	"columns[11][data]": 11,
	"columns[11][name]": "city",
	"columns[11][searchable]": true,
	"columns[11][orderable]": true,
	"columns[11][search][value]": "",
	"columns[11][search][regex]": false,
	"columns[12][data]": 12,
	"columns[12][name]": "management",
	"columns[12][searchable]": true,
	"columns[12][orderable]": true,
	"columns[12][search][value]": "",
	"columns[12][search][regex]": false,
	"order[0][column]": 0,
	"order[0][dir]": "desc",
	start: 0,
	length: 10,
	"search[value]": "",
	"search[regex]": false,
	urgency: "ignore",
	category: "ignore",
	city: "ignore",
	management: "ignore",
	operator: 2,
	customer_type: "ignore",
	agent: "ignore",
	status: "ignore",
	queue: "ignore",
	discount_reason: "ignore",
	discount_responsable_area: "ignore",
	endtime: "",
	inittime: "",
	_: 1594388332230,
};

export const city = [
	{ ID: "SANTIAGO", name: "SANTIAGO" },
	{ ID: "ARICA", name: "ARICA" },
	{ ID: "OTRA", name: "OTRA" },
];

export const management = [
	{ ID: "VISITA TÉCNICA", name: "VISITA TÉCNICA" },
	{ ID: "INSTALACIÓN", name: "INSTALACIÓN" },
	{ ID: "RETIRO DE EQUIPOS", name: "RETIRO DE EQUIPOS" },
	{ ID: "CONFIGURACIÓN ONLINE", name: "CONFIGURACIÓN ONLINE" },
];

export const urgency = [
	{ ID: "SIN URGENCIA", name: "SIN URGENCIA" },
	{ ID: "NORMAL", name: "NORMAL" },
	{ ID: "CRITICO", name: "CRITICO" },
	{ ID: "RECLAMO", name: "RECLAMO" },
];

export const customer = [
	{ ID: "Cliente", name: "Es Cliente" },
	{ ID: "No cliente", name: "No cliente" },
	{ ID: "Prospecto", name: "Prospecto" },
	{ ID: "Nuevo cliente", name: "Nuevo cliente" },
];

export const discountReason = [
	{ ID: "CAIDA MASIVA", name: "CAIDA MASIVA" },
	{ ID: "PROBLEMAS CON EL SERVICIO", name: "PROBLEMAS CON EL SERVICIO" },
	{ ID: "INCUMPLIMIENTO TECNICO", name: "INCUMPLIMIENTO TECNICO" },
	{ ID: "ERROR DE FINANZAS", name: "ERROR DE FINANZAS" },
	{ ID: "RETENCION", name: "RETENCION" },
	{ ID: "MALA INFORMACION", name: "MALA INFORMACION" },
];

export const discountResponsable = [
	{ ID: "TECNICA", name: "TECNICA" },
	{ ID: "PROBLEMAS CON EL SERVICIO", name: "PROBLEMAS CON EL SERVICIO" },
	{ ID: "SOPORTE ONLINE", name: "SOPORTE ONLINE" },
	{ ID: "CX", name: "CX" },
	{ ID: "PRODUCTO", name: "PRODUCTO" },
	{ ID: "FINANZAS", name: "FINANZAS" },
	{ ID: "VENTAS", name: "VENTAS" },
];

export const status = [
	{ ID: "CERRADO", name: "CERRADO" },
	{ ID: "ABIERTO", name: "ABIERTO" },
	{ ID: "ESPERANDO RESPUESTA", name: "ESPERANDO RESPUESTA" },
	{ ID: "DAR SEGUIMIENTO", name: "DAR SEGUIMIENTO" },
	{ ID: "LLAMAR LUEGO", name: "LLAMAR LUEGO" },
];


