import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Select, Modal } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import {
    endPointTemplateEmailTest,
    endPointTemplateEmail,
} from "../../services/endpoints.jsx";
import store from "../../redux/store.js";
import FormComp from "../../components/form/Form.jsx";
import { can } from "../../helpers/helpers.js";
import { template } from "../../config/permissions.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import TagField from "./TagField.jsx";

/**
 *
 * @param {props} props
 * required:
 * 		visible: bool, sets the modal status from the parent component
 * 		onCancel: function,  change the visible status of the modal so it can be closed from within itself too
 *
 * non required:
 * 		id: int: the event id in case we are going to edit it
 * 		type: the template type that you want to edit (correo, text, whatsapp, llamadadevoz)
 */

const EmailTemplateForm = (props) => {
    const [type, setType] = useState("Tipo de Plantilla");
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [subject, setSubject] = useState("");
    const [sender, setSender] = useState("");
    const [reciever, setReciever] = useState("");
    const [error, setError] = useState("");
    const [exito, setExito] = useState("");
    const [loading, setLoading] = useState();
    const [testLoading, setTestLoading] = useState();
    const [form] = Form.useForm();
    const formRefEmail = useRef();
    const formRefTestEmail = useRef();
    const [id, setId] = useState(props.id);
    const [tagList, setTagList] = useState([])
    const [showTags, setShowTags] = useState(false);
    const [selectedTags, setSelectedTags] = useState({})
    const [prevLoading, setPrevLoading] = useState(false);
    const [renderTemplate, setRenderTemplate] = useState('')

    const { Option } = Select;

    /**
     * @function Retrieves the data for the template that we are about to edit. Setting the
     * initial values
     *
     * @param {string} templateId the Id of the template that we are currently editing
     * 		  {string} templateType the type of the template that we are currently editing
     */
    async function getData(templateId) {
        setType("correo");
        HttpRequest.endpoint = endPointTemplateEmail + templateId;
        HttpRequest.get({ fields: "name,subject,template_html" }, null, false)
            .then((res) => {
                setName(res["name"]);
                setSubject(res["subject"]);
                setContent(res["template_html"]);
                getTags()
            })
    }
    const getTags = () => {
        HttpRequest.endpoint = "communications/template_email/tags/"
        HttpRequest.get().then(res => {
            if (res && Array.isArray(res.tags)) {
                setTagList(res.tags)
                setShowForm(true)
            }
        })
    }

    useEffect(() => {
        form.resetFields();
        setShowForm(false);
        if (id) {
            getData(id);
        } else {
            getTags()
        }
    }, []);

    /**
     * @function Creates or updates the template with the previously validated field values.
     * Making a request to the correct endpoint depending on the template type
     */

    const submitTemplate = () => {
        if (props.id) {
            if (!can(template.email.edit)) {
                return
            }
        } else {
            if (!can(template.email.create)) {
                return
            }
        }
        let op = store.getState().operator;
        if (!op || op == 0) {
            Modal.error({
                title: "Error",
                content: "Por favor seleccione un operador válido",
            });
            return;
        }
        setLoading(true);
        let values = null;
        values = formRefEmail.current.values();
        const formData = new FormData();
        formData.append("name", values["name"]);
        formData.append("template_html", values["content"]);
        formData.append("subject", values["subject"]);
        HttpRequest.endpoint = endPointTemplateEmail;

        id
            ? HttpRequest.patch(id, formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    autoCloseModal("Plantilla editada exitosamente")
                    props.closeModal()
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al modificar la plantilla" })
                }
            })
            : HttpRequest.post(formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    autoCloseModal("Plantilla creada exitosamente")
                    props.closeModal()
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al crear la plantilla" })
                }
            });
    };

    /**
     * @function Allows to test the template with the previously validated field values.
     * Making a request to the correct endpoint depending on the template type
     */

    const testTemplate = () => {
        if (props.id) {
            if (!can(template.email.edit)) {
                return
            }
        } else {
            if (!can(template.email.create)) {
                return
            }
        }
        setTestLoading(true);
        let values = null;
        values = formRefEmail.current.values();
        let testValues = null;
        testValues = formRefTestEmail.current.values();

        const formData = new FormData();

        formData.append("template", values["content"]);
        formData.append("subject", values["subject"]);
        formData.append("from_email", testValues["emailSender"]);
        formData.append("to_email", testValues["emailReciever"]);
        formData.append('data', JSON.stringify(selectedTags));
        HttpRequest.endpoint = endPointTemplateEmailTest;

        HttpRequest.post(formData).then((res) => {
            setTestLoading(false);
            if (Object.keys(res).length === 0) {
                autoCloseModal("Prueba realizada exitosamente")
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al realizar la prueba" })
            }
        });
    };

    /**
     * @function Updating the state of the components on change
     *
     * @param {event} e the event that holds the new value of the input fields
     *
     */

    const checkValidate = (e) => {
        let form = formRefEmail;
        form.current
            .validate()
            .then(() => {
                formRefTestEmail.current.submit();
            })
            .catch((error) => console.log(false));
    };

    const forField1 = (
        <FormComp
            customSubmit={submitTemplate}
            ref={formRefEmail}
            info={{
                size: [6, 16],
                title: "Form1",
                items: [
                    {
                        custom: false,
                        label: "Nombre",
                        name: "name",
                        type: "input",
                        input: "string",
                        required: true,
                        initialValue: id ? name : "",
                    },
                    {
                        custom: false,
                        label: "Asunto",
                        name: "subject",
                        type: "input",
                        input: "string",
                        required: true,
                        initialValue: id ? subject : "",
                    },
                    {
                        custom: false,
                        label: "Contenido",
                        name: "content",
                        type: "textArea",
                        input: "string",
                        required: true,
                        initialValue: id ? content : "",
                    },
                ],
            }}
        ></FormComp>
    );

    let testFormEmail = (
        <FormComp
            customSubmit={testTemplate}
            ref={formRefTestEmail}
            info={{
                size: [6, 16],
                title: "Form1",
                items: [
                    {
                        custom: false,
                        label: "Correo Emisor",
                        name: "emailSender",
                        type: "input",
                        input: "email",
                        required: true,
                        initialValue: id ? sender : "",
                    },
                    {
                        custom: false,
                        label: "Correo Receptor",
                        name: "emailReciever",
                        type: "input",
                        input: "email",
                        required: true,
                        initialValue: id ? reciever : "",
                    },
                ],
                multipart: false,
                submitButton: false,
            }}
        />
    );

    const handleSubmit = () => {
        formRefEmail.current.submit();
    };

    const saveTag = (type, val) => {
        setSelectedTags({ ...selectedTags, [type]: val })
    }
    const previsualize = () => {
        setPrevLoading(true)
        const values = formRefEmail.current.values();
        if (values["content"]) {
            HttpRequest.endpoint = 'communications/template_email/preview_template/';
            const formData = new FormData();
            formData.append("template", values["content"]);
            formData.append('data', JSON.stringify(selectedTags))
            HttpRequest.post(formData).then(res => {
                if (res && res.template) {
                    setPrevLoading(false)
                    setRenderTemplate(res.template)
                }
            })
        } else {
            setPrevLoading(false)
            Modal.error({
                title: 'Error',
                content: 'Debe definir un contenido antes de poder previsualizarlo'
            })
        }
    }

    return (
        <div>
            {showForm ? (
                <div
                    style={{
                        marginTop: "10px",
                    }}
                >
                    {forField1}
                    <p>Prueba</p>
                    {testFormEmail}
                    {showTags ?
                        <>
                            <div className='tag-list-container'>
                                {tagList.map(tag => <TagField key={tag.name} tag={tag} onChange={saveTag} />)}
                            </div>
                            <Button type='link' className='tag-center' onClick={() => setShowTags(false)}>Ocultar tags</Button>
                        </>
                        :
                        <Button type='link' className='tag-center' onClick={() => setShowTags(true)}>Mostrar tags</Button>
                    }
                    <div className="form-group">
                        <p className="text-danger text-center">{error}</p>
                        <p className="text-center">{exito}</p>
                    </div>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <Button onClick={checkValidate} loading={testLoading}>Probar</Button>
                        <Button onClick={previsualize} loading={prevLoading}>Previsualizar</Button>
                        <Button type="primary" onClick={handleSubmit} loading={loading} >
                            {id ? "Modificar" : "Crear"}
                        </Button>
                    </div>
                </div>
            ) : (
                ""
            )}
            <Modal width={900} footer={null} visible={renderTemplate} onCancel={() => setRenderTemplate('')}>
                <iframe width='850' height='350' srcDoc={renderTemplate} style={{ border: 0 }}></iframe>
            </Modal>
        </div>
    );
};

export default EmailTemplateForm;
