import HttpRequest from "./HttpRequest";
import store from "./../redux/store";
import ACTIONS from "./../redux/creator";
import { endPointUserTokenAuth, endPointUserRecover } from "./endpoints";

class AuthenticationService extends HttpRequest {
    static endpoint = "auth";

    static async login(username, password) {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        this.endpoint = endPointUserTokenAuth;
        try {
            const response = await this.post(formData);
            const preferences = localStorage.getItem("preferences");
            if (!preferences) localStorage.setItem("preferences", "{}");
            return response
        } catch (e) {
            console.log(e)
            return false;
        }
    }

    static async verify() {
        this.endpoint = "user/token/verify/";
        try {
            const localToken = localStorage.getItem("authorization");
            if (localToken) {
                const formData = new FormData()
                formData.append('token', localToken)
                const response = await this.post(formData);
                console.log('verify', response)
                if (!response.token) {
                    AuthenticationService.logout();
                } else {
                    return true
                }
            }
        } catch (e) {
            console.log(e)
            AuthenticationService.logout();
            //store.dispatch(ACTIONS.setHttpError(e.response.status));
        }
    }

    static async refresh() {
        this.endpoint = "user/token/refresh/";
        try {
            const localToken = localStorage.getItem("authorization");
            if (localToken) {
                const formData = new FormData()
                formData.append('token', localToken)
                const response = await this.post(formData);
                const preferences = localStorage.getItem("preferences");
                console.log('refresh', response)
                if (response.token) {
                    localStorage.setItem("authorization", response.token);
                    localStorage.setItem("token-date", Date.now())
                    store.dispatch(ACTIONS.setAuthorizationToken(response.token));
                    if (!preferences) localStorage.setItem("preferences", "{}");
                    return response.token;
                } else {
                    AuthenticationService.logout();
                    return false
                }

            }
        } catch (e) {
            console.log(e)
            AuthenticationService.logout();
            //store.dispatch(ACTIONS.setHttpError(e.response.status));
        }
    }

    static logout() {
        store.dispatch(ACTIONS.setAuthorizationToken(null));
        store.dispatch(ACTIONS.closeSession());
        sessionStorage.clear();
        localStorage.removeItem("authorization");
        localStorage.removeItem('token-date');
        localStorage.removeItem('permissions');
        localStorage.removeItem('operator');
        localStorage.removeItem('groups');
        localStorage.removeItem('userInfo');
        return true;
    }

    static async recoverPassword(username) {
        this.endpoint = endPointUserRecover;
        const formData = new FormData();
        formData.append("username", username)
        return this.post(formData);
    }
}

export default AuthenticationService;
