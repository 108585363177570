import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import {
    endPointTemplateText,
    endPointTemplateCall,
    endPointTemplateEmail,
    endPointTemplateWhatsapp,
    endPointTemplateTextTableStruct,
    endPointTemplateCallTableStruct,
    endPointTemplateEmailTableStruct,
    endPointTemplateWhatsappTableStruct,
    endPointTemplateTextDataTable,
    endPointTemplateEmailDataTable,
    endPointTemplateCallDataTable,
    endPointTemplateWhatsappDataTable,
} from "../../services/endpoints";
import TextTemplateForm from "./textTemplateForm.jsx";
import CallTemplateForm from "./callTemplateForm.jsx";
import EmailTemplateForm from "./emailTemplateForm.jsx";
import WhatsappTemplateForm from "./whatsappTemplateForm.jsx";
import { template } from '../../config/permissions';

function Template2(props) {
    const forms = {
        text: <TextTemplateForm />,
        call: <CallTemplateForm />,
        email: <EmailTemplateForm />,
        whatsapp: <WhatsappTemplateForm />,
    };
    const endpoints = {
        text: {
            delete: endPointTemplateText,
            columns: endPointTemplateTextTableStruct,
            rows: endPointTemplateTextDataTable,
        },
        call: {
            delete: endPointTemplateCall,
            columns: endPointTemplateCallTableStruct,
            rows: endPointTemplateCallDataTable,
        },
        email: {
            delete: endPointTemplateEmail,
            columns: endPointTemplateEmailTableStruct,
            rows: endPointTemplateEmailDataTable,
        },
        whatsapp: {
            delete: endPointTemplateWhatsapp,
            columns: endPointTemplateWhatsappTableStruct,
            rows: endPointTemplateWhatsappDataTable,
        },
    };

    return (
        <>
            <FullTable permissions={template[props.type]} modalWidth={800} modalContent={forms[props.type]} endpoints={endpoints[props.type]} tableName='plantilla' />
        </>
    )
}

export default Template2;