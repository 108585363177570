import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import Emails from './emails';

function EmailSearchTables() {
    const endpoint = 'communications/email_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<Emails />} />
        </>
    )
}

export default EmailSearchTables;