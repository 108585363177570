import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Select, Modal } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import {
    endPointTemplateTextTest,
    endPointTemplateText,
} from "../../services/endpoints.jsx";
import store from "../../redux/store.js";
import FormComp from "../../components/form/Form.jsx";
import { can } from "../../helpers/helpers.js";
import { template } from "../../config/permissions.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import TagField from "./TagField.jsx";

/**
 *
 * @param {props} props
 * required:
 * 		visible: bool, sets the modal status from the parent component
 * 		onCancel: function,  change the visible status of the modal so it can be closed from within itself too
 *
 * non required:
 * 		id: int: the event id in case we are going to edit it
 * 		type: the template type that you want to edit (correo, text, whatsapp, llamadadevoz)
 */

const TextTemplateForm = (props) => {
    const [type, setType] = useState("Tipo de Plantilla");
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [number, setNumber] = useState();
    const [error, setError] = useState("");
    const [exito, setExito] = useState("");
    const [loading, setLoading] = useState();
    const [testLoading, setTestLoading] = useState();
    const [form] = Form.useForm();
    const formRef = useRef();
    const formRefTest = useRef();
    const [id, setId] = useState(props.id);
    const [tagList, setTagList] = useState([])
    const [showTags, setShowTags] = useState(false);
    const [selectedTags, setSelectedTags] = useState({})
    const [renderTemplate, setRenderTemplate] = useState('')
    const [prevLoading, setPrevLoading] = useState(false);

    const { Option } = Select;

    /**
     * @function Retrieves the data for the template that we are about to edit. Setting the
     * initial values
     *
     * @param {string} templateId the Id of the template that we are currently editing
     * 		  {string} templateType the type of the template that we are currently editing
     */

    async function getData(templateId) {
        setType("texto");
        HttpRequest.endpoint = endPointTemplateText + templateId;
        HttpRequest.get({ fields: "name,template" }, null, false)
            .then((res) => {
                setName(res["name"]);
                setContent(res["template"]);
                getTags()
            })
    }

    useEffect(() => {
        form.resetFields();
        setShowForm(false);
        if (id) {
            getData(id);
        } else {
            getTags()
        }
    }, []);

    const handleSubmit = () => {
        formRef.current.submit();
    };

    /**
     * @function Creates or updates the template with the previously validated field values.
     * Making a request to the correct endpoint depending on the template type
     */

    const submitTemplate = () => {
        if (props.id) {
            if (!can(template.text.edit)) {
                return
            }
        } else {
            if (!can(template.text.create)) {
                return
            }
        }
        let op = store.getState().operator;
        if (!op || op == 0) {
            Modal.error({
                title: "Error",
                content: "Por favor seleccione un operador válido",
            });
            return;
        }
        setLoading(true)
        let values = null;
        values = formRef.current.values();
        const formData = new FormData();
        formData.append("name", values["name"]);
        formData.append("template", values["content"]);
        HttpRequest.endpoint = endPointTemplateText;
        id
            ? HttpRequest.patch(id, formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    autoCloseModal("Plantilla editada exitosamente")
                    props.closeModal()
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al modificar la plantilla" })
                }
            })
            : HttpRequest.post(formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    autoCloseModal("Plantilla creada exitosamente")
                    props.closeModal()
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al crear la plantilla" })
                }
            });
    };

    const getTags = () => {
        HttpRequest.endpoint = "communications/template_email/tags/"
        HttpRequest.get().then(res => {
            if (res && Array.isArray(res.tags)) {
                setTagList(res.tags)
                setShowForm(true);
            }
        })
    }
    const saveTag = (type, val) => {
        setSelectedTags({ ...selectedTags, [type]: val })
    }

    /**
     * @function Allows to test the template with the previously validated field values.
     * Making a request to the correct endpoint depending on the template type
     */

    const testTemplate = () => {
        if (props.id) {
            if (!can(template.text.edit)) {
                return
            }
        } else {
            if (!can(template.text.create)) {
                return
            }
        }
        setTestLoading(true);
        let values = null;
        values = formRef.current.values();
        const formData = new FormData();
        formData.append("template", values["content"]);
        formData.append("telephone", number.substring(1));
        formData.append('data', JSON.stringify(selectedTags));
        HttpRequest.endpoint = endPointTemplateTextTest;

        HttpRequest.post(formData).then((res) => {
            setTestLoading(false);
            if (Object.keys(res).length === 0) {
                autoCloseModal("Prueba realizada exitosamente")
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al realizar la prueba" })
            }
        });
    };

    /**
     * @function Updating the state of the components on change
     *
     * @param {event} e the event that holds the new value of the input fields
     *
     */

    const checkValidate = (e) => {
        let form = formRef;
        form.current
            .validate()
            .then(() => {
                if (number.length >= 6) {
                    setError([]);
                    formRefTest.current.submit();
                } else {
                    Modal.error({ title: "Error", content: "Ingrese un número válido" })
                }
            })
            .catch((error) => {
                Modal.error({ title: "Error", content: "Ingrese un número válido" })
            });
    };

    let forField2 = (
        <FormComp
            customSubmit={submitTemplate}
            ref={formRef}
            info={{
                size: [6, 16],
                title: "Form1",
                items: [
                    {
                        custom: false,
                        label: "Nombre",
                        name: "name",
                        type: "input",
                        input: "string",
                        required: true,
                        initialValue: id ? name : "",
                    },
                    {
                        custom: false,
                        label: "Contenido",
                        name: "content",
                        type: "textArea",
                        input: "string",
                        required: true,
                        initialValue: id ? content : "",
                    },
                ],
                multipart: false,
                submitButton: false,
            }}
        />
    );

    let testForm = (
        <FormComp
            customSubmit={testTemplate}
            ref={formRefTest}
            info={{
                size: [6, 16],
                title: "Form1",
                items: [
                    {
                        custom: true,
                        label: "Numero Prueba",
                        name: "number",
                        required: true,
                        initialValue: number,
                        component: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <PhoneInput
                                    defaultCountry="CL"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={number}
                                    onChange={setNumber}
                                />
                            </div>
                        ),
                    },
                ],
                multipart: false,
                submitButton: false,
            }}
        />
    );

    const previsualize = () => {
        setPrevLoading(true)
        const values = formRef.current.values();
        if (values["content"]) {
            HttpRequest.endpoint = 'communications/template_text/preview_template/';
            const formData = new FormData();
            formData.append("template", values["content"]);
            formData.append('data', JSON.stringify(selectedTags))
            HttpRequest.post(formData).then(res => {
                if (res && res.template) {
                    setPrevLoading(false)
                    setRenderTemplate(res.template)
                }
            })
        } else {
            setPrevLoading(false)
            Modal.error({
                title: 'Error',
                content: 'Debe definir un contenido antes de poder previsualizarlo'
            })
        }
    }

    return (
        <div>
            {showForm && (
                <div
                    style={{
                        marginTop: "10px",
                    }}
                >
                    {forField2}

                    <p>Prueba</p>
                    {testForm}
                    <div className="form-group">
                        <p className="text-danger text-center">{error}</p>
                        <p className="text-center">{exito}</p>
                    </div>

                    {showTags ?
                        <>
                            <div className='tag-list-container'>
                                {tagList.map(tag => <TagField key={tag.name} tag={tag} onChange={saveTag} />)}
                            </div>
                            <Button type='link' className='tag-center' onClick={() => setShowTags(false)}>Ocultar tags</Button>
                        </>
                        :
                        <Button type='link' className='tag-center' onClick={() => setShowTags(true)}>Mostrar tags</Button>
                    }
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <Button onClick={checkValidate} loading={testLoading}>Probar</Button>
                        <Button onClick={previsualize} loading={prevLoading}>Previsualizar</Button>
                        <Button type="primary" onClick={handleSubmit} loading={loading}>
                            {id ? "Modificar" : "Crear"}
                        </Button>
                    </div>
                </div>
            )}
            <Modal width={900} footer={null} visible={renderTemplate} onCancel={() => setRenderTemplate('')}>
                <iframe width='850' height='350' srcDoc={renderTemplate} style={{ border: 0 }}></iframe>
            </Modal>
        </div>
    );
};

export default TextTemplateForm;
