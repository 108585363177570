import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import EscalationTab from './EscalationTab';

function EscalationSearchTables() {
    const endpoint = 'escalation_ti/escalation_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<EscalationTab />} />
        </>
    )
}

export default EscalationSearchTables;