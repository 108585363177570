import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Select, Modal, Input } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import {
    endPointTemplateWhatsappTest,
    endPointWhatsappTags,
    endPointTemplateWhatsapp,
} from "../../services/endpoints.jsx";
import store from "../../redux/store.js";
import FormComp from "../../components/form/Form.jsx";
import { can } from "../../helpers/helpers.js";
import { template as templates } from "../../config/permissions.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import PrevisualizeWhatsapp from "./PrevisualizeWhatsapp.jsx";

/**
 *
 * @param {props} props
 * required:
 * 		visible: bool, sets the modal status from the parent component
 * 		onCancel: function,  change the visible status of the modal so it can be closed from within itself too
 *
 * non required:
 * 		id: int: the event id in case we are going to edit it
 * 		type: the template type that you want to edit (correo, text, whatsapp, llamadadevoz)
 */

const WhatsappTemplateForm = (props) => {
    const [type, setType] = useState("Tipo de Plantilla");
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [number, setNumber] = useState();
    const [templateDict, setTemplateDict] = useState();
    const [tags, setTags] = useState([]);
    const [error, setError] = useState("");
    const [exito, setExito] = useState("");
    const [templateList, setTemplateList] = useState([]);
    const [template, setTemplate] = useState({ name: null, description: null });
    const [loading, setLoading] = useState(false);
    const [testLoading, setTestLoading] = useState(false);
    const [form] = Form.useForm();
    const formRef = useRef();
    const formRefTest = useRef();
    const [id, setId] = useState(props.id);
    const [showPrevisualizeForm, setShowPrevisualizeForm] = useState(false);
    const [tagList, setTagList] = useState([])

    const { Option } = Select;

    /**
     * @function Retrieves the whatsapp tags available from the db
     *
     */

    const getTags = () => {
        HttpRequest.endpoint = "communications/template_email/tags/"
        HttpRequest.get().then(res => {
            if (res && Array.isArray(res.tags)) {
                setTagList(res.tags)
            }
        })
    }

    /**
     * @function Retrieves the data for the template that we are about to edit. Setting the
     * initial values
     *
     * @param {string} templateId the Id of the template that we are currently editing
     * 		  {string} templateType the type of the template that we are currently editing
     */

    async function getData(templateId) {
        setType("whatsapp");
        HttpRequest.endpoint = endPointTemplateWhatsapp + templateId;
        HttpRequest.get({ fields: "name,order,template" }, null, false)
            .then((res) => {
                setTemplate({ name: res["name"], description: res["template"] })
                setTags(res["order"]);
            })
            .then(() => setShowForm(true));
    }

    const getTemplates = () => {
        let list = [];
        HttpRequest.endpoint = "communications/whatsapp/whatsapp_templates/";
        HttpRequest.get().then((res) => {
            res.forEach(element => {
                list.push(<Option key={element["template"]} value={element["template"]}>{element["name"]}</Option>)
            });
            setTemplateList(list);
        })
        setShowForm(true);
    }

    useEffect(() => {
        form.resetFields();
        setShowForm(false);
        getTags();
        if (id) {
            getData(id);
        } else {
            getTemplates();
        }
    }, []);

    const handleSubmit = () => {
        form.submit();
    };

    /**
     * @function Creates or updates the template with the previously validated field values.
     * Making a request to the correct endpoint depending on the template type
     */

    const submitTemplate = () => {
        if (props.id) {
            if (!can(templates.whatsapp.edit)) {
                return
            }
        } else {
            if (!can(templates.whatsapp.create)) {
                return
            }
        }
        let op = store.getState().operator;
        if (!op || op == 0) {
            return Modal.error({
                title: "Error",
                content: "Por favor seleccione un operador válido",
            });
        }
        setLoading(true);
        let values = null;
        // values = formRef.current.values();
        console.log(values);
        const formData = new FormData();
        if (!template.name) {
            return Modal.error({ title: "Error", content: "Seleccione una plantilla" })
        }

        formData.append("name", template.name);
        formData.append("template", template.description);
        formData.append("order", JSON.stringify(tags));
        HttpRequest.endpoint = endPointTemplateWhatsapp;

        id
            ? HttpRequest.patch(id, formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    autoCloseModal("Plantilla editada exitosamente")
                    props.closeModal()
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al modificar la plantilla" })
                }
            })
            : HttpRequest.post(formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    autoCloseModal("Plantilla creada exitosamente")
                    props.closeModal()
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al crear la plantilla" })
                }
            });
    };

    /**
     * @function Allows to test the template with the previously validated field values.
     * Making a request to the correct endpoint depending on the template type
     */

    const testTemplate = () => {
        if (props.id) {
            if (!can(templates.whatsapp.edit)) {
                return
            }
        } else {
            if (!can(templates.whatsapp.create)) {
                return
            }
        }
        setTestLoading(true);
        const formData = new FormData();
        formData.append("template_name", template.name);
        formData.append("telephone", number.substring(1));
        formData.append("params", JSON.stringify(tags));
        HttpRequest.endpoint = endPointTemplateWhatsappTest;
        HttpRequest.post(formData).then((res) => {
            setTestLoading(false);
            if (Object.keys(res).length === 0) {
                autoCloseModal("Prueba realizada exitosamente")
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al realizar la prueba" })
            }
        });
    };

    function handleSelectChange(value) {
        setTags(value);
    }

    /**
     * @function Updating the state of the components on change
     *
     * @param {event} e the event that holds the new value of the input fields
     *
     */

    const checkValidate = (e) => {

        if (number.length >= 6) {
            setError([]);
            formRefTest.current.submit();
        } else {
            Modal.error({ title: "Error", content: "Ingrese un número válido" })
        }
    };

    let testForm = (
        <FormComp
            customSubmit={testTemplate}
            ref={formRefTest}
            info={{
                size: [6, 16],
                title: "Form1",
                items: [
                    {
                        custom: true,
                        label: "Numero Prueba",
                        name: "number",
                        required: true,
                        initialValue: number,
                        component: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <PhoneInput
                                    defaultCountry="CL"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={number}
                                    onChange={setNumber}
                                />
                            </div>
                        ),
                    },
                ],
                multipart: false,
                submitButton: false,
            }}
        />
    );
    return (
        <div>
            {showForm ? (
                <div
                    style={{
                        marginTop: "10px",
                    }}
                >
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form} onFinish={submitTemplate}>
                        <Form.Item label="Nombre" required initialValue={id ? template.name : null}>
                            <Select
                                labelInValue placeholder="Plantillas" defaultValue={id ? { label: template.name } : { label: null }}
                                onChange={(option) => {
                                    setTemplate({ name: option["label"], description: option["key"] }); console.log(option);
                                }}
                            >
                                {templateList}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Contenido" initialValue={template.description} >
                            <Input.TextArea rows={template.description ? 10 : 3} style={{ backgroundColor: "white", color: "rgba(0,0,0,0.65)" }} value={template.description} disabled />
                        </Form.Item>
                        <Form.Item label="Tags">
                            <Select
                                mode="tags"
                                placeholder="Por favor seleccione entre las diferentes opciones"
                                defaultValue={id ? tags : []}
                                onChange={handleSelectChange}
                            >
                                {tagList.map(tag => <Option key={tag.name} value={tag.name}>{tag.label}</Option>)}
                            </Select>
                        </Form.Item>
                    </Form>

                    <p>Prueba</p>
                    {testForm}
                    <div className="form-group">
                        <p className="text-danger text-center">{error}</p>
                        <p className="text-center">{exito}</p>
                    </div>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <Button onClick={checkValidate} loading={testLoading}>Probar</Button>
                        {tags.length > 0 && template && template.description && <Button onClick={() => setShowPrevisualizeForm(true)} >Previsualizar</Button>}
                        <Button type="primary" onClick={handleSubmit} loading={loading}>
                            {id ? "Modificar" : "Crear"}
                        </Button>
                    </div>
                </div >
            ) : (
                ""
            )
            }
            <Modal destroyOnClose width={600} footer={null} visible={showPrevisualizeForm} onCancel={() => setShowPrevisualizeForm(false)}>
                <PrevisualizeWhatsapp template={template} tagList={tagList} tags={tags} />
            </Modal>
        </div >
    );
};

export default WhatsappTemplateForm;
