import React, { useState, useEffect } from 'react';
import './Wizard.css';
import { Steps, Button, Modal } from 'antd';
import LeadCreateModal from '../leads/LeadCreateForm';
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import store from "../../redux/store";
import HttpRequest from '../../services/HttpRequest';
import { endPointLead, endPointLeadlet } from '../../services/endpoints';
import CreateCustomer from './CreateCustomer';
import TimePicker from "../iclass/TimePicker"

function Wizard(props) {
    const [currentStep, setCurrentStep] = useState(props.step ? props.step : 1);
    const [availablePlans, setAvailablePlans] = useState([])
    const [prospectData, setProspectData] = useState(null);
    const [fullAddress, setFullAddress] = useState(null);
    const [serviceNumber, setServiceNumber] = useState(null);
    const [refresh, setRefresh] = useState(true)
    const [loading, setLoading] = useState(false)
    const [serviceId, setServiceId] = useState(null)
    const [typifyChannel, setTypifyChannel] = useState(0)
    const [tipifyIds, setTipifyIds] = useState({});
    const [names, setNames] = useState(null);
    const nextStep = () => {
        setCurrentStep(currentStep + 1)
    }

    useEffect(() => {
        if (props.typify) {
            props.categories.forEach(cat => {
                setTipifyIds(prev => ({
                    ...prev,
                    [cat.name]: cat.category
                }))
            })
        }
    }, [])
    useEffect(() => {
        if (props.LeadletId) {
            HttpRequest.endpoint = endPointLeadlet + props.LeadletId + "/"
            HttpRequest.get().then((res) => {
                console.log(res);
                setProspectData(res)
            })
        }
    }, [])
    const startAgain = () => {
        if (props.typifyReset) props.typifyReset();
        if (currentStep == 3) {
            setCurrentStep(1);
            setAvailablePlans([]);
            setProspectData(null);
            setFullAddress(null);
            setServiceNumber(null);
            // Modal.success({
            //     title: "Éxito",
            //     content: "Se ha creado el registro con éxito",
            //     onOk() {
            //         setCurrentStep(1);
            //         setAvailablePlans([]);
            //         setProspectData(null);
            //         setFullAddress(null);
            //         setServiceNumber(null);
            //     }
            // })
        } else {
            setCurrentStep(1);
            setAvailablePlans([]);
            setProspectData(null);
            setFullAddress(null);
            setServiceNumber(null);
        }
    }
    const checkFact = (formData, id, address, names) => {
        setNames(names)
        setLoading(true)
        const street = formData.get('street_location');
        const operator = store.getState().operator;
        HttpRequest.endpoint = `pulso/factibility/?street_location=${street}&operator=${operator}`;
        HttpRequest.get().then(res => {
            setLoading(false)
            if (res.factibility === 'NO') {
                createLead(formData)
                if (props.typify) {
                    typifyFactibility(tipifyIds['Factibilidad'])
                }
            } else {
                createProspect(formData, res)
            }
        })
    }
    const createLead = formData => {
        HttpRequest.endpoint = endPointLead;
        HttpRequest.post(formData).then(res => {
            if (res.ID) {
                Modal.warning({
                    title: 'No existe factibilidad',
                    content: 'Esta dirección no presenta factibilidad. Se creó el lead.'
                })

            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error al crear el registro'
                })
            }
            startAgain()
            setRefresh(false);
            setRefresh(true);
        }).catch(err => {
            console.log(err)
        })
    }
    const createProspect = (formData, planRes) => {
        HttpRequest.endpoint = endPointLeadlet;
        HttpRequest.post(formData).then(res => {
            if (res.ID) {
                setAvailablePlans(planRes.plans);
                setFullAddress(planRes.address);
                setProspectData(res);
                nextStep()
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error al crear el registro'
                })
                startAgain()
                setRefresh(false);
                setRefresh(true);
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const typifyFactibility = (category, rut = null, service = null) => {
        const formData = new FormData();
        formData.append("category", category);
        formData.append("channel", typifyChannel);
        formData.append("status", 1);
        formData.append('process', props.process)
        if (rut != null) formData.append("rut", JSON.stringify([rut]));
        if (rut != null) formData.append("customer_type", 3);
        if (service != null) {
            let serviceList = []
            serviceList.push(service)
            formData.append("services", JSON.stringify(serviceList));
        }
        HttpRequest.endpoint = "tickets/typify/";
        HttpRequest.post(formData).then(res => {

        })
    }
    const createCustomer = res => {
        if (res.service) {
            if (props.typify) {
                typifyFactibility(tipifyIds['Nuevo servicio'])  //nuevo servicio
                typifyFactibility(tipifyIds['Factibilidad'], res.customer.rut, res.service.id) //factibilidad
            }
            setServiceNumber(res.service.number)
            setServiceId(res.service.id)
            Modal.confirm({
                title: 'Crear orden',
                content: '¿Desea crear el agendamiento o ver el cliente en Matrix?',
                onOk() {
                    nextStep()
                },
                onCancel() {
                    var link = res.service.customer.replace('api/v1/', '')
                    const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
                    if (newWindow) newWindow.opener = null
                    startAgain()
                },
                okText: 'Continuar agendamiento',
                cancelText: 'Ver en Matrix'
            })
        } else {
            if (!res.ID) {
                Modal.error({
                    title: 'Error',
                    content: 'Ocurrió un error al crear el registro, intenta nuevamente'
                })
            }
            startAgain()
        }
    }

    const steps = [
        {
            title: 'Primero',
            description: 'Verificación de factibilidad',
            content: <LeadCreateModal customRequest={checkFact} typify={props.typify} loading={loading} setChannel={setTypifyChannel} />
        },
        {
            title: 'Segundo',
            description: 'Creación de cliente',
            content: prospectData && <CreateCustomer names={names} searchInfo={props.searchInfo} startAgain={props.LeadletId ? null : startAgain} typify={() => typifyFactibility(tipifyIds['Factibilidad no venta']) /*no vent*/} fullAddress={fullAddress} plans={availablePlans} prospectData={prospectData} customSubmit={createCustomer} />
        },
        {
            title: 'Tercero',
            description: 'Creación de servicio',
            content:
                <>
                    <TimePicker service={serviceId} updateFunction={startAgain} />
                </>
        }
    ];

    return (
        <>
            <Panel>
                {!props.searchInfo && !props.typify && <PanelHeader />}
                <PanelBody>
                    <Steps current={currentStep - 1}>
                        {steps.map(step => <Steps.Step key={step.title} description={step.description} title={step.title} />)}
                    </Steps>
                    {refresh && <div className="steps-content">{steps[currentStep - 1].content}</div>}
                </PanelBody>
            </Panel>
        </>
    )
}

export default Wizard;