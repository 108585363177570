import React, { useEffect, useState } from "react";
import HttpRequest from "./../../services/HttpRequest";
import { Form, Select, Radio, Button, Row, Spin } from "antd";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import CustomerInfoModal from "../typify/customerInfoModal";
import ServiceInfoModal from "../typify/servicesInfoModal";
import "./ServiceSearch.css";

function ServiceSearch(props) {
	const [rut, setRut] = useState(null);
	const [typing, setTyping] = useState(false);
	const [serviceList, setServiceList] = useState([]);
	const [customerList, setCustomerList] = useState([]);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [criterion, setCriterion] = useState("service");
	const [customerModal, setCustomerModal] = useState(false);
	const [serviceModal, setServiceModal] = useState(false);

	const { form } = props;

	const getServices = (rut) => {
		let list = [];
		const formData = {
			customer__rut: rut,
			operator: props.operator,
			fields: "number",
		};
		HttpRequest.endpoint = "matrix/services/";
		HttpRequest.get(formData).then((res) => {
			res.forEach((element) => {
				list.push(
					<Select.Option
						key={element["number"]}
						value={element["number"]}
					>
						{element["number"]}
					</Select.Option>
				);
			});
			setServiceList(list);
			if (list.length === 1) {
				form.setFieldsValue({ services: [res[0].number] });
			}
		});
	};
	const searchUser = (value) => {
		if (value != null && value.length > 0) {
			setCustomerList([]);
			setLoadingUsers(true);
			HttpRequest.endpoint = "matrix/customer_filter/";
			const formData = { criterion: criterion, search: value };
			HttpRequest.get(formData).then((res) => {
				setCustomerList(res);
			});
		}
	};
	const onKeyPress = (e) => {
		const specialCharRegex = new RegExp("^[0-9]*$");
		const pressedKey = String.fromCharCode(
			!e.charCode ? e.which : e.charCode
		);
		if (!specialCharRegex.test(pressedKey)) {
			e.preventDefault();
			return false;
		}
	};
	useEffect(() => {
		if (rut && typing) {
			const delay = setTimeout(() => {
				searchUser(rut);
			}, 1500);
			return () => clearTimeout(delay);
		}
	}, [rut]);

	useEffect(() => {
		if (form.getFieldValue("customer_rut") != null) {
			getServices(form.getFieldValue("customer_rut")["key"]);
		}
		form.setFieldsValue({
			services: undefined,
		});
	}, [props.operator]);
	return (
		<>
			<div>
				<div className="radio-group-div">
					<Form.Item>
						<Radio.Group
							className="radio-group"
							onChange={(e) => setCriterion(e.target.value)}
							defaultValue={"service"}
						>
							<Radio value={"rut"}>Rut</Radio>
							<Radio value={"name"}>Nombre</Radio>
							<Radio value={"composite_address"}>Dirección</Radio>
							<Radio value={"service"}>Servicio</Radio>
						</Radio.Group>
					</Form.Item>
				</div>
			</div>
			<Row justify="center">
				<div className="so-rut-container">
					<Form.Item
						className="search-rut"
						label="Cliente:"
						name="customer_rut"
						rules={[
							{
								required: props.requiredCustomer,
								message: "Este campo es requerido",
							},
						]}
					>
						{criterion == "service" ? (
							<Select
								onKeyPress={onKeyPress}
								placeholder="Ingrese el número de servicio"
								showSearch
								notFoundContent={
									loadingUsers ? <Spin size="small" /> : null
								}
								filterOption={false}
								onSearch={(val) => {
									setTyping(true);
									setRut(val);
								}}
								onChange={(value) => {
									getServices(value);
									setTyping(false);
									setRut(value);
									form.setFieldsValue({
										services: undefined,
									});
								}}
							>
								{customerList.map((element) => (
									<Select.Option
										key={element.id}
										value={element.id}
									>
										{element.text}
									</Select.Option>
								))}
							</Select>
						) : (
							<Select
								placeholder={
									criterion == "rut"
										? "Ingrese el rut"
										: criterion == "composite_address"
										? "Ingrese la dirección"
										: "Ingrese el nombre del cliente"
								}
								showSearch
								notFoundContent={
									loadingUsers ? <Spin size="small" /> : null
								}
								filterOption={false}
								onSearch={(val) => {
									setTyping(true);
									setRut(val);
								}}
								onChange={(value) => {
									getServices(value);
									setTyping(false);
									setRut(value);
									form.setFieldsValue({
										services: undefined,
									});
								}}
							>
								{customerList.map((element) => (
									<Select.Option
										key={element.id}
										value={element.id}
									>
										{element.text}
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>
					<Button
						shape="circle"
						icon={<SearchOutlined />}
						onClick={() => setCustomerModal(true)}
					/>
				</div>
				<div className="so-rut-container">
					<Form.Item
						className="search-rut"
						label="Servicios"
						name="services"
						rules={[
							{
								required: props.requiredService,
								message: "Este campo es requerido",
							},
						]}
					>
						<Select placeholder="Servicio" mode="multiple">
							{serviceList}
						</Select>
					</Form.Item>
					<Button
						shape="circle"
						icon={<SearchOutlined />}
						onClick={() => setServiceModal(true)}
					/>
				</div>
			</Row>
			{customerModal && (
				<CustomerInfoModal
					visible={customerModal}
					setVisible={setCustomerModal}
					rut={rut}
				/>
			)}
			{serviceModal && (
				<ServiceInfoModal
					visible={serviceModal}
					setVisible={setServiceModal}
					rut={rut}
				/>
			)}
		</>
	);
}

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(ServiceSearch);
