import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Modal, Select, InputNumber, DatePicker, Spin, Col, Row } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import LocationForm from '../../components/form/locationInput';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import RutField from './RutField';
import moment from 'moment';
import autoCloseModal from './../../components/modal/AutoCloseModal';
import { drives } from '../../config/permissions';
import { can } from '../../helpers/helpers';

function DriveForm(props) {
    const [firstColumn, setFirstColumn] = useState([]);
    const [secondColumn, setSecondColumn] = useState([]);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [plans, setPlans] = useState(null);
    const [users, setUsers] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [initialInfo, setInitialInfo] = useState(null);
    const [showRut, setShowRut] = useState({});
    const formRef = useRef()

    const typesComponent = {
        'string': <Input />,
        'int': <InputNumber className='total-width' />,
        'float': <InputNumber step={0.01} className='total-width' />,
        'email': <Input />,
        'datetime': <DatePicker format={'DD/MM/YYYY HH:MM:ss'} className='total-width' />,
        'logic': <Select>
            <Select.Option value={true} key={'1'}>Verdadero</Select.Option>
            <Select.Option value={false} key={'2'}>Falso</Select.Option>
        </Select>,
        'select_dynamic_plans': <Select>
            {plans}
        </Select>,
        'select_dynamic_users_iris': <Select>
            {users}
        </Select>,
        'select_dynamic_services': <InputNumber className='total-width' />,
    }

    const submitForm = async e => {
        setButtonLoading(true)
        Object.keys(e).forEach(key => {
            if (e[key] === "") {
                e[key] = null
            }
            if (moment.isMoment(e[key])) {
                e[key] = e[key].format('YYYY/MM/DD HH:MM:ss')
            }
        })
        const formData = new FormData();
        formData.append('drive', props.driveID);
        formData.append('register', JSON.stringify(e));
        HttpRequest.endpoint = 'drives/register/';
        if (props.id) {
            if (!can(drives.register.edit)) {
                return;
            }
        } else {
            if (!can(drives.register.create)) {
                return;
            }
        }
        const res = props.id ? await HttpRequest.patch(props.id, formData) : await HttpRequest.post(formData)
        setButtonLoading(false)
        if (res.ID) {
            autoCloseModal(`Se ha ${props.id ? 'editado' : 'creado'} el registro con éxito`)
            props.closeModal()
        } else {
            const errors = {
                'Description repeat error': 'No pueden haber columnas con el mismo nombre',
                'Error default_value format: phone format': 'El formato del número teléfonico es incorrecto',
                'Error dynamic_services value': 'El servicio especificado no existe',
                'Error float value': 'El número decimal debe contener decimal',
                'Error int value': 'El número entero no debe contener decimales',
                'Description default_value error: static selects': 'No se pueden tener opciones iguales en el campo de tipo opciones',
                'Error default_value value: email': 'El formato del email es inválido',
                "Error address value": 'La dirección especificada no existe'
            }
            Object.keys(errors).forEach(err => {
                if (Object.keys(res).length > 0 && Object.keys(res)[0].includes(err)) {
                    Modal.error({
                        title: 'Error',
                        content: errors[err]
                    })
                }
            })
        }
    }
    const defaultDate = def => {
        let defaultVal;
        if (props.id) {
            defaultVal = initialInfo.register[def.name] && moment(initialInfo.register[def.name], 'YYYY/MM/DD HH:MM:ss')
        } else {
            defaultVal = def.default_value && moment(def.default_value, 'YYYY/MM/DD HH:MM:ss')
        }
        return defaultVal;
    }

    const getDefinition = () => {
        let reqInitial = true;
        if (props.id) {
            reqInitial = initialInfo;
        }
        if (plans && users && reqInitial) {
            HttpRequest.endpoint = 'drives/definition/' + props.driveID + '/';
            HttpRequest.get().then(res => {
                if (res.ID) {
                    const fields = [];
                    res.definition.forEach(def => {
                        const rules = []
                        if (def.required) {
                            rules.push({ required: true, message: 'Este campo es requerido' })
                        }
                        if (def.type === 'email') {
                            rules.push({ type: 'email', message: 'Formato de email inválido' })
                        }
                        if (def.type === 'phone') {
                            let number;
                            if (props.id) {
                                number = initialInfo.register[def.name] && parsePhoneNumber(initialInfo.register[def.name]);
                            } else {
                                number = def.default_value && parsePhoneNumber(def.default_value);
                            }
                            fields.push(
                                <div key={def.name + 'b'} className='drive-phone-input'>
                                    {def.required && <p className='drive-asterisk'>*</p>}
                                    <p className='drive-name-asterisk'>{def.name}:</p>
                                    <PhoneInput placeholder='Teléfono' className='drive-form-input total-width' key={def.name} onChange={val => formRef.current.setFieldsValue({ [def.name]: val })} defaultCountry={number ? number.country : 'CL'} value={number && number.nationalNumber} />
                                </div>
                            )
                        }
                        fields.push(
                            <Form.Item hidden={def.type === 'phone'} label={def.name} name={def.name} key={def.name} initialValue={props.id ? def.type === 'datetime' ? defaultDate(def) : initialInfo.register[def.name] : /*creando a partir de aqui*/def.type === 'datetime' ? defaultDate(def) : def.default_value} rules={rules}>
                                {def.type === "select_static" ?
                                    <Select>
                                        {def.values.map(opt => <Select.Option key={opt.value} value={opt.value}>{opt.name}</Select.Option>)}
                                    </Select>
                                    :
                                    def.type === 'select_dynamic_location' ?
                                        <LocationForm className='total-width' drive onChange={val => formRef.current.setFieldsValue({ [def.name]: val.split(':')[1].concat(`:${props.operator}`) })} />
                                        :
                                        def.type === 'select_dynamic_rut' ?
                                            <Input onClick={() => setShowRut({
                                                defaultVal: props.id ? initialInfo.register[def.name] : def.default_value,
                                                name: def.name
                                            })} placeholder='Buscar RUT' defaultValue={props.data && props.data.default_value} />
                                            :
                                            typesComponent[def.type]
                                    //<Test onChange={val => formRef.current.setFieldsValue({ [def.name]: val })} />
                                }
                            </Form.Item>
                        )
                    })
                    const half = fields.length / 2;
                    if (Number.isInteger(half)) {
                        setFirstColumn(fields.slice(0, half))
                        setSecondColumn(fields.slice(half))
                    } else {
                        const int = Math.floor(half) + 1;
                        setFirstColumn(fields.slice(0, int))
                        setSecondColumn(fields.slice(int))
                    }
                    //setItems(fields)
                    setTitle(res.name)
                    setLoading(false)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo la definición del formulario, por favor intente nuevamente'
                    })
                }
            })
        }
    }
    const getPlans = () => {
        HttpRequest.endpoint = 'matrix/plans/';
        HttpRequest.get({ operator: props.operator, fields: 'id,name' }).then(res => {
            if (res && Array.isArray(res)) {
                const planList = [];
                res.forEach(plan => {
                    planList.push(<Select.Option key={plan.id} value={plan.id}>{plan.name}</Select.Option>)
                })
                setPlans(planList)
            }
        })
    }
    const getUsers = () => {
        HttpRequest.endpoint = 'user/data/information/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                const userList = [];
                res.forEach(user => {
                    userList.push(<Select.Option key={user.name} value={user.ID}>{user.name}</Select.Option>)
                })
                setUsers(userList)
            }
        })
    }

    const getInitialInfo = async () => {
        if (props.id) {
            HttpRequest.endpoint = 'drives/register'
            const res = await HttpRequest.get(null, props.id)
            if (res.ID) {
                setInitialInfo(res)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error cargando la información inicial. Por favor intente nuevamente'
                })
            }
        }
    }

    useEffect(() => { getInitialInfo() }, []);
    useEffect(getPlans, [])
    useEffect(getUsers, [])
    useEffect(getDefinition, [plans, users, initialInfo])

    return (

        <>
            {!loading ?
                <>
                    <Form name='drive' onFinish={submitForm} ref={formRef} onFinishFailed={() => Modal.error({ title: 'Error', content: 'Todos los campos marcados con asteriscos son requeridos' })}>
                        <h1 className='center-text'>{props.id ? 'Editar' : 'Nuevo'} {title}</h1>
                        <Row justify='space-between'>
                            <Col span={11}>
                                {firstColumn}
                            </Col>
                            <Col span={11}>
                                {secondColumn}
                            </Col>
                        </Row>
                        <Button type='primary' htmlType='submit' className='center-btn' loading={buttonLoading}>Guardar</Button>
                    </Form>
                    <Modal key={showRut.name} footer={null} visible={showRut.name} onCancel={() => setShowRut({})} >
                        <RutField defaultValue={showRut.defaultVal} placeholder='Valor por defecto' onChange={val => formRef.current.setFieldsValue({ [showRut.name]: val })} />
                        <Button onClick={() => setShowRut({})} className='center-btn' type='primary'>Cerrar</Button>
                    </Modal>
                </>
                :
                <Spin size='large' className='center-spin' />
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(DriveForm);