import { Row, Button } from "antd";
import React from "react";
import icon from "../../assets/images/os-icon.svg";

function IclassSchedules() {
	return (
		<>
			<Row justify="center">
				<a
					href="https://fs2.iclass.com.br/iclassfs/restrict/equipe_ausencia_search.seam"
					rel="noopener noreferrer "
					target="_blank"
				>
					<Button className="os-button">Ausencia</Button>
				</a>
				<a
					href="https://fs2.iclass.com.br/iclassfs/restrict/equipe_search.seam"
					rel="noopener noreferrer "
					target="_blank"
				>
					<Button className="os-button">Técnicos</Button>
				</a>
				<a
					href="https://fs2.iclass.com.br/iclassfs/restrict/tipoperiodo_search.seam"
					rel="noopener noreferrer "
					target="_blank"
				>
					<Button className="os-button">Períodos</Button>
				</a>
				<a
					href="https://fs2.iclass.com.br/iclassfs/restrict/turno_search.seam"
					rel="noopener noreferrer "
					target="_blank"
				>
					<Button className="os-button">Turnos</Button>
				</a>
			</Row>
			<p className="center-text os-text">
				Haga click en alguna de las opciones mostradas arriba para ver
				la información deseada.
			</p>
			<img src={icon} alt="Icon" className="os-icon" />
		</>
	);
}

export default IclassSchedules;
