import HttpRequest from "./HttpRequest";

class UserService extends HttpRequest {
	static endpoint = "user";

	static async getUser() {
		this.endpoint = "user/info/";
		return await this.get({}, null, false);
	}
}

export default UserService;
