import React from 'react'
import {Link} from 'react-router-dom'

const Breadcrumb = props => (
	<ol className="breadcrumb float-xl-right">
		{
			props.list
			? props.list.map(item => <li className="breadcrumb-item"><Link to={item.link}>{item.title}</Link></li>)
			: null
		}
	</ol>
);

export default Breadcrumb