import React, { useState, useEffect } from 'react';
import HttpRequest from './../../services/HttpRequest';
import { Modal, Form, Select, DatePicker, Input, Row, Col, ConfigProvider, Button, Radio, Spin, Checkbox } from 'antd';
import { connect } from "react-redux";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import esES from 'antd/lib/locale/es_ES';
import { SearchOutlined } from '@ant-design/icons';
import CustomerInfoModal from '../typify/customerInfoModal';
import ServiceInfoModal from '../typify/servicesInfoModal';
import { useForm } from "antd/lib/form/Form";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import moment from 'moment';
import { qc } from '../../config/permissions';
import { can } from '../../helpers/helpers';
import errorModal from './../../components/modal/errorModal';

function InspeccionesForm(props) {
    const [indicators, setIndicators] = useState([]);
    const [conformData, setConformData] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [categories, setCategories] = useState({
        type: [],
        category: [],
        subcategory: [],
        secondSubcategory: []
    });
    const [initialValues, setInitialValues] = useState({});
    const [extensions, setExtensions] = useState([]);
    const [criterion, setCriterion] = useState("service");
    const [serviceList, setServiceList] = useState([]);
    const [customerModal, setCustomerModal] = useState(false);
    const [serviceModal, setServiceModal] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [typing, setTyping] = useState(false);
    const [rut, setRut] = useState(null);
    const [channel, setChannel] = useState(null);
    const [parentInfo, setParentInfo] = useState(null);
    const [phone, setPhone] = useState('');
    const [channelList, setChannelList] = useState([]);
    const [users, setUsers] = useState([])
    const [form] = useForm();

    const formatData = () => {
        const formattedData = []
        for (let parentID in conformData) {
            const children = [];
            for (let childID in conformData[parentID]) {
                children.push({
                    id: Number(childID),
                    result: conformData[parentID][childID]
                })
            }
            formattedData.push({
                id_parent: Number(parentID),
                children
            })
        }
        return formattedData;
    }
    const getUsers = () => {
        HttpRequest.endpoint = 'user/data/information/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                const userList = [];
                res.forEach(user => {
                    userList.push(<Select.Option key={user.name} value={user.ID}>{user.name}</Select.Option>)
                })
                setUsers(userList)
            }
        })
    }
    const submitForm = e => {
        if (e.phone && !isPossiblePhoneNumber(e.phone)) {
            return Modal.error({
                title: 'Error',
                content: 'El número de teléfono introducido no es válido'
            })
        }
        setButtonLoading(true)
        const formData = new FormData();
        e.rut && formData.append('customer_rut', JSON.stringify([e.rut]));
        e.phone && formData.append('customer_phone', e.phone);
        e.mail && formData.append('customer_email', e.email);
        e.date && e.date._isValid && formData.append('contact_date', e.date.format("YYYY-MM-DD HH:mm"));
        e.acceptedDate && e.acceptedDate._isValid && formData.append('accept_date', e.acceptedDate.format("YYYY-MM-DD HH:mm"));
        e.answerDate && e.answerDate._isValid && formData.append('answer_date', e.answerDate.format("YYYY-MM-DD HH:mm"));
        e.extension && formData.append('agent_extension', e.extension);
        e.services && formData.append('services', e.services);
        e.interaction_lost && formData.append('interaction_lost', e.interaction_lost);
        formData.append('address', e.address);
        formData.append('channel', e.channel)
        formData.append('agent', e.agent)
        formData.append('monitoring', props.monitoreoID);
        if (!props.id) {
            formData.append('kind', e.kind);
            formData.append('answers_data', JSON.stringify(formatData()))
        }
        if (e.comment) {
            formData.append('commentaries', e.comment);
        }
        if (e.secondSubcategory || e.subcategory) {
            formData.append('category', e.secondSubcategory || e.subcategory)
        }
        HttpRequest.endpoint = 'qc/monitoring_record/';
        if (props.id) {
            if (!can(qc.inspection.edit)) {
                return;
            }
            HttpRequest.patch(props.id, formData).then(res => {
                if (res && res.ID) {
                    autoCloseModal('Se ha editado el registro con éxito')
                    props.closeModal()
                } else {
                    setButtonLoading(false)
                    if (res['customer_phone'] && res['customer_phone'][0] === "The phone number entered is not valid.") {
                        Modal.error({
                            title: 'Error',
                            content: 'El número de teléfono introducido no es válido'
                        })
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error editando el registro'
                        })
                    }
                }
            })
        } else {
            if (!can(qc.inspection.create)) {
                return;
            }
            HttpRequest.post(formData).then(res => {
                if (res && res.ID) {
                    autoCloseModal('Se ha creado el registro con éxito')
                    setButtonLoading(false)
                    props.closeModal && props.closeModal()
                } else {
                    setButtonLoading(false)
                    if (res['customer_phone'] && res['customer_phone'][0] === "The phone number entered is not valid.") {
                        Modal.error({
                            title: 'Error',
                            content: 'El número de teléfono introducido no es válido'
                        })
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error creando el registro'
                        })
                    }
                }
            })
        }
    }

    const getTypeCategories = () => {
        HttpRequest.endpoint = "tickets/category/";
        HttpRequest.get({ operator: props.operator, classification: 1 }).then(res => {
            const typeCategories = [];
            res.forEach(cat => {
                typeCategories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                type: typeCategories
            }));
        })
    }
    const getChildCategories = (classification, parent) => {
        HttpRequest.endpoint = "tickets/category/";
        HttpRequest.get({ operator: props.operator, parent: parent }).then(res => {
            const categories = [];
            res.forEach(cat => {
                categories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                [classification]: categories
            }));
        })
    }
    const getRecursiveCategories = id => {
        HttpRequest.endpoint = "tickets/category/" + id + "/";
        HttpRequest.get({ operator: props.operator }).then((res) => {
            switch (res["classification"]) {
                case 4:
                    getChildCategories("secondSubcategory", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        secondSubcategory: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 3:
                    getChildCategories("subcategory", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        subcategory: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 2:
                    getChildCategories("category", res["parent"])
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        category: res["ID"]
                    }))
                    getRecursiveCategories(res["parent"])
                    break;
                case 1:
                    getTypeCategories();
                    setInitialValues(prevInit => ({
                        ...prevInit,
                        type: res["ID"]
                    }))
                    break;
                default:
                    break;
            }
        })
    }

    const getServices = rut => {
        let list = [];
        const formData = { customer__rut: rut, operator: props.operator, fields: 'number' }
        HttpRequest.endpoint = "matrix/services/";
        HttpRequest.get(formData).then((res) => {
            res.forEach(element => {
                list.push(<Select.Option key={element["number"]} value={element["number"]}>{element["number"]}</Select.Option>)
            });
            setServiceList(list);
            if (list.length === 1) {
                form.setFieldsValue({ services: [res[0].number] })
            }
        })
    }
    const searchUser = (value, newCriterion = null) => {
        if (value != null && value.length > 0) {
            setCustomerList([]);
            setLoadingUsers(true);
            HttpRequest.endpoint = "matrix/customer_filter/";
            const formData = { criterion: newCriterion || criterion, search: value }
            HttpRequest.get(formData).then((res) => {
                setCustomerList(res)
            })
        }
    }
    const onKeyPress = (e) => {
        const specialCharRegex = new RegExp("^[0-9]*$");
        const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!specialCharRegex.test(pressedKey)) {
            e.preventDefault();
            return false;
        }
    }

    const getData = () => {
        HttpRequest.endpoint = 'qc/monitoring_record';
        HttpRequest.get(null, props.id).then(res => {
            if (res && res.ID) {
                setInitialValues({
                    address: res.address,
                    extension: res.agent_extension,
                    comment: res.commentaries,
                    date: moment(res.contact_date, "YYYY-MM-DD HH:mm"),
                    acceptedDate: moment(res.accept_date, "YYYY-MM-DD HH:mm"),
                    answerDate: moment(res.answer_date, "YYYY-MM-DD HH:mm"),
                    rut: res.customer_rut[0],
                    email: res.customer_email,
                    kind: res.kind,
                    phone: res.customer_phone,
                    services: res.services,
                    channel: res.channel,
                    agent: res.agent,
                    interaction_lost: res.interaction_lost
                })
                setPhone(res.customer_phone)
                res.customer_rut && setRut(res.customer_rut[0])
                setChannel(res.kind)
                res.customer_rut && searchUser(res.customer_rut[0], 'rut');
                res.customer_rut && getServices(res.customer_rut[0])
                setParentInfo(res.parent_info)
                if (res.category) {
                    getRecursiveCategories(res.category)
                } else {
                    getTypeCategories()
                }
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo la data'
                })
            }
        })
    }

    const getMatrixData = rut => {
        HttpRequest.endpoint = 'matrix/customers/';
        HttpRequest.get({ rut }).then(res => {
            if (res && res[0].id) {
                setPhone(res[0].phone[0] === '+' ? res[0].phone : ('+' + res[0].phone))
                form.setFieldsValue({
                    address: res[0].composite_address,
                    email: res[0].email,
                    phone: res[0].phone[0] === '+' ? res[0].phone : ('+' + res[0].phone)
                })
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo la data de Matrix'
                })
            }
        })
    }
    useEffect(() => {
        if (rut && typing) {
            const delay = setTimeout(() => {
                searchUser(rut)
            }, 1500)
            return () => clearTimeout(delay)
        }
    }, [rut])

    useEffect(() => {
        if (form.getFieldValue("customer_rut") != null) {
            getServices(form.getFieldValue("customer_rut")["key"]);
        }
        form.setFieldsValue({
            services: undefined
        })
    }, [props.operator])

    useEffect(() => {
        if (!props.id) {
            getTypeCategories()
        } else if (props.id) {
            getData()
        }
    }, [props.operator])

    useEffect(() => {
        HttpRequest.endpoint = "user/user_description/voip_extentions/";
        HttpRequest.get().then(res => {
            if (res && res.data) {
                setExtensions(res.data)
                if (res.data.length === 0) {
                    errorModal('No existen extensiones de voip creadas')
                }
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo la lista de extensiones'
                })
            }
        })
    }, [])
    useEffect(() => {
        getUsers()
        HttpRequest.endpoint = "qc/qa_channel/";
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                setChannelList(res)
                if (res.length === 0) {
                    errorModal('No existen canales creados para este operador')
                }
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo la lista de canales'
                })
            }
        })
    }, [])

    return (
        <>
            {categories.type.length > 0 && extensions.length > 0 && channelList.length > 0 ?
                <>
                    <Form form={form} onFinish={submitForm} initialValues={initialValues}>
                        <div className="radio-group-div">
                            <Radio.Group className="radio-group" onChange={(e) => setCriterion(e.target.value)} defaultValue={"service"}>
                                <Radio value={"rut"}>Rut</Radio>
                                <Radio value={"name"}>Nombre</Radio>
                                <Radio value={"composite_address"}>Dirección</Radio>
                                <Radio value={"service"}>Servicio</Radio>
                            </Radio.Group>
                        </div>
                        <Row justify='center' className='qc-search-rut'>
                            <div className='client-search-container'>
                                <Form.Item label="Cliente:" name="rut">
                                    {criterion == "service" ?
                                        <Select className='typify-rut-field' onKeyPress={onKeyPress} placeholder="Ingrese el número de servicio" showSearch notFoundContent={loadingUsers ? <Spin size="small" /> : null}
                                            filterOption={false} onSearch={val => { setTyping(true); setRut(val) }} onChange={(option) => { getServices(option); setTyping(false); setRut(option); form.setFieldsValue({ services: undefined }); getMatrixData(option) }} >
                                            {customerList.map(element => (
                                                <Select.Option key={element["id"]} value={element["id"]}>{element["text"]}</Select.Option>
                                            ))}
                                        </Select>
                                        :
                                        <Select className='typify-rut-field' placeholder={criterion == "rut" ? "Ingrese el rut" : criterion == "composite_address" ? "Ingrese la dirección" : "Ingrese el nombre del cliente"} showSearch notFoundContent={loadingUsers ? <Spin size="small" /> : null}
                                            filterOption={false} onSearch={val => { setTyping(true); setRut(val) }} onChange={(option) => { getServices(option); setTyping(false); setRut(option); form.setFieldsValue({ services: undefined }); getMatrixData(option) }} >
                                            {customerList.map(element => (
                                                <Select.Option key={element["id"]} value={element["id"]}>{element["text"]}</Select.Option>
                                            ))}
                                        </Select>}
                                </Form.Item>
                                <Button shape="circle" icon={<SearchOutlined />} onClick={() => setCustomerModal(true)} />
                            </div>
                            <div className='client-search-container'>
                                <Form.Item label="Servicios" name="services">
                                    <Select className='typify-rut-field' placeholder="Servicio" mode="multiple">
                                        {serviceList}
                                    </Select>
                                </Form.Item>
                                <Button shape="circle" icon={<SearchOutlined />} onClick={() => setServiceModal(true)} />
                            </div>
                        </Row>
                        <Row justify='space-between'>
                            <Col span={8}>
                                <Row>
                                    <Form.Item className='wizard-phone-item' label='Teléfono' name='phone'></Form.Item>
                                    <PhoneInput placeholder='Teléfono' className='wizard-phone phone-width' defaultCountry={'CL'} value={phone} onChange={e => { form.setFieldsValue({ phone: e }); setPhone(e) }} />
                                </Row>
                                <Form.Item name='type' label='Tipo'>
                                    <Select placeholder='Tipo' onChange={e => { getChildCategories('category', e); form.setFieldsValue({ category: null, subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {categories.type}
                                    </Select>
                                </Form.Item>
                                <Form.Item name='secondSubcategory' label='Segunda subcategoría'>
                                    <Select placeholder='Segunda subcategoría' showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {categories.secondSubcategory}
                                    </Select>
                                </Form.Item>
                                <ConfigProvider locale={esES}>
                                    <Form.Item name='date' label='Fecha y hora de contacto' >
                                        <DatePicker showTime={{ format: 'HH:mm' }} format={'DD/MM/YYYY HH:mm'} placeholder='Fecha y hora' />
                                    </Form.Item>
                                </ConfigProvider>
                            </Col>
                            <Col span={7}>
                                <Form.Item name='email' label='Correo del cliente' rules={[{ type: 'email', message: 'Formato de email inválido' }]}>
                                    <Input placeholder='Email' />
                                </Form.Item>
                                <Form.Item name='category' label='Categoría'>
                                    <Select placeholder='Categoría' onChange={(e) => { getChildCategories('subcategory', e); form.setFieldsValue({ subcategory: null, secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {categories.category}
                                    </Select>
                                </Form.Item>
                                <Form.Item name='channel' label='Canal' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                    <Select placeholder='Canal'>
                                        {channelList.map(chan => <Select.Option key={chan.ID} value={chan.ID}>{chan.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                                {channel === 1 ?
                                    <ConfigProvider locale={esES}>
                                        <Form.Item name='acceptedDate' label='Fecha y hora de aceptación' >
                                            <DatePicker showTime={{ format: 'HH:mm' }} format={'DD/MM/YYYY HH:mm'} placeholder='Fecha y hora' />
                                        </Form.Item>
                                    </ConfigProvider>
                                    :
                                    channel === 0 &&
                                    <Form.Item name='extension' label='Extensión de Voipnow'>
                                        <Select placeholder='Extensión'>
                                            {extensions.map(ext => <Select.Option key={ext.extention} value={ext.extention}>{ext.extention} - {ext.username}</Select.Option>)}
                                        </Select>
                                    </Form.Item>

                                }
                            </Col>
                            <Col span={8}>
                                <Form.Item name='kind' label='Tipo de canal' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                    <Select placeholder='Tipo de canal' disabled={props.id} onChange={val => { setChannel(val); setConformData({}) }}>
                                        <Select.Option key='llamada' value={0}>Llamadas</Select.Option>
                                        <Select.Option key='escrito' value={1}>Canal escrito</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name='subcategory' label='Subcategoría'>
                                    <Select placeholder='Subcategoría' onChange={(e) => { getChildCategories('secondSubcategory', e); form.setFieldsValue({ secondSubcategory: null }) }} showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {categories.subcategory}
                                    </Select>
                                </Form.Item>
                                {channel === 1 && <ConfigProvider locale={esES}>
                                    <Form.Item name='answerDate' label='Fecha y hora de respuesta' >
                                        <DatePicker showTime={{ format: 'HH:mm' }} format={'DD/MM/YYYY HH:mm'} placeholder='Fecha y hora' />
                                    </Form.Item>
                                </ConfigProvider>}
                                <Form.Item name='interaction_lost' valuePropName="checked" label='Pérdida de Interacción'>
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name='agent' label='Agente' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select>
                                {users}
                            </Select>
                        </Form.Item>
                        <Form.Item name='address' label='Dirección'>
                            <Input placeholder='Dirección' />
                        </Form.Item>
                        <Form.Item name='comment' label='Observaciones QA'>
                            <Input.TextArea placeholder='Comentarios' />
                        </Form.Item>
                    </Form>
                    {!props.closed && <Button type='primary' className='center-btn' loading={buttonLoading} onClick={() => form.submit()}>Guardar</Button>}
                    {customerModal && <CustomerInfoModal visible={customerModal} setVisible={setCustomerModal} rut={rut} />}
                    {serviceModal && <ServiceInfoModal visible={serviceModal} setVisible={setServiceModal} rut={rut} />}
                </>
                :
                <Spin size='large' className='center-spin' />
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator
    };
}

export default connect(mapStateToProps)(InspeccionesForm);