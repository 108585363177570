/**
 * @constant {string} endPointUserTokenAuth to consult nickname and password of the user.
 */
export const endPointUserTokenAuth = "user/token/auth/";

/**
 * @constant {string} endPointTickectsCategory to consult all of tickets category aviables in the system
 */
export const endPointTickectsCategory = "tickets/category/";

/**
 * @constant {string} endPoitUserDataInformation to consult all of tickets category aviables in the system
 */
export const endPoitUserDataInformation = "user/data/information/";

/**
 * @constant {string} endPointTicketsQueueTypify
 */
export const endPointTicketsQueueTypify = "tickets/queuetypify/";

/**
 * @constant {string} endPointMatrixTechnicians to consult the technicians that exist in Matrix Server
 */
export const endPointMatrixTechnicians = "matrix/technicians/";

/**
 * @constant {string} endPointTypify to consult typify on data base.
 */
export const endPointTypify = "tickets/typify/datatables";

/**
 * @constant {string} endPointUserImage to consult and update users profile picture
 */
export const endPointUserImage = "user/user_image/";

/**
 * @constant {string} endPointUserRecover to send the id of a user who wants to recover their password
 */
export const endPointUserRecover = "user/user_recover/";

/**
 * @constant {string} endPointUserInformation to consult the pk and username
 */
export const endPointUserInformation = "user/data/user_information/";

/**
 * @constant {string} endPointTemplateTextTableStruct to build table for template text
 */
export const endPointTemplateTextTableStruct =
    "communications/template_text/datatables_struct/";

/**
 * @constant {string} endPointTemplateCallTableStruct to build table for template voice call
 */
export const endPointTemplateCallTableStruct =
    "communications/template_voice_call/datatables_struct/";

/**
 * @constant {string} endPointTemplateEmailTableStruct to build table for template email
 */
export const endPointTemplateEmailTableStruct =
    "communications/template_email/datatables_struct/";

/**
 * @constant {string} endPointTemplateWhatsappTableStruct to build table for template Whatsapp
 */
export const endPointTemplateWhatsappTableStruct =
    "communications/template_whatsapp/datatables_struct/";

export const endPointTemplateTextDataTable =
    "communications/template_text/datatables/";

export const endPointTemplateEmailDataTable =
    "communications/template_email/datatables/";

export const endPointTemplateCallDataTable =
    "communications/template_voice_call/datatables/";

export const endPointTemplateWhatsappDataTable =
    "communications/template_whatsapp/datatables/";
/**
 * @constant {string} endPointTemplateTextTest to test the text template
 */
export const endPointTemplateTextTest =
    "communications/template_text/test_template/";

/**
 * @constant {string} endPointTemplateWhatsappTest to test the whatsapp template
 */
export const endPointTemplateWhatsappTest =
    "communications/template_whatsapp/test_template/";

/**
 * @constant {string} endPointTemplateCallTest to test the voice call template
 */
export const endPointTemplateCallTest =
    "communications/template_voice_call/test_template/";

/**
 * @constant {string} endPointTemplateEmailTest to test the email template
 */
export const endPointTemplateEmailTest =
    "communications/template_email/test_template/";

/**
 * @constant {string} endPointWhatsappTags to consult the tag list for the whatsapp template
 */
export const endPointWhatsappTags = "communications/template_whatsapp/tags/";

/**
 * @constant {string} endPointTemplateText to create or destroy a text template
 */
export const endPointTemplateText = "communications/template_text/";

/**
 * @constant {string} endPointTemplateCall to create a voice call template
 */
export const endPointTemplateCall = "communications/template_voice_call/";

/**
 * @constant {string} endPointTemplateEmail to create an email template
 */
export const endPointTemplateEmail = "communications/template_email/";

/**
 * @constant {string} endPointTemplateWhatsapp to create a whatsapp template
 */
export const endPointTemplateWhatsapp = "communications/template_whatsapp/";

/**
 * @constant {string} endPointCreateEvent to create an event
 */
export const endPointCreateEvent = "communications/trigger_event/";

/**
 * @constant {string} endPointTriggerEventDataTable to retrieve the data for the events Table
 */
export const endPointTriggerEventDataTable =
    "communications/trigger_event/datatables/";

/**
 * @constant {string} endPointTriggerEventTableStruct to retrieve the struct of the events table
 */
export const endPointTriggerEventTableStruct =
    "communications/trigger_event/datatables_struct/";

/**
 * @constant {string} endPointCreateMassives to create and retrieve massives
 */
export const endPointCreateMassives = "communications/massive/";

/**
 * @constant {string} endPointMassiveFilters to add and retrieve massive filters
 */
export const endPointMassiveFilters = "communications/massive/filters/";

/**
 * @constant {string} endPointMassiveDataTable to retrieve the data for the massives Table
 */
export const endPointMassiveDataTable = "communications/massive/datatables/";

/**
 * @constant {string} endPointMassiveTableStruct to retrieve the struct of the massives table
 */
export const endPointMassiveTableStruct =
    "communications/massive/datatables_struct/";

/**
 * @constant {string} endPointCreateCycle to create and retrieve cycles
 */
export const endPointCreateCycle = "communications/cycle/";

/**
 * @constant {string} endPointCycleFilters to add and retrieve cycle filters
 */
export const endPointCycleFilters = "communications/cycle/datatables/";

/**
 * @constant {string} endPointCycleDataTable to retrieve the data for the cycles Table
 */
export const endPointCycleDataTable = "communications/cycle/datatables/";

/**
 * @constant {string} endPointCycleTableStruct to retrieve the struct of the cycles table
 */
export const endPointCycleTableStruct =
    "communications/cycle/datatables_struct/";

/**
 * @constant {string} endPointSlackChannel to retrieve slack channels
 */
export const endPointSlackChannel = "slackapp/conversations.list";

/**
 * @constant {string} endPointLead to create and retrieve leads
 */
export const endPointLead = "customers/lead_customer/";

/**
 * @constant {string} endPointLeadDatatable to retrieve the data for the leads table
 */
export const endPointLeadDatatable = "customers/lead_customer/datatables/";

/**
 * @constant {string} endPointLeadTableStruct to retrieve the struct of the leads table
 */
export const endPointLeadTableStruct =
    "customers/lead_customer/datatables_struct/";

/**
 * @constant {string} endPointLeadlet to create and retrieve leads
 */
export const endPointLeadlet = "customers/leadlet_customer/";

/**
 * @constant {string} endPointLeadletDatatable to retrieve the data for the leadlet table
 */
export const endPointLeadletDatatable =
    "customers/leadlet_customer/datatables/";

/**
 * @constant {string} endPointLeadletTableStruct to retrieve the struct of the leadlet table
 */
export const endPointLeadletTableStruct =
    "customers/leadlet_customer/datatables_struct/";

/**
* @constant {string} endPointOpportunityDatatable to retrieve the data for the opportunities table
*/
export const endPointOpportunityDatatable =
    "customers/leadlet_customer/datatables_opportunity/";

/**
* @constant {string} endPointOpportunityTableStruct to retrieve the struct of the opportunities table
*/
export const endPointOpportunityTableStruct =
    "customers/leadlet_customer/datatables_opportunity_struct/";

/**
* @constant {string} endpointTypificationsTableStruct to retrieve the struct of the opportunities table
*/
export const endpointTypificationsStruct =
    "tickets/typify_message/datatables_struct/";

/**
* @constant {string} endpointTypificationsDatatable to retrieve the struct of the opportunities table
*/
export const endpointTypificationsDatatable =
    "tickets/typify_message/datatables/";

/**
* @constant {string} endpointTypifications to retrieve the struct of the opportunities table
*/
export const endpointTypifications = 'tickets/typify_message/'
