import React, { useState } from 'react';
import Moment from 'react-moment';
import FullTable from '../../components/datatable/FullTable';
import TicketsWizard from '../tickets/TicketsWizard';
import permissions from '../../config/permissions';
import IclassHolidayCreator from './iclassHolidaysCreator';

function HolidaysTable(props) {
    const [tableRows, setTableRows] = useState([]);
    let endpoints = {
        columns: "iclass/service_orders/so_holidays/datatables_struct/",
        rows: "iclass/service_orders/so_holidays/datatables/",
        delete: `iclass/service_orders/so_holidays/`,
    }
    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            day: <Moment format="DD/MM/YYYY">{row.day}</Moment>
        })))
    }
    return (
        <>
            <FullTable handleRows={handleRows} modalWidth={800} permissions={permissions.iclass} tableRows={tableRows} modalContent={<IclassHolidayCreator />} endpoints={endpoints} tableName='feriados' />
        </>
    )
}

export default HolidaysTable;