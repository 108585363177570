import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

function TestResultField(props) {
    const [resultOptions, setResultOptions] = useState([]);
    const [testID, setTestID] = useState([]);
    const [resultVal, setResultVal] = useState(null)

    const getPossibleResults = testID => {
        const options = [];
        for (let i = 0; i < props.tests.length; i++) {
            if (props.tests[i].ID === testID) {
                const results = props.tests[i].results;
                results.forEach(result => {
                    options.push(<Select.Option key={result.id} value={result.id}>{result.result}</Select.Option>)
                })
            }
        }
        setResultOptions(options);
        setTestID(testID);
    }

    useEffect(() => {
        if (props.initial) {
            setResultVal(props.initial.result)
            getPossibleResults(props.initial.test)
        }
    }, [])

    return (
        <div className='dynamic-field-item'>
            <Select className='testresultfielditem' disabled={props.disabled} defaultValue={props.initial && props.initial.test} onChange={e => { getPossibleResults(e); setResultVal(null) }} placeholder='Seleccionar prueba'>{props.testOptions}</Select>
            <Select className='testresultfielditem' disabled={props.disabled} value={resultVal} onChange={e => { props.addTest({ id: testID, result: e, index: props.index }); setResultVal(e) }} placeholder='Seleccionar resultado'>{resultOptions}</Select>
        </div>
    )
}

export default TestResultField;