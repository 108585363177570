import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Modal, Button, Row } from "antd";

function ColorPicker(props) {
	const [visible, setVisible] = useState(false);
	const [color, setColor] = useState(props.value);

	return (
		<>
			<div
				className="color-picker-preview"
				onClick={() => setVisible(true)}
				style={{ backgroundColor: props.value ? props.value : "black" }}
			>
				Click para ver
			</div>
			<Modal
				visible={visible}
				footer={null}
				onCancel={() => setVisible(false)}
				width={250}
				closable={false}
			>
				<Row justify="center">
					<HexColorPicker color={props.value} onChange={setColor} />
				</Row>
				<Button
					onClick={() => {
						props.onChange(color);
						props.saveColor(props.id, color);
						setVisible(false);
					}}
					className="center-btn"
				>
					Guardar
				</Button>
			</Modal>
		</>
	);
}

export default ColorPicker;
