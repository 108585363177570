import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel'
import { Paragraph } from '../../components/typography/Typography'
import Image from '../../components/images/Image'
import BellPNG from './../../assets/images/bell.png'
import NotificationList from '../../components/notifications/NotificationList'



const Home = props => {

	const [notifications, setNotifications] = useState([])
	const notificactionsStorage = localStorage.getItem('notifications')


	useEffect(() => {
		getNotifications()
	}, [notificactionsStorage])


	function getNotifications() {
		setTimeout(() => {
			setNotifications(JSON.parse(notificactionsStorage))
		}, 500);
	}



	return (
		<>
			<Breadcrumb />
			<div className="row">
				<div className="col-md-8">
					<Panel>
						<PanelHeader noButton>Bienvenidos a Iris</PanelHeader>
						<PanelBody>
							<div className="row">

							</div>
						</PanelBody>
					</Panel>
				</div>
				<div className="col-md-4">
					<Panel className="mb-5">
						<PanelHeader noButton>Notificaciones</PanelHeader>
						<PanelBody>
							{
								notifications
									? (
										<div style={{ height: "200px", overflowY: "scroll" }} >
											<NotificationList
												notifications={notifications}
											/>
										</div >)
									: (<>
										<div className="row">
											<div className="col-12 d-flex justify-content-center mb-4">
												<Image src={BellPNG} alt="Notificaciones" width="100px" className="opacity-2" />
											</div>
											<div className="col-12">
												<Paragraph className="text-center text-muted align-self-end">Sin notificaciones</Paragraph>

											</div>
										</div>
									</>)
							}
						</PanelBody>
					</Panel>
				</div>
			</div>
		</>
	)
}

export default Home