import React from 'react';
import { escalationTables } from '../../config/permissions';
import TypificationsTable from '../TypificationsTables/TypificationsTables';

function FinanceEscalationCustomTables() {
    const endpoints = {
        formEndpoints: ['escalation_finance/escalation_tables/', 'escalation_finance/escalation_tables/definition/', 'escalation_finance/escalation_tables'],
        tableEndpoints: {
            columns: "escalation_finance/escalation_tables/datatables_struct/",
            rows: "escalation_finance/escalation_tables/datatables/",
            delete: "escalation_finance/escalation_tables/",
        }
    }
    return (
        <TypificationsTable endpoints={endpoints} permissions={escalationTables} />
    )
}

export default FinanceEscalationCustomTables;