import React, { useEffect, useState } from "react";
import {
	Form,
	Input,
	DatePicker,
	Select,
	Checkbox,
	InputNumber,
	Button,
	ConfigProvider,
} from "antd";
import HttpRequest from "./../../services/HttpRequest";
import store from "../../redux/store.js";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import errorModal from "./../../components/modal/errorModal";
import esES from "antd/lib/locale/es_ES";
import moment from "moment";

function IclassReminder(props) {
	const [constant, setConstant] = useState(false);
	const [repeats, setRepeats] = useState(1);
	const [loading, setLoading] = useState(false);
	const [initialData, setInitialData] = useState(null);

	const { Option } = Select;

	let cycleTypeOpc = [
		<Option key={"O"} value={"O"}>
			Una vez
		</Option>,
		<Option key={"I"} value={"I"}>
			Minutos
		</Option>,
		<Option key={"H"} value={"H"}>
			Cada Hora
		</Option>,
		<Option key={"D"} value={"D"}>
			Diariamente
		</Option>,
		<Option key={"W"} value={"W"}>
			Semanalmente
		</Option>,
		<Option key={"M"} value={"M"}>
			Mensualmente
		</Option>,
		<Option key={"Q"} value={"Q"}>
			Trimestralmente
		</Option>,
		<Option key={"Y"} value={"Y"}>
			Anualmente
		</Option>,
	];

	const submit = (e) => {
		const formData = new FormData();
		formData.append("start", e.date.format("YYYY-MM-DD HH:mm"));
		formData.append("type_repeats", e.cycleType);
		constant
			? formData.append("number_repeats", -1)
			: formData.append("number_repeats", repeats);
		setLoading(true);
		HttpRequest.endpoint = "iclass/slack_msg/";
		HttpRequest.post(formData).then((res) => {
			setLoading(false);
			if (res && res.start) {
				autoCloseModal("Se ha creado el recordatorio con éxito");
			} else {
				errorModal("Ha ocurrido un error creando el recordatorio");
			}
		});
	};

	useEffect(() => {
		HttpRequest.endpoint = "iclass/slack_msg";
		HttpRequest.get({ operator: store.getState().operator }).then((res) => {
			if (res && res.start) {
				setInitialData({
					date: moment(res.start),
					cycleType: res.type_repeats
				});
				if (res.number_repeats < 0) {
					setConstant(true);
				} else {
					setRepeats(res.number_repeats);
				}
			} else {
				setInitialData({ cycleType: "M" });
			}
		});
	}, []);

	if (!initialData) {
		return null;
	}

	console.log(initialData);

	return (
		<>
			<Form onFinish={submit} initialValues={initialData}>
				<Form.Item label="Repeticiones" name="repetitions">
					<div>
						<InputNumber
							defaultValue={constant ? 1 : repeats}
							disabled={constant}
							required={!constant}
							min={1}
							precision={0}
							type={"number"}
							value={repeats}
							onChange={(e) => setRepeats(e)}
						/>
						<Checkbox
							checked={constant}
							style={{ marginLeft: "20px" }}
							onChange={(e) => {
								setConstant(e.target.checked);
							}}
						>
							Constantemente
						</Checkbox>
					</div>
				</Form.Item>
				<ConfigProvider locale={esES}>
					<Form.Item
						name="date"
						label="Fecha"
						rules={[
							{
								required: true,
								message: "Este campo es requerido",
							},
						]}
					>
						<DatePicker
							style={{ width: "100%" }}
							format={"DD/MM/YYYY HH:mm"}
							showTime
						/>
					</Form.Item>
				</ConfigProvider>
				<Form.Item
					label="Tipo de Ciclo"
					name="cycleType"
					rules={[
						{ required: true, message: "Este campo es requerido" },
					]}
				>
					<Select placeholder="Tipo">{cycleTypeOpc}</Select>
				</Form.Item>
				<Button
					className="center-btn"
					type="primary"
					htmlType="submit"
					loading={loading}
				>
					Guardar
				</Button>
			</Form>
		</>
	);
}

export default IclassReminder;
