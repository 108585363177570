import React, { useState, useEffect } from "react";
import { Select } from "antd";
import Title from "antd/lib/skeleton/Title";

const { Option } = Select;

function onBlur() { }

function onFocus() { }

function onSearch(val) {
    console.log("search:", val);
}

const SelectSearch = ({ optionValue = 'ID', optionHumanRead = 'name', ...props }) => {
    const [nameSelect, setNameSelect] = useState(props.name ? props.name : "");
    const [data, setData] = useState([]);

    /**
     * function {onChange} to send name and value of the select component.
     * @param {*} value
     */
    function onChange(value) {
        props.action(nameSelect, value);
    }

    useEffect(() => {
        setData(props.dataSources);
    }, [props]);

    useEffect(() => {
        if (props.name) {
            setNameSelect(props.name)
        }
    }, [props.name])
    return (
        <Select
            defaultValue={props.defaultValue ? props.defaultValue : ""}
            showSearch
            style={props.style}
            placeholder={props.placeholder ? props.placeholder : ""}
            optionFilterProp="children"
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {data.map((value, index) => (
                <Option key={index} value={value[optionValue]}>
                    {value[optionHumanRead]}
                </Option>
            ))}
        </Select>
    );
};

export default React.memo(SelectSearch);
