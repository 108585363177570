import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import Lead2 from './Lead2';

function LeadSearchTables() {
    const endpoint = 'customers/lead_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<Lead2 />} />
        </>
    )
}

export default LeadSearchTables;