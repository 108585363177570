import React, { useState, useEffect } from 'react';
import { Popover, Input, Button, Modal, Checkbox } from 'antd';

function DynamicSelect(props) {
    const [visible, setVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsSubmit, setOptionsSubmit] = useState({});
    const [id, setId] = useState(0);

    const generateDefaultOptions = () => {
        if (props.defaultValues.length > 0) {
            const actualOptions = [];
            props.defaultValues.forEach(val => {
                setId(prevId => {
                    actualOptions.push(
                        <div key={prevId} className='column-item-container'>
                            <Input defaultValue={val.name} placeholder='Nombre' onChange={e => {
                                const value = e.target.value; setOptionsSubmit(prevOpts => {
                                    prevOpts[prevId] = {
                                        ...prevOpts[prevId],
                                        name: value,
                                        value: value
                                    };
                                    return prevOpts;
                                })
                            }} />

                            <Checkbox defaultChecked={val.checked} onChange={e => {
                                const value = e.target.checked; setOptionsSubmit(prevOpts => {
                                    prevOpts[prevId] = {
                                        ...prevOpts[prevId],
                                        checked: value
                                    };
                                    return prevOpts;
                                })
                            }}>Por defecto</Checkbox>
                            <Button danger type='primary' onClick={() => deleteOption(prevId)}>X</Button>
                        </div>
                    )
                    setOptionsSubmit(prevOpts => {
                        prevOpts[prevId] = {
                            name: val.name,
                            value: val.value,
                            checked: val.checked
                        };
                        return prevOpts;
                    })
                    return prevId + 1
                })
            })
            setOptions(actualOptions);
        }
    }

    const addNewOption = () => {
        const actualOptions = [...options];
        actualOptions.push(
            <div key={id} className='column-item-container'>
                <Input placeholder='Nombre' onChange={e => {
                    const value = e.target.value; setOptionsSubmit(prevOpts => {
                        prevOpts[id] = {
                            ...prevOpts[id],
                            name: value,
                            value: value
                        };
                        return prevOpts;
                    })
                }} />
                {/*<Input placeholder='Valor' onChange={e => {
                    const value = e.target.value; setOptionsSubmit(prevOpts => {
                        prevOpts[id] = {
                            ...prevOpts[id],
                            value: value
                        };
                        return prevOpts;
                    })
                }} />*/}
                <Checkbox onChange={e => {
                    const value = e.target.checked; setOptionsSubmit(prevOpts => {
                        prevOpts[id] = {
                            ...prevOpts[id],
                            checked: value
                        };
                        return prevOpts;
                    })
                }}>Por defecto</Checkbox>
                <Button danger type='primary' onClick={() => deleteOption(id)}>X</Button>
            </div>
        )
        setOptions(actualOptions);
        setId(prevState => prevState + 1);
    }

    const deleteOption = key => {
        setOptions(prevState => prevState.filter(col => Number(col.key) !== key));
        setOptionsSubmit(prevState => {
            delete prevState[key]
            return prevState
        })
    }
    const saveOptions = () => {
        const selectArr = Object.values(optionsSubmit);
        let valid = true;
        let defaultValue = null;
        if (selectArr.length === 0) {
            Modal.error({
                title: 'Error',
                content: 'Debe definir al menos 1 opción'
            })
        }
        selectArr.forEach(sel => {
            if (!sel.name || !sel.value) {
                valid = false
            }
            if (sel.checked) {
                if (defaultValue === null) {
                    defaultValue = sel.value
                } else {
                    return Modal.error({
                        title: 'Error',
                        content: 'Solo 1 opción puede ser por defecto'
                    })
                }
            }
        })
        if (!valid) {
            return Modal.error({
                title: 'Error',
                content: 'Debe definir todos los nombres'
            })
        }
        props.setValues(selectArr);
        props.setDefaultValue(defaultValue);
        selectArr.length !== 0 && setVisible(false);
        /*if (props.defaultValues.length > 0 && props.editing) {
            props.setSaveDynamic(true)
        }*/

    }

    const dynamicForm = (
        <>
            <Button type='primary' onClick={addNewOption} className='center-btn'>Añadir nueva opción</Button>
            {options}
            <Button onClick={saveOptions} className='center-btn'>Guardar</Button>
        </>
    )

    useEffect(generateDefaultOptions, [])

    return (
        <Popover
            content={dynamicForm}
            trigger="click"
            visible={visible}
            onVisibleChange={visible => setVisible(visible)}
        >
            <Button className={props.className} disabled={props.disabled}>Definir</Button>
        </Popover>
    )
}

export default DynamicSelect;