import React from "react";
import styled from "styled-components";

const AvatarContainer = styled.div`
	&.fontawesome {
		justify-content: center;
		font-size: 2em;
	}
`;
const AvatarIcon = styled.span`
	position: relative;
	top: 0.5rem;
`;

const Avatar = (props) => (
	<AvatarContainer
		className={`image image-icon bg-black text-grey-darker ${props.className} ${
			!props.src ? "fontawesome" : ""
		}`}
	>
		{props.src ? (
			<img src={props.src} alt="" />
		) : (
			<AvatarIcon className="fa fa-user" />
		)}
	</AvatarContainer>
);

export default Avatar;
