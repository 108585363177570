import React, { useState } from "react";
import { Button, Row } from "antd";
import HttpRequest from "./../../services/HttpRequest";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import errorModal from "./../../components/modal/errorModal";

function RetentionStatus(props) {
	const [buttonLoading, setButtonLoading] = useState(false);

	const closeRetention = (isSuccess) => {
		setButtonLoading(true);
		const formData = new FormData();
		formData.append("succesfull", isSuccess ? true : false);
		formData.append("status", 1);
		HttpRequest.endpoint = "retentions/customer_retention/";
		HttpRequest.patch(props.id, formData).then((res) => {
			setButtonLoading(false);
			if (res.ID) {
				autoCloseModal("Se ha cerrado la retención con éxito");
				props.setClosed(true);
			} else {
				errorModal("Ha ocurrido un error cerrando la retención");
			}
		});
	};

	if (props.closed) {
		return (
			<p className="center-text">Esta retención se encuentra cerrada</p>
		);
	}

	const channels = {
		1: "Correo (LA)",
		2: "Llamadas (LL)",
		5: "Facebook (FB)",
		6: "Instagram (IG)",
		4: "WhatsApp (WS)",
	};

	return (
		<>
			<p className="center-text">Canal: {channels[props.channel]}</p>
			<p className="center-text">
				Si desea cerrar la retención, seleccione alguno de los
				siguientes botones de acuerdo a su estado
			</p>
			<Row justify="center">
				<Button
					className="center-btn"
					type="primary"
					onClick={() => closeRetention(true)}
					loading={buttonLoading}
				>
					Cerrar retención exitosa
				</Button>
				<Button
					className="center-btn"
					type="primary"
					onClick={() => closeRetention(false)}
					loading={buttonLoading}
				>
					Cerrar retención fallida
				</Button>
			</Row>
		</>
	);
}

export default RetentionStatus;
