import React, { useState } from "react";
import MassiveCreateForm from "./MassiveCreateForm.jsx";
import { endPointCreateMassives, endPointMassiveDataTable, endPointMassiveTableStruct } from "../../services/endpoints";
import { Tag, Modal, Button, Divider, Row } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import HttpRequest from "../../services/HttpRequest";
import FullTable from '../../components/datatable/FullTable';
import Moment from "react-moment";
import ButtonWithModal from "../../components/buttons/ButtonWithModal"
import { can } from "../../helpers/helpers.js";
import { massive } from "../../config/permissions.js";
import Config from "../../config";
import autoCloseModal from './../../components/modal/AutoCloseModal';

function Massive2() {
    const [tableRows, setTableRows] = useState([]);
    const [forceReload, setForceReload] = useState(false);
    const [previsualizeInfo, setPrevisualizeInfo] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);

    const endpoints = {
        columns: endPointMassiveTableStruct,
        rows: endPointMassiveDataTable,
        delete: endPointCreateMassives,
    }

    const handleTestAndActivate = (id, test) => {
        if (can(massive.edit)) {
            HttpRequest.endpoint = "communications/massive";
            HttpRequest.get(null, id).then(res => {
                if (res && res.filters) {
                    const emailTemplateURL = res.email_event;
                    fetch(Config.API_URL + '/matrix/services-filters-count/', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ filters: res.filters, includes: res.includes, excludes: res.excludes, unpaid_ballot: res.unpaid_ballot, criterion: res.type_filters })
                    }).then(res => res.json()).then(res => {
                        if (res && typeof res.count === 'number') {
                            const serviceAffectedNumber = res.count;
                            if (emailTemplateURL && typeof emailTemplateURL === 'string') {
                                HttpRequest.endpoint = emailTemplateURL.split('/v1/')[1];
                                HttpRequest.get().then(res => {
                                    if (res && res.template) {
                                        const emailTemplateID = res.template;
                                        HttpRequest.endpoint = 'communications/template_email/'
                                        HttpRequest.get({ ID: emailTemplateID }).then(res => {
                                            if (res && Array.isArray(res) && res[0]) {
                                                const emailTemplateHTML = res[0].template_html;
                                                setPrevisualizeInfo({ id, serviceAffectedNumber, emailTemplateHTML, test })
                                            } else {
                                                Modal.error({
                                                    title: 'Error',
                                                    content: 'Ha ocurrido un error consultando la información de la plantilla de email'
                                                })
                                            }
                                        })
                                    } else {
                                        Modal.error({
                                            title: 'Error',
                                            content: 'Ha ocurrido un error consultando la información de la plantilla'
                                        })
                                    }
                                })
                            } else {
                                Modal.confirm({
                                    title: 'Confirmación',
                                    content: `Esta acción afectará a ${serviceAffectedNumber} servicios`,
                                    okText: 'Ok',
                                    cancelText: 'No probar',
                                    onOk: () => test ? handleTestMassive(id) : handleSendMassive(id),
                                })
                            }
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: "Ha ocurrido un error calculando el número de servicios afectados"
                            })
                        }
                    }).catch(err => {
                        console.log(err);
                        Modal.error({
                            title: 'Error',
                            content: "Ha ocurrido un error calculando el número de servicios afectados"
                        })
                    })
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error consultando la información del masivo'
                    })
                }
            })
        }
    }
    const handleTestMassive = id => {
        setButtonLoading(true)
        HttpRequest.endpoint = endPointCreateMassives + id + "/test/";
        HttpRequest.post().then(res => {
            if (Object.keys(res).length !== 0) {
                Modal.error({
                    title: 'Error',
                    content: "Ha ocurrido un error al probar el masivo"
                })
                setButtonLoading(false)
            } else {
                autoCloseModal("La prueba se ha realizado exitosamente")
                setButtonLoading(false)
            }
        });
    }
    const handleSendMassive = id => {
        setButtonLoading(true)
        HttpRequest.endpoint = endPointCreateMassives + id + "/activate/";
        HttpRequest.post().then(res => {
            if (Object.keys(res).length !== 0) {
                Modal.error({
                    title: 'Error',
                    content: "Ha ocurrido un error al activar el masivo"
                })
                setButtonLoading(false)
            } else {
                autoCloseModal("Se ha activado el masivo exitosamente")
                setButtonLoading(false)
            }
        });
    }
    const deleteRow = id => {
        if (can(massive.delete)) {
            Modal.confirm({
                title: '¿Está seguro que desea eliminar este registro?',
                icon: <ExclamationCircleOutlined />,
                cancelText: 'Cancelar',
                onOk() {
                    HttpRequest.endpoint = endPointCreateMassives;
                    HttpRequest.delete(id).then(res => {
                        if (res.status === 400) {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error eliminando este item'
                            })
                        } else {
                            Modal.success({
                                title: 'Éxito',
                                content: 'Se ha eliminado el item'
                            })
                            setForceReload(prevState => !prevState)
                        }
                    })
                },
            });
        }
    }
    const customRendering = (text, value, id) => {
        switch (value) {
            case "created":
                return <Moment fromNow locale="es">{text}</Moment>;
            case "custom_change":
                return <ButtonWithModal
                    name='Modificar'
                    title={'Editar masivo'}
                    onCancel={reload => reload && setForceReload(prevState => !prevState)}
                    content={<MassiveCreateForm id={id} />}
                    width={850}
                />
            case "custom_delete":
                return <Button
                    type='primary'
                    className="form-control"
                    danger
                    onClick={() => deleteRow(id)}
                >
                    Eliminar
                </Button>
            case "ID_active":
                return <Button type='primary' onClick={() => handleTestAndActivate(id, false)}>Activar</Button>;
            case "ID":
                return <Button type='primary' onClick={() => handleTestAndActivate(id, true)}>Probar</Button>;

            default:
                break;
        }
    }

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            channels: row.channels.map(channel => {
                switch (channel) {
                    case "text":
                        return <Tag color={"geekblue"} key={channel}>Mensaje de texto</Tag>
                        break;
                    case "call":
                        return <Tag color={"volcano"} key={channel}>Llamada</Tag>
                        break;
                    case "whatsapp":
                        return <Tag color={"green"} key={channel}>WhatsApp</Tag>
                        break;
                    case "email":
                        return <Tag color={"purple"} key={channel}>Correo</Tag>
                        break;
                    default:
                        break;
                }
            })

        })))
    }
    return (
        <>
            <FullTable modalWidth={700} permissions={massive} customRendering={{ renderFunction: customRendering, keys: ['ID', 'ID_active'] }} forceReload={forceReload} modalContent={<MassiveCreateForm />} endpoints={endpoints} handleRows={handleRows} tableRows={tableRows} tableName='masivo' />
            {previsualizeInfo &&
                <Modal width={900} title='Previsualización' footer={null} onCancel={() => setPrevisualizeInfo(null)} visible={previsualizeInfo}>
                    {/*<div dangerouslySetInnerHTML={{ __html: previsualizeInfo.emailTemplateHTML }} />*/}
                    <iframe width='850' height='350' srcDoc={previsualizeInfo.emailTemplateHTML}></iframe>
                    <Divider />
                    <p className='center-text'>Esta acción afectará a {previsualizeInfo.serviceAffectedNumber} servicios</p>
                    <Row justify='center'>
                        {previsualizeInfo.test ? <Button loading={buttonLoading} type='primary' onClick={() => handleTestMassive(previsualizeInfo.id)}>Probar</Button> : <Button loading={buttonLoading} type='primary' onClick={() => handleSendMassive(previsualizeInfo.id)}>Activar</Button>}
                        <Button onClick={() => setPrevisualizeInfo(null)}>Cancelar</Button>
                    </Row>
                </Modal>
            }
        </>
    )
}

export default Massive2;