// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import HttpRequest from "../../services/HttpRequest";
import {
	endPointTypify,
	endPointTickectsCategory,
	endPointMatrixTechnicians,
	endPointTicketsQueueTypify,
} from "../../services/endpoints";
import { Drawer, Form, Col, Row, Space, DatePicker } from "antd";
import SelectSearch from "../../components/select/select-search";

// Components
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Button from "../../components/buttons/Button";

import { DataTable } from "../../components/table/ListTable";
import { column } from "./columnsTypifications";

import {
	defaultValues,
	city,
	management,
	urgency,
	customer,
	status,
	discountReason,
	discountResponsable,
	filtersTables,
} from "./params";

const AllTypifications = ({ setNotification }) => {
	//Declare const using useState
	//const [data, fetchData] = useState([]);
	const [isLoaded, setLoaded] = useState(false);

	const [drawer, setDrawer] = useState(false);
	const [closeDrawer, setCloseDrawer] = useState(true);

	//The typifications
	const [paramsDefauls, setParamsDefauls] = useState(defaultValues);
	const [requestData, setRequestData] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10 });

	//The filters
	const [category, setCategory] = useState([]);
	const [technicians, setTechnicians] = useState([]);
	const [queueTypify, setQueueTypify] = useState([]);

	//Declaration Variable to date.
	const { RangePicker } = DatePicker;

	async function typify() {
		setLoaded(!isLoaded);
		HttpRequest.endpoint = endPointTypify;
		await HttpRequest.get(paramsDefauls, null, false).then((res) => {
			let newData = [];
			for (let index = 0; index < res.data.length; index++) {
				newData.push({
					sla: res.data[index][0],
					date: res.data[index][1],
					ID: res.data[index][2],
					category: res.data[index][3],
					client: res.data[index][4],
					service: res.data[index][5],
					liveagent: res.data[index][6],
					channel: res.data[index][7],
					type: res.data[index][8],
					stated: res.data[index][9],
					priority: res.data[index][10],
					city: res.data[index][11],
					management: res.data[index][12],
				});
			}
			setPagination({ ...pagination, total: res.recordsFiltered });
			setRequestData(newData);
			setLoaded(false);
		});
	}

	async function tickectCategory() {
		HttpRequest.endpoint = endPointTickectsCategory;
		await HttpRequest.get(
			{ fields: "ID,name", operator__ID: 2 },
			null,
			false
		).then((res) => {
			setCategory(res);
		});
	}

	async function ticketsQueueTypify() {
		HttpRequest.endpoint = endPointTicketsQueueTypify;
		await HttpRequest.get(
			{ fields: "ID,name", operator__ID: 2 },
			null,
			false
		).then((res) => {
			setQueueTypify(res);
		});
	}

	async function matrixTechnicians() {
		HttpRequest.endpoint = endPointMatrixTechnicians;
		await HttpRequest.get({ fields: "id,name" }, null, false).then(
			(res) => {
				setTechnicians(res);
			}
		);
	}

	async function load() {
		typify();
		tickectCategory();
		matrixTechnicians();
		ticketsQueueTypify();
	}

	useEffect(() => {
		//Se consulta la endpoind para carga la data previa.
		load();
		/* if (!isLoaded) {
		} */
	}, []);

	const showDrawer = () => {
		setDrawer(!drawer);
	};

	const onCloseDrawer = () => {
		setDrawer(!closeDrawer);
	};

	const doUpdate = () => {
		typify();
		console.log(paramsDefauls);
	};

	function changeData(changes) {
		let startData =
			(changes.pagination.current - 1) * changes.pagination.pageSize;

		//let lengthData = defaultValues.length;
		let currentPage = 1;

		if (pagination.pageSize !== changes.pagination.pageSize) {
			console.log("cambio tamaño de paginacion");

			setPagination({
				pageSize: changes.pagination.pageSize,
			});

			setParamsDefauls({
				...paramsDefauls,
				start: startData,
				length: changes.pagination.pageSize,
			});
		}

		if (changes.pagination.current > currentPage) {
			console.log("navego por la paginacion");
			setParamsDefauls({
				...paramsDefauls,
				draw: changes.pagination.current,
				start: startData,
			});
			currentPage = changes.pagination.current;
		} else {
			setParamsDefauls({
				...paramsDefauls,
				draw: changes.pagination.current,
				start: startData,
			});
			currentPage = changes.pagination.current;
		}

		console.log(
			pagination,
			"pagina:" + currentPage,
			"dibuja: " + paramsDefauls.draw,
			"comienza: " + paramsDefauls.start,
			"Cuanto: " + paramsDefauls.length
		);

		//typify();
	}

	async function actionChange(name, values) {
		await setParamsDefauls({ ...paramsDefauls, [name]: values });
		doUpdate();
	}

	console.log(column);

	return (
		<>
			<Breadcrumb />
			<div className="row">
				<div className="col-xs-12">
					<Panel>
						<PanelBody>
							{/* {sections.map(
								({ id, name, content: { table, filters } }) =>
									id === activeTab && (
										<TabsContent
											key={id}
											id={id}
											name={name}
											table={table}
											filters={filters}
										/>
									)
							)} */}
							<div
								className="form-group"
								style={{ textAlign: "right" }}
							>
								<Space>
									<Button
										color="info"
										onClick={() => showDrawer()}
									>
										Nuevo
									</Button>
									<div className="input-group">
										<input
											type="email"
											className="form-control"
											placeholder="iris@tipificaciones.com"
										/>
										<div className="input-group-append">
											<button
												className="btn btn-outline-secondary"
												title="Enviar al correo"
											>
												<i className="fas fa-download"></i>
											</button>
										</div>
									</div>

									<Button onClick={() => showDrawer()}>
										Filtros
									</Button>
								</Space>
							</div>
							<DataTable
								changeData={changeData}
								column={column}
								dataSource={requestData}
								size={"default"}
								loading={isLoaded}
								pagination={pagination}
							/>
						</PanelBody>

						<Drawer
							drawerStyle={{ paddingTop: 48 }}
							title="Filtros de Tipificaciones"
							width={850}
							onClose={() => onCloseDrawer()}
							visible={drawer}
							bodyStyle={{ paddingBottom: 80 }}
							footer={
								<div
									className="form-group"
									style={{
										textAlign: "right",
									}}
								>
									<Button
										type="primary"
										onClick={() => showDrawer()}
										style={{ marginRight: 8 }}
									>
										Aplicar
									</Button>
								</div>
							}
						>
							<Row gutter={1}>
								<Col span={8}>
									<Form.Item label="Categoria:">
										<SelectSearch
											name={"category"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={category}
											placeholder={"Categoria"}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Ciudad:">
										<SelectSearch
											name={"city"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={city}
											placeholder={"Ciudad"}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Gestión:">
										<SelectSearch
											name={"management"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={management}
											placeholder={"Gestión"}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={8}>
									<Form.Item label="Urgencia">
										<SelectSearch
											name={"urgency"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={urgency}
											placeholder={"Urgencia"}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Cliente">
										<SelectSearch
											name={"customer"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={customer}
											placeholder={"Cliente"}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Agente">
										<SelectSearch
											name={"techicians"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={technicians}
											placeholder={"Agente"}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={8}>
									<Form.Item label="Motivo de Descuento:">
										<SelectSearch
											name={"discountReason"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={discountReason}
											placeholder={"Motido de descuento"}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Área responsable descuento">
										<SelectSearch
											name={"discountResponsable"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={discountResponsable}
											placeholder={"Responsable"}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Cola">
										<SelectSearch
											name={"queueTypify"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={queueTypify}
											placeholder={"Cola"}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={8}>
									<Form.Item label="Estado:">
										<SelectSearch
											name={"status"}
											style={{ width: 250, fontSize: 12 }}
											action={actionChange}
											dataSources={status}
											placeholder={"Estado"}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Fecha Inicio / Fin: ">
										<RangePicker format={"DD/MM/YYYY"} />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label=""></Form.Item>
								</Col>
							</Row>
						</Drawer>
					</Panel>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AllTypifications);
