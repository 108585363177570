import React, { useState } from 'react';
import HttpRequest from "../../services/HttpRequest.js";
import { AutoComplete, Modal, Form, Input, Button } from 'antd';
import { can, validOperator } from '../../helpers/helpers.js';
import { client } from '../../config/permissions.js';
import autoCloseModal from './../../components/modal/AutoCloseModal';

function SocialForm(props) {
    const [rutOptions, setRutOptions] = useState([]);
    const [selectedRut, setSelectedRut] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [changed, setChanged] = useState(false);

    const getRUTs = rut => {
        setSelectedRut(false);
        setChanged(true);
        if (rut && rut.replace(/\./, '').length > 2) {
            HttpRequest.endpoint = 'matrix/customers/?fields=rut&rut__icontains=' + rut;
            HttpRequest.get().then(res => {
                setRutOptions(res.map(rutObj => ({ value: rutObj.rut })))
            })
        } else {
            setRutOptions([]);
        }
    }
    const submitForm = formData => {
        if (validOperator()) {
            const validRut = selectedRut || rutOptions.length === 1 ? true : props.customerInfo && !changed ? true : false;
            if (validRut) {
                const errors = {
                    web: 'Formato inválido de Web. El formato es xxxxx.xxxxx',
                    facebook: 'Formato inválido de Facebook. El formato es facebook.com/xxxxx',
                    instagram: 'Formato inválido de Instagram. El formato es instagram.com/xxxxx',
                    twitter: 'Formato inválido de Twitter. El formato es twitter.com/xxxxxx',
                }

                const data = new FormData();
                const formKeys = Object.keys(formData);
                formKeys.forEach(key => {
                    if (formData[key]) {
                        if (key !== 'rut') {
                            data.set(key, 'https://' + formData[key])
                        } else {
                            data.set(key, formData[key])
                        }
                    }
                })
                if (props.customerInfo) {
                    if (can(client.edit)) {
                        setButtonLoading(true);
                        HttpRequest.endpoint = `customers/customer/`
                        HttpRequest.patch(props.customerInfo.ID, data).then(res => {
                            console.log('cliente editado', res)
                            setButtonLoading(false);
                            if (res.ID) {
                                autoCloseModal('Se ha editado la información del cliente con éxito')
                                props.updateRows();
                            } else {
                                const error = Object.keys(res)[0];
                                Modal.error({
                                    title: 'Error',
                                    content: errors[error]
                                })
                            }
                        }).catch(err => {
                            console.log('error catch al editar', err);
                            setButtonLoading(false);
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error al editar la información del cliente'
                            })
                        })
                    }
                } else {
                    if (can(client.create)) {
                        setButtonLoading(true);
                        HttpRequest.endpoint = 'customers/customer/'
                        HttpRequest.post(data)
                            .then(res => {
                                console.log('customer created', res)
                                setButtonLoading(false);
                                if (res.ID) {
                                    props.setNewClient(res)
                                    autoCloseModal('Se ha creado el cliente con éxito')
                                    props.updateRows();
                                } else {
                                    const error = Object.keys(res)[0];
                                    Modal.error({
                                        title: 'Error',
                                        content: errors[error]
                                    })
                                }
                            })
                            .catch(err => {
                                console.log('error catch al crear', err);
                                setButtonLoading(false);
                                Modal.error({
                                    title: 'Error',
                                    content: 'Ha ocurrido un error al crear el cliente'
                                })
                            })
                    }
                }
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Debe seleccionar algún RUT de las opciones disponibles.'
                })
            }
        }
    }
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const initialValues = {
        rut: props.customerInfo ? props.customerInfo.rut : null,
        web: props.customerInfo ? props.customerInfo.web && props.customerInfo.web.split('https://')[1] : null,
        facebook: props.customerInfo ? props.customerInfo.facebook && props.customerInfo.facebook.split('https://')[1] : null,
        instagram: props.customerInfo ? props.customerInfo.instagram && props.customerInfo.instagram.split('https://')[1] : null,
        twitter: props.customerInfo ? props.customerInfo.twitter && props.customerInfo.twitter.split('https://')[1] : null
    }

    return (
        <>
            <Form name='social-form' {...layout} onFinish={submitForm} initialValues={initialValues}>
                <Form.Item name='rut' label='RUT (Seleccionar)' rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                    <AutoComplete options={rutOptions} onSearch={rut => getRUTs(rut)} onSelect={() => setSelectedRut(true)} placeholder="Seleccione un RUT" />
                </Form.Item>
                <Form.Item name='web' label='Web' rules={[{ validator: (_, value) => /[\S+]\.[\S+]/.test(value) ? Promise.resolve() : value ? Promise.reject('Formato inválido. Debe ser xxxxx.xxxxx') : Promise.resolve() }]}>
                    <Input addonBefore="https://" />
                </Form.Item>
                <Form.Item name='facebook' label='Facebook' rules={[{ validator: (_, value) => /facebook\.com\/[\S+]/.test(value) ? Promise.resolve() : value ? Promise.reject('Formato inválido. Debe ser facebook.com/xxxxx') : Promise.resolve() }]}>
                    <Input addonBefore="https://" />
                </Form.Item>
                <Form.Item name='instagram' label='Instagram' rules={[{ validator: (_, value) => /instagram\.com\/[\S+]/.test(value) ? Promise.resolve() : value ? Promise.reject('Formato inválido. Debe ser instagram.com/xxxxx') : Promise.resolve() }]}>
                    <Input addonBefore="https://" />
                </Form.Item>
                <Form.Item name='twitter' label='Twitter' rules={[{ validator: (_, value) => /twitter\.com\/[\S+]/.test(value) ? Promise.resolve() : value ? Promise.reject('Formato inválido. Debe ser twitter.com/xxxxx') : Promise.resolve() }]}>
                    <Input addonBefore="https://" />
                </Form.Item>
                <Button loading={buttonLoading} htmlType='submit' className='center-btn' type='primary'>{props.customerInfo ? 'Guardar' : 'Crear'}</Button>
            </Form>
        </>
    )
}

export default SocialForm;