import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import './InspeccionesItem.css';

function InspeccionesItem(props) {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if (!props.title && !props.editOption) {
            props.saveItem({
                parent: props.parent,
                child: props.id,
                conform: value
            })
        }
    }, [value])

    return (
        <div className={`${props.title ? 'gray-bg' : ''} inspecciones-container`}>
            <p className='inspecciones-title'>{props.text}</p>
            {props.title ?
                <>
                    <p className='inspecciones-item'>{props.conform === true && "Conforme"}</p>
                    <p className='inspecciones-item'>{props.conform === false && "No conforme"}</p>
                </>
                :
                <>
                    <Radio className='inspecciones-item' checked={value === true} onChange={() => { setValue(true); props.editOption && props.editOption(props.editID, true) }} />
                    <Radio className='inspecciones-item' checked={value === false} onChange={() => { setValue(false); props.editOption && props.editOption(props.editID, false) }} />
                </>
            }
        </div>

    )
}

export default InspeccionesItem;