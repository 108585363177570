import React, { Fragment } from "react";
import FullTable from '../../../components/datatable/FullTable';
import {webhooks} from "../../../config/permissions";

import FormCreateWebhookTable from "./FormCreateWebhookTable";

const WebhooksTables = () => {
    let endpoints = {
        columns: "webhooks/webhook_tables/datatables_struct/",
        rows: "webhooks/webhook_tables/datatables/",
        delete: "webhooks/webhook_tables/"
    };
    return (
        <Fragment>
            <FullTable 
                endpoints={endpoints} 
                tableName='Tablas Webhooks'
                modalWidth={700}
				modalContent={<FormCreateWebhookTable />}
                permissions={webhooks}
            />
        </Fragment>
    )
};

export default WebhooksTables;
