import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../../components/images/Image'
import KrakenPNG from './../../assets/images/kraken.png'
import Button from '../../components/buttons/Button'
import ACTIONS from '../../redux/creator'
import { Paragraph } from '../../components/typography/Typography'
import Animated from '../../hoc/Animated'

const Error500Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-color: white;
	color: black;
	h1 {
		font-size: 2rem;
		font-weight: bold;
		text-transform: uppercase;
		color: #F06292;
	}
	.oops {
		font-weight: bold;
		font-size: 2rem;
		margin-bottom: 0;
	}
	p.text-muted {
		font-size: 1rem;
	}
	@media screen and (min-width: 700px) {
		img {
			display: table;
			margin: auto;
			max-width: 30%;
		}
	}
`


const Error500 = ({ closeSession, httpError, history }) => {
	const [redirect, setRedirect] = useState(false)

	const logout = () => {
		setRedirect(true)
		closeSession()
		history.go("/")
	}

	useEffect(() => {
		if (httpError) history.replace(`/${httpError}`)
	}, [])

	return (
		<Error500Container>
			<div className="container">
				<div className="row">
					<div className="col-12 d-flex justify-content-center mb-4">
						<Animated animation="fadeIn" duration="1.5s">
							<Image src={KrakenPNG} alt="Error 500" />
						</Animated>
					</div>
					<div className="col-12">
						<Animated animation="fadeIn" className="full" duration="2s">
							<h1 className="text-center">Error {httpError}</h1>
						</Animated>
						<Animated animation="fadeIn" className="full" duration="2.5s">
							<Paragraph className="text-center oops">Ups! Ha ocurrido un error con el servidor</Paragraph>
						</Animated>
					</div>
					<div className="col-6 offset-3">
						<Animated animation="fadeIn" className="full" duration="3s">
							<Paragraph className="text-center text-muted">No estamos exactamente seguros de qué ha ocurrido, pero fue un error con el servidor. Te recomendamos contactar con soporte.</Paragraph>
						</Animated>
					</div>
					<div className="col-12 d-flex justify-content-center">
						<Animated animation="fadeIn" className="full" duration="3.5s">
							<Button type="button" role="button" color="warning" size="lg" onClick={logout}>Ir a home</Button>
						</Animated>
					</div>
				</div>
			</div>
		</Error500Container>
	);
}

const mapStateToProps = state => ({
	httpError: state.httpError
})

const mapDispatchToProps = dispatch => ({
	closeSession() {
		dispatch(ACTIONS.closeSession())
		dispatch(ACTIONS.setHttpError(false))
	}
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error500))