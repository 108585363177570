import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, Spin } from 'antd';
import HttpRequest from './../../services/HttpRequest';
import autoCloseModal from './../../components/modal/AutoCloseModal';

function CanalesForm(props) {
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [data, setData] = useState(null);

    const submit = e => {
        setButtonLoading(true)
        HttpRequest.endpoint = 'qc/qa_channel/';
        const formData = new FormData();
        formData.append('name', e.name);
        if (!props.id) {
            HttpRequest.post(formData).then(res => {
                if (res.ID) {
                    autoCloseModal('Se ha creado el canal con éxito')
                    props.closeModal()
                } else {
                    Modal.error({
                        title: 'Error',
                        content: `Ha ocurrido un error creando el canal`
                    })
                }
                setButtonLoading(false);
            })
        } else {
            HttpRequest.patch(props.id, formData).then(res => {
                if (res.ID) {
                    autoCloseModal('Se ha editado el canal con éxito')
                    props.closeModal()
                } else {
                    Modal.error({
                        title: 'Error',
                        content: `Ha ocurrido un error editando el canal`
                    })
                }
                setButtonLoading(false);
            })
        }
    }

    useEffect(() => {
        if (props.id) {
            HttpRequest.endpoint = 'qc/qa_channel';
            HttpRequest.get(null, props.id).then(res => {
                if (res.ID) {
                    setData(res)
                    setLoading(false);
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo la información del canal'
                    })
                }
            })
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <>
            {loading ?
                <Spin className='center-spin' size='large' />
                :
                <Form initialValues={data} onFinish={submit}>
                    <Form.Item label='Nombre' name='name' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Input placeholder='Nombre' />
                    </Form.Item>
                    <Button size='large' block type='primary' htmlType='submit' loading={buttonLoading}>{props.id ? 'Editar' : 'Crear'}</Button>
                </Form>
            }
        </>
    )
}

export default CanalesForm;