import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import HttpRequest from "../../services/HttpRequest";

import { Drawer, Space } from "antd";
import Button from "../../components/buttons/Button";

import { DataTable, ShowFilters } from "../../components/table/ListTable";
//Moverlo para la dataTable List
import Moment from "react-moment";
import "moment/locale/es";

const ProofDataTable = () => {
	const [isInitial, setIsInitial] = useState(true);
	const [changeData, setChangeData] = useState(false);
	const [isLoadedData, setIsLoadedData] = useState(false);
	const [column, setColumn] = useState([]);
	const [data, setData] = useState([]);
	const [defaultsValues, setDefaultsValues] = useState({
		start: "0",
		offset: "10",
		order_field: "name",
		order_type: "desc",
		filters: [],
		search: "",
	});

	const [drawer, setDrawer] = useState(false);
	const [closeDrawer, setCloseDrawer] = useState(true);
	
	const [filters, setFilters] = useState({});
	const [queryFilters, setQueryFilters] = useState({});
	const [comparisons, setComparisons] = useState({});
	const [typeOfComparisons, setTypeOfComparisons] = useState({});
	const [pagination, setPagination] = useState({ pageSize: 10 });

	/**
	 * Build columns to use it in the table
	 */
	async function getColumn() {
		let temp = [];
		let newColumns = [];

		HttpRequest.endpoint =
			"communications/template_text/datatables_struct/";
		await HttpRequest.get().then((res) => {
			temp = Object.keys(res.columns);
			//Build the columns for table with allow structure.
			for (let index = 0; index < temp.length; index++) {
				newColumns.push({
					title: temp[index],
					dataIndex: Object.values(res.columns)[index].field,
					key: Object.values(res.columns)[index].field,
					sorter: Object.values(res.columns)[index].sortable,
					render: fieldRender.includes(
						Object.values(res.columns)[index].field
					)
						? (text, next) =>
								redering(
									text,
									Object.values(res.columns)[index].field,
									next["ID"]
								)
						: "",
				});
			}

			let tempTOC = {};
			for (let m = 0; m < Object.keys(res.filters).length; m++) {
				let aFilter = Object.values(res.filters)[m].type;
				let arrayTocomp = Object.values(
					res.type_of_comparisons[aFilter]
				);

				let type = [];
				arrayTocomp.forEach((element) => {
					type.push({
						ID: Object.values(res.comparisons[element])[0],
						name: element,
					});
				});
				tempTOC[aFilter] = type;
			}

			setColumn(newColumns); //Columns
			setFilters(res.filters); //filters
			setTypeOfComparisons(tempTOC);
			setComparisons(res.comparisons);
		});
	}

	/**
	 * Get data of the backend
	 */
	async function getData() {
		setIsLoadedData(!isLoadedData);
		let data = new FormData();
		data.set(
			`${Object.keys(defaultsValues)[0]}`,
			`${Object.values(defaultsValues)[0]}`
		);
		data.set(
			`${Object.keys(defaultsValues)[1]}`,
			`${Object.values(defaultsValues)[1]}`
		);
		data.set(
			`${Object.keys(defaultsValues)[2]}`,
			`${Object.values(defaultsValues)[2]}`
		);
		data.set(
			`${Object.keys(defaultsValues)[3]}`,
			`${Object.values(defaultsValues)[3]}`
		);
		data.set(
			`${Object.keys(defaultsValues)[4]}`,
			JSON.stringify(Object.values(defaultsValues)[4])
		);
		data.set(
			`${Object.keys(defaultsValues)[5]}`,
			`${Object.values(defaultsValues)[5]}`
		);

		HttpRequest.endpoint = "communications/template_text/datatables/";
		await HttpRequest.post(data, true, true).then((res) => {
			setData(res);
			setIsLoadedData(false);
		});
	}

	//This custom array content the fields to will be render in the table
	const fieldRender = ["created", "custom_change", "custom_delete"];

	/**
	 *
	 * @param {String} text This variable is used for datatime
	 * @param {String} value This variable is used to find out which field it render
	 * @param {number} id This variable content {ID} of the record
	 */
	const redering = (text, value, id = null) => {
		switch (value) {
			case "created":
				return (
					<Moment fromNow locale="es">
						{text}
					</Moment>
				);
			case "custom_change":
				return (
					<Button
						className="form-control"
						color={"success"}
						onClick={() => {
							console.log("Cambio " + id);
						}}
						value={id}
					>
						Modificar
					</Button>
				);
			case "custom_delete":
				return (
					<Button
						className="form-control"
						color={"danger"}
						onClick={() => {
							console.log("Eliminando " + id);
						}}
						value={id}
					>
						Eliminar
					</Button>
				);
			default:
				break;
		}
	};

	const onSorter = (obj) => {
		let page = obj.pagination;
		let sort = obj.sorter;
		const dict = { undefined: "desc", ascend: "asc", descend: "desc" };
		setDefaultsValues({
			...defaultsValues,
			Start: (page.current - 1) * page.pageSize,
			offset: page.pageSize,
			order_field: sort.field,
			order_type: dict[sort.order],
		});
		setChangeData(true);
	};

	const onSearch = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		updateStasteDefaultsValues(name, value);
		setChangeData(true);
	};

	const setFiltersValue = (key, name, comparison, value) => {
		let arrayFilter = [name, comparison, value];
		let updatedObject = queryFilters;
		Object.assign(updatedObject, { [key]: arrayFilter });
		updateStateFilter(key, arrayFilter);
		updateStasteDefaultsValues("filters", Object.values(updatedObject));
		setChangeData(true);
	};

	const callBack = () => {
		getData();
	};

	const updateStateFilter = useCallback(
		(keyToUpdate, newValue) => {
			setQueryFilters({ ...queryFilters, [keyToUpdate]: newValue });
		},
		[queryFilters]
	);

	const updateStasteDefaultsValues = useCallback(
		(keyToUpdate, newValue) => {
			setDefaultsValues({
				...defaultsValues,
				[keyToUpdate]: newValue,
			});
			console.log(keyToUpdate, newValue);
		},
		[defaultsValues]
	);

	const updateAll = useCallback(() => {
		console.log("updating .....");
	}, [updateStateFilter, updateStasteDefaultsValues]);

	useEffect(() => {
		if (isInitial) {
			getColumn();
			getData();
			setIsInitial(false);
		}
	}, []);

	useEffect(() => {
		if (changeData) {
			callBack();
			setChangeData(false);
		}
	}, [updateAll]);

	const showDrawer = () => {
		setDrawer(!drawer);
	};

	const onCloseDrawer = () => {
		setDrawer(!closeDrawer);
	};

	return (
		<Fragment>
			<div className="row">
				<div className="col-xs-12">
					<Panel>
						<PanelHeader>Tabla de prueba</PanelHeader>
						<PanelBody>
							<div
								className="form-group"
								style={{ textAlign: "right" }}
							>
								<Space>
									<div className="input-group">
										<Button
											className="form-control"
											color={"success"}
										>
											Nuevo
										</Button>
									</div>
									<div className="input-group">
										<input
											name="search"
											type="text"
											className="form-control"
											placeholder="Buscar"
											onChange={onSearch}
										/>
										<div className="input-group-append">
											<button
												className="btn btn-outline-secondary"
												title="Bucar"
											>
												<i className="fas fa-search"></i>
											</button>
										</div>
									</div>
									<div className="input-group">
										<Button
											className="form-control"
											onClick={() => showDrawer()}
										>
											Filtros
										</Button>
									</div>
								</Space>
							</div>
							<DataTable
								column={column}
								dataSource={data}
								size={"default"}
								loading={isLoadedData}
								pagination={pagination}
								onChange={onSorter}
							/>
							<Drawer
								drawerStyle={{ paddingTop: 48 }}
								title="Filtros"
								width={670}
								onClose={() => onCloseDrawer()}
								visible={drawer}
								bodyStyle={{ paddingBottom: 80 }}
								footer={
									<div
										className="form-group"
										style={{
											textAlign: "right",
										}}
									>
										<Button
											type="success"
											onClick={() => showDrawer()}
											style={{ marginRight: 8 }}
										>
											Cancelar
										</Button>
										<Button
											type="primary"
											onClick={() => showDrawer()}
											style={{ marginRight: 8 }}
										>
											Aplicar
										</Button>
									</div>
								}
							>
								{filters
									? Object.values(
											filters
									  ).map((value, index) => (
											<ShowFilters
												key={index}
												name={
													Object.keys(filters)[index]
												}
												filters={filters}
												comparison={comparisons}
												typeOfComparison={
													typeOfComparisons
												}
												valueFilter={
													Object.keys(filters)[index]
												}
												function={setFiltersValue}
											/>
									  ))
									: ""}
							</Drawer>
						</PanelBody>
					</Panel>
				</div>
			</div>
		</Fragment>
	);
};

export default ProofDataTable;
