import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, Spin, Select } from 'antd';
import HttpRequest from './../../services/HttpRequest';
import autoCloseModal from './../../components/modal/AutoCloseModal';
import { connect } from "react-redux";

function IndicadoresForm(props) {
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [data, setData] = useState(null);
    const [parents, setParents] = useState(null);
    const [type, setType] = useState(null);

    const submit = e => {
        if (!e.parent && typeof e.channel !== 'number') {
            return Modal.error({
                title: 'Error',
                content: 'Debe definir el canal ya que es un indicador padre'
            })
        }
        setButtonLoading(true)
        HttpRequest.endpoint = 'qc/indicator/';
        const formData = new FormData();
        formData.append('description', e.description);
        typeof e.channel === "number" && formData.append('channel', e.channel);
        e.parent && formData.append('parent', e.parent);
        if (!props.id) {
            HttpRequest.post(formData).then(res => {
                if (res.ID) {
                    autoCloseModal('Se ha creado el indicador con éxito')
                    props.closeModal()
                } else {
                    Modal.error({
                        title: 'Error',
                        content: `Ha ocurrido un error creando el indicador`
                    })
                }
                setButtonLoading(false);
            })
        } else {
            HttpRequest.patch(props.id, formData).then(res => {
                if (res.ID) {
                    autoCloseModal('Se ha editado el indicador con éxito')
                    props.closeModal()
                } else {
                    Modal.error({
                        title: 'Error',
                        content: `Ha ocurrido un error editando el indicador`
                    })
                }
                setButtonLoading(false);
            })
        }
    }

    useEffect(() => {
        if (props.id) {
            HttpRequest.endpoint = 'qc/indicator';
            HttpRequest.get(null, props.id).then(res => {
                if (res.ID) {
                    setData(res)
                    setLoading(false);
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo la información del indicador'
                    })
                }
            })
        }
    }, [])

    useEffect(() => {
        HttpRequest.endpoint = 'qc/indicator/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                setParents(res)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo los padres'
                })
            }
        })
    }, [])

    useEffect(() => {
        if (props.id) {
            if (data && Array.isArray(parents)) {
                setLoading(false);
            }
        } else {
            if (Array.isArray(parents)) {
                setLoading(false);
            }
        }
    }, [data, parents])

    return (
        <>
            {loading ?
                <Spin className='center-spin' size='large' />
                :
                <Form initialValues={data} onFinish={submit}>
                    <Form.Item label='Descripción' name='description' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Input placeholder='Descripción' />
                    </Form.Item>
                    <Form.Item label='Tipo de indicador' name='type' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select placeholder='Descripción' onChange={val => setType(val)}>
                            <Select.Option key='parent' value={0}>Padre</Select.Option>
                            <Select.Option key='child' value={1}>Hijo</Select.Option>
                        </Select>
                    </Form.Item>
                    {type === 0 ?
                        <Form.Item label='Canal' name='channel' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select placeholder='Tipo de canal'>
                                <Select.Option key='llamada' value={0}>Llamadas</Select.Option>
                                <Select.Option key='escrito' value={1}>Canal escrito</Select.Option>
                            </Select>
                        </Form.Item>
                        :
                        type === 1 &&
                        <Form.Item label='Padre' name='parent' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select placeholder='Indicador padre'>
                                {parents.filter(par => par.parent === null && props.id !== par.ID).map(par => <Select.Option key={par.ID} value={par.ID}>{par.description}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    }
                    <Button size='large' block type='primary' htmlType='submit' loading={buttonLoading}>{props.id ? 'Editar' : 'Crear'}</Button>
                </Form>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(IndicadoresForm);