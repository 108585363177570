import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { Tag, Modal } from 'antd';
import CommunicationHistory from './CommunicationHistory'
import AccordionForm from './AccordionForm'
import ButtonWithModal from '../../components/buttons/ButtonWithModal';
import { client } from '../../config/permissions';
import './Client.css'

function Cliente2() {
    const [tableRows, setTableRows] = useState([]);
    const [communicationHistoryID, setCommunicationHistoryID] = useState(null);
    const [newClient, setNewClient] = useState(null);
    const [forceReload, setForceReload] = useState(false)

    const endpoints = {
        columns: "customers/customer/datatables_struct/",
        rows: "customers/customer/datatables/",
        delete: `customers/customer/`,
    }

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            emails: row.emails.map(email => <Tag key={email}>{email}</Tag>),
            phones: row.phones.map(phone => <Tag key={phone}>{phone}</Tag>),
            rut: <div style={{ display: 'inline', color: 'rgb(24, 144, 255)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setCommunicationHistoryID(row.ID)}>{row.rut}</div>,
        })))
    }

    return (
        <>
            <FullTable
                forceReload={forceReload}
                buttons={[]}
                modalContent={<AccordionForm />}
                endpoints={endpoints}
                handleRows={handleRows}
                tableRows={tableRows}
                tableName='cliente'
                permissions={client}
            />
            {communicationHistoryID &&
                <Modal width={850} title='Historial de comunicaciones' visible={communicationHistoryID} onCancel={() => setCommunicationHistoryID(null)} footer={null}>
                    <CommunicationHistory customerID={communicationHistoryID} />
                </Modal>
            }
        </>
    )
}

export default Cliente2;
