import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import FormComp from "../../components/form/Form.jsx";
import HttpRequest from "../../services/HttpRequest.js";
import { Select, Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { endPointTemplateText, endPointTemplateCall, endPointTemplateWhatsapp, endPointCreateEvent } from "../../services/endpoints.jsx";
import { can } from "../../helpers/helpers.js";

const PhoneForm = (props) => {
    const { Option } = Select;
    const [templateList, setTemplateList] = useState();
    const [template, setTemplate] = useState();
    const [showForm, setShowForm] = useState(false);
    const [number, setNumber] = useState(null);
    const [phoneParsed, setPhoneParsed] = useState(["CL", null]);
    const formRef = useRef();

    let endpoints = {
        text: endPointTemplateText,
        call: endPointTemplateCall,
        whatsapp: endPointTemplateWhatsapp
    }

    const createOptions = () => {
        let templatesArray = [];
        HttpRequest.endpoint = endpoints[props.type];
        let filters = { operator: props.operator }
        HttpRequest.get(filters)
            .then((res) => {
                for (let index = 0; index < res.length; index++) {
                    templatesArray.push(
                        <Option key={res[index]["ID"]} value={res[index]["ID"]}>
                            {res[index]["name"]}
                        </Option>
                    );
                }
                if (templatesArray.length <= 0) {
                    return;
                } else {
                    setTemplateList(templatesArray);
                }
            })
            .then(() => {
                setShowForm(true);
            });
    };

    const addChannel = () => {
        let permission = true;
        if (props.editing) {
            permission = can(props.permissions.edit)
        } else {
            permission = can(props.permissions.create)
        }
        if (permission) {
            let values = formRef.current.values();
            const formData = new FormData();
            formData.append("channel", props.type);
            formData.append("phone_number_test", number);
            formData.append("template", template)
            HttpRequest.endpoint = endPointCreateEvent + props.id + "/add_channel/";
            HttpRequest.post(formData).then((res) => {
                if (Object.keys(res).length === 0) {
                    Modal.success({
                        title: "Éxito",
                        content: "Canal añadido exitosamente"
                    })
                    props.updateRows()
                } else {
                    Modal.error({
                        title: "Error",
                        content: "Ha ocurrido un error al añadir el canal de comunicación"
                    })
                }
            });
        }
    };
    let formTexto = {
        size: [6, 16],
        title: "Form1",
        items: [
            {
                custom: true,
                label: "Numero Prueba",
                name: "template",
                rules: [{ required: true, message: 'Este campo es obligatorio' }],
                initialValue: number,
                component: (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <PhoneInput defaultCountry={phoneParsed[0]} style={{ width: "100%", }} value={phoneParsed[1]} onChange={setNumber} />
                    </div>
                ),
            },
        ],
        multipart: false,
        submitButton: false,
    };

    const getData = () => {
        if (props.event) {
            HttpRequest.endpoint = props.event.split("v1/").pop();
            HttpRequest.get().then((res) => {
                let phoneNumber = parsePhoneNumber(res["phone_number_test"]);
                setPhoneParsed([phoneNumber.country, phoneNumber.nationalNumber]);
                setNumber(res["phone_number_test"]);
                setTemplate(res["template"]);
                createOptions();
            });
        } else {
            createOptions();
        }
    }
    useEffect(() => {
        props.id ? getData() :
            Modal.warning({ title: "Error", content: "Debe crear el evento primero" })
    }, [props.id])

    // useEffect(() => {
    //     createOptions();
    // }, [props.operator])
    useEffect(() => {
        if (props.reset) {
            setPhoneParsed(['CL', null])
            setTemplate(null)
            setNumber(null)
            setShowForm(false);
            setTimeout(() => {
                setShowForm(true)
            }, 100)
        }
    }, [props.reset])


    return (
        <div>
            {props.id && showForm &&
                <div>
                    <FormComp ref={formRef} customSubmit={addChannel} info={formTexto} />
                    <Form style={{ width: "100%" }}>
                        <Form.Item name='plantilla' initialValue={template} label="Plantilla" wrapperCol={{ span: 16 }} labelCol={{ span: 6 }}>
                            <Select
                                required
                                placeholder="Seleccione la plantilla"
                                style={{ width: "100%", }}
                                value={template}
                                onChange={(option) => setTemplate(option)} >
                                {templateList}
                            </Select>
                        </Form.Item>
                    </Form>
                    <Button className='center-btn' type="primary" onClick={() => formRef.current.submit()} style={{ marginRight: "10px", }}>
                        Agregar
                </Button>
                    <Button className='center-btn' style={{ alignSelf: "right" }} type="primary" danger onClick={() => props.permissions ? can(props.permissions.delete) ? props.deleteChannel(props.type) : null : props.deleteChannel(props.type)}   >
                        Eliminar
                </Button>
                </div>}
        </div>
    );

}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(PhoneForm);