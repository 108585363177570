import React, { useState, useEffect } from 'react'
import { Select, Form, Radio, Row, Spin, Button, Modal } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { useForm } from "antd/lib/form/Form";
import { SearchOutlined } from '@ant-design/icons';
import CustomerInfoModal from '../typify/customerInfoModal';
import ServiceInfoModal from '../typify/servicesInfoModal';
import autoCloseModal from '../../components/modal/AutoCloseModal';

export default function CustomerSelector(props) {

    const { Option } = Select;
    const [rut, setRut] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [criterion, setCriterion] = useState("service");
    const [customerModal, setCustomerModal] = useState(false);
    const [serviceModal, setServiceModal] = useState(false);
    const [typing, setTyping] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [form] = useForm();

    const getServices = (rut) => {
        let list = [];
        const formData = { customer__rut: rut, operator: props.operator, fields: 'number' }
        HttpRequest.endpoint = "matrix/services/";
        HttpRequest.get(formData).then((res) => {
            res.forEach(element => {
                list.push(<Option key={element["number"]} value={element["number"]}>{element["number"]}</Option>)
            });
            setServiceList(list);
            if (list.length === 1) {
                form.setFieldsValue({ services: [res[0].number] })

            }
        })
    }

    const assignCustomer = (values) => {
        HttpRequest.endpoint = "communications/conversation/"
        const formData = new FormData();
        formData.append("services", JSON.stringify(values["services"]))
        HttpRequest.patch(props.id, formData).then((res) => {
            if (res["ID"]) {
                autoCloseModal('Se ha asignado el cliente con éxito')
                props.setCustomerSelector();
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error al asignar el cliente'
                })
            }
        })
    }
    const searchUser = (value) => {
        if (value != null && value.length > 0) {
            setCustomerList([]);
            setLoadingUsers(true);
            HttpRequest.endpoint = "matrix/customer_filter/";
            const formData = { criterion: criterion, search: value }
            HttpRequest.get(formData).then((res) => {
                setCustomerList(res)
            })
        }
    }
    const onKeyPress = (e) => {
        const specialCharRegex = new RegExp("^[0-9]*$");
        const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!specialCharRegex.test(pressedKey)) {
            e.preventDefault();
            return false;
        }
    }
    useEffect(() => {
        if (rut && typing) {
            const delay = setTimeout(() => {
                searchUser(rut)
            }, 1500)
            return () => clearTimeout(delay)
        }
    }, [rut])

    useEffect(() => {
        if (form.getFieldValue("customer_rut") != null) {
            getServices(form.getFieldValue("customer_rut")["key"]);
        }
        form.setFieldsValue({
            services: undefined
        })
    }, [props.operator])

    return (
        <>
            <Form form={form} onFinish={(values) => assignCustomer(values)}>
                <h1 className='form-title'>Seleccione el cliente y servicios asociados</h1>
                <>
                    <div>
                        <div className="radio-group-div">
                            <Form.Item>
                                <Radio.Group className="radio-group" onChange={(e) => setCriterion(e.target.value)} defaultValue={"service"}>
                                    <Radio value={"rut"}>Rut</Radio>
                                    <Radio value={"name"}>Nombre</Radio>
                                    <Radio value={"composite_address"}>Dirección</Radio>
                                    <Radio value={"service"}>Servicio</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                    <Row>
                        <Form.Item className='rut-width' label="Cliente:" name="customer_rut" rules={[{ required: props.requiredRut, message: 'Este campo es requerido' }]}>
                            {criterion == "service" ?
                                <Select labelInValue onKeyPress={onKeyPress} placeholder="Ingrese el número de servicio" showSearch notFoundContent={loadingUsers ? <Spin size="small" /> : null}
                                    filterOption={false} onSearch={val => { setTyping(true); setRut(val) }} onChange={(option) => { getServices(option["key"]); setTyping(false); setRut(option["key"]); form.setFieldsValue({ services: undefined }); }} >
                                    {customerList.map(element => (
                                        <Option key={element["id"]}>{element["text"]}</Option>
                                    ))}
                                </Select>
                                :
                                <Select labelInValue placeholder={criterion == "rut" ? "Ingrese el rut" : criterion == "composite_address" ? "Ingrese la dirección" : "Ingrese el nombre del cliente"} showSearch notFoundContent={loadingUsers ? <Spin size="small" /> : null}
                                    filterOption={false} onSearch={val => { setTyping(true); setRut(val) }} onChange={(option) => { getServices(option["key"]); setTyping(false); setRut(option["key"]); form.setFieldsValue({ services: undefined }); }} >
                                    {customerList.map(element => (
                                        <Option key={element["id"]}>{element["text"]}</Option>
                                    ))}
                                </Select>}
                        </Form.Item>
                        <Button shape="circle" icon={<SearchOutlined />} onClick={() => setCustomerModal(true)} />
                    </Row>
                </>
                <Row>
                    <Form.Item className='rut-width' label="Servicios" name="services" rules={[{ required: props.requiredServices, message: 'Este campo es requerido' }]}>
                        <Select placeholder="Servicio" mode="multiple">
                            {serviceList}
                        </Select>
                    </Form.Item>
                    <Button shape="circle" icon={<SearchOutlined />} onClick={() => setServiceModal(true)} />
                </Row>
                <Button htmlType='submit' type='primary'>Asignar</Button>
            </Form>
            {customerModal && <CustomerInfoModal visible={customerModal} setVisible={setCustomerModal} rut={rut} />}
            {serviceModal && <ServiceInfoModal visible={serviceModal} setVisible={setServiceModal} rut={rut} />}
        </>
    )
}
