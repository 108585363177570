import React from "react";
import { Route, withRouter } from "react-router-dom";
import routes from "./../../config/page-route.jsx";
import { PageSettings } from "./../../config/page-settings.js";
import ProtectedRoute from "./../../middleweare/ProtectedRoute";
import Config from "./../../config";
import TabPanel from "./../tabs/tab-panel";
import { element } from "prop-types";

function setTitle(path, routeArray) {
	var pageTitle;
	for (var i = 0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = `${Config.TITLE} | ${routeArray[i].title}`;
		}
	}
	document.title = pageTitle ? pageTitle : Config.TITLE;
}

class Content extends React.Component {
	componentDidMount() {
		setTitle(this.props.history.location.pathname, routes);
	}
	UNSAFE_componentWillMount() {
		this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname, routes);
		});
	}

	render() {
		let route = routes.filter(
			(route) => this.props.history.location.pathname === route.path
		);
		route["key"] = routes.indexOf(this.props.history.location.pathname);
		return (
			<PageSettings.Consumer>
				{({
					pageContentFullWidth,
					pageContentClass,
					pageContentInverseMode,
				}) => (
					<div
						className={
							"content " +
							(pageContentFullWidth
								? "content-full-width "
								: "") +
							(pageContentInverseMode
								? "content-inverse-mode "
								: "") +
							pageContentClass
						}
					>
						{routes.map((route, index) =>
							route.private ? (
								<ProtectedRoute
									key={index}
									path={route.path}
									exact={route.exact}
									component={route.component}
								/>
							) : (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									component={route.component}
								/>
							)
						)}
						{/* <TabPanel
							path={this.props.history.location.pathname}
						></TabPanel> */}
					</div>
				)}
			</PageSettings.Consumer>
		);
	}
}

export default withRouter(Content);

/* {routes.map((route, index) =>
	route.private ? (
		<ProtectedRoute
			key={index}
			path={route.path}
			exact={route.exact}
			component={route.component}
		/>
	) : (
		<Route
			key={index}
			path={route.path}
			exact={route.exact}
			component={route.component}
		/>
	)
)} */
