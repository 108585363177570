import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import FinanceEscalationTab from './FinanceEscalationTab';

function FinanceEscalationSearchTables() {
    const endpoint = 'escalation_finance/escalation_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<FinanceEscalationTab />} />
        </>
    )
}

export default FinanceEscalationSearchTables;