import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from "react";
import {
    Button,
    Input,
    Form,
    Select,
    Checkbox,
    DatePicker,
    Switch,
} from "antd";
import HttpRequest from "../../services/HttpRequest";

/**
 *
 * @function Returns a form with fixed and custom fields and a submit button (optional). In order to access the
 * methods of the form and being able to sumbit it from the parent component we use a ref with useRef().
 *
 * A submit from a aprent component looks like this onClick={() => formRef.current.submit()}
 * and a method call looks like this formRef.current.values();
 *
 *
 * @param (dict, red) props, ref
 * 		props:
 * 			-info: A dictionary cointaining the basic properties of the form: Size (Required. An array with the with of the
 * columns for the labels and fields, Title (String. The title of the form), endpoint (string, the url to make the request),
 * Multipart (string), submitButton (Required. Bool. If the submit button will be shown) and the fields.
 *
 * 	The fields in the info are a dictionary and they must have a label, a name, a type (this represents the type of the field
 * and can be an input, textArea, checkBox, select or datePicker), an input (if the type is input or text area, this helps with defining the
 * validation), required, initialValue and custom (A bool to see if it uses a custom component, in case this is true, pass the component in
 * another element call component within the same item).
 *
 * 			-ref: Required. A ref to the form. This will be used to call the methods within the form defined in useImperativeHandle
 *  		-customSubmit: A function in case that a special submit function is needed.
 *  */

const FormComp = forwardRef((props, ref) => {
    const [formFields, setFormFields] = useState([]);
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: props.info["size"][0] },
        wrapperCol: { span: props.info["size"][1] },
    };

    const handleSubmit = () => {
        const formData = new FormData();
        HttpRequest.endpoint = props.info["endpoint"];
        props.info["items"].forEach((element) => {
            if (element["required"] == true) {
                formData.append(element["name"], element["name"]);
            }
        });
        HttpRequest.post(formData, true, props.info["multipart"]);
    };

    /**
     * Creates the FormItem for each of the components passed in items, setting the label, key, if its required and
     * the validation rules
     */

    const createFields = () => {
        let fields = [];
        props.info["items"].forEach((element) => {
            if (!element["custom"]) {
                fields.push(
                    <Form.Item
                        label={element["label"]}
                        name={element["name"]}
                        key={element["name"]}
                        initialValue={element["initialValue"]}
                        rules={element.rules ?
                            element.rules :
                            [
                                {
                                    required: element["required"],
                                    message: 'Este campo es requerido',
                                },
                                {
                                    type: element["input"],
                                    message: 'Este campo debe ser de tipo ' + element["input"],
                                }
                            ]
                        }
                    >
                        {generateComponent(element)}
                    </Form.Item>
                );
            } else {
                fields.push(
                    <Form.Item
                        label={element["label"]}
                        name={element["name"]}
                        key={element["name"]}
                        initialValue={element["initialValue"]}
                        rules={element["rules"]}
                    >
                        {element["component"]}
                    </Form.Item>
                );
            }
        });
        setFormFields(fields);
    };

    /**
     * @function Makes sure the fields are generated everytime the component is rendered
     */

    useEffect(() => {
        createFields();
    }, []);

    /**
     * @function Generates a form field component depending on the specifications in the info
     * @param {dic} element A dictionary with the info of the element
     */

    const generateComponent = (element) => {
        switch (element["type"]) {
            case "input":
                return <Input />;
                break;
            case "textArea":
                return <Input.TextArea />;
                break;
            case "checkBox":
                return <Checkbox>{element["label"]}</Checkbox>;
                break;
            case "select":
                let options = [];
                element["options"].forEach((element) => {
                    options.push(
                        <Select.Option key={element["value"]}>
                            {element["label"]}
                        </Select.Option>
                    );
                });
                return <Select>{options}</Select>;
                break;
            case "datePicker":
                return <DatePicker />;
                break;
            default:
                break;
        }
    };

    /**
     * @function Allows access to the form methods from the parent component, this methods can be accesed by ref.current
     */

    useImperativeHandle(ref, () => ({
        submit() {
            form.submit();
        },
        values() {
            return form.getFieldsValue();
        },
        validate() {
            return form.validateFields();
        },
    }));

    return (
        <Form
            {...layout}
            name={props.info["title"]}
            onFinish={props.customSubmit ?? handleSubmit}
            form={form}
        >
            {formFields ?? ""}
            {props.info["submitButton"] ? (
                <Button htmlType="submit" type='primary' className='center-btn'>Listo</Button>
            ) : (
                    ""
                )}
        </Form>
    );
});

export default FormComp;
