import "react-phone-number-input/style.css";
import React, { useState, useEffect, useRef } from "react";
import { Button, Select, Modal, Form, Input, Checkbox, InputNumber, DatePicker, Row } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import { endPointMassiveFilters, endPointCreateMassives } from "../../services/endpoints.jsx";
import moment from "moment";
import { can } from "../../helpers/helpers.js";
import { massive, typifications } from "../../config/permissions.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import errorModal from './../../components/modal/errorModal';

const MassiveFilters = (props) => {
    const [showForm, setShowForm] = useState(true);
    const [filterType, setFilterType] = useState();
    const [filterTypeList, setFilterTypeList] = useState();
    const [id, setId] = useState(props.id);
    const [showFilters, setShowFilters] = useState(false);
    const [unpaid, setUnpaid] = useState(false);
    const [filtros, setFiltros] = useState(props.filtros);
    const [firstFilter, setFirstFilter] = useState([]);
    const [secondFilter, setSecondFilter] = useState([]);
    const [thirdFilter, setThirdFilter] = useState([]);
    const [filtersTranslation, setFiltersTranslation] = useState([]);
    const [error, setError] = useState([]);
    const [exito, setExito] = useState("");
    const formRef = useRef();
    const formRef2 = useRef();
    const formRef3 = useRef();
    const { Option } = Select;
    const [commercialActivities, setCommercialActivities] = useState([]);
    const [services, setServices] = useState([])

    const lists = {
        status: {
            1: "Activo",
            2: "Pendiente",
            3: "Inactivo",
            4: "No instalado",
            5: "Intercambio",
            6: "Instalación rechazada",
            7: "Plan no en venta",
            8: "Moroso",
            9: "Reprogramar",
            10: "Cambio de titular",
            11: "Descartado",
            12: "En espera",
            13: "Perdido",
        },
        technology_kind: { 1: "Cable de red", 2: "Gpon", 3: "Gepon", 4: "Antena" },
        document_type: { 1: "Boleta", 2: "Factura", 3: 'Nota de venta' },
        customer__kind: { 1: "Hogar", 2: "Empresa", 3: 'Intercambio' },
        service__category: {
            INTERNET_HOGAR: "Internet hogar",
            INTERNET_EMPRESAS: "Internet empresas",
            TELEFONIA_EMPRESAS: "Telefonía empresas",
            RED_EMPRESAS: "Red empresas",
            TELEVISION_HOGAR: "Televisión hogar",
        },
        customer__commercial_activity__id: commercialActivities,
        plan__id: services,
    };
    const getCommercialActivities = () => {
        HttpRequest.endpoint = 'matrix/commercial-activities';
        HttpRequest.get().then(res => {
            const commercial = {}
            res.forEach(commercialAct => commercial[commercialAct.id] = commercialAct.name)
            setCommercialActivities(commercial);
        })
    }
    const getServices = () => {
        HttpRequest.endpoint = 'matrix/plans';
        HttpRequest.get().then(res => {
            const services = {}
            res.forEach(serv => services[serv.id] = serv.name)
            setServices(services);
        })
    }

    let strInputField = (defaultValue, index) => {
        return (
            <Form.Item rules={[{ required: true, }]} style={{ marginLeft: "20px", width: "30%", }} >
                <Input style={{ width: "100%", }} defaultValue={defaultValue} onChange={(e) => { updateFilter(e.target.value, index, 2); }} />
            </Form.Item>
        );
    };

    let intInputField = (defaultValue, index) => {
        return (
            <Form.Item rules={[{ required: true, },]} style={{ marginLeft: "20px", width: "30%" }} >
                <InputNumber style={{ width: "100%", }} required defaultValue={defaultValue} onChange={(value) => { updateFilter(value, index, 2); }} />
            </Form.Item>
        );
    };

    let dateInputField = (defaultValue, index) => {
        return (
            <Form.Item rules={[{ required: true, }]} style={{ marginLeft: "20px", width: "30%", }} >
                <DatePicker style={{ width: "100%", }} required defaultValue={moment(defaultValue, "DD/MM/YYYY")}
                    format="DD/MM/YYYY" onChange={(date, dateStr) => { updateFilter(dateStr, index, 2); }} />
            </Form.Item>
        );
    };

    let selectInputfield = (defaultValue, index, options = null) => {
        let optionsList = [];
        if (options) {
            for (var key in options) { optionsList.push(<Option value={key}>{options[key]}</Option>); }
        } else {
            optionsList.push(<Option value={true}>Verdadero</Option>);
            optionsList.push(<Option value={false}>Falso</Option>);
        }

        return (
            <Form.Item rules={[{ required: true, }]} style={{ marginLeft: "20px", width: "30%" }} initialValue={defaultValue} >
                <Select placeholder="Tipo de condición" required defaultValue={defaultValue} style={{ width: "100%", }} onChange={(option) => { updateFilter(option, index, 2); }} >
                    {optionsList}
                </Select>
            </Form.Item>
        );
    }

    const deleteFilter = (index) => setFiltros(filtros.filter((_, i2) => i2 != index));

    const updateFilter = (option, index, element) => {
        if (element === 0) filtros[index][1] = null; filtros[index][2] = null;
        filtros[index][element] = option;
        setFiltros([...filtros]);
    };

    const getSecondfilterOptions = (filter) => {
        let list = [];
        let filterType = secondFilter[filter];
        let FilterList = thirdFilter[filterType];
        FilterList.forEach((element) => { list.push(<Option key={element}>{element}</Option>); });
        return list;
    };

    const addFilter = () => {
        setShowFilters(false);
        filtros.push(["technology_kind", null, null]);
        setFiltros([...filtros]);
    };

    const submitFilters = (values) => {
        let vacio = false;
        const formData = new FormData();
        filtros.forEach((element) => {
            element.forEach((filtro) => {
                if (filtro == null) {
                    errorModal('No debe dejar ningún campo vacío')
                    vacio = true;
                    return;
                }
            });
        });
        if (!vacio) {
            if (props.getFilters) {
                props.getFilters(values, filtros)
                return
            }
            formData.append("type_filters", values["typeFilter"]["value"]);
            formData.append("filters", JSON.stringify(filtros));
            unpaid
                ? formData.append("unpaid_ballot", unpaid)
                : formData.append("unpaid_ballot", false);
            HttpRequest.endpoint = endPointCreateMassives;
            HttpRequest.patch(props.id, formData).then((res) => {
                if (res["ID"]) {
                    props.updateRows()
                    autoCloseModal("Se han guardado los filtros con éxito")
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al guardar los filtros" })
                }
            });
        }
    };

    const getFilterList = () => {
        let filters = [];
        HttpRequest.endpoint = endPointMassiveFilters;
        HttpRequest.get().then((res) => {
            let firstFilter = res["filters_translation"];
            for (let key in firstFilter) {
                filters.push(<Option key={firstFilter[key]}>{key}</Option>);
            }
            setFirstFilter(res["filters_translation"]);
            setSecondFilter(res["filters"]);
            setThirdFilter(res["type_filters"]);
            setFilterTypeList(filters);
            setFiltersTranslation(res["type_filters_tranlation"]);
            setShowForm(true);
        });
    };

    useEffect(() => {
        setShowForm(false);
        props.id || props.tipify ? getFilterList() :
            Modal.warning({ title: "Error", content: "Debe crear el masivo primero" })
    }, [props.id])

    useEffect(() => {
        setShowFilters(true);
    }, [filtros])

    useEffect(getCommercialActivities, [])
    useEffect(getServices, [])

    return (
        <div>
            {(props.id || props.tipify) && (
                <div>
                    {showForm && (
                        <Form onFinish={submitFilters} ref={formRef2}>
                            <Form.Item label="Tipo de condición" name="typeFilter" rules={[{ required: true }]} initialValue={{ value: props.filterType ?? "D", }}>
                                <Select placeholder="Tipo de condición" labelInValue defaultValue={{ value: props.filterType ?? "D", }}
                                    style={{ width: "100%", }} placeholder="Plantillas" onChange={(option) => setFilterType(option.key)}>
                                    <Option key="D">Cumple al menos una</Option>
                                    <Option key="C">Cumple todas</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="unpaid_ballot" valuePropName="checked" initialValue={props.unpaid}>
                                <Checkbox checked={unpaid} onChange={(e) => setUnpaid(e.target.checked)}>
                                    Boleta Impaga
                            </Checkbox>
                            </Form.Item>
                            <div>
                                <div>
                                    <Form ref={formRef3} onFinish={() => { }}>
                                        {showFilters &&
                                            filtros.map((element, index) => {
                                                let defaultValue1 = Object.keys(firstFilter).find((key) => firstFilter[key] === element[0]);
                                                let defaultValue2 = Object.keys(filtersTranslation).find((key) => filtersTranslation[key] === element[1]);
                                                return (
                                                    <Row>
                                                        <Form.Item rules={[{ required: true, },]} style={{ width: "35%", }} initialValue={defaultValue1} >
                                                            <Select
                                                                style={{ width: "100%", }}
                                                                placeholder="Tipo de condición"
                                                                labelInValue
                                                                defaultValue={{ value: defaultValue1, }}
                                                                placeholder="Plantillas"
                                                                aria-required
                                                                onChange={(option) => {
                                                                    setShowFilters(false);
                                                                    updateFilter(option["value"], index, 0);
                                                                }}
                                                            >
                                                                {filterTypeList}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            rules={[{ required: true, },]}
                                                            style={{ width: "20%", }} >
                                                            <Select
                                                                style={{ width: "100%", marginLeft: "10px", marginRight: "10px", }}
                                                                placeholder="Tipo de condición"
                                                                labelInValue
                                                                defaultValue={{ value: defaultValue2, }}
                                                                placeholder="Plantillas"
                                                                onChange={(option) => {
                                                                    setShowFilters(false);
                                                                    updateFilter(filtersTranslation[option["value"]], index, 1);
                                                                }}
                                                            >
                                                                {getSecondfilterOptions(Object.keys(firstFilter).find((key) => firstFilter[key] === element[0]))}
                                                            </Select>
                                                        </Form.Item>
                                                        {{
                                                            bool: selectInputfield(element[2], index),
                                                            str: strInputField(element[2], index),
                                                            list: selectInputfield(element[2], index, lists[element[0]]),
                                                            int: intInputField(element[2], index),
                                                            date: {
                                                                year: intInputField(element[2], index),
                                                                month: intInputField(element[2], index),
                                                                day: intInputField(element[2], index),
                                                                week: intInputField(element[2], index),
                                                                quarter: intInputField(element[2], index),
                                                                equal: dateInputField("2020/10/03", index),
                                                                gt: dateInputField("2020/10/03", index),
                                                                gte: dateInputField("2020/10/03", index),
                                                                lt: dateInputField("2020/10/03", index),
                                                                lte: dateInputField("2020/10/03", index),
                                                                different: dateInputField("2020/10/03", index),
                                                                week_day: selectInputfield(
                                                                    element[2], index,
                                                                    { 0: "Lunes", 1: "Martes", 2: "Miercoles", 3: "Jueves", 4: "Viernes", 5: "Sábado", 6: "Domingo", }),
                                                            }[element[1]],
                                                        }[secondFilter[Object.keys(firstFilter).find((key) => firstFilter[key] === element[0])]]
                                                        }
                                                        <Form.Item style={{ marginLeft: "10px" }} >
                                                            <Button type="primary" danger
                                                                style={{ marginLeft: "10px", }}
                                                                onClick={() => {
                                                                    setShowFilters(false);
                                                                    deleteFilter(index);
                                                                }}
                                                            >X</Button>
                                                        </Form.Item>
                                                    </Row>
                                                );
                                            })}
                                    </Form>
                                </div>
                                <Form.Item>
                                    <Button type="primary" onClick={() => { addFilter(); setShowFilters(true); }} className='center-btn'>
                                        Agregar filtro
                                    </Button>
                                </Form.Item>
                                <Button loading={props.loading} type="primary" onClick={() => props.getFilters ? can(typifications.create) && formRef2.current.submit() : props.editing ? can(massive.edit) && formRef2.current.submit() : can(massive.create) && formRef2.current.submit()} className='center-btn'>
                                    Guardar
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
            )}
        </div>
    )

}

export default MassiveFilters;