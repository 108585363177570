import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "../helpers/helpers";

const ProtectedRoute = ({ component: Component, auth, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			auth || isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect to="/login" />
			)
		}
	/>
);

export default ProtectedRoute;
