import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Select, Collapse, Row } from 'antd';
import Moment from "react-moment"
import moment from 'moment';
import HttpRequest from './../../services/HttpRequest';
import errorModal from './../../components/modal/errorModal';
import autoCloseModal from './../../components/modal/AutoCloseModal';
import Config from "../../config";

function Step3(props) {
    const [trellos, setTrellos] = useState({});
    const [commits, setCommits] = useState({});
    const [id, setId] = useState(1);
    const [loading, setLoading] = useState(false);
    const [disabledFields, setDisabledFiels] = useState({})
    const [systems, setSystems] = useState([]);

    const submit = e => {
        setLoading(true)
        const trelloUrls = [];
        const commitIds = [];
        for (let key in e) {
            if (key.includes('trello')) {
                trelloUrls.push(e[key])
            } else if (key.includes('commit')) {
                commitIds.push(e[key])
            }
        }
        const body = {
            trello_urls: trelloUrls,
            commits: commitIds
        };
        if (e.internal_status !== props.ticketInfo.internal_status) {
            body.internal_status = e.internal_status
        }
        if (e.internal_status === 0) { //si ticket esta en proceso, status general es en proceso
            body.status = 3;
        }
        if (e.system && e.system !== props.ticketInfo.assigned_team) {
            body.assigned_team = e.system;  //escalo a sistema, agente nulo y estado en pausa
            body.agent = null;
            body.internal_status = 1;
            body.status = 1;
        }
        fetch(Config.API_URL + '/tech_support/ticket/' + props.ticket + '/', {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`
            },
            body: JSON.stringify(body)
        }).then(res => res.json()).then(res => {
            setLoading(false)
            if (res.ID) {
                props.updateRows && props.updateRows()
                props.setTicketInfo(res)
                if (e.system && e.system !== props.ticketInfo.assigned_team) {
                    props.setStep(2)
                }
                autoCloseModal('Se ha editado el ticket con éxito')
            } else {
                errorModal('Ha ocurrido un error actualizando el ticket')
            }
        }).catch(err => {
            errorModal('Ha ocurrido un error actualizando el ticket')
        })
    }
    const rejectTicket = () => {
        setLoading(true);
        fetch(Config.API_URL + '/tech_support/ticket/' + props.ticket + '/', {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`
            },
            body: JSON.stringify({
                internal_status: 1,
                status: 2,
                agent: null
            })
        }).then(res => res.json()).then(res => {
            setLoading(false)
            if (res.ID) {
                props.updateRows && props.updateRows()
                props.setTicketInfo(res)
                props.setStep(1)
                autoCloseModal('Se ha rechazado el ticket con éxito')
            } else {
                errorModal('Ha ocurrido un error rechanzando el ticket')
            }
        }).catch(err => {
            errorModal('Ha ocurrido un error rechanzando el ticket')
        })
    }
    const addTrello = (initialVal = null) => {
        setId(id => {
            setTrellos(prevTrellos => {
                const trelloCopy = { ...prevTrellos };
                trelloCopy[`trello${id}`] = initialVal;
                return trelloCopy
            })
            return id + 1
        })
    }
    const deleteTrello = trelloKey => {
        setTrellos(prevTrellos => {
            const trelloCopy = { ...prevTrellos };
            delete trelloCopy[trelloKey]
            return trelloCopy
        })
    }
    const addCommit = (initialVal = null) => {
        setId(id => {
            setCommits(prevCommits => {
                const commitCopy = { ...prevCommits };
                commitCopy[`commit${id}`] = initialVal;
                return commitCopy
            })
            return id + 1
        })
    }
    const deleteCommit = commitKey => {
        setCommits(prevCommits => {
            const commitCopy = { ...prevCommits };
            delete commitCopy[commitKey]
            return commitCopy
        })
    }

    useEffect(() => {
        props.ticketInfo.commits.forEach(commit => addCommit(commit));
        props.ticketInfo.trello_urls.forEach(trello => addTrello(trello));
    }, [])

    useEffect(() => {
        const isTicketOwner = props.ticketInfo.agent_name === JSON.parse(localStorage.getItem('userInfo')).username
        const disabledItems = {
            internal_status: !isTicketOwner || props.ticketInfo.internal_status === 2,
            input: !isTicketOwner || props.ticketInfo.internal_status !== 0
        }
        setDisabledFiels(disabledItems);
    }, [props.ticketInfo])

    useEffect(() => {
        HttpRequest.endpoint = 'tech_support/ticket/system_groups/';
        HttpRequest.get().then(res => {
            if (res && Array.isArray(res)) {
                setSystems(res)
            } else {
                errorModal('Ha ocurrido un error obteniendo la lista de sistemas')
            }
        })
    }, [])

    return (
        <Form onFinish={submit}>
            <p className='center-text'>Una vez solucionado el problema, agregue las tarjetas de Trello y Commits (si aplica) </p>
            <p className='center-text'><strong>SLA: </strong><Moment fromNow ago locale='en'>{moment().add(-1 * props.ticketInfo.SLA, 's')}</Moment> abierto</p>
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel forceRender header='General' key='1'>
                    <Form.Item name='internal_status' label='Estado' initialValue={props.ticketInfo.internal_status}>
                        <Select disabled={disabledFields.internal_status}>
                            <Select.Option value={0}>En proceso</Select.Option>
                            <Select.Option value={1}>Pausado</Select.Option>
                            <Select.Option value={3}>Pendiente</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='system' label='Escalar a'>
                        <Select disabled={disabledFields.input} placeholder='Seleccionar sistema'>
                            {systems.map(sys => <Select.Option value={sys.ID} key={sys.ID}>{sys.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Button loading={loading} disabled={disabledFields.internal_status} htmlType='submit' type='primary' className='center-btn'>Guardar</Button>
                    <Row justify='center'>
                        <Button disabled={disabledFields.input} type='link' onClick={rejectTicket}>Rechazar ticket</Button>
                    </Row>
                </Collapse.Panel>
                <Collapse.Panel forceRender header='Trello' key='2'>
                    <Button disabled={disabledFields.input} onClick={() => addTrello()} className='center-btn'>Agregar link de trello</Button>
                    {Object.keys(trellos).map(trello => {
                        return (
                            <div key={trello} className='ticket-flex'>
                                <Form.Item className='ticket-flex-input' name={trello} initialValue={trellos[trello]} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                    <Input disabled={disabledFields.input} placeholder='Link del trello' />
                                </Form.Item>
                                <Button disabled={disabledFields.input} type='primary' danger onClick={() => deleteTrello(trello)}>X</Button>
                            </div>
                        )
                    })}
                    <Button loading={loading} disabled={disabledFields.internal_status} htmlType='submit' type='primary' className='center-btn'>Guardar</Button>
                </Collapse.Panel>
                <Collapse.Panel forceRender header='Commits' key='3'>
                    <Button onClick={() => addCommit()} className='center-btn' disabled={disabledFields.input}>Agregar commit</Button>
                    {Object.keys(commits).map(commit => {
                        return (
                            <div key={commit} className='ticket-flex'>
                                <Form.Item className='ticket-flex-input' name={commit} initialValue={commits[commit]} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                    <Input disabled={disabledFields.input} placeholder='Commit' />
                                </Form.Item>
                                <Button disabled={disabledFields.input} type='primary' danger onClick={() => deleteCommit(commit)}>X</Button>
                            </div>
                        )
                    })}
                    <Button loading={loading} disabled={disabledFields.internal_status} htmlType='submit' type='primary' className='center-btn'>Guardar</Button>
                </Collapse.Panel>
            </Collapse>
        </Form>
    )
}

export default Step3;