import React, { useState, useEffect } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { Button, Modal } from 'antd';
import OrganizationChart from "@dabeng/react-orgchart";
import CategoryForm from './CategoryForm';
import HttpRequest from '../../services/HttpRequest';
import './Categories.css';
import Moment from 'react-moment';
import { typifyCategories } from '../../config/permissions';

function Categories(props) {
    const [categoryTree, setCategoryTree] = useState(null);
    const [categoryInfo, setCategoryInfo] = useState(null);
    const [forceReload, setForceReload] = useState(false);
    const [treeNode, setTreeNode] = useState(null);
    const [deletedNodeID, setDeletedNodeID] = useState(null);

    const tableEndpoints = {
        columns: "tickets/category/datatables_struct/",
        rows: "tickets/category/datatables/",
        delete: "tickets/category/",
    }
    const formEndpoint = 'tickets/category/'

    const getCategoryTree = id => {
        if (deletedNodeID !== id) {
            if (categoryInfo === null && id) {
                HttpRequest.endpoint = props.endpoints ? props.endpoints.formEndpoint + id + '/tree/' : formEndpoint + id + '/tree/';
                HttpRequest.get().then(res => {
                    setCategoryTree(res)
                })
            }
            setTreeNode(id);
        } else {
            setCategoryTree(null);
            setForceReload(prevState => !prevState);
            setTreeNode(null)
        }
    }
    const getCategoryData = id => {
        let endpoint = props.endpoints ? props.endpoints.formEndpoint : formEndpoint;
        endpoint = endpoint.split('');
        endpoint.pop();
        endpoint = endpoint.join('');
        HttpRequest.endpoint = endpoint;
        HttpRequest.get(null, id).then(res => {
            setCategoryInfo(res);
        })
    }
    const customRendering = (text, value, id) => {
        switch (value) {
            case "created":
                return <Moment fromNow locale="es">{text}</Moment>;
            case "custom_change":
                return <Button type='primary' onClick={() => getCategoryTree(id)}>Ver</Button>
            default:
                break;
        }
    }

    useEffect(() => getCategoryTree(treeNode), [categoryInfo])

    return (
        <>
            <FullTable permissions={props.permissions || typifyCategories} forceReload={forceReload} customRendering={{ renderFunction: customRendering }} modalContent={<CategoryForm endpoint={props.endpoints ? props.endpoints.formEndpoint : formEndpoint} permissions={props.permissions || typifyCategories} />} endpoints={props.endpoints ? props.endpoints.tableEndpoints : tableEndpoints} tableName={props.tableName || 'categoría'} />
            {categoryTree &&
                <>
                    <Modal width={920} title='Arbol de categorías' visible={categoryTree} onCancel={() => { setCategoryTree(null); setForceReload(prevState => !prevState); setTreeNode(null) }} footer={null}>
                        <OrganizationChart pan={true} zoom={true} datasource={categoryTree} onClickNode={e => getCategoryData(e.id)} containerClass='container-tree' />
                    </Modal>
                    <Modal destroyOnClose title='Detalles' visible={categoryInfo} onCancel={() => setCategoryInfo(null)} footer={null}>
                        <CategoryForm updateRows={() => setForceReload(!forceReload)} endpoint={props.endpoints ? props.endpoints.formEndpoint : formEndpoint} permissions={props.permissions || typifyCategories} categoryInfo={categoryInfo} setDeletedNodeID={setDeletedNodeID} />
                    </Modal>
                </>
            }
        </>
    )
}

export default Categories;