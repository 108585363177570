import React from "react";
import SearchTables from "../TypificationsTables/SearchTables";
import RetentionsTable from "./RetentionsTable";

function RetentionsSearchTables() {
	const endpoint = "retentions/retention_table/";
	return (
		<>
			<SearchTables endpoint={endpoint} component={<RetentionsTable />} />
		</>
	);
}

export default RetentionsSearchTables;
