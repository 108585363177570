import React from 'react'
import styled from 'styled-components'

const ImageUI = styled.img`
	max-width: 100%;
`

const Image = ({src, alt, className, ...rest}) => (
	<ImageUI src={src} alt={alt} className={className} {...rest} />
);

export default Image