import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { slackMessage } from '../../config/permissions';
import { endpointTypifications, endpointTypificationsStruct, endpointTypificationsDatatable } from "../../services/endpoints";
import TypificationSlackForm from './TypificationSlackForm';

function TypificationsSlack(props) {
    const tableEndpoints = {
        columns: endpointTypificationsStruct,
        rows: endpointTypificationsDatatable,
        delete: endpointTypifications,
    }
    const formEndpoints = {
        message: 'tickets/typify_message',
        categories: "tickets/category/",
        slackChannels: "slackapp/conversations.list",
        fieldOptions: 'tickets/typify_message/fields_options/'
    }

    return (
        <>
            <FullTable
                permissions={props.permissions || slackMessage}
                modalContent={<TypificationSlackForm typify={props.endpoints ? false : true} endpoints={props.endpoints ? props.endpoints.formEndpoints : formEndpoints} permissions={props.permissions || slackMessage} />}
                endpoints={props.endpoints ? props.endpoints.tableEndpoints : tableEndpoints}
                tableName='notificación de slack'
            />
        </>
    )
}

export default TypificationsSlack;