import React, { useState, useEffect } from 'react';
import { Panel, PanelBody } from "../../components/panel/panel";
import { connect } from "react-redux";
import PaymentDelayWiz from './PaymentDelayWiz';
import ActivationLatePaymentWiz from './ActivationLatePaymentWiz';
import './PaymentWizards.css';
import Typify from '../typify/typify';
import PaymentAmountWiz from './PaymentAmountWiz';
import Wizard from "../wizard/Wizard";
import ProcessSelector from './../WizardConfigurations/ProcessSelector';
import HttpRequest from '../../services/HttpRequest';
import { Select } from 'antd';

function WizardController(props) {
    const [currentStep, setCurrentStep] = useState(1);
    const [tipifyData, setTipifyData] = useState(null);
    const [selectedWizard, setSelectedWizard] = useState(null);
    const [processes, setProcesses] = useState([]);
    const [processList, setProcessList] = useState([]);
    const [selectedProcess, setSelectedProcess] = useState(null)

    const startAgain = () => {
        setCurrentStep(1);
        setTipifyData(null);
        setSelectedWizard(null);
    }
    const receiveTipification = name => {
        if (name !== 'Otro') {
            processList.forEach(proc => {
                if (proc.name === name) {
                    setTipifyData(proc.description)
                    setSelectedProcess(proc.ID)
                }
            })
        }
        setSelectedWizard(name);
        setCurrentStep(currentStep + 1)
    }

    const wizardNames = {
        "Otro": <Typify updateRows={props.updateRows} startAgain={startAgain} />,
        "Monto a pagar": <PaymentAmountWiz process={selectedProcess} updateRows={props.updateRows} categories={tipifyData} startAgain={startAgain} />,
        "Prorroga de pago": <PaymentDelayWiz process={selectedProcess} updateRows={props.updateRows} categories={tipifyData} startAgain={startAgain} />,
        "Activación pago tardio / Prorroga de pago": <ActivationLatePaymentWiz process={selectedProcess} updateRows={props.updateRows} categories={tipifyData} startAgain={startAgain} />,
        "Factibilidad": <Wizard process={selectedProcess} updateRows={props.updateRows} categories={tipifyData} typify={true} typifyReset={startAgain} />,
    }

    const steps = [
        {
            content: <ProcessSelector receiveProcess={receiveTipification} processes={processes} />
        },
        {
            content: wizardNames[selectedWizard]
        }
    ];

    useEffect(() => {
        setProcesses([])
        setProcessList([])
        HttpRequest.endpoint = 'tickets/process/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                const processList = [];
                res.forEach(proc => {
                    processList.push(<Select.Option key={proc.name} value={proc.name}>{proc.name}</Select.Option>)
                })
                processList.push(<Select.Option key={'Otro'} value={'Otro'}>Otro</Select.Option>)
                setProcesses(processList)
                setProcessList(res)
            }
        })
    }, [props.operator])

    return (
        <>
            <Panel>
                <PanelBody>
                    {<div className="steps-content">{steps[currentStep - 1].content}</div>}
                </PanelBody>
            </Panel>
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(WizardController);