import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import DriveTable from './DriveTable';

function DrivesSearchTables() {
    const endpoint = 'drives/definition/';
    return (
        <>
            <SearchTables endpoint={endpoint} component={<DriveTable />} />
        </>
    )
}

export default DrivesSearchTables;