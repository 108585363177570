import store from "./../redux/store";
import { Modal } from 'antd';

export const isAuthenticated = () => {
    const session = sessionStorage.getItem("authorization");
    const local = localStorage.getItem("authorization");
    const state = store.getState("token");
    if (session === null && local === null && state.token === null) {
        console.log('isauth retorna falso')
        return false;
    }

    return (
        (session && session.length > 10) ||
        (local && local.length > 10) ||
        (state && state.token && state.token.length > 10)
    );
};

export const today = (format = "Y-m-d") => {
    let _format = format;
    const date = new Date();
    const year = date.getFullYear();
    const month =
        date.getMonth().toString().length === 1
            ? `0${Number(date.getMonth() + 1)}`
            : Number(date.getMonth() + 1);
    const day =
        date.getDate().toString().length === 1
            ? `0${date.getDate()}`
            : date.getDate();
    _format = _format.replace("Y", year);
    _format = _format.replace("m", month);
    _format = _format.replace("d", day);
    return _format;
};

const size = {
    mobile: "425px",
    tablet: "768px",
    desktop: "1024px",
};

export const device = {
    mobile: `(min-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    desktop: `(min-width: ${size.desktop})`,
};

export const validRut = {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    validateRut: function (rutCompleto) {
        const rutCompleto2 = rutCompleto.replace(/\./g, '')
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto2))
            return false;
        const tmp = rutCompleto2.split('-');
        let digv = tmp[1];
        const rut = tmp[0];
        if (digv == 'K') digv = 'k';
        return (validRut.dv(rut) == digv);
    },
    dv: function (T) {
        let M = 0, S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        return S ? S - 1 : 'k';
    }
}
export const validOperator = () => {
    const op = store.getState().operator;
    if (!op || op == 0) {
        Modal.error({
            title: "Error",
            content: "Por favor seleccione un operador válido",
        });
        return false;
    } else {
        return true
    }
}
export const can = permission => {
    const userPermissions = localStorage.getItem("permissions");
    const parsedUserPermissions = JSON.parse(userPermissions);
    if (!parsedUserPermissions || parsedUserPermissions.error) {
        Modal.error({
            title: 'Error',
            content: 'Por favor cierre sesión e ingrese nuevamente'
        })
        return false
    }
    if (parsedUserPermissions.includes(permission)) {
        return true
    } else {
        Modal.error({
            title: 'Error',
            content: 'Usted no tiene permiso para realizar esta acción.'
        })
        return false
    }
}
export const canWithoutMessage = permission => {
    const userPermissions = localStorage.getItem("permissions");
    const parsedUserPermissions = JSON.parse(userPermissions)
    if (!parsedUserPermissions) {
        Modal.error({
            title: 'Error - Permisos',
            content: 'Por favor cierre sesión e ingrese nuevamente'
        })
        return false
    }
    if (parsedUserPermissions.includes(permission)) {
        return true
    } else {
        return false
    }
}
export const canGroup = group => {
    const userGroups = localStorage.getItem("groups");
    const parsedUserGroups = JSON.parse(userGroups);
    if (!parsedUserGroups) {
        Modal.error({
            title: 'Error - Grupos',
            content: 'Por favor cierre sesión e ingrese nuevamente'
        })
        return false
    }
    if (parsedUserGroups.includes(group)) {
        return true
    } else {
        return false
    }
}
