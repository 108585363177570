import React, { useState, useRef } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { drives } from '../../config/permissions';
import DriveForm from './DriveForm';
import { Input, Modal, Select, InputNumber, DatePicker } from 'antd';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import RutField from './RutField';
import LocationForm from '../../components/form/locationInput';
import { connect } from 'react-redux';
import Config from "../../config";

function DriveTable(props) {
    const [tableRows, setTableRows] = useState([]);
    const [rutInfo, setRutInfo] = useState(null);
    const [phone, setPhone] = useState(null);
    const endpoints = {
        columns: 'drives/register/' + props.tableID + '/datatables_struct/',
        rows: 'drives/register/' + props.tableID + '/datatables/',
        delete: 'drives/register/',
        download: 'drives/register/' + props.tableID + '/download_data/',
    }
    const phoneRef = useRef();
    phoneRef.current = phone;

    const handleEdit = ({ name, value, required, id }) => {
        if (required && !value) {
            return Modal.error({
                title: 'Error',
                content: 'La columna ' + name + ' es requerida'
            })
        }
        fetch(Config.API_URL + '/drives/register/' + id + '/update_field_value/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            },
            body: JSON.stringify({
                fields: name,
                value: value
            })
        }).then(res => res.json()).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }
    const specialValidation = (newVal, oldVal) => {
        if (oldVal === null && newVal === '') {
            return false
        } else {
            return true
        }
    }
    const savePhone = (rowID, oldVal) => {
        const phone = phoneRef.current
        if (phone) {
            if (phone.id === rowID) {
                if (phone.value !== oldVal && specialValidation(phone.value, oldVal)) {
                    handleEdit({ name: phone.name, value: phone.value, required: phone.required, id: phone.id })
                }
            }
        }
    }
    const handleRows = res => {
        const rows = [];
        res.forEach(row => {
            const modifiedRow = {};
            Object.keys(row).forEach(key => {
                let component = row[key];
                if (typeof row[key] === 'object') {
                    switch (row[key].type) {
                        case 'string':
                            component = <Input onBlur={e => e.target.value !== row[key].value && specialValidation(e.target.value, row[key].value) && handleEdit({ name: key, value: e.target.value, required: row[key].required, id: row.ID })} defaultValue={row[key].value} />;
                            break;
                        case 'int':
                            component = <InputNumber className='total-width' onBlur={e => e.target.value !== '' && Number(e.target.value) !== row[key].value && handleEdit({ name: key, value: Number(e.target.value), required: row[key].required, id: row.ID })} defaultValue={row[key].value} />;
                            break;
                        case 'float':
                            component = <InputNumber className='total-width' onBlur={e => e.target.value !== '' && Number(e.target.value) !== row[key].value && Number(e.target.value) && handleEdit({ name: key, value: Number(e.target.value), required: row[key].required, id: row.ID })} defaultValue={row[key].value} step={0.01} />;
                            break;
                        case 'phone':
                            component = <PhoneInput defaultCountry={row[key].value ? parsePhoneNumber(row[key].value).country : 'CL'} value={row[key].value && parsePhoneNumber(row[key].value).nationalNumber} onBlur={() => savePhone(row.ID, row[key].value)} onChange={val => setPhone({ name: key, value: val, required: row[key].required, id: row.ID })} />;
                            break;
                        case 'email':
                            component = <Input onBlur={e => e.target.value !== row[key].value && specialValidation(e.target.value, row[key].value) && handleEdit({ name: key, value: e.target.value, required: row[key].required, id: row.ID })} defaultValue={row[key].value} />;
                            break;
                        case 'datetime':
                            component = <DatePicker defaultValue={row[key].value && moment(row[key].value, 'YYYY/MM/DD HH:MM:ss')} onChange={e => handleEdit({ name: key, value: e ? e.format('YYYY/MM/DD HH:MM:ss') : null, required: row[key].required, id: row.ID })} format='DD/MM/YYYY HH:MM:ss' />;
                            break;
                        case 'logic':
                            component = <Select className='total-width' onChange={val => handleEdit({ name: key, value: val, id: row.ID })} defaultValue={row[key].value === 'Verdadero' ? true : false}>
                                <Select.Option value={true} key={'1'}>Verdadero</Select.Option>
                                <Select.Option value={false} key={'2'}>Falso</Select.Option>
                            </Select>;
                            break;
                        case 'select_dynamic_plans':
                            component = <Select className='total-width' defaultValue={row[key].value} onChange={val => handleEdit({ name: key, value: val, id: row.ID })}>
                                {row[key].values.map(val => <Select.Option value={val.value} key={val.value}>{val.name}</Select.Option>)}
                            </Select>;
                            break;
                        case 'select_dynamic_users_iris':
                            component = <Select className='total-width' onChange={val => handleEdit({ name: key, value: val, id: row.ID })} defaultValue={row[key].value}>
                                {row[key].values.map(val => <Select.Option value={val.value} key={val.value}>{val.name}</Select.Option>)}
                            </Select>;
                            break;
                        case 'select_dynamic_services':
                            component = <InputNumber className='total-width' onBlur={e => e.target.value !== '' && Number(e.target.value) !== row[key].value && handleEdit({ name: key, value: Number(e.target.value), required: row[key].required, id: row.ID })} defaultValue={row[key].value} />;
                            break;
                        case 'select_static':
                            component = <Select className='total-width' onChange={val => handleEdit({ name: key, value: val, id: row.ID })} defaultValue={row[key].value}>
                                {row[key].values.map(val => <Select.Option value={val.value} key={val.value}>{val.name}</Select.Option>)}
                            </Select>;
                            break;
                        case 'select_dynamic_location':
                            component = <LocationForm driveValue={row[key].value} className='total-width' drive driveEdit onChange={val => val.split(':')[0] === "streetLocation" && handleEdit({ name: key, value: val.split(':')[1].concat(`:${props.operator}`), id: row.ID })} />;
                            break;
                        case 'select_dynamic_rut':
                            component = <Input defaultValue={row[key].value} onClick={() => setRutInfo({ name: key, value: row[key].value, required: row[key].required, id: row.ID })} />;
                            break;
                        default:
                            component = row[key];
                            break;
                    }
                }
                modifiedRow[key] = component;
            })
            rows.push(modifiedRow)
        })
        setTableRows(rows);
    }

    return (
        <>
            {props.tableID && <FullTable handleRows={handleRows} tableRows={tableRows} permissions={drives.register} modalWidth={800} modalContent={<DriveForm driveID={props.tableID} />} endpoints={endpoints} tableName='drive' />}
            {rutInfo && <Modal visible={rutInfo.name} footer={null} onCancel={() => setRutInfo(null)}>
                <RutField defaultValue={rutInfo.value} onChange={val => handleEdit(rutInfo.name, val, rutInfo.required, rutInfo.id)} />
            </Modal>}
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(DriveTable);