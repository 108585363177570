import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import { PageSettings } from "./../config/page-settings.js";
import LoginSVG from "../assets/images/login.svg";
import { isAuthenticated } from "./../helpers/helpers";
import AuthenticationService from "./../services/AuthenticationService";
import ACTIONS from "../redux/creator";

class RecoveryPassword extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLogged: false,
            message: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.context.handleSetPageSidebar(false);
        this.context.handleSetPageHeader(false);
        this.setState({ isLogged: isAuthenticated() });
    }

    componentWillUnmount() {
        this.context.handleSetPageSidebar(true);
        this.context.handleSetPageHeader(true);
    }

    validateForm(event) {
        if (event.target.username.value.length < 1) {
            this.setState({
                error: "El username no puede estar vacío",
            });
            return false;
        }
        return true;
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (this.validateForm(event)) {
            AuthenticationService.recoverPassword(event.target.username.value).then(res => {
                console.log(res)
                if (Object.keys(res).length === 0) {
                    this.setState({
                        message:
                            "Se le ha enviado un correo para recuperar su usuario",
                    });
                } else {
                    this.setState({
                        error: "No se ha podido completar la petición",
                    });
                }
            });
        }
    }

    render() {
        const { error, isLogged, message } = this.state;

        return (
            <React.Fragment>
                {isLogged ? <Redirect to="/" /> : null}
                <div className="login-cover"></div>

                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">Recuperar contraseña</div>
                    </div>
                    <div className="login-content">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-5">
                                    <img src={LoginSVG} alt="Login" />
                                </div>
                                <form
                                    className="col-7 pl-4"
                                    onSubmit={this.handleSubmit}
                                >
                                    <div className="form-group m-b-20">
                                        <input
                                            name="username"
                                            className={`form-control form-control-lg ${error ? "is-invalid" : ""
                                                }`}
                                            placeholder="Username"
                                            autoComplete="none"
                                            required
                                        />
                                    </div>
                                    <div className="login-buttons">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block btn-lg"
                                        >
                                            Enviar
										</button>
                                    </div>
                                    <div className="form-group">
                                        <p
                                            className="text-danger text-center"
                                            style={{
                                                // color: "#ffffff",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {error}
                                        </p>
                                        <p
                                            className="text-center"
                                            style={{
                                                color: "#ffffff",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {message}
                                        </p>
                                    </div>
                                    <div className="form-group text-center mt-2">
                                        <Link to="/login">Volver</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setUser(payload) {
        dispatch(ACTIONS.setUser(payload));
    },
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword)
);
