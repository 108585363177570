import React, { useState, useEffect } from "react";
import { Form, Select, Button, Radio, Spin, Row, Input } from "antd";
import LocationForm from "../../components/form/locationInput";
import HttpRequest from "./../../services/HttpRequest";
import { useForm } from "antd/lib/form/Form";
import { SearchOutlined } from "@ant-design/icons";
import CustomerInfoModal from "../typify/customerInfoModal";
import ServiceInfoModal from "../typify/servicesInfoModal";

function ServiceOrder(props) {
	const [osTypes, setOsTypes] = useState(null);
	const [location, setLocation] = useState(null);
	const [rut, setRut] = useState(null);
	const [customerList, setCustomerList] = useState([]);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [criterion, setCriterion] = useState("service");
	const [customerModal, setCustomerModal] = useState(false);
	const [serviceModal, setServiceModal] = useState(false);
	const [typing, setTyping] = useState(false);
	const [serviceList, setServiceList] = useState([]);
	const [form] = useForm();

	const submitForm = (e) => {
		const selectedOsIndex = Object.keys(osTypes).indexOf(e.type);
		props.setInfo(
			Object.assign(
				{ osTypes },
				{ selectedOsIndex },
				{ location },
				{ service: e.services[0] },
				{ subject: e.subject }
			)
		);
		props.setStep(2);
	};

	const searchOsTypes = (address) => {
		HttpRequest.endpoint = "iclass/service_orders/pulso_os/";
		HttpRequest.get().then((res) => {
			if (res && typeof res === "object") {
				setOsTypes(res);
				setLocation(address);
			}
		});
	};

	const getServices = (rut) => {
		let list = [];
		const formData = {
			customer__rut: rut,
			operator: props.operator,
			fields: "number",
		};
		HttpRequest.endpoint = "matrix/services/";
		HttpRequest.get(formData).then((res) => {
			res.forEach((element) => {
				list.push(
					<Select.Option
						key={element["number"]}
						value={element["number"]}
					>
						{element["number"]}
					</Select.Option>
				);
			});
			setServiceList(list);
			if (list.length === 1) {
				form.setFieldsValue({ services: [res[0].number] });
			}
		});
	};
	const searchUser = (value) => {
		if (value != null && value.length > 0) {
			setCustomerList([]);
			setLoadingUsers(true);
			HttpRequest.endpoint = "matrix/customer_filter/";
			const formData = { criterion: criterion, search: value };
			HttpRequest.get(formData).then((res) => {
				setCustomerList(res);
			});
		}
	};
	const onKeyPress = (e) => {
		const specialCharRegex = new RegExp("^[0-9]*$");
		const pressedKey = String.fromCharCode(
			!e.charCode ? e.which : e.charCode
		);
		if (!specialCharRegex.test(pressedKey)) {
			e.preventDefault();
			return false;
		}
	};
	useEffect(() => {
		if (rut && typing) {
			const delay = setTimeout(() => {
				searchUser(rut);
			}, 1500);
			return () => clearTimeout(delay);
		}
	}, [rut]);

	useEffect(() => {
		if (form.getFieldValue("customer_rut") != null) {
			getServices(form.getFieldValue("customer_rut")["key"]);
		}
		form.setFieldsValue({
			services: undefined,
		});
	}, [props.operator]);

	return (
		<>
			<Form name="service-order" onFinish={submitForm} form={form}>
				<div>
					<div className="radio-group-div">
						<Form.Item>
							<Radio.Group
								className="radio-group"
								onChange={(e) => setCriterion(e.target.value)}
								defaultValue={"service"}
							>
								<Radio value={"rut"}>Rut</Radio>
								<Radio value={"name"}>Nombre</Radio>
								<Radio value={"composite_address"}>
									Dirección
								</Radio>
								<Radio value={"service"}>Servicio</Radio>
							</Radio.Group>
						</Form.Item>
					</div>
				</div>
				<Row justify="center">
					<div className="so-rut-container">
						<Form.Item
							className="so-rut"
							label="Cliente:"
							name="customer_rut"
						>
							{criterion == "service" ? (
								<Select
									className="so-rut-select"
									onKeyPress={onKeyPress}
									placeholder="Ingrese el número de servicio"
									showSearch
									notFoundContent={
										loadingUsers ? (
											<Spin size="small" />
										) : null
									}
									filterOption={false}
									onSearch={(val) => {
										setTyping(true);
										setRut(val);
									}}
									onChange={(value) => {
										getServices(value);
										setTyping(false);
										setRut(value);
										form.setFieldsValue({
											services: undefined,
										});
									}}
								>
									{customerList.map((element) => (
										<Select.Option
											key={element.id}
											value={element.id}
										>
											{element.text}
										</Select.Option>
									))}
								</Select>
							) : (
								<Select
									className="so-rut-select"
									placeholder={
										criterion == "rut"
											? "Ingrese el rut"
											: criterion == "composite_address"
											? "Ingrese la dirección"
											: "Ingrese el nombre del cliente"
									}
									showSearch
									notFoundContent={
										loadingUsers ? (
											<Spin size="small" />
										) : null
									}
									filterOption={false}
									onSearch={(val) => {
										setTyping(true);
										setRut(val);
									}}
									onChange={(value) => {
										getServices(value);
										setTyping(false);
										setRut(value);
										form.setFieldsValue({
											services: undefined,
										});
									}}
								>
									{customerList.map((element) => (
										<Select.Option
											key={element.id}
											value={element.id}
										>
											{element.text}
										</Select.Option>
									))}
								</Select>
							)}
						</Form.Item>
						<Button
							shape="circle"
							icon={<SearchOutlined />}
							onClick={() => setCustomerModal(true)}
						/>
					</div>
					<div className="so-rut-container">
						<Form.Item
							className="so-rut"
							label="Servicios"
							name="services"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Select placeholder="Servicio" mode="multiple">
								{serviceList}
							</Select>
						</Form.Item>
						<Button
							shape="circle"
							icon={<SearchOutlined />}
							onClick={() => setServiceModal(true)}
						/>
					</div>
				</Row>
				<Form.Item
					label="Dirección"
					name="address"
					rules={[
						{ required: true, message: "Este campo es requerido" },
					]}
				>
					<LocationForm
						className="full-width"
						drive
						driveEdit
						onChange={(val) => searchOsTypes(val)}
					/>
				</Form.Item>
				{osTypes && (
					<>
						<Form.Item
							label="Tipo"
							name="type"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Select placeholder="Seleccione">
								{Object.keys(osTypes).map((osName) => (
									<Select.Option key={osName} value={osName}>
										{osName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Asunto"
							name="subject"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input />
						</Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="center-btn"
						>
							Ver disponibilidad
						</Button>
					</>
				)}
			</Form>
			{customerModal && (
				<CustomerInfoModal
					visible={customerModal}
					setVisible={setCustomerModal}
					rut={rut}
				/>
			)}
			{serviceModal && (
				<ServiceInfoModal
					visible={serviceModal}
					setVisible={setServiceModal}
					rut={rut}
				/>
			)}
		</>
	);
}

export default ServiceOrder;
