import React, { useState } from 'react'
import Icon from '../icons/Icon'

export const  PanelAccordion = props => (
	<div className="panel-accordion">
		{props.children}
	</div>
)

export const PanelAccordionGroup = props => {
	const [isOpen, toggle] = useState(false)

	return (
		<div className="panel-accordion__group">
			<div className={`panel-accordion__group-title clickable ${isOpen ? "open" : ""}`} onClick={() => toggle(!isOpen)}>
				<Icon icon="plus" />
				{props.title}
			</div>
			<div className={`panel-accordion__group-items ${isOpen ? "open" : ""}`}>
				{props.children}
			</div>
		</div>
	)
}

export const PanelAccordionItem = props => (
	<div className="panel-accordion__item">
		{props.children}
	</div>
)