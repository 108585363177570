import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { qc } from '../../config/permissions';
import MonitoreoForm from './MonitoreoForm';
import { Modal, Button } from 'antd';
import MonitoreoRegisterTable from './MonitoreoRegisterTable';

function MonitoreoTable() {
    const [tableRows, setTableRows] = useState([]);
    const [monitoreoData, setMonitoreoData] = useState(null);

    const endpoints = {
        delete: 'qc/monitoring/',
        columns: 'qc/monitoring/datatables_struct/',
        rows: 'qc/monitoring/datatables/',
        download: 'qc/monitoring/download_data/',
    };

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            add_record: <Button type='primary' onClick={() => setMonitoreoData({ id: row.ID, closed: row.status === 'CERRADO' })}>Ver</Button>
        })))
    }

    return (
        <>
            <FullTable
                handleRows={handleRows}
                tableRows={tableRows}
                permissions={qc.monitoreo}
                modalContent={<MonitoreoForm />}
                endpoints={endpoints}
                tableName='monitoreo'
            />
            {monitoreoData &&
                <Modal width='90%' footer={null} visible={monitoreoData} onCancel={() => setMonitoreoData(null)}>
                    <MonitoreoRegisterTable monitoreoID={monitoreoData.id} closed={monitoreoData.closed} />
                </Modal>
            }
        </>
    )
}

export default MonitoreoTable;