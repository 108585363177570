import React, { useState, useEffect } from 'react';
import { Button, Row, Modal } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import TimePicker from "../iclass/TimePicker";

function IclassOrder(props) {

    const [iframeLoad, setIframeLoad] = useState({ url: 'https://fs2.iclass.com.br/iclassfs/restrict/ativo_search.seam', loaded: 0 })
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState(null)
    const [iclass, setIclassId] = useState(null)

    const getServices = () => {
        HttpRequest.endpoint = "formerCustomers/remove_equipament/" + props.id + "/"
        HttpRequest.get().then((res) => {
            setServices(res["service"])
        })
    }
    const updateStatus = (status, iclass_id) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("status_agendamiento", status)
        formData.append("iclass_id", iclass_id)
        HttpRequest.endpoint = "formerCustomers/remove_equipament/"
        HttpRequest.patch(props.id, formData).then((res) => {
            setLoading(false)
            if (res["ID"]) {
                props.close(false);
                props.changeReload()
            } else {
                Modal.error({
                    title: "Error",
                    content: "Ha ocurrido un error al actualizar el estado"
                })
            }
        })
    }

    useEffect(() => {
        if (props.id) {
            getServices()
        }
    }, [])

    return (
        <TimePicker remove={true} service={services} updateIclass={setIclassId} updateFunction={(iclass_id) => updateStatus("1", iclass_id)} />
    )
}

export default IclassOrder;
