import React, { useState, useEffect } from "react";
import FullTable from "../../components/datatable/FullTable";
import { Tabs, Tag, Modal } from "antd";
import { connect } from "react-redux";
import Moment from "react-moment";
import Typify from "../typify/typify";
import { typifications } from "../../config/permissions";
import { can } from "../../helpers/helpers";
import WizardController from "./../payment-wizards/WizardController";

function TableTab(props) {
	const [tableRows, setTableRows] = useState([]);
	const [tabID, setTabID] = useState(1);
	const [tabList, setTabList] = useState([]);
	const [tableKey, setTableKey] = useState(0);
	const [modal, setModal] = useState(false);

	const endpoints = {
		columns: "tickets/typify/" + props.tableID + "/datatables_struct/",
		rows: "tickets/typify/" + props.tableID + "/datatables/",
		download: "tickets/typify/" + props.tableID + "/download_data/",
	};
	const customRendering = (text, value, id) => {
		switch (value) {
			case "created":
				return (
					<Moment fromNow locale="es">
						{text}
					</Moment>
				);
			case "custom_change":
				return (
					<div
						style={{
							display: "inline",
							color: "rgb(24, 144, 255)",
							textDecoration: "underline",
							cursor: "pointer",
						}}
						onClick={() => createTab(id)}
					>
						{id}
					</div>
				);
			default:
				break;
		}
	};
	const handleRows = (res) => {
		setTableRows(
			res.map((row) => ({
				...row,
				updated: (
					<Moment fromNow locale="es">
						{row.updated}
					</Moment>
				),
				services: row.services.map((serv) => (
					<Tag key={serv}>{serv}</Tag>
				)),
				rut:
					row.rut.length < 5
						? row.rut.map((rut) => <Tag key={rut}>{rut}</Tag>)
						: row.rut
								.slice(0, 4)
								.map((rut) => <Tag key={rut}>{rut}</Tag>)
								.concat(
									<Tag key="etc">+{row.rut.length - 4}</Tag>
								),
				category: row.category.join(" / "),
			}))
		);
	};
	const createTab = (typeID) => {
		if (can(typifications.view)) {
			setTabID((prevTabID) => {
				setTabList((prevTabList) => {
					let isNew = true;
					prevTabList.forEach((tab) => {
						if (tab.props.tab === typeID) {
							isNew = false;
						}
					});
					if (isNew) {
						return prevTabList.concat([
							<Tabs.TabPane tab={typeID} key={prevTabID}>
								<Typify
									setModal={setModal}
									tabs
									id={typeID}
									closeTab={deleteTab}
									tabID={prevTabID}
								/>
							</Tabs.TabPane>,
						]);
					} else {
						return prevTabList;
					}
				});
				return prevTabID + 1;
			});
		}
	};
	const deleteTab = (tabID) => {
		setTabList((prevTabList) =>
			prevTabList.filter((tab) => Number(tab.key) !== tabID)
		);
	};

	useEffect(() => {
		setTabList([]);
	}, [props.operator]);

	return (
		<>
			<Tabs
				className="typify-tab"
				type="card"
				size="small"
				onChange={(e) => {
					if (e.includes("table")) {
						setTableKey(tableKey + 1);
					}
				}}
			>
				<Tabs.TabPane tab="Tabla" key="table">
					{props.tableID && (
						<FullTable
							permissions={typifications}
							modalWidth={1050}
							customRendering={{
								renderFunction: customRendering,
								keys: ["ID"],
							}}
							key={tableKey}
							handleRows={handleRows}
							tableRows={tableRows}
							modalContent={<Typify />}
							endpoints={endpoints}
							tableName="tipificación"
						/>
					)}
				</Tabs.TabPane>
				{tabList}
			</Tabs>
			<Modal
				width={1050}
				footer={null}
				visible={modal}
				onCancel={() => setModal(false)}
			>
				<Typify closeModal={() => setModal(false)} />
			</Modal>
		</>
	);
}

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(TableTab);
