import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Input, Modal, Select } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { can } from '../../helpers/helpers';
import autoCloseModal from '../../components/modal/AutoCloseModal';

function FinanceEscalationConfigForm(props) {
    const [configData, setConfigData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [submittedNewFormId, setSubmittedNewFormId] = useState(null);
    const [resultOptions, setResultOptions] = useState([]);
    const [editedResults, setEditedResults] = useState([]);
    const [initialOpts, setInitialOpts] = useState([]);
    const formRef = useRef()

    const submitForm = e => {
        HttpRequest.endpoint = props.endpoint + '/';
        const formData = new FormData();
        formData.append('name', e.name);
        formData.append('description', e.description);
        if (props.id) {
            if (!can(props.permissions.edit)) {
                return;
            }
            setButtonLoading(true)
            HttpRequest.patch(props.id, formData).then(res => {
                if (res.ID) {
                    if (props.test) {
                        HttpRequest.endpoint = 'escalation_ti/test_option/'
                        Promise.all(editedResults.map(result => {
                            const formData = new FormData();
                            formData.append('test', res.ID);
                            formData.append('result', result)
                            return HttpRequest.post(formData)
                        })).then(allRes => {
                            autoCloseModal('Se ha editado con éxito')
                            props.closeModal()
                        })
                    } else {
                        autoCloseModal('Se ha editado con éxito')
                        props.closeModal()
                    }
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error editando'
                    })
                    setButtonLoading(false);
                }
            })
        } else {
            if (!can(props.permissions.create)) {
                return;
            }
            setButtonLoading(true)
            HttpRequest.post(formData).then(res => {
                if (res.ID) {
                    setSubmittedNewFormId(res.ID);
                    if (props.test) {
                        HttpRequest.endpoint = 'escalation_ti/test_option/'
                        Promise.all(e.results.map(result => {
                            const formData = new FormData();
                            formData.append('test', res.ID);
                            formData.append('result', result)
                            return HttpRequest.post(formData)
                        })).then(allRes => {
                            autoCloseModal('Se ha creado con éxito')
                            props.closeModal()
                        })
                    } else {
                        autoCloseModal('Se ha creado con éxito')
                        props.closeModal()
                    }
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error creando'
                    })
                    setButtonLoading(false);
                    setDisabledButton(true)
                }
            })
        }
    }
    const getData = () => {
        if (props.id) {
            HttpRequest.endpoint = props.endpoint
            HttpRequest.get(null, props.id).then(res => {
                if (res) {
                    setConfigData(res)
                    if (props.test) {
                        const resOpts = [];
                        const initialOpts = [];
                        res.results.forEach(result => {
                            resOpts.push(<Select.Option key={result.id} value={result.id}>{result.result}</Select.Option>)
                            initialOpts.push(result.id)
                        })
                        setInitialOpts(initialOpts);
                        setResultOptions(resOpts);
                    }
                    setLoading(false)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo la información'
                    })
                }
            })
        } else {
            setLoading(false)
        }
    }
    const handleEnter = e => {
        const key = e.key;
        const tag = e.target.value;
        if (key === 'Enter' && tag.trim().length > 0) {
            const newResults = formRef.current.getFieldsValue().results;
            newResults.push(tag);
            setResultOptions(prevState => {
                const oldTags = [...prevState];
                oldTags.push(<Select.Option key={tag} value={tag}>{tag}</Select.Option>)
                formRef.current.setFieldsValue({ results: newResults })
                e.target.blur()
                e.target.focus()
                return oldTags;
            })
            if (props.id) {
                setEditedResults([...editedResults, tag])
            }
        }
    }
    const handleDeselect = val => {
        if (props.id) {
            if (typeof val === 'number') {
                HttpRequest.endpoint = 'escalation_ti/test_option/'
                HttpRequest.delete(val)
                props.updateRows()
            } else {
                setEditedResults(prevRes => {
                    return prevRes.filter(res => res !== val)
                })
            }
        }
    }

    useEffect(getData, [])

    return (
        <>
            {!loading && <Form ref={formRef} name='problems' initialValues={{ name: configData && configData.name, description: configData && configData.description, results: props.test && initialOpts ? initialOpts : [] }} onFinish={submitForm}>
                <Form.Item name='name' label='Nombre' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='description' label='Descripción' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input.TextArea />
                </Form.Item>
                {props.test && <Form.Item name='results' label={'Resultados (presionar enter)'} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onInputKeyDown={e => handleEnter(e)}
                        onDeselect={handleDeselect}
                    >
                        {resultOptions}
                    </Select>
                </Form.Item>}
                <Button disabled={disabledButton} loading={buttonLoading} size='large' block type='primary' htmlType='submit'>{props.id ? 'Editar' : 'Crear'}</Button>
            </Form>}
        </>
    )
}

export default FinanceEscalationConfigForm;