import React from 'react'

export const PageTitle = props => (
	<h1 className="page-header">{props.title} <small>{props.subtitle}</small></h1>
);

export const Heading = props => (
	<h4 className={props.className}>{props.children}</h4>
)

export const Caption = props => (
	props.tag === "p"
		? <p className={`caption ${props.className}`}>{props.children}</p>
		: <span className={`caption ${props.className}`}>{props.children}</span>
)

export const Paragraph = props => (
	<p className={props.className}>{props.children}</p>
)

export const ErrorText = props => (
	<p className={`text-danger ${props.className}`}>{props.children}</p>
)