import { Modal } from 'antd';

export default function autoCloseModal(message) {
    const modal = Modal.success({
        title: 'Éxito',
        content: message
    });
    setTimeout(() => {
        modal.destroy();
    }, 3000);
}
