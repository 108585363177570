import React from "react";
import TypificationsTable from "../TypificationsTables/TypificationsTables";
import { retentionsTables } from '../../config/permissions';

function RetentionsCustomTables() {
	const endpoints = {
		formEndpoints: [
			"retentions/retention_table/",
			"retentions/retention_table/definition/",
			"retentions/retention_table",
		],
		tableEndpoints: {
			columns: "retentions/retention_table/datatables_struct/",
			rows: "retentions/retention_table/datatables/",
			delete: "retentions/retention_table/",
		},
	};
	return (
		<TypificationsTable
			endpoints={endpoints}
			removeCreated={true}
			removeAsigned={true}
			permissions={retentionsTables}
		/>
	);
}

export default RetentionsCustomTables;
