import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Select, Modal, Checkbox, Button, Table, DatePicker, Row, Alert, Col } from 'antd';
import HttpRequest from "../../services/HttpRequest.js";
import { ConsoleSqlOutlined, FormOutlined } from '@ant-design/icons';
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber } from "react-phone-number-input";
import store from '../../redux/store';
import { can, validOperator, validRut } from '../../helpers/helpers';
import { client, leadlet } from '../../config/permissions.js';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';

function CreateCustomer(props) {
    const [showPlans, setShowPlans] = useState(true);
    const [formLoading, setFormLoading] = useState(true);
    const [commercialActivities, setCommercialActivities] = useState([]);
    const [prospectInfo, setProspectInfo] = useState(null);
    const [plansRows, setPlansRows] = useState(null);
    const [plans, setPlans] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [fullAddress, setFullAddress] = useState(null);
    const [formError, setFormError] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState(false);
    const [secondaryPhone, setSecondaryPhone] = useState(false);
    const [isBuilding, setIsBuilding] = useState(false)
    const [autoRutInput, setAutoRutInput] = useState("")

    const formRef = useRef()

    const getRutNumbers = (minValue = 1e6, maxValue = 4e7) => {
        let ruts = [];

        function getRandomNumber() {
            var t, e, n, r;
            r = rutOpts.minValue;
            n = rutOpts.maxValue;
            e = parseInt(t);
            if (!e) {
                return Math.floor(Math.random() * (n - r + 1)) + r
            } else {
                return e
            }
        };

        function getMod(t) {
            var e, n;
            e = 0;
            n = 1;
            while (t) {
                n = (n + t % 10 * (9 - e++ % 6)) % 11;
                t = Math.floor(t / 10)
            }
            if (n) {
                return n - 1
            } else {
                return "K"
            }
        };

        const rutOpts = {
            minValue: minValue,
            maxValue: maxValue
        };

        function generateRuts() {
            var e, n, r, u, i;
            n = [];
            i = getRandomNumber();
            r = 0;
            e = false;

            while (r < n.length) {
                if (n[r].number === i) {
                    e = true;
                    break
                }
                r++
            }
            if (!e) {
                u = getMod(i);
                n.push({
                    number: i,
                    mod: u
                })
            }

            return ruts = n[0]
        };

        var rut = generateRuts()

        setAutoRutInput(`${rut.number}-${rut.mod}`)
        formRef.current.setFieldsValue({ rut: `${rut.number}-${rut.mod}` })
    }

    const plansColumns = [
        {
            title: 'Nombre comercial',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Velocidad Bajada',
            dataIndex: 'download',
            key: 'download',
            align: 'center'
        },
        {
            title: 'Velocidad Subida',
            dataIndex: 'upload',
            key: 'upload',
            align: 'center'
        },
        {
            title: 'Precio normal',
            dataIndex: 'price',
            key: 'price',
            align: 'center'
        },
        {
            title: 'Precio promocional',
            dataIndex: 'promotional_price',
            key: 'promotional_price',
            className: 'highlight-table',
            align: 'center'
        },
        {
            title: 'Costo de habilitación normal',
            dataIndex: 'availability_price',
            key: 'availability_price',
            align: 'center'
        },
        {
            title: 'Costo de habilitación promocional',
            dataIndex: 'promotional_availability_price',
            key: 'promotional_availability_price',
            className: 'highlight-table',
            align: 'center'
        },
        {
            title: 'Contratar',
            dataIndex: 'select',
            key: 'select',
            align: 'center'
        }
    ]
    const updateFormField = (name, value) => {
        formRef.current.setFieldsValue({
            [name]: value
        });
    }
    const selectPlan = id => {
        updateFormField('plan', id)
        setShowPlans(false);
    }
    const getCommercialActivities = () => {
        HttpRequest.endpoint = 'matrix/commercial-activities';
        HttpRequest.get().then(res => {
            const commercialActList = res.map(commercialAct => <Select.Option key={commercialAct.id} value={commercialAct.id}>{commercialAct.name}</Select.Option>)
            setCommercialActivities(commercialActList);
            getProspectAndPlanInfo();
        })
    }
    const getProspectAndPlanInfo = () => {
        if (props.id || props.searchInfo) {
            HttpRequest.endpoint = props.searchInfo ? `customers/leadlet_customer/${props.prospectData.ID}/` : `customers/leadlet_customer/${props.id}/`;
            HttpRequest.get().then(res => {
                if (props.names) {
                    setProspectInfo({
                        rut: res.rut,
                        firstName: props.names.first,
                        lastName: props.names.last,
                        phone: res['primary_phone'],
                        email: res['primary_email'],
                        id: res.ID,
                        street_location: res['street_location']
                    })
                } else {
                    setProspectInfo({
                        rut: res.rut,
                        name: res.name,
                        phone: res['primary_phone'],
                        email: res['primary_email'],
                        id: res.ID,
                        street_location: res['street_location']
                    })
                }
                const street = res['street_location'];
                const operator = store.getState().operator;
                HttpRequest.endpoint = `pulso/factibility/?street_location=${street}&operator=${operator}`;
                HttpRequest.get().then(res => {
                    if (res.factibility === 'SI') {
                        const availablePlans = res.plans.map(plan => ({
                            key: plan.name,
                            name: plan.tradename,
                            download: plan['download_speed'] + ' Megas',
                            upload: plan['upload_speed'] + ' Megas',
                            promotional_price: plan['promotional_price'].toLocaleString('es-VE'),
                            availability_price: plan['availability_price'].toLocaleString('es-VE'),
                            promotional_availability_price: plan['promotional_availability_price'].toLocaleString('es-VE'),
                            price: plan.price.toLocaleString('es-VE'),
                            select: <Button type='primary' onClick={() => { console.log(plan); selectPlan(plan.pk) }}>Seleccionar</Button>,
                        }))
                        availablePlans.sort(function (a, b) {
                            return b.download_speed - a.download_speed
                        });
                        setPlansRows(availablePlans);
                        const selectPlans = res.plans.map(plan => <Select.Option key={plan.pk} value={plan.pk}>{plan.name}</Select.Option>);
                        setPlans(selectPlans);
                        setFullAddress(res.address);
                        setFormLoading(false);
                    }
                })
            })
        } else {
            if (props.names) {
                setProspectInfo({
                    rut: props.prospectData.rut,
                    firstName: props.names.first,
                    lastName: props.names.last,
                    phone: props.prospectData['primary_phone'],
                    email: props.prospectData['primary_email'],
                    id: props.prospectData.ID,
                    street_location: props.prospectData['street_location']
                })
            } else {
                setProspectInfo({
                    rut: props.prospectData.rut,
                    name: props.prospectData.name,
                    phone: props.prospectData['primary_phone'],
                    email: props.prospectData['primary_email'],
                    id: props.prospectData.ID,
                    street_location: props.prospectData['street_location']
                })
            }
            const availablePlans = props.plans.map(plan => ({
                key: plan.name,
                name: plan.tradename,
                download: plan['download_speed'] + ' Megas',
                upload: plan['upload_speed'] + ' Megas',
                promotional_price: plan['promotional_price'].toLocaleString('es-VE'),
                availability_price: plan['availability_price'].toLocaleString('es-VE'),
                promotional_availability_price: plan['promotional_availability_price'].toLocaleString('es-VE'),
                price: plan.price.toLocaleString('es-VE'),
                select: <Button type='primary' onClick={() => { selectPlan(plan.pk) }}>Seleccionar</Button>,
            }))
            availablePlans.sort(function (a, b) {
                return b.download_speed - a.download_speed
            });
            setPlansRows(availablePlans);
            const selectPlans = props.plans.map(plan => <Select.Option key={plan.pk} value={plan.pk}>{plan.name}</Select.Option>);
            setPlans(selectPlans);
            setFullAddress(props.fullAddress);
            setFormLoading(false);
        }
    }
    const getRegionData = () => {
        HttpRequest.endpoint =
            "pulso/search-complete-location/" + prospectInfo.street_location + "/";
        HttpRequest.get().then((res) => {
            let department;
            let buildings = ["Block", "Edificio", "Edificio Corporativo", "Local Comercial"]
            res.forEach(dept => {
                if (dept.id === props.prospectData.address) {
                    department = dept.departament
                }
            })
            if (formRef.current) {
                formRef.current.setFieldsValue({
                    "region": res[0]["region"],
                    "commune": res[0]["commune"],
                    "street": res[0]["street"],
                    "street_location": res[0]["number"],
                    "department": department || 'No especificado'
                })
            }
            if (buildings.includes(res[0]["edification_type"])) setIsBuilding(true)
        });
    };
    const submitForm = e => {
        var address_fields = ["region", "street_location", "secundary_email_check", "secundary_phone_check"]
        if (!props.startAgain) {
            if (!can(leadlet.edit) || !can(client.create)) {
                return
            }
        }
        if (validOperator()) {
            if (isValidPhoneNumber(e.phone)) {
                setButtonLoading(true);
                e.kind = 1;
                if (e['birth_date']) {
                    e['birth_date'] = e['birth_date'].format('YYYY-MM-DD');
                }
                const formData = new FormData();
                Object.keys(e).forEach(key => {
                    if (e[key] !== undefined && e[key] !== null && !address_fields.includes(key)) {
                        formData.append(key, e[key])
                    }
                })
                formData.append("notes", "_")
                formData.append("commercial_activity", 94)
                formData.append("document_type", 1)
                formData.append('location', 'loc')
                formData.append("house_number", e["street_location"])
                if (e["department"] != 'No especificado') {
                    formData.append("apartment_number", e["department"])
                }
                HttpRequest.endpoint = `matrix/customers/?rut=${e["rut"]}`
                HttpRequest.get().then(res => {
                    if (res.length > 0) {
                        Modal.confirm({
                            title: 'Cliente registrado',
                            content: 'Este cliente ya está registrado en Matrix ¿Desea crear un nuevo servicio?',
                            onOk() {
                                HttpRequest.endpoint = `customers/leadlet_customer/${prospectInfo.id}/create_customer/`
                                HttpRequest.post(formData).then(res => {
                                    let existingRut = false;
                                    Object.keys(res).forEach(key => {
                                        if (typeof res[key] === 'string') {
                                            if (res[key].includes('duplicate key value')) {
                                                existingRut = true
                                            }
                                        }
                                    })
                                    if (!existingRut) {
                                        props.customSubmit(res)
                                    } else {
                                        Modal.error({
                                            title: 'Error',
                                            content: 'Ya existe un cliente con este RUT.'
                                        })
                                    }
                                    setButtonLoading(false);
                                })
                            },
                            onCancel() {
                                HttpRequest.endpoint = `customers/leadlet_customer/`
                                const formData = new FormData();
                                formData.append("is_customer", true)
                                formData.append("street_location", prospectInfo.street_location)
                                formData.append("name", props.prospectData.name)
                                formData.append("rut", e.rut)
                                formData.append("primary_email", props.prospectData.primary_email)
                                formData.append("primary_phone", props.prospectData.primary_phone)
                                HttpRequest.patch(prospectInfo.id, formData).then(res => {
                                    props.customSubmit(res)
                                })
                            },
                            okText: 'Sí',
                            cancelText: 'No'
                        })
                    } else {
                        HttpRequest.endpoint = `customers/leadlet_customer/${prospectInfo.id}/create_customer/`
                        HttpRequest.post(formData).then(res => {
                            let existingRut = false;
                            Object.keys(res).forEach(key => {
                                if (typeof res[key] === 'string') {
                                    if (res[key].includes('duplicate key value')) {
                                        existingRut = true
                                    }
                                }
                            })
                            if (!existingRut) {
                                props.customSubmit(res)
                            } else {
                                Modal.error({
                                    title: 'Error',
                                    content: 'Ya existe un cliente con este RUT.'
                                })
                            }
                            setButtonLoading(false);
                        })
                    }
                })
            } else {
                setFormError('Formato de número telefónico incorrecto.')
            }

        }
    }

    const initialValues = {
        send_email: true,
        send_snail_mail: false,
        rut: prospectInfo && prospectInfo.rut,
        first_name: prospectInfo && prospectInfo.firstName,
        last_name: prospectInfo && prospectInfo.lastName,
        name: prospectInfo && prospectInfo.name,
        email: prospectInfo && prospectInfo.email,
        phone: prospectInfo && prospectInfo.phone,
        location: fullAddress,
        default_due_day: 5,
        unified_billing: false,
        full_process: true,
        first_category_activity: false,
        technology_kind: 1,
    }
    const editRut = rut => {
        const incomingRut = rut.target.value.replace(/\./g, '').replace(/-/g, '');
        if (incomingRut.length > 1) {
            const firstPart = Number(incomingRut.slice(0, incomingRut.length - 1));
            const secondPart = incomingRut.slice(incomingRut.length - 1);
            const formattedRut = firstPart.toLocaleString('es-VE').concat('-').concat(secondPart);
            formRef.current.setFieldsValue({
                rut: formattedRut
            })
            setAutoRutInput(formattedRut)
            formRef.current.validateFields(['rut'])
        }
    }
    const checkDelete = e => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
            formRef.current.setFieldsValue({
                rut: ''
            })
            formRef.current.validateFields(['rut'])
        }
    }

    useEffect(() => {
        getCommercialActivities();
    }, [])

    useEffect(() => {
        if (formRef.current != null) getRegionData();
    }, [formRef.current])

    return (
        <>
            {!formLoading &&
                <>
                    <Form ref={formRef} name='create-customer' onFinish={submitForm} initialValues={initialValues}>
                        {props.startAgain && <Button size='large' type='primary' danger className='center-btn wizard-btn' onClick={() => { props.startAgain(); /*if (props.typify != null) props.typify()*/ }}>No deseo contratar servicio. Salir</Button>}
                        <Row className='wizard-form-row'>
                            <Col span={10}>
                                <p className="wizard-form-title">Dirección y plan</p>
                                <Form.Item name="region" label="Región" >
                                    <Input disabled style={{ backgroundColor: "white", color: "rgba(0,0,0,0.65)" }} />
                                </Form.Item>
                                <Form.Item name="commune" label="Comuna" >
                                    <Input disabled style={{ backgroundColor: "white", color: "rgba(0,0,0,0.65)" }} />
                                </Form.Item>
                                <Form.Item name="street" label="Calle" >
                                    <Input disabled style={{ backgroundColor: "white", color: "rgba(0,0,0,0.65)" }} />
                                </Form.Item>
                                <Form.Item name="street_location" label="Número" >
                                    <Input disabled style={{ backgroundColor: "white", color: "rgba(0,0,0,0.65)" }} />
                                </Form.Item>
                                <Form.Item name="department" label="Departamento" >
                                    <Input disabled style={{ backgroundColor: "white", color: "rgba(0,0,0,0.65)" }} />
                                </Form.Item>
                                <Form.Item label='Plan' name='plan' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                    <Select onClick={() => setShowPlans(true)}>
                                        {plans}
                                    </Select>
                                </Form.Item>
                                <Row>
                                    <Form.Item className='wizard-row-item' label='Fecha de pago mensual' name='default_due_day' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                        <Select>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]
                                                .map(day => <Select.Option key={day} value={day}>{day}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                    <p className='wizard-due-day'>de cada mes</p>
                                </Row>
                            </Col>
                            <Col span={13}>
                                <p className="wizard-form-title">Datos del titular</p>
                                {props.names ?
                                    <>
                                        <Form.Item label='Nombre' name='first_name' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                            <Input placeholder='Ejemplo Juan' />
                                        </Form.Item>
                                        <Form.Item label='Apellido' name='last_name' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                            <Input placeholder='Pérez' />
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item label='Nombre' name='first_name' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                            <Input placeholder='Ejemplo Juan' />
                                        </Form.Item>
                                        <Form.Item label='Apellido' name='last_name' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                                            <Input placeholder='Pérez' />
                                        </Form.Item>
                                    </>
                                }
                                <Form.Item label='RUT' name='rut' rules={[{ required: true, message: 'Este campo es requerido' }, { validator: (_, value) => validRut.validateRut(value) ? Promise.resolve() : value ? Promise.reject('Formato de RUT inválido.') : Promise.resolve() }]}>
                                    <Input placeholder='Ingrese un RUT' onKeyDown={checkDelete} onChange={editRut} value={autoRutInput} />
                                    <Button icon={<FormOutlined />} onClick={() => getRutNumbers()} />
                                </Form.Item>
                                <ConfigProvider locale={esES}>
                                    <Form.Item label='Fecha de nacimiento' name='birth_date'>
                                        <DatePicker format='DD/MM/YYYY' placeholder='Ejemplo 20-05-1950' className='datepicker-wizard' />
                                    </Form.Item>
                                </ConfigProvider>
                                <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Este campo es requerido' }, { type: 'email', message: 'Este campo debe ser de tipo email' }]}>
                                    <Input />
                                </Form.Item>
                                <Row>
                                    <Form.Item className='wizard-phone-item' label='Teléfono' name='phone' rules={[{ required: true, message: 'Este campo es requerido' }]}></Form.Item>
                                    <PhoneInput className='wizard-phone wizard-row-item' defaultCountry={parsePhoneNumber(prospectInfo.phone).country} value={parsePhoneNumber(prospectInfo.phone).nationalNumber} onChange={e => formRef.current.setFieldsValue({ phone: e })} />
                                </Row>
                                <Row className='wizard-second-fields-row'>
                                    <Form.Item label='Añadir Email Secundario' name='secundary_email_check' >
                                        <Checkbox onChange={e => setSecondaryEmail(e.target.checked)} />
                                    </Form.Item>
                                    <Form.Item label='Email Secundario' name='secundary_email' rules={[{ type: 'email', message: 'Este campo debe ser de tipo email' }]}>
                                        <Input disabled={!secondaryEmail} />
                                    </Form.Item>
                                </Row>
                                <Row className='wizard-second-fields-row'>
                                    <Form.Item label='Añadir Telf Secundario' name='secudary_phone_check' >
                                        <Checkbox onChange={e => setSecondaryPhone(e.target.checked)} />
                                    </Form.Item>
                                    <div className='wizard-second-phone'>
                                        <Form.Item className='wizard-phone-item' label='Telf Secundario' name='secundary_phone'></Form.Item>
                                        <PhoneInput disabled={!secondaryPhone} className='wizard-phone wizard-row-item' defaultCountry={parsePhoneNumber(prospectInfo.phone).country} onChange={e => formRef.current.setFieldsValue({ secundary_phone: e })} />
                                    </div>
                                </Row>
                            </Col>
                        </Row>

                        {/*<Form.Item label='Tipo de tecnología' name='technology_kind' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select>
                                <Select.Option value={1}>UTP</Select.Option>
                                <Select.Option value={2}>GPON</Select.Option>
                                <Select.Option value={3}>GEPON</Select.Option>
                                <Select.Option value={4}>Antenna</Select.Option>
                            </Select>
                        </Form.Item>*/}






                        {/*<Row className='wizard-row'>
                            <Form.Item className='wizard-row-item' label='Facturación unificada' name='unified_billing' >
                                <Checkbox onChange={e => updateFormField('unified_billing', e.target.checked)} />
                            </Form.Item>
                            <Form.Item className='wizard-row-item' label='Proceso full' name='full_process'>
                                <Checkbox defaultChecked onChange={e => updateFormField('full_process', e.target.checked)} />
                            </Form.Item>
                            <Form.Item className='wizard-row-item' label='Giro de primera categoría' name='first_category_activity'>
                                <Checkbox onChange={e => updateFormField('first_category_activity', e.target.checked)} />
                            </Form.Item>
                            <Form.Item className='wizard-row-item' label='Enviar email' name='send_email'>
                                <Checkbox defaultChecked onChange={e => updateFormField('send_email', e.target.checked)} />
                            </Form.Item>
                            <Form.Item label='Enviar documento a domicilio' name='send_snail_mail'>
                                <Checkbox onChange={e => updateFormField('send_snail_mail', e.target.checked)} />
                            </Form.Item>
                                    </Row>*/}
                        <Button size='large' htmlType='submit' type='primary' loading={buttonLoading} className='center-btn wizard-btn'>Enviar</Button>
                    </Form>
                    {formError && <Alert type='error' message={formError} />}
                    <Modal width={950} visible={showPlans} footer={null} onCancel={() => setShowPlans(false)}>
                        <Table columns={plansColumns} dataSource={plansRows} pagination={false} />
                    </Modal>
                </>
            }
        </>
    )
}

export default CreateCustomer;
