import React, { useState } from "react";
import { connect } from "react-redux";
import Icon from "../icons/Icon";
import { Drawer, Tooltip } from "antd";

/**
 * @function Returns a floating button and a drawer on the right side of the window. The drawer has a
 * border that makes it rezisable
 *
 * @param {[dictionary]} props a dictionary with the height and content of the floating button
 * and the width, title and content of the drawer
 */

const DrawerRight = (props) => {
	const [isResizing, setResizing] = useState(false);
	const [visible, setVisible] = useState(false);
	var [DrawerWidth, setDrawerWidth] = useState(props.width ?? "50%");

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const handleMousedown = (e) => {
		setResizing(true);
	};

	const handleMouseMove = (e) => {
		if (isResizing) {
			let offsetRight =
				document.body.offsetWidth -
				(e.clientX - document.body.offsetLeft);
			let minWidth = 250;
			let maxWidth = 1300;
			if (offsetRight > minWidth && offsetRight < maxWidth) {
				setDrawerWidth(offsetRight);
			}
		}
	};

	const handleMouseUp = (e) => {
		setResizing(false);
	};

	return (
		<div
			className={`panel-right ${
				props.sidebar.show
					? "active show"
					: !props.sidebar.show
					? "active disable"
					: ""
			}`}
		>
			<Tooltip placement="left" title={props.title ?? ""}>
				<div
					className="panel-right-toggle"
					style={{ top: props.height + "%" }}
					onClick={showDrawer}
				>
					<Icon icon={props.icon} />
				</div>
			</Tooltip>

			<div
				onMouseUp={(event) => {
					handleMouseUp(event);
				}}
				onMouseMove={(event) => {
					handleMouseMove(event);
				}}
			>
				<Drawer
					width={DrawerWidth}
					title="Basic Drawer"
					placement="right"
					closable={false}
					onClose={onClose}
					visible={visible}
				>
					<div>
						<div
							className="panel-right-dragger"
							onMouseDown={(event) => {
								handleMousedown(event);
							}}
						></div>
						<div
							style={{
								margin: 0,
								marginLeft: 10,
							}}
						>
							{props.content}
						</div>
					</div>
				</Drawer>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	sidebar: state.sidebar,
});

export default connect(mapStateToProps)(DrawerRight);
