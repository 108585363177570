import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Input, Modal, Select } from 'antd';
import HttpRequest from './../../services/HttpRequest';
import { can } from './../../helpers/helpers';
import './escalations.css';
import autoCloseModal from './../../components/modal/AutoCloseModal';

function EscalationConfigForm(props) {
    const [savedDocs, setSavedDocs] = useState([]);
    const [newDocs, setNewDocs] = useState([]);
    const [configData, setConfigData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [submittedNewFormId, setSubmittedNewFormId] = useState(null);
    const [resultOptions, setResultOptions] = useState([]);
    const [editedResults, setEditedResults] = useState([]);
    const [initialOpts, setInitialOpts] = useState([]);
    const formRef = useRef()

    const submitForm = e => {
        let docsAdded = []
        Object.keys(e).forEach(key => {
            if (key.includes('doc')) {
                let newDoc = true;
                savedDocs.forEach(savedDoc => {
                    if (key === savedDoc.props.children[0].props.name) {
                        newDoc = false
                    }
                })
                if (newDoc) {
                    docsAdded.push('http://' + e[key])
                }
            }
        })
        HttpRequest.endpoint = props.endpoint + '/';
        const formData = new FormData();
        formData.append('name', e.name);
        formData.append('description', e.description);
        if (props.id) {
            if (!can(props.permissions.edit)) {
                return;
            }
            setButtonLoading(true)
            HttpRequest.patch(props.id, formData).then(res => {
                if (res.ID) {
                    if (props.test && docsAdded.length > 0) {
                        HttpRequest.endpoint = 'escalation_ti/test_option/'
                        Promise.all(editedResults.map(result => {
                            const formData = new FormData();
                            formData.append('test', res.ID);
                            formData.append('result', result)
                            return HttpRequest.post(formData)
                        })).then(allRes => {
                            makeDocRequest(docsAdded, res.ID)
                        })
                    } else if (props.test) {
                        HttpRequest.endpoint = 'escalation_ti/test_option/'
                        Promise.all(editedResults.map(result => {
                            const formData = new FormData();
                            formData.append('test', res.ID);
                            formData.append('result', result)
                            return HttpRequest.post(formData)
                        })).then(allRes => {
                            autoCloseModal('Se ha editado con éxito')
                            props.closeModal()
                        })
                    } else if (docsAdded.length > 0) {
                        makeDocRequest(docsAdded, res.ID)
                    } else {
                        autoCloseModal('Se ha editado con éxito')
                        props.closeModal()
                    }
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error editando'
                    })
                    setButtonLoading(false);
                }
            })
        } else {
            if (!can(props.permissions.create)) {
                return;
            }
            if (submittedNewFormId) {
                if (docsAdded.length > 0) {
                    setButtonLoading(true)
                    makeDocRequest(docsAdded, submittedNewFormId)
                }
                return;
            }
            setButtonLoading(true)
            HttpRequest.post(formData).then(res => {
                if (res.ID) {
                    setSubmittedNewFormId(res.ID);
                    if (props.test && docsAdded.length > 0) {
                        HttpRequest.endpoint = 'escalation_ti/test_option/'
                        Promise.all(e.results.map(result => {
                            const formData = new FormData();
                            formData.append('test', res.ID);
                            formData.append('result', result)
                            return HttpRequest.post(formData)
                        })).then(allRes => {
                            makeDocRequest(docsAdded, res.ID)
                        })
                    } else if (props.test) {
                        HttpRequest.endpoint = 'escalation_ti/test_option/'
                        Promise.all(e.results.map(result => {
                            const formData = new FormData();
                            formData.append('test', res.ID);
                            formData.append('result', result)
                            return HttpRequest.post(formData)
                        })).then(allRes => {
                            autoCloseModal('Se ha creado con éxito')
                            props.closeModal()
                        })
                    } else if (docsAdded.length > 0) {
                        makeDocRequest(docsAdded, res.ID)
                    }
                    else {
                        autoCloseModal('Se ha creado con éxito')
                        props.closeModal()
                    }
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error creando'
                    })
                    setButtonLoading(false);
                    setDisabledButton(true)
                }
            })
        }
    }
    const makeDocRequest = (docArr, id) => {
        HttpRequest.endpoint = props.endpoint + `/${id}/add_documentation/`
        Promise.all(docArr.map(doc => {
            const docs = new FormData();
            docs.append('url', doc)
            return HttpRequest.post(docs)
        })).then(allRes => {
            setButtonLoading(false)
            let error = 0;
            for (let i = 0; i < allRes.length; i++) {
                if (typeof allRes[i].url !== 'string') {
                    Modal.error({
                        title: 'Error',
                        content: `Se ha ${props.id ? 'editado' : 'creado'} con éxito, pero el formato de la URL de alguna documentación es inválida`
                    })
                    error = 1;
                    props.updateRows()
                    break;
                }
            }
            if (!error) {
                autoCloseModal(`Se ha ${props.id ? 'editado' : 'creado'} con éxito`)
                props.closeModal()
            }
        })
    }
    const getData = () => {
        if (props.id) {
            HttpRequest.endpoint = props.endpoint
            HttpRequest.get(null, props.id).then(res => {
                if (res) {
                    generateSavedDocs(res.documentation)
                    setConfigData(res)
                    if (props.test) {
                        const resOpts = [];
                        const initialOpts = [];
                        res.results.forEach(result => {
                            resOpts.push(<Select.Option key={result.id} value={result.id}>{result.result}</Select.Option>)
                            initialOpts.push(result.id)
                        })
                        setInitialOpts(initialOpts);
                        setResultOptions(resOpts);
                    }
                    setLoading(false)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error obteniendo la información'
                    })
                }
            })
        } else {
            setLoading(false)
        }
    }
    const generateSavedDocs = savedDocs => {
        const prevDocs = [];
        savedDocs.forEach((doc, i) => {
            prevDocs.push(
                <div key={i + 1} className='client-phone-field'>
                    <Form.Item initialValue={doc.url.split('://')[1]} name={'doc' + i} rules={[{ required: true, whitespace: true, message: 'Este campo es obligatorio' }]}>
                        <Input addonBefore='http://' placeholder={'Documentación ' + i} onBlur={e => editDoc(e, i, savedDocs)} />
                    </Form.Item >
                    <Button danger type='primary' onClick={() => deleteDoc(i + 1, false, doc)}>X</Button>
                </div>
            )
        })
        return setSavedDocs(prevDocs);
    }
    const editDoc = (e, i, savedDocs) => {
        if (can(props.permissions.edit)) {
            const newDoc = e.target.value;
            const oldDoc = savedDocs[i].url;
            const oldDocId = savedDocs[i].id;
            if (oldDoc !== newDoc) {
                HttpRequest.endpoint = props.endpoint + `/${props.id}/add_documentation/`
                const data = new FormData()
                data.set('url', 'http://' + newDoc)
                HttpRequest.post(data).then(res => {
                    if (typeof res.url !== 'string') {
                        Modal.error({
                            title: 'Error',
                            content: 'Formato de URL inválido'
                        })
                    } else {
                        HttpRequest.endpoint = props.endpoint + `/${props.id}/remove_documentation/`
                        const data = new FormData();
                        data.set('documentation_id', oldDocId);
                        HttpRequest.post(data).then(res => {
                            if (Object.keys(res).length > 0) {
                                Modal.error({
                                    title: 'Error',
                                    content: 'Ha ocurrido un error al editar la documentación.'
                                })
                            } else {
                                autoCloseModal('Se he editado la documentación con éxito')
                                props.updateRows()
                            }
                        })
                    }
                })
            }
        }
    }
    const generateNewDoc = () => {
        if (props.id) {
            if (!can(props.permissions.edit)) {
                return
            }
        } else {
            if (!can(props.permissions.create)) {
                return
            }
        }
        const actualDocs = [...newDocs];
        const newDocNumber = newDocs.length + savedDocs.length + 1;
        actualDocs.push(
            <div key={newDocNumber} className='client-phone-field'>
                <Form.Item name={'doc' + newDocNumber} rules={[{ required: true, whitespace: true, message: 'Este campo es obligatorio' }]}>
                    <Input addonBefore='http://' placeholder={'Documentación ' + newDocNumber} />
                </Form.Item >
                <Button danger type='primary' onClick={() => deleteDoc(newDocNumber, true)}>X</Button>
            </div>
        )
        setNewDocs(actualDocs)
    }
    const deleteDoc = (index, isNew, doc = null) => {
        if (!isNew) {
            if (can(props.permissions.edit)) {
                HttpRequest.endpoint = props.endpoint + `/${props.id}/remove_documentation/`
                const data = new FormData()
                data.set('documentation_id', doc.id)
                HttpRequest.post(data).then(res => {
                    if (Object.keys(res).length > 0) {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error al eliminar la documentación.'
                        })
                    } else {
                        setSavedDocs(prevState => {
                            let docIndexToDelete = 0;
                            prevState.forEach((docDiv, i) => {
                                if (Number(docDiv.key) === index) {
                                    docIndexToDelete = i;
                                }
                            })
                            return prevState.slice(0, docIndexToDelete).concat(prevState.slice(docIndexToDelete + 1))
                        });
                        autoCloseModal('Se ha eliminado la documentación con éxito')
                        props.updateRows()
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        } else {
            setNewDocs(prevState => {
                let docIndexToDelete = 0;
                prevState.forEach((docDiv, i) => {
                    if (Number(docDiv.key) === index) {
                        docIndexToDelete = i;
                    }
                })
                return prevState.slice(0, docIndexToDelete).concat(prevState.slice(docIndexToDelete + 1))
            });
        }
    }
    const handleEnter = e => {
        const key = e.key;
        const tag = e.target.value;
        if (key === 'Enter' && tag.trim().length > 0) {
            const newResults = formRef.current.getFieldsValue().results;
            newResults.push(tag);
            setResultOptions(prevState => {
                const oldTags = [...prevState];
                oldTags.push(<Select.Option key={tag} value={tag}>{tag}</Select.Option>)
                formRef.current.setFieldsValue({ results: newResults })
                e.target.blur()
                e.target.focus()
                return oldTags;
            })
            if (props.id) {
                setEditedResults([...editedResults, tag])
            }
        }
    }
    const handleDeselect = val => {
        if (props.id) {
            if (typeof val === 'number') {
                HttpRequest.endpoint = 'escalation_ti/test_option/'
                HttpRequest.delete(val)
                props.updateRows()
            } else {
                setEditedResults(prevRes => {
                    return prevRes.filter(res => res !== val)
                })
            }
        }
    }

    useEffect(getData, [])

    return (
        <>
            {!loading && <Form ref={formRef} name='problems' initialValues={{ name: configData && configData.name, description: configData && configData.description, results: props.test && initialOpts ? initialOpts : [] }} onFinish={submitForm}>
                <Form.Item name='name' label='Nombre' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='description' label='Descripción' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input.TextArea />
                </Form.Item>
                {props.test && <Form.Item name='results' label={'Resultados (presionar enter)'} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onInputKeyDown={e => handleEnter(e)}
                        onDeselect={handleDeselect}
                    >
                        {resultOptions}
                    </Select>
                </Form.Item>}
                <Button type='primary' onClick={generateNewDoc} className='add-doc-btn'>Agregar documentación</Button>
                {savedDocs}
                {newDocs}
                <Button disabled={disabledButton} loading={buttonLoading} size='large' block type='primary' htmlType='submit'>{props.id ? 'Editar' : 'Crear'}</Button>
            </Form>}
        </>
    )
}

export default EscalationConfigForm;