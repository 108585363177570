import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Select,
    Steps,
    Input,
    DatePicker,
    InputNumber,
    Checkbox,
    Form,
    Row,
    Modal,
} from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import {
    endPointSlackChannel,
    endPointCreateMassives,
    endPointCreateCycle,
} from "../../services/endpoints.jsx";
import moment from "moment";
import store from "../../redux/store.js";
import { cycle } from "../../config/permissions.js";
import { can } from "../../helpers/helpers.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';

const CycleCreateModal = (props) => {
    const [type, setType] = useState();
    const [showForm, setShowForm] = useState(false);
    const [channel, setChannel] = useState();
    const [channelList, setChannelList] = useState([]);
    const [massiveOpc, setMassiveOpc] = useState();
    const [massiveList, setMassiveList] = useState();
    const [massives, setMassives] = useState([]);
    const [constant, setConstant] = useState(false);
    const [repeats, setRepeats] = useState(1);
    const [id, setId] = useState(props.id);
    const [showFilters, setShowFilters] = useState(false);
    const [name, setName] = useState("");
    const [error, setError] = useState('');
    const [exito, setExito] = useState("");
    const [loading, setLoading] = useState(false)
    const formRef = useRef();
    const { Option } = Select;

    let cycleTypeOpc = [
        <Option key={"O"} value={"O"}>
            Una vez
        </Option>,
        <Option key={"I"} value={"I"}>
            Minutos
        </Option>,
        <Option key={"H"} value={"H"}>
            Cada Hora
        </Option>,
        <Option key={"D"} value={"D"}>
            Diariamente
        </Option>,
        <Option key={"W"} value={"W"}>
            Semanalmente
        </Option>,
        <Option key={"M"} value={"M"}>
            Mensualmente
        </Option>,
        <Option key={"Q"} value={"Q"}>
            Trimestralmente
        </Option>,
        <Option key={"Y"} value={"Y"}>
            Anualmente
        </Option>,
    ];

    let errors = {
        "Cycle is active or test in running":
            "Error. Hay un test ejecutándose o el ciclo está activo",
        "Invalid datetime field": "Error. Las fechas deben ser ascendentes",
        "Overlapping dates":
            "Error. La última fecha no debe ser superior al siguiente ciclo de la fecha inicial",
        "name": 'Ya existe un ciclo con este nombre'
    };

    function getData(cycleId) {
        HttpRequest.endpoint = endPointCreateCycle + cycleId;
        HttpRequest.get().then((res) => {
            setName(res["name"]);
            setChannel(res["slack_channel"]);
            setRepeats(res["number_repeats"]);
            setConstant(res["number_repeats"] === -1);
            setType(res["type_repeats"]);
            for (const [key, value] of Object.entries(
                res["massives_communications"]
            )) {
                let list = [value["massive"], value["datetime"]];
                massives.push(list);
            }
            getMassives();
        })
    }

    const getSlackChannels = nextCursor => {
        HttpRequest.endpoint = endPointSlackChannel;
        const operator = store.getState().operator;
        const filters = { operator: operator };
        if (nextCursor) {
            filters.cursor = nextCursor
            setChannelList(prevChannels => prevChannels.concat([<Select.Option value={null} key={'none'}>Ninguno</Select.Option>]))
        }
        HttpRequest.get(filters).then(res => {
            if (res.ok) {
                setChannelList(prevChannels => prevChannels.concat(res.channels.map(chan => <Select.Option key={chan.id} value={chan.id}>{chan.name}</Select.Option>)))
            }
            if (res.response_metadata && res.response_metadata.next_cursor) {
                getSlackChannels(res.response_metadata.next_cursor)
            } else {
                if (props.id) {
                    getData(props.id);
                } else {
                    getMassives();
                }
            }
        })
    }

    const getMassives = () => {
        let massives = [];
        let op = store.getState().operator;
        HttpRequest.endpoint = endPointCreateMassives + '?operator=' + op;
        HttpRequest.get({ fields: "ID,name" }, null, null).then((res) => {
            res.forEach((element) => {
                massives.push(
                    <Option value={element["ID"]}>{element["name"]}</Option>
                );
            });
            setMassiveOpc(massives);
            setMassiveList(res);
            setShowForm(true);
        });
    };

    useEffect(() => {
        getSlackChannels()
    }, []);

    function handleSelectChange(option) {
        setChannel(option["key"]);
    }

    const createCycle = (values) => {
        let op = store.getState().operator;
        if (!op || op == 0) {
            Modal.error({
                title: "Error",
                content: "Por favor seleccione un operador válido",
            });
            return;
        }
        let empty = false;
        massives.forEach((element) => {
            if (element) {
                element.forEach((masivo) => {
                    if (!masivo) {
                        Modal.error({
                            title: 'Error',
                            content: "No debe dejar ningún campo vacío"
                        })
                        empty = true;
                        return;
                    }
                });
            }
        });
        if (!empty) {
            let massiveDict = {};
            const formData = new FormData();
            formData.append("name", values["name"]);
            if (values["slackChannel"] != null)
                formData.append(
                    "slack_channel",
                    values["slackChannel"]
                );
            formData.append("type_repeats", values["cycleType"]["value"]);
            if (massives.length !== 0) {
                massives.forEach((element, index) => {
                    massiveDict[index] = {
                        datetime: element[1],
                        massive: element[0],
                    };
                });
            } else {
                Modal.error({
                    title: 'Error',
                    content: "Debe agregar al menos un masivo"
                })
                return;
            }
            formData.append(
                "massives_communications",
                JSON.stringify(massiveDict)
            );
            constant
                ? formData.append("number_repeats", -1)
                : formData.append("number_repeats", repeats);
            HttpRequest.endpoint = endPointCreateCycle;
            setLoading(true)
            id ? can(cycle.edit) && HttpRequest.patch(id, formData).then((res) => {
                if (res["ID"]) {
                    autoCloseModal("Se ha editado el ciclo exitosamente")
                    props.closeModal()
                } else {
                    setLoading(false)
                    Object.keys(res).forEach((element) => {
                        if (element in errors) {
                            Modal.error({
                                title: 'Error',
                                content: errors[element]
                            })
                        }
                    });
                    Object.values(res).forEach((element) => {
                        Object.keys(element).forEach((element2) => {
                            if (element2 in errors) {
                                Modal.error({
                                    title: 'Error',
                                    content: errors[element2]
                                })
                            }
                        });
                    });
                }
            })
                : can(cycle.create) && HttpRequest.post(formData).then((res) => {
                    if (res["ID"]) {
                        setLoading(false)
                        autoCloseModal("Se ha creado el ciclo exitosamente")
                        props.closeModal()
                    } else {
                        setLoading(false)
                        Object.keys(res).forEach((element) => {
                            if (element in errors) {
                                setError(errors[element])
                            }
                        });
                        Object.values(res).forEach((element) => {
                            Object.keys(element).forEach((element2) => {
                                if (element2 in errors) {
                                    Modal.error({
                                        title: 'Error',
                                        content: errors[element2]
                                    })
                                }
                            });
                        });
                    }
                });
        }
    };


    const addFilter = () => {
        setShowFilters(false);
        massives.push([null, null]);
        setMassives([...massives]);
    };

    useEffect(() => {
        setShowFilters(true);
    }, [massives]);

    const deleteFilter = (index) => {
        setMassives(massives.filter((_, i2) => i2 != index));
    };

    return (
        <div>
            {showForm && (
                <div>
                    <Form
                        onFinish={createCycle}
                        ref={formRef}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Form.Item
                            label="Nombre"
                            name="name"
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            initialValue={id ? name : null}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Canal de Slack"
                            name="slackChannel"
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            initialValue={channel ?? null}
                        >
                            <Select
                                placeholder="Seleccione el canal"

                                onChange={handleSelectChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {channelList}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Repeticiones" name="number_times">
                            <div>
                                <InputNumber
                                    defaultValue={constant ? 1 : repeats}
                                    disabled={constant}
                                    onChange={(value) => setRepeats(value)}
                                    required={!constant}
                                    min={1}
                                    precision={0}
                                    type={"number"}
                                    value={repeats}
                                />
                                <Checkbox
                                    defaultChecked={constant}
                                    style={{ marginLeft: "20px" }}
                                    onChange={(e) => {
                                        setConstant(e.target.checked);
                                        setRepeats(0);
                                    }}
                                >
                                    Constantemente
                                </Checkbox>
                            </div>
                        </Form.Item>

                        <Form.Item
                            label="Tipo de Ciclo"
                            name="cycleType"
                            rules={[{ required: true }]}
                            initialValue={{
                                value: type ?? "M",
                            }}
                        >
                            <Select
                                placeholder="Tipo"
                                labelInValue
                                defaultValue={{
                                    value: type ?? "M",
                                }}
                                style={{
                                    width: "100%",
                                }}
                                onChange={(opc) => setType(opc["value"])}
                            >
                                {cycleTypeOpc}
                            </Select>
                        </Form.Item>
                    </Form>

                    <div>
                        {showForm && (
                            <div style={{ margin: "5px" }}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        addFilter();
                                        setShowFilters(true);
                                    }}
                                    style={{
                                        marginBottom: "20px",
                                    }}
                                    className='center-btn'
                                >
                                    Agregar Masivo
                                </Button>
                                <Form>
                                    {showFilters &&
                                        massives.map((element, index) => {
                                            let defaultValue2 = massiveList.find(
                                                (e) => e.ID === element[0]
                                            );

                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Row
                                                        style={{
                                                            width: "98%",
                                                        }}
                                                    >
                                                        <Form.Item
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                            style={{
                                                                width: "45%",
                                                            }}
                                                        >
                                                            <Select
                                                                placeholder="Tipo de condición"
                                                                labelInValue
                                                                onChange={(
                                                                    option
                                                                ) => {
                                                                    massives[
                                                                        index
                                                                    ][0] =
                                                                        option[
                                                                        "value"
                                                                        ];
                                                                    setMassives(
                                                                        [
                                                                            ...massives,
                                                                        ]
                                                                    );
                                                                }}
                                                                defaultValue={{
                                                                    value: defaultValue2
                                                                        ? defaultValue2[
                                                                        "name"
                                                                        ]
                                                                        : null,
                                                                }}
                                                            >
                                                                {massiveOpc}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                            style={{
                                                                width: "40%",
                                                            }}
                                                        >
                                                            <DatePicker
                                                                defaultValue={
                                                                    massives[
                                                                        index
                                                                    ][1]
                                                                        ? moment(
                                                                            massives[
                                                                            index
                                                                            ][1],
                                                                            "DD/MM/YYYY HH:mm"
                                                                        )
                                                                        : null
                                                                }
                                                                onChange={(
                                                                    value,
                                                                    string
                                                                ) => {
                                                                    massives[
                                                                        index
                                                                    ][1] = string;
                                                                    setMassives(
                                                                        [
                                                                            ...massives,
                                                                        ]
                                                                    );
                                                                }}
                                                                format="DD/MM/YYYY HH:mm"
                                                                showTime={{
                                                                    defaultValue: moment(
                                                                        "00:00:00",
                                                                        "HH:mm"
                                                                    ),
                                                                }}
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button
                                                                type="primary"
                                                                danger
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                                onClick={() => {
                                                                    setShowFilters(
                                                                        false
                                                                    );
                                                                    deleteFilter(
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                X
                                                            </Button>
                                                        </Form.Item>
                                                    </Row>
                                                </div>
                                            );
                                        })}
                                </Form>
                            </div>
                        )}
                        <div className="form-group">
                            <p className="text-danger text-center">{error}</p>
                            <p className="text-center">{exito}</p>
                        </div>
                        <Button
                            type="primary"
                            onClick={() => formRef.current.submit()}
                            style={{ marginTop: "10px" }}
                            className='center-btn'
                            loading={loading}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CycleCreateModal;
