import React from 'react';
import { escalationCategories } from '../../config/permissions';
import Categories from '../category/Categories';

function EscalationProblems() {
    const endpoints = {
        formEndpoint: 'escalation_ti/problems/',
        tableEndpoints: {
            columns: "escalation_ti/problems/datatables_struct/",
            rows: "escalation_ti/problems/datatables/",
            delete: "escalation_ti/problems/",
        }
    }

    return (
        <Categories endpoints={endpoints} permissions={escalationCategories} />
    )
}

export default EscalationProblems;