import React from 'react';
import TypificationsTable from '../TypificationsTables/TypificationsTables';

function InspeccionesCustomTables() {
    const endpoints = {
        formEndpoints: ['qc/inspection_tables/', 'qc/inspection_tables/definition', 'qc/inspection_tables'],
        tableEndpoints: {
            columns: 'qc/inspection_tables/datatables_struct/',
            rows: 'qc/inspection_tables/datatables/',
            delete: 'qc/inspection_tables/',
        }
    }
    return (
        <TypificationsTable
            endpoints={endpoints}
            // permissions={removeEquipmentTables}
            removeCreated={true}
            removeAsigned={true}
        />
    )
}

export default InspeccionesCustomTables;