import React, { useState } from "react";
import { Modal } from "antd";
import Button from "../../components/buttons/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { can } from "../../helpers/helpers";

const ButtonWithModal = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);

    return (
        <div>
            <Button
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                    }
                    if (props.permission) {
                        if (typeof props.permission === 'string') {
                            if (can(props.permission)) {
                                setShowModal(true)
                            }
                        } else {
                            let hasPermission = true
                            for (let i = 0; i < props.permission.length; i++) {
                                if (!can(props.permission[i])) {
                                    hasPermission = false;
                                    break;
                                }
                            }
                            if (hasPermission) {
                                setShowModal(true)
                            }
                        }
                    } else {
                        setShowModal(true)
                    }
                }}
                className="form-control"
                color={"success"}
            >
                {props.name}
            </Button>
            <Modal
                footer={null}
                visible={showModal}
                onCancel={() => {
                    if (props.onCancel) {
                        props.onCancel(reload);
                    }
                    setReload(false);
                    setShowModal(false);
                }}
                title={props.title}
                destroyOnClose={true}
                width={props.width ?? "520px"}
            >
                {React.cloneElement(props.content, {
                    updateRows: (status = true) => setReload(status),
                    closeModal: (update = true) => { setShowModal(false); props.onCancel && props.onCancel(update) }
                })}
            </Modal>
        </div>
    );
};

export default ButtonWithModal;
