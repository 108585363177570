import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Input, InputNumber, DatePicker, Modal } from 'antd';
import errorModal from './../../components/modal/errorModal';
import HttpRequest from './../../services/HttpRequest';
import moment from 'moment';

function PrevisualizeWhatsapp(props) {
    const [formItems, setFormItems] = useState([]);
    const [renderTemplate, setRenderTemplate] = useState('');
    const [loading, setLoading] = useState(false);
    const formRef = useRef();

    const submit = e => {
        setLoading(true)
        for (const key in e) {
            if (moment.isMoment(e[key])) {
                e[key] = e[key].format('DD/MM/YYYY')
            }
        }
        HttpRequest.endpoint = 'communications/template_whatsapp/preview_template/';
        const formData = new FormData();
        formData.append("template", props.template.description);
        formData.append('data', JSON.stringify(Object.values(e)))
        HttpRequest.post(formData).then(res => {
            if (res && res.template) {
                setLoading(false)
                setRenderTemplate(res.template)
            } else {
                setLoading(false)
                errorModal('Ha ocurrido un error previsualizando')
            }
        })
    }

    useEffect(() => {
        const items = [];
        props.tags.forEach(tagName => {
            props.tagList.forEach(tag => {
                if (tag.name === tagName) {
                    const rules = [{ required: true, message: 'Este campo es requerido' }]
                    let input = <Input placeholder={tag.label} />
                    if (tag.type === 'int') {
                        rules.push({ type: 'integer', message: 'Este campo debe ser numérico' })
                        input = <InputNumber placeholder={tag.label} />
                    } else if (tag.type === 'datetime') {
                        input = <DatePicker placeholder={tag.label} format={'DD/MM/YYYY'} />
                    } else if (tag.type === 'email') {
                        rules.push({ type: 'string', message: 'Email inválido' })
                    }
                    items.push(
                        <Form.Item key={tagName} label={tag.label} name={tagName} rules={rules} >
                            {input}
                        </Form.Item>
                    )
                }
            })
        })
        setFormItems(items)
    }, [])

    return (
        <>
            <Form onFinish={submit} ref={formRef}>
                {formItems}
                <Button type='primary' htmlType='submit' loading={loading} className='center-btn'>Previsualizar</Button>
            </Form>
            <Modal width={600} footer={null} visible={renderTemplate} onCancel={() => setRenderTemplate('')}>
                <iframe width='550' height='300' srcDoc={renderTemplate} style={{ border: 0 }}></iframe>
            </Modal>
        </>
    )
}
export default PrevisualizeWhatsapp;