import React, { useState, useEffect } from 'react';
import { Button, Row, Modal } from 'antd';
import HttpRequest from '../../services/HttpRequest';


function RegisterIclass(props) {

    const [iframeLoad, setIframeLoad] = useState({ url: 'https://fs2.iclass.com.br/iclassfs/restrict/ativo_search.seam', loaded: 0 })
    const [loading, setLoading] = useState(false)

    const handleLoad = (props) => {
        if (iframeLoad.loaded >= 1) {
            setIframeLoad({
                "loaded": iframeLoad.loaded + 1,
                "url": "https://fs2.iclass.com.br/iclassfs/restrict/ativo_search.seam"
            })
        }
    }
    const updateState = () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("status_agendamiento", 0)
        HttpRequest.endpoint = "formerCustomers/remove_equipament/"
        HttpRequest.get().then((res) => console.log(res))
        HttpRequest.patch(props.id, formData).then((res) => {
            if (res["ID"]) {
                props.close(false);
                var reload = props.reload
                props.changeReload()
            } else {
                Modal.error({
                    title: "Error",
                    content: "Ha ocurrido un error al actualizar el estado"
                })
            }
            setLoading(false)
        })
    }

    return (
        <div>
            <iframe className='remove-equip-iframe' onLoad={handleLoad} src={iframeLoad.url} title="Creación de servicio en Matrix"></iframe>
            <Row justify="space-between">
                <h4>Realizó el registró en Iclass</h4> <Button loading={loading} type="primary" onClick={() => { updateState() }}>Listo</Button>
            </Row>
        </div>
    )
}

export default RegisterIclass;
