import React from "react";
import FullTable from "../../components/datatable/FullTable";
import { escalation } from "../../config/permissions";

function RetentionEscalationsTable(props) {
	const endpoints = {
		columns:
			"escalation_ti/escalation/" +
			props.tableID +
			"/datatables_struct_view/",
		rows: "escalation_ti/escalation/" + props.tableID + "/datatables/",
		delete: "escalation_ti/escalation/",
		download:
			"escalation_ti/escalation/" + props.tableID + "/download_data/",
	};
	return (
		<FullTable
			permissions={escalation}
			buttons={[]}
			endpoints={endpoints}
			initialParams={{
				filters: [["services", "icontains", props.service], ["status", "equal", "0"]],
			}}
		/>
	);
}

export default RetentionEscalationsTable;
