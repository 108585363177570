import { Button, Drawer, Tag, Row, Modal, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import HttpRequest from '../../services/HttpRequest';
import TypifyComments from '../typify/typifyComments';
import autoCloseModal from './../../components/modal/AutoCloseModal';
import EscalationHistory from './EscalationHistory';
import Moment from "react-moment"
import moment from 'moment';
import PhotoList from '../removeEquipment/PhotoList';

function EscalationDetails(props) {
    const [details, setDetails] = useState(null);
    const [agent, setAgent] = useState(null);
    const [tests, setTests] = useState(null);
    const [problems, setProblems] = useState(null);
    const [solutions, setSolutions] = useState(null);
    const [showComments, setShowComments] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPhotos, setShowPhotos] = useState(false)

    const returnToTi = () => {
        setLoading(true)
        HttpRequest.endpoint = 'tickets/typify/' + props.tipifyID + '/give_back/';
        HttpRequest.post().then(res => {
            if (res) {
                const errors = Object.keys(res);
                if (errors.length > 0) {
                    Modal.error({
                        title: 'Error',
                        content: 'Ha ocurrido un error devolviendo el ticket a TI'
                    })
                } else {
                    autoCloseModal('Se ha devuelto el escalamiento a TI con éxito')
                    setDetails(null)
                }
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error devolviendo el ticket a TI'
                })
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        if (props.escalationID && details === null) {
            HttpRequest.endpoint = 'escalation_ti/escalation';
            HttpRequest.get(null, props.escalationID).then(res => {
                if (res && res.ID) {
                    let agentName = 'No hay'
                    props.agents.forEach(ag => {
                        const agentId = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                        if (ag.ID === agentId) {
                            agentName = ag.name
                        }
                    })
                    setAgent(agentName)
                    HttpRequest.endpoint = 'escalation_ti/test/'
                    HttpRequest.get().then(tests => {
                        if (tests && Array.isArray(tests)) {
                            const list = []
                            res.tests.forEach(test => {
                                for (let i = 0; i < tests.length; i++) {
                                    if (test.test === tests[i].ID) {
                                        list.push(<Tag color='geekblue' key={tests[i].ID}>{tests[i].name}</Tag>)
                                        break;
                                    }
                                }
                            })
                            if (list.length > 0) {
                                setTests(list)
                            } else {
                                setTests('No hay')
                            }
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error obteniendo los datos del escalamiento'
                            })
                        }
                    })
                    HttpRequest.endpoint = 'escalation_ti/solution/'
                    HttpRequest.get().then(solutions => {
                        if (solutions && Array.isArray(solutions)) {
                            const list = []
                            res.solutions.forEach(sol => {
                                for (let i = 0; i < solutions.length; i++) {
                                    if (sol === solutions[i].ID) {
                                        list.push(<Tag color='geekblue' key={solutions[i].ID}>{solutions[i].name}</Tag>)
                                        break;
                                    }
                                }
                            })
                            if (list.length > 0) {
                                setSolutions(list)
                            } else {
                                setSolutions('No hay')
                            }
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error obteniendo los datos del escalamiento'
                            })
                        }
                    })
                    HttpRequest.endpoint = 'escalation_ti/problems/'
                    HttpRequest.get().then(problems => {
                        if (problems && Array.isArray(problems)) {
                            const list = [];
                            res.problems.forEach(prob => {
                                for (let i = 0; i < problems.length; i++) {
                                    if (prob === problems[i].ID) {
                                        list.push(<Tag color='red' key={problems[i].ID}>{problems[i].name}</Tag>)
                                        break;
                                    }
                                }
                            })
                            if (list.length > 0) {
                                setProblems(list)
                            } else {
                                setProblems('No hay')
                            }
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error obteniendo los datos del escalamiento'
                            })
                        }
                    })
                    setDetails(res)
                }
            })
        }
    }, [props.escalationID, details])

    return (
        <>
            {details && solutions && tests && problems ?
                <div className='escalation-details-container'>
                    <p><strong>Nivel de escalamiento: </strong>{details.escalation_level === 0 ? 'Primero' : details.escalation_level === 1 ? 'Segundo' : 'Tercero'}</p>
                    <p><strong>Status: </strong>{details.status === 0 ? 'Abierto' : details.status === 1 ? 'En SAC' : 'Cerrado'}</p>
                    <p><strong>Agente: </strong>{agent}</p>
                    <p><strong>Pruebas: </strong>{tests}</p>
                    <p><strong>Problemas: </strong>{problems}</p>
                    <p><strong>Soluciones: </strong>{solutions}</p>
                    <p><strong>SLA: </strong>Hace {<Moment fromNow ago locale='en'>{moment().add(-1 * details.SLA, 's')}</Moment>}</p>
                    <Row justify='space-around' className='center-row escalation-buttonws-row-details'>
                        <Button onClick={() => setShowComments(true)} type='primary'>Comentarios</Button>
                        <Button onClick={() => setShowPhotos(true)} type='primary'>Fotos</Button>
                        <Button onClick={() => setShowHistory(true)} type='primary'>Historial</Button>
                        {details.status === 1 && <Button onClick={returnToTi} type='secondary' loading={loading}>Devolver a TI</Button>}
                    </Row>
                    <Drawer title="Comentarios" placement="right" width='380px' closable={false} onClose={() => setShowComments(false)} visible={showComments} >
                        {details && details.commentaries && <TypifyComments id={details.commentaries} />}
                    </Drawer>
                    <Drawer title="Historial" placement="right" width='600px' closable={false} onClose={() => setShowHistory(false)} visible={showHistory} >
                        <EscalationHistory escalationID={details.ID} />
                    </Drawer>
                    <Modal
                        title="Fotos"
                        width={800}
                        onCancel={() => setShowPhotos(false)}
                        visible={showPhotos}
                        footer={null}
                    >
                        <PhotoList endpoint="escalation_ti/escalation_photo/" photoFieldName='escalation' removeID={props.escalationID} />
                    </Modal>
                </div>
                :
                <Spin size='large' className='center-spin' />
            }
        </>
    )
}

export default EscalationDetails;