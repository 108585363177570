import React, { useState } from 'react';
import HttpRequest from '../../services/HttpRequest';
import { Row, Button, Modal } from 'antd';
import PaymentAmount from './PaymentAmount';
import MatrixPaymentForm from './MatrixPaymentForm';
import store from '../../redux/store';
import Config from "../../config";
import autoCloseModal from '../../components/modal/AutoCloseModal';

function PaymentDelay(props) {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [paymentAmountLoading, setPaymentAmountLoading] = useState(false);
    const [paid, setPaid] = useState(null);

    const createTipification = (status) => {
        const formData = new FormData();
        formData.append('customer_type', 3)
        formData.append('channel', props.channel);
        formData.append('status', status);
        formData.append('category', props.categories.category);
        formData.append('rut', JSON.stringify([props.rut]));
        formData.append('services', JSON.stringify(props.services));
        formData.append('type', 0);
        formData.append('process', props.process)
        HttpRequest.endpoint = "tickets/typify/";
        return HttpRequest.post(formData)
    }
    const closeTipification = (isCut) => {
        const formData = new FormData();
        formData.append('status', 1);
        HttpRequest.endpoint = "tickets/typify/";
        HttpRequest.patch(props.tipifyID, formData).then((res) => {
            if (res["ID"]) {
                props.updateRows()
                autoCloseModal(isCut ? "Se ha cerrado la tipificación exitosamente y se ha cortado el servicio" : "Se ha registrado el pago en Matrix y se ha cerrado la tipificación existosamente")
                props.reset(props.tipifyID)
                props.setVisible(false)
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al cerrar la tipificación" })
            }
        })
    }
    const delayPayment = () => {
        setPaymentAmountLoading(true)
        createTipification(0).then((res) => {
            if (res["ID"]) {
                props.updateRows()
                activateService(false, res.ID)
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al crear la tipificación. Intente nuevamente" })
                setPaymentAmountLoading(false)
            }
        })
    }
    const dontDelayPayment = () => {
        setPaymentAmountLoading(true)
        createTipification(1).then((res) => {
            if (res["ID"]) {
                //Modal.success({ title: "Éxito", content: "No se prorrogó el pago y se ha creado la tipificación exitosamente" })
                props.updateRows()
                props.startAgain()
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al crear la tipificación" })
                setPaymentAmountLoading(false)
            }
        })
    }
    const activateService = (retry = false, tipify) => {
        let success = 0;
        Promise.all(props.services.map(serv => {
            return fetch(Config.API_URL + '/matrix/network_active_drop/' + serv + '/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ action: 'activar' })
            }).then(res => res.json())
        }))
            .then(allRes => {
                allRes.forEach((res, i) => {
                    if (res.mensaje === "Se ha activado el servicio.") {
                        success++
                    } else {
                        Modal.error({
                            title: "Error",
                            content: !retry ? `Se ha creado la tipificación, pero no se ha podido activar el servicio ${props.services[i]}` : `Ha ocurrido un error activando el servicio ${props.services[i]}`,
                        })
                    }
                })

                if (success === allRes.length) {
                    props.setTipifyID(tipify)
                    props.setStep(3)
                    props.updateRows()
                    //Modal.success({ title: "Éxito", content: !retry ? "Se ha creado la tipificación y se ha activado el servicio con éxito" : 'Se activó el servicio con éxito' })
                    //props.startAgain()
                }
            })
    }
    const cutService = () => {
        let success = 0;
        Promise.all(props.services.map(serv => {
            return fetch(Config.API_URL + '/matrix/network_active_drop/' + serv + '/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ action: 'cortar' })
            }).then(res => res.json())
        }))
            .then(allRes => {
                allRes.forEach((res, i) => {
                    if (res.mensaje === "Se ha cortado el servicio.") {
                        success++
                    } else {
                        Modal.error({
                            title: "Error",
                            content: "Ocurrió un problema al cortar el servicio " + props.services[i],
                        })
                    }
                })
                if (success === allRes.length) {
                    closeTipification(true)
                }
            })
            .catch(err => {
                console.log(err)
                Modal.error({
                    title: "Error",
                    content: "Ocurrió un problema al cortar el servicio",
                })
            })
    }
    const receiveInfo = e => {
        setButtonLoading(true)
        e.paid_on = e.paid_on.format('YYYY-MM-DD');
        e.deposited_on = e.deposited_on.format('YYYY-MM-DD');
        e.customer_rut = props.rut;
        e.operator = store.getState().operator;
        let initialRes;
        fetch(Config.API_URL + '/matrix/register_payments/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            },
            body: JSON.stringify(e)
        })
            .then(res => { initialRes = res; return res.json() })
            .then(res2 => {
                const errors = res2 && Object.keys(res2)
                if (res2 && errors.length === 0) {
                    closeTipification(false);
                } else {
                    if (initialRes.ok) {
                        Modal.warning({
                            title: 'Mensaje',
                            content: 'Se ha registrado el pago en Matrix con éxito, sin embargo ocurrieron los siguientes errores: ' +
                                errors.map(key => errors[key]).join(' / ')
                        })
                        closeTipification(false);
                    } else {
                        console.log(res2)
                        throw new Error('error')
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setButtonLoading(false)
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error registrando el pago en matrix'
                })
            })
    }

    return (
        <>
            {
                !props.setVisible ?
                    <PaymentAmount services={props.services} startAgain={props.startAgain} loading={paymentAmountLoading} rut={props.rut} delayWizard delayPayment={delayPayment} dontDelayPayment={dontDelayPayment} />
                    :
                    <Modal footer={null} visible onCancel={() => props.setVisible(false)}>
                        {
                            !paid ?
                                <>
                                    <h4 className='form-subtitle'>Esta tipificación es de prórroga de pago y se encuentra abierta</h4>
                                    <h4 className='form-subtitle'>¿El cliente pagó?</h4>
                                    <Row className='center-row'>
                                        <Button onClick={() => setPaid(true)} type='primary'>Sí pagó</Button>
                                        <Button onClick={cutService} type='secondary' danger>No pagó</Button>
                                    </Row>
                                    <Row className='center-row'>
                                        <Button onClick={() => props.setVisible(false)} type='secondary'>Ver más tarde</Button>
                                    </Row>
                                </>
                                :
                                <MatrixPaymentForm services={props.services} delayPayment loading={buttonLoading} receiveInfo={receiveInfo} rut={props.rut} hideModal={() => props.setVisible(false)} />
                        }
                    </Modal>
            }
        </>
    )
}

export default PaymentDelay;