import React, { useState, useEffect } from "react";
import { Select, TimePicker, Button } from "antd";
import HttpRequest from "./../../services/HttpRequest";
import store from "../../redux/store";
import moment from "moment";
import autoCloseModal from "../../components/modal/AutoCloseModal";
import errorModal from "./../../components/modal/errorModal";

function IClassRestDays(props) {
	const [weekday, setWeekday] = useState(null);
	const [times, setTimes] = useState([null, null]);
	const [timesToSave, setTimesToSave] = useState(null);

	const submit = () => {
		console.log(weekday);
		HttpRequest.endpoint = "iclass/service_orders/technician_break/";
		const formData = new FormData();
		formData.append("week_day", weekday);
		formData.append("start", timesToSave.start);
		formData.append("end", timesToSave.end);
		formData.append(
			"creator",
			JSON.parse(localStorage.getItem("userInfo")).username
		);
		HttpRequest.post(formData).then((res) => {
			if (res && res.ID) {
				autoCloseModal("Se ha creado el descanso con éxito");
			} else {
				errorModal("Ha ocurrido un error creando el descanso");
			}
		});
	};

	useEffect(() => {
		if (weekday !== null) {
			HttpRequest.endpoint = "iclass/service_orders/technician_break/";
			HttpRequest.get({
				operator: store.getState().operator,
				week_day: weekday,
			}).then((res) => {
				if (res && !Array.isArray(res) && res.ID) {
					setTimes([
						moment(res.start, "HH:mm"),
						moment(res.end, "HH:mm"),
					]);
				} else if (res && !Array.isArray(res)) {
					errorModal(
						"Ha ocurrido un error obteniendo el horario del día seleccionado"
					);
				}
			});
		}
	}, [weekday]);

	const handleTime = (times) => {
		setTimesToSave({
			start: times[0].format("HH:mm"),
			end: times[1].format("HH:mm"),
		});
		setTimes(times);
	};

	return (
		<>
			<p className="center-text">
				{weekday !== null
					? "Seleccione el horario de descanso"
					: "Seleccione el día de semana"}
			</p>
			{weekday !== null ? (
				<>
					<TimePicker.RangePicker
						value={times}
						onChange={(val) => handleTime(val)}
					/>
					<Button
						onClick={() => {
							setWeekday(null);
							setTimes([null, null]);
						}}
						className="center-btn"
					>
						Escoger otro día
					</Button>
				</>
			) : (
				<Select
					onChange={(val) => setWeekday(val)}
					placeholder="Seleccionar"
				>
					<Select.Option key="lunes" value={0}>
						Lunes
					</Select.Option>
					<Select.Option key="martes" value={1}>
						Martes
					</Select.Option>
					<Select.Option key="miercoles" value={2}>
						Miércoles
					</Select.Option>
					<Select.Option key="jueves" value={3}>
						Jueves
					</Select.Option>
					<Select.Option key="viernes" value={4}>
						Viernes
					</Select.Option>
					<Select.Option key="sabado" value={5}>
						Sábado
					</Select.Option>
					<Select.Option key="domingo" value={6}>
						Domingo
					</Select.Option>
				</Select>
			)}
			{timesToSave && (
				<Button className="center-btn" type="primary" onClick={submit}>
					Guardar
				</Button>
			)}
		</>
	);
}

export default IClassRestDays;
