import { Button, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import HttpRequest from '../../services/HttpRequest'

export default function TemplateSelector(props) {

    const [templateList, setTemplateList] = useState([])
    const [template, setTemplate] = useState(null)
    const [listHTML, setListHTML] = useState([])
    const [preview, setPreview] = useState(null)

    const { Option } = Select;

    const getTemplates = () => {
        HttpRequest.endpoint = "communications/template_email/"
        HttpRequest.get().then((res) => {
            let list = [];
            let htmlList = [];
            res.forEach(element => {
                list.push(<Option key={element["ID"]} value={element["ID"]}>{element["name"]}</Option>)
                htmlList[element["ID"]] = element["template_html"]
            });
            setTemplateList(list);
            setListHTML(htmlList);
        })
    }

    useEffect(() => {
        getTemplates()
    }, [])

    return (
        <div>
            <Modal
                width={900}
                footer={null}
                visible={preview != null}
                onCancel={() => setPreview(null)}
            >
                <iframe width="850" height="350" srcDoc={preview}></iframe>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button type='primary' onClick={() => props.sendTemplate(listHTML[template])}>Enviar</Button>
                </div>
            </Modal>
            < Modal footer={null} visible={props.visible} width="65%" onCancel={() => props.setVisible(false)} >
                {templateList.length > 0 &&
                    <>
                        <h1>Seleccione la plantilla</h1>
                        <Select style={{ width: "100%", marginTop: "20px" }} onChange={(value) => setTemplate(value)}>
                            {templateList}
                        </Select>
                        <Button onClick={() => setPreview(listHTML[template])}>Previsualizar</Button>
                    </>
                }
            </Modal >
        </div>
    )
}
