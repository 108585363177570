import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { financeEscalationSolutions } from '../../config/permissions';
import FinanceEscalationConfigForm from './FinanceEscalationConfigForm';

function FinanceEscalationResults() {
    const endpoints = {
        rows: 'escalation_finance/solution/datatables/',
        columns: 'escalation_finance/solution/datatables_struct/',
        delete: 'escalation_finance/solution/'
    }
    const formEndpoint = 'escalation_finance/solution';
    return (
        <>
            <FullTable
                modalContent={<FinanceEscalationConfigForm permissions={financeEscalationSolutions} endpoint={formEndpoint} />}
                endpoints={endpoints}
                tableName='solución'
                permissions={financeEscalationSolutions}
            />
        </>
    )
}

export default FinanceEscalationResults;