import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
    parsePhoneNumber,
    isPossiblePhoneNumber,
} from "react-phone-number-input";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Select, Form, Modal, Input } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import FormComp from "../../components/form/Form.jsx";
import { endPointLead } from "../../services/endpoints.jsx";
import store from "../../redux/store.js";
import "./leads.css";
import { can, validRut } from "../../helpers/helpers.js";
import { lead, leadlet } from "../../config/permissions.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';

const LeadCreateModal = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [mainNumber, setMainNumber] = useState();
    const [secondaryNumber, setSecondaryNumber] = useState();
    const [mainPhoneParsed, setMainPhoneParsed] = useState(["CL", null]);
    const [secondaryPhoneParsed, setSecondaryPhoneParsed] = useState(["CL", null]);
    const [mainEmail, setMainEmail] = useState("");
    const [secondaryEmail, setSecondaryEmail] = useState(null);
    const [rut, setRut] = useState();
    const [id, setId] = useState(props.id);
    const [regionsList, setRegionsList] = useState();
    const [region, setRegion] = useState(null);
    const [communeList, setCommuneList] = useState();
    const [commune, setCommune] = useState();
    const [streetList, setStreetList] = useState();
    const [street, setStreet] = useState();
    const [streetLocationsList, setStreetLocationsList] = useState();
    const [streetLocation, setStreetLocation] = useState();
    const [compLocation, setCompLocation] = useState();
    const [address, setAddress] = useState();
    const [plansList, setPlansList] = useState();
    const [plan, setPlan] = useState();
    const [loading, setLoading] = useState(false)
    const { Option } = Select;
    const [name, setName] = useState("");
    const [error, setError] = useState([]);
    const [exito, setExito] = useState("");
    const formRef2 = useRef();
    const [form] = Form.useForm();

    let buildings = ["Block", "Edificio", "Edificio Corporativo", "Local Comercial"]
    let info;
    if (!props.hideLastName) {
        info = {
            size: [4, 18],
            title: "Form1",
            items: [
                {
                    custom: true,
                    label: "Nombre",
                    name: "firstName",
                    rules: [{ required: true, message: 'Este campo es requerido' }],
                    component: <Input placeholder='Ejemplo: Juan' />,
                    initialValue: id ? name : "",
                },
                {
                    custom: true,
                    label: "Apellido",
                    name: "lastName",
                    rules: [{ required: true, message: 'Este campo es requerido' }],
                    component: <Input placeholder='Ejemplo: Pérez' />,
                    initialValue: id ? name : "",
                },
                {
                    custom: true,
                    label: "Correo Principal",
                    name: "mainEmail",
                    rules: [{ required: true, message: 'Este campo es requerido' }, { type: 'email', message: 'Debe ser un campo de email' }],
                    component: <Input placeholder='Ejemplo: juan@gmail.com' />,
                    initialValue: id ? mainEmail : "",
                }
            ],
            multipart: false,
            submitButton: false,
        };
    } else {
        info = {
            size: [4, 18],
            title: "Form1",
            items: [
                {
                    custom: true,
                    label: "Nombre",
                    name: "name",
                    rules: [{ required: true, message: 'Este campo es requerido' }],
                    component: <Input placeholder='Ejemplo: Juan Pérez' />,
                    initialValue: id ? name : "",
                },
                {
                    custom: true,
                    label: "Correo Principal",
                    name: "mainEmail",
                    rules: [{ required: true, message: 'Este campo es requerido' }, { type: 'email', message: 'Debe ser un campo de email' }],
                    component: <Input placeholder='Ejemplo: juan@gmail.com' />,
                    initialValue: id ? mainEmail : "",
                }
            ],
            multipart: false,
            submitButton: false,
        };
    }

    let errors = {
        "Error address feasibility": [
            "Esta dirección es factible. Por favor créelo como oportunidad. ",
            "/futuro-cliente/oportunidades",
            "Esta dirección no es factible. Por favor créelo como lead. ",
            "/futuro-cliente/leads",
        ],
    };

    let channelOpc = [<Option value={0}>Correo</Option>, <Option value={1}>Llamada</Option>,
    <Option value={2}>Chat</Option>, <Option value={3}>Redes</Option>, <Option value={4}>WhatsApp</Option>,]

    const getPlans = (streetLocation) => {
        if (streetLocation) {
            let plans = [];
            let op = store.getState().operator;
            HttpRequest.endpoint = `pulso/factibility/?street_location=${streetLocation}&operator=${op}`;
            HttpRequest.get().then((res) => {
                if (res.plans) {
                    if (res.plans.length > 0) {
                        for (const element in res.plans) {
                            let pk = res.plans[element].pk;
                            let name = res.plans[element].name;
                            plans.push(<Option key={pk} value={pk}>{name}</Option>);
                        }
                        setPlansList(plans);
                    }
                }
            });
        } else { setPlansList([]); }
    };

    const getRegionData = (streetLocation = null) => {
        let regions = [];
        HttpRequest.endpoint = "pulso/search-region/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                if (element.id === 13 || element.id === 15) regions.push(<Option key={element["id"]} value={element["id"]}>{element["name"]}</Option>);
            });
            setRegionsList(regions);
        });
        if (props.id) {
            HttpRequest.endpoint =
                "pulso/search-complete-location/" + streetLocation + "/";
            HttpRequest.get().then((res) => {
                getCommuneData(res[0]["region_id"]);
                getStreet(res[0]["commune_id"]);
                getStreetLocation(res[0]["street_id"]);
                setRegion(res[0]["region_id"]);
                setCommune(res[0]["commune_id"]);
                setStreet(res[0]["street_id"]);
                setShowForm(true);
            });
            getCompLocation(streetLocation);
        } else {
            setShowForm(true);
        }
    };
    const getCommuneData = (region) => {
        let communes = [];
        HttpRequest.endpoint = "pulso/search-commune/" + region + "/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                communes.push(<Option key={element["id"]} value={element["id"]}>{element["name"]}</Option>);
            });
            setCommuneList(communes);
        });
    };
    const getStreet = (commune) => {
        let streets = [];
        HttpRequest.endpoint = "pulso/search-street/" + commune + "/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                streets.push(<Option key={element["id"]} value={element["id"]}>{element["name"]}</Option>);
            });
            setStreetList(streets);
        });
    };
    const getStreetLocation = (street) => {
        let streetLocations = [];
        HttpRequest.endpoint = "pulso/search-street-location/" + street + "/";
        HttpRequest.get().then((res) => {
            res.forEach((element) => {
                streetLocations.push(<Option key={element["id"]} value={element["id"]}>{element["street_no"]}</Option>);
            });
            setStreetLocationsList(streetLocations);
        });
    };
    const getCompLocation = (streetLocation) => {
        HttpRequest.endpoint =
            "pulso/search-complete-location/" + streetLocation + "/";
        let compLocations = [];
        HttpRequest.get().then((res) => {
            if (res.length > 1) {
                console.log("Aquí");
            }
            res.forEach((element) => {
                if (buildings.includes(element["edification_type"]) && element["departament"] != null) {
                    compLocations.push(<Option key={element["id"]} value={element["id"]}>{element["departament"]}</Option>);
                } else {
                    compLocations.push(<Option key={element["id"]} value={element["id"]}>{element["number"]}</Option>);
                }
            });
            setCompLocation(compLocations);
        });
    };

    async function getData(eventId) {
        HttpRequest.endpoint = props.endpoint + eventId;
        HttpRequest.get().then((res) => {
            setStreetLocation(res["street_location"]);
            setName(res["name"]);
            setMainEmail(res["primary_email"]);
            setRut(res["rut"]);
            setSecondaryEmail(res["secundary_email"]);
            setMainNumber(res["primary_phone"]);
            let MainPhoneNumber = parsePhoneNumber(res["primary_phone"]);
            setMainPhoneParsed([
                MainPhoneNumber.country,
                MainPhoneNumber.nationalNumber,
            ]);
            setSecondaryNumber(res["secundary_phone"]);
            if (res["secundary_phone"]) {
                let secundaryPhoneNumber = parsePhoneNumber(res["secundary_phone"]);
                setSecondaryPhoneParsed([
                    secundaryPhoneNumber.country,
                    secundaryPhoneNumber.nationalNumber,
                ]);
            }
            setAddress(res["address"]);
            getRegionData(res["street_location"]);
            setPlan(res["plan"]);
        });
    }

    useEffect(() => {
        setShowForm(false);
        if (id) {
            getData(id);
        } else {
            getRegionData();
        }
    }, []);
    useEffect(() => { getPlans(streetLocation) }, [streetLocation]);

    const createLead = () => {
        if (props.id) {
            if (props.lead) {
                if (!can(lead.edit)) {
                    return
                }
            } else {
                if (!can(leadlet.edit)) {
                    return
                }
            }
        } else {
            if (props.lead) {
                if (!can(lead.create)) {
                    return
                }
            } else {
                if (!can(leadlet.create)) {
                    return
                }
            }
        }
        let op = store.getState().operator;
        if (!op || op == 0) {
            Modal.error({
                title: "Error",
                content: "Por favor seleccione un operador válido",
            });
            return;
        }
        let values = formRef2.current.values();
        if (!isPossiblePhoneNumber(mainNumber)) {
            return Modal.error({
                title: "Error",
                content: "Por favor ingrese un teléfono válido",
            });
        }
        if (!streetLocation) {
            return Modal.error({
                title: "Error",
                content: "Por favor seleccione una dirección",
            });
        }
        const formData = new FormData();
        if (props.id) {
            formData.append("name", values["name"])
        } else {
            formData.append("name", values["firstName"] + ' ' + values["lastName"]);
        }
        formData.append("primary_email", values["mainEmail"]);
        if (values["secondEmail"]) formData.append("secundary_email", values["secondEmail"]);
        if (rut && props.endpoint != endPointLead) {
            if (validRut.validateRut(rut)) {
                formData.append("rut", rut);
            } else {
                return Modal.error({
                    title: "Error",
                    content: "Por favor seleccione un RUT válido",
                });
            }
        }
        if (plan) formData.append("plan", plan);
        if (secondaryNumber && secondaryNumber.length > 4) {
            if (!isPossiblePhoneNumber(mainNumber)) {
                return Modal.error({
                    title: "Error",
                    content: "Por favor ingrese un teléfono secundario válido",
                });
            } else { formData.append("secundary_phone", secondaryNumber); }
        }
        formData.append("primary_phone", mainNumber);
        formData.append("street_location", streetLocation);
        let addressName;
        if (address) {
            formData.append("address", address);
        }
        setLoading(true)
        if (props.customRequest) { props.customRequest(formData, id, addressName, { first: values["firstName"], last: values["lastName"] }); } else {
            HttpRequest.endpoint = props.endpoint;
            id
                ? HttpRequest.patch(id, formData).then((res) => {
                    setLoading(false)
                    if (!res["ID"]) {
                        Object.keys(res).forEach((element) => {
                            if (element in errors) {
                                if (props.endpoint === endPointLead) {
                                    error.push(errors[element][0] + "\n",);
                                    error.push(<Link to={errors[element][1]}>Ir a prospectos.</Link>);
                                    setError([...error]);
                                } else {
                                    error.push(errors[element][2] + "\n");
                                    error.push(<Link to={errors[element][3]}>Ir a leads.</Link>);
                                    setError([...error]);
                                }
                            } else {
                                Modal.error({
                                    title: "Error",
                                    content: "Ha ocurrido un error",
                                });
                                setLoading(false)
                            }
                        });
                    } else {
                        autoCloseModal("Se ha guardado la información correctamente")
                        props.closeModal()
                        setLoading(false)
                    }
                })
                : HttpRequest.post(formData).then((res) => {
                    setLoading(false)
                    props.parentFunction && props.parentFunction(res);
                    if (!res["ID"]) {
                        Object.keys(res).forEach((element) => {
                            if (element in errors) {
                                if (props.endpoint === endPointLead) {
                                    error.push(errors[element][0] + "\n");
                                    error.push(<Link to={errors[element][1]}>Ir a oportunidades.</Link>);
                                    setError([...error]);
                                } else {
                                    error.push(errors[element][2] + "\n");
                                    error.push(<Link to={errors[element][3]}>Ir a leads.</Link>);
                                    setError([...error]);
                                }
                            } else {
                                Modal.error({
                                    title: "Error",
                                    content: "Ha ocurrido un error",
                                });
                                setLoading(false)
                            }
                        });
                    } else {
                        autoCloseModal("Se ha guardado la información correctamente")
                        props.closeModal()
                        setLoading(false)
                    }
                });
            setLoading(false)
        }
    };

    return (
        <div>
            {showForm ? (
                <div>
                    <h1 className="form-title">Datos del usuario</h1>
                    <FormComp ref={formRef2} customSubmit={createLead} info={info} />
                    <Form.Item required label="Teléfono principal" labelCol={{ span: 4, offset: 0 }} wrapperCol={{ span: 18, offset: 0 }}>
                        <PhoneInput placeholder='Ejemplo: 9 5505 4433' required={true} defaultCountry={mainPhoneParsed[0]} defaultValue={mainNumber} value={mainPhoneParsed[1]} onChange={setMainNumber} />
                    </Form.Item>
                    <p className="form-title">Dirección</p>
                    <div className="direction-form">
                        <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
                            <Form.Item rules={[{ required: true, message: 'Este campo es requerido' }]} name="region" label="Región" initialValue={{ value: region ?? null }}>
                                <Select
                                    className="direction-select" placeholder="Ejemplo: Metropolitana de Santiago" labelInValue showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onChange={(option) => {
                                        getCommuneData(option.key);
                                        form.setFieldsValue({ commune: undefined, street: undefined });
                                        setStreetLocation(null);
                                        setAddress(undefined);
                                        setStreetList([]);
                                        setStreetLocationsList([]);
                                        setCompLocation([]);
                                        setPlan(null);
                                    }}
                                >
                                    {regionsList}
                                </Select>
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: 'Este campo es requerido' }]} name="commune" label="Comuna" initialValue={{ value: commune ?? null }}>
                                <Select
                                    className="direction-select" placeholder="Ejemplo: Providencia" labelInValue showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onChange={(option) => {
                                        getStreet(option.key);
                                        form.setFieldsValue({
                                            street: undefined,
                                        });
                                        setStreetLocation(null);
                                        setAddress(undefined);
                                        setStreetLocationsList([]);
                                        setCompLocation([]);
                                        setPlan(null);
                                    }}
                                >
                                    {communeList}
                                </Select>
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: 'Este campo es requerido' }]} name="street" label="Calle" initialValue={{ value: street ?? null }} >
                                <Select
                                    className="direction-select" placeholder="Ejemplo: Santa Rita" labelInValue showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onChange={(option) => {
                                        getStreetLocation(option.key);
                                        setStreetLocation(null);
                                        setAddress(undefined);
                                        setCompLocation([]);
                                        setPlan(null);
                                    }}
                                >
                                    {streetList}
                                </Select>
                            </Form.Item>
                            <Form.Item required label="Número" >
                                <Row className="direction-select-row" >
                                    <Select
                                        style={{ width: "40%", height: '40px' }} placeholder="Ejemplo: 694" labelInValue showSearch optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onChange={(option) => {
                                            setStreetLocation(option.key);
                                            getCompLocation(option.key);
                                            setAddress(undefined);
                                            setPlan(null);
                                        }}
                                        value={{ key: streetLocation ?? null }}
                                    >
                                        {streetLocationsList}
                                    </Select>
                                    <Form.Item label='Departamento'>
                                        <Select
                                            className='wizard-fix-width'
                                            placeholder="Ejemplo: 205" labelInValue showSearch optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            defaultValue={{ value: address ?? null, }} value={{ key: address ?? null }}
                                            onChange={(option) => { setAddress(option.key) }}>
                                            {compLocation}
                                        </Select>
                                    </Form.Item>
                                </Row>
                            </Form.Item>
                            {props.typify &&
                                <Form.Item required style={{ width: "50%" }} label="Canal" >
                                    <Select defaultValue={0} onChange={(value) => { console.log(value); props.setChannel(value) }}>
                                        {channelOpc}
                                    </Select>
                                </Form.Item>}
                        </Form>
                    </div>
                    <Button
                        loading={loading}
                        type="primary" onClick={() => {
                            setError([]); formRef2.current.submit();
                        }} className="center-btn">{props.endpoint ? 'Guardar' : "Consultar Factibilidad"}</Button>
                    <div className="form-group">
                        <p className="text-danger text-center">{error}</p>
                        <p className="text-center">{exito}</p>
                    </div>
                </div>
            ) : (
                ""
            )
            }
        </div >
    );
};

export default LeadCreateModal;
