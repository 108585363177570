import React from 'react'
import { connect } from 'react-redux'

import Icon from './../icons/Icon'
import { Input, Button, Row, Col } from 'reactstrap'
// import Input from './../inputs/Input'

import ACTIONS from './../../redux/creator'

const PanelRightHeader = ({ sidebar, handleSection, ...props }) => {
	const clearPanel = () => {
		props.toggleSidebar({
			child: null,
			active: false,
			show: false
		})
	}

	return (
		<>
			<Row>
				<Col xs={10}>
					<Input type="select" onChange={handleSection}>
						<option value="general">General</option>
					</Input>
				</Col>
				<Col xs={2}>
					<Button color="default" onClick={clearPanel}><Icon icon="trash" /></Button>
				</Col>
			</Row>
		</>
	)
}

const mapStateToProps = state => ({
	sidebar: state.sidebar
})

const mapDispatchToProps = dispatch => ({
	toggleSidebar(payload) {
		dispatch(ACTIONS.toggleSidebar(payload))
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(PanelRightHeader)