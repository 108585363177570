import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import RemoveEquipTable from './removeEquipTable';

function RemoveEquipSearchTables() {
    const endpoint = 'formerCustomers/remove_equipament_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<RemoveEquipTable />} />
        </>
    )
}

export default RemoveEquipSearchTables;