import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { escalationTests } from '../../config/permissions';
import SacTestForm from './SacTestForm';

function SacTestTable(props) {
    let endpoints = {
        columns: "escalation_ti/test_group/datatables_struct/",
        rows: "escalation_ti/test_group/datatables/",
        delete: `escalation_ti/test_group/`,
    }

    return (
        <FullTable modalContent={<SacTestForm />} permissions={escalationTests} endpoints={endpoints} tableName='prueba' />
    )
}

export default SacTestTable;