import React, { useEffect, useState } from "react";
import { Form, Comment, List, Button, Input, Tooltip, Modal, Row } from "antd";
import moment from "moment";
import { CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import HttpRequest from "../../services/HttpRequest";
import Moment from "react-moment";
import { can } from "../../helpers/helpers";
import { tipifyComments } from "../../config/permissions";

const TypifyComments = (props) => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("");
    const [agentList, setAgentList] = useState();
    const [editing, setEditing] = useState(false);
    const [editId, setEditId] = useState();
    const [editValue, setEditValue] = useState();
    const { TextArea } = Input;
    const [form] = useForm();

    const createComment = () => {
        if (can(tipifyComments.create)) {
            let formData = new FormData();
            HttpRequest.endpoint = "communications/internal_chat/";
            formData.append("message", value);
            formData.append("chat", props.id);
            HttpRequest.post(formData).then((res) => {
                if (res["ID"]) {
                    Modal.success({ title: "Éxito", content: "Se ha añadido el comentario exitosamente" })
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al crear el comentario" })
                }
                getCommentaries(props.id)
                setValue("");
            })
        }
    }

    const deleteComment = (id) => {
        if (can(tipifyComments.delete)) {
            HttpRequest.endpoint = "communications/internal_chat/";
            HttpRequest.delete(id).then((res) => {
                if (res['invalid user'] === 'only the creator can edit this message') {
                    Modal.error({
                        title: 'Error',
                        content: 'No puede eliminar el comentario si no es el creador'
                    })
                } else {
                    getCommentaries(props.id)
                }

            })
        }
    }

    const editComment = (id, message) => {
        if (can(tipifyComments.edit)) {
            HttpRequest.endpoint = "communications/internal_chat/";
            let formData = new FormData();
            formData.append("message", message);
            formData.append("chat", props.id)
            HttpRequest.patch(id, formData).then((res) => {
                if (res['invalid user'] === 'only the creator can edit this message') {
                    Modal.error({
                        title: 'Error',
                        content: 'No puede eliminar el comentario si no es el creador'
                    })
                } else {
                    setEditing(false);
                    setEditId(null);
                }
            })
        }
    }

    const getAgents = () => {
        let list = {};
        HttpRequest.endpoint = "user/data/information/";
        HttpRequest.get().then((res) => {
            res.forEach(element => {
                list[element["ID"]] = element["name"]
            });
            setAgentList(list);
        })
    }

    useEffect(() => {
        if (agentList) getCommentaries(props.id);
    }, [agentList, editId])

    const getCommentaries = (id) => {
        let list = [];
        HttpRequest.endpoint = "communications/internal_chat/";
        let filters = { chat: id };
        HttpRequest.get(filters).then((res) => {
            res.forEach(element => {
                if (editing && element["ID"] == editId) {
                    list.push({
                        key: element["ID"],
                        author: agentList[element["creator"]],
                        content: <Row><Form form={form}><Form.Item name="editValue">
                            <Input defaultValue={element["message"]} style={{ width: "200px" }} />
                        </Form.Item></Form>
                            <Button onClick={() => editComment(element["ID"], form.getFieldValue("editValue"))}><CheckOutlined /></Button><Button onClick={() => { setEditing(false); setEditId(null) }}><CloseOutlined /></Button></Row>,
                        datetime: <Moment fromNow locale="es">{element["created"]}</Moment>,
                        actions: []
                    })
                } else {
                    list.push({
                        key: element["ID"],
                        author: agentList[element["creator"]],
                        content: <div className="typify-comments">{element["message"]}</div>,
                        datetime: <Moment fromNow locale="es">{element["created"]}</Moment>,
                        actions: !props.hideButtons && [
                            <span>
                                <Tooltip key="delete-comment" title="Eliminar">
                                    <CloseOutlined onClick={() => deleteComment(element["ID"])} />
                                </Tooltip>
                                <Tooltip key="edit-comment" title="Editar">
                                    <EditOutlined onClick={() => {
                                        setEditing(true);
                                        setEditId(element["ID"]);
                                    }} />
                                </Tooltip>
                            </span>
                        ]
                    })
                }
            });
            setComments(list)
        })
    }

    const CommentList = ({ comments }) => (
        <List
            dataSource={comments}
            itemLayout="horizontal"
            renderItem={props => <Comment {...props} />}
        />
    );

    useEffect(() => {
        getAgents();
        if (!props.id) Modal.error({ title: "Error", content: "La tipificación no ha sido registrada" })
    }, [])

    return (
        <>
            {comments.length > 0 &&
                <CommentList comments={comments} />
            }
            {!props.hideButtons && <Comment
                content={
                    <>
                        <Form.Item>
                            <TextArea rows={4} value={value} onChange={(e) => setValue(e.target.value)} />
                        </Form.Item>
                        <Form.Item>
                            <div style={{ display: "flex", alignContent: "flex-end", width: "100%", justifyContent: "flex-end" }}>
                                <Button htmlType="submit" type="primary" onClick={createComment} disabled={value.length == 0}>
                                    Añadir Comentario
                            </Button>
                            </div>
                        </Form.Item>
                    </>
                }
            />}
        </>
    );
}

export default TypifyComments;