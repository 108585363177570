import React, { useEffect, useState } from "react";
import { Panel, PanelBody } from "../../components/panel/panel";
import {
	Radio,
	Form,
	Select,
	Row,
	Spin,
	Button,
	Modal,
	Drawer,
	Tooltip,
	Col,
	Tabs,
	Popover,
} from "antd";
import HttpRequest from "../../services/HttpRequest";
import { connect } from "react-redux";
import {
	SearchOutlined,
	MessageOutlined,
	HistoryOutlined,
	SolutionOutlined,
	CommentOutlined
} from "@ant-design/icons";
import "./typify.css";
import TypifyComments from "./typifyComments.jsx";
import { useForm } from "antd/lib/form/Form";
import CustomerInfoModal from "./customerInfoModal";
import ServiceInfoModal from "./servicesInfoModal";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/es-us";
import TypifyHistory from "./typifyHistory";
import TypifyReconnect from "./typifyReconnect";
import TypifyCommunication from "./typifyCommunication";

import { can } from "../../helpers/helpers";
import {
	typifications,
	tipifyComments,
	recontact,
	qc,
} from "../../config/permissions";
import Escalation from "../escalations/Escalation";
import EscalationDetails from "../escalations/EscalationDetails";
import PaymentDelay from "./../payment-wizards/PaymentDelay";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import InspeccionesForm from "../qc/InspeccionesForm";
import { canWithoutMessage } from "./../../helpers/helpers";
import MassiveFilters from "./../massive/filtersForm";
import Config from "../../config";
import errorModal from "./../../components/modal/errorModal";
import CategoryForm from "./categoryForm";
import Conversation from "../conversations/Conversation";

const Typify = (props) => {

	const { Option } = Select;
	const [customer, setCustomer] = useState(true);
	const [customerList, setCustomerList] = useState([]);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [criterion, setCriterion] = useState("service");
	const [serviceList, setServiceList] = useState([]);
	const [agentList, setAgentList] = useState([]);
	const [customerModal, setCustomerModal] = useState(false);
	const [serviceModal, setServiceModal] = useState(false);
	const [customerRut, setCustomerRut] = useState();
	const [SLA, setSLA] = useState();
	const [visible, setVisible] = useState(false);
	const [id, setId] = useState(props.id);
	const [chatId, setChatId] = useState(null);
	const [customerType, setCustomerType] = useState();
	const [drawerContent, setDrawerContent] = useState("commentary");
	const [SlaColor, setSlaColor] = useState("");
	const [form] = useForm();
	const [showEscalation, setShowEscalation] = useState(false);
	const [escalationID, setEscalationID] = useState(null);
	const [customerServices, setCustomerServices] = useState(null);
	const [escalationButtonLoading, setEscalationButtonLoading] =
		useState(false);
	const [categoriesList, setCategoriesList] = useState([]);
	const [agents, setAgents] = useState(null);
	const [disableFields, setDisableFields] = useState(false);
	const [tipifyLoading, setTipifyLoading] = useState(false);
	const [typing, setTyping] = useState(false);
	const [delayPaymentWizard, setDelayPaymentWizard] = useState(false);
	const [disabledFields, setDisabledFields] = useState([]);
	const [showInspection, setShowInspection] = useState(false);
	const [openOptions, setOpenOptions] = useState(true);
	const [showFilterServices, setShowFilterServices] = useState(false);
	const [hideRutSearch, setHideRutSearch] = useState(false);
	const [multipleClients, setMultipleClients] = useState([]);
	const [filterLoading, setFilterLoading] = useState(false);
	const [filterServicesList, setFilterServicesList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [categoryValues, setCategoriesValues] = useState([]);
	const [whatsappConversation, setWhatsappConversation] = useState(null);
	const [emailConversation, setEmailConversation] = useState(null);

	let clientTypeOpc = [
		<Option key="0" value={0}>
			No Cliente
		</Option>,
		<Option value={3} key="1">
			Cliente
		</Option>,
		<Option value={4} key="4">
			Múltiples clientes
		</Option>,
	];
	let channelOpc = [
		<Option key="0" value={0}>
			Correo (LA)
		</Option>,
		<Option key="1" value={1}>
			Llamadas (LL)
		</Option>,
		<Option key="2" value={5}>
			Facebook (FB)
		</Option>,
		<Option key="3" value={6}>
			Instagram (IG)
		</Option>,
		<Option key="4" value={4}>
			WhatsApp (WS)
		</Option>,
	];
	let stateOpc = [
		<Option key="0" value={0}>
			Abierto
		</Option>,
		<Option key="1" value={1}>
			Cerrado
		</Option>,
		<Option key="2" value={2}>
			Esperando respuesta
		</Option>,
		<Option key="3" value={3}>
			Dar seguimiento
		</Option>,
		<Option key="4" value={4}>
			Llamar luego
		</Option>,
	];
	let cityOpc = [
		<Option key="0" value={0}>
			Arica
		</Option>,
		<Option key="1" value={1}>
			Santiago
		</Option>,
	];
	let channelTypeOpc = [
		<Option key="0" value={0}>
			In
		</Option>,
		<Option key="1" value={1}>
			Out
		</Option>,
	];

	let channelContact = [
		<Option key="0" value={0} >
			Otro
		</Option>,
		<Option key="1" value={1} >
			Conserjes
		</Option>,
		<Option key="2" value={2} >
			Promotores
		</Option>,
		<Option key="3" value={3} >
			Volantes
		</Option>,
		<Option key="4" value={4} >
			Web
		</Option>,
		<Option key="5" value={5} >
			Redes
		</Option>,

	]



	if (props.id) {
		stateOpc.push(
			<Option key="5" value={5}>
				Cancelado
			</Option>
		);
	}


	const getTypifyData = (id) => {
		HttpRequest.endpoint = "tickets/typify/" + id + "/";
		HttpRequest.get().then((res) => {
			setCustomerType(res["customer_type"]);
			setChatId(res["commentaries"])
			setWhatsappConversation(res["conversation_whatsapp"])
			setEmailConversation(res["conversation_email"])
			form.setFieldsValue({
				liveagent: res["liveagent"],
				channel: res["channel"],
				type: res["type"],
				status: res["status"],
				agent: res["agent"],
				city: res.city,
				contactChannel: res["contact_channel"],
				typifySubcategory: res.category,
			});
			setCategoriesValues(res.category);
			HttpRequest.endpoint = "tickets/typify/categories_names/";
			const formData = new FormData();
			formData.append("categories", JSON.stringify(res.category));
			HttpRequest.post(formData).then((res2) => {
				if (res2 && Array.isArray(res2)) {
					setCategoriesList(
						res2.map((cat) => ({
							value: cat.ID,
							label: cat.name,
						}))
					);
				}
			});
			setDisableFields(res.status === 1 || res.status === 5);
			setCustomerRut(res["rut"]);
			const start = moment().add(-1 * res["SLA"], "s");
			setSLA(start);
			setSlaColor(
				res["status_SLA"] == "danger"
					? "lightsalmon"
					: res["status_SLA"] == "alert"
						? "khaki"
						: "aliceblue"
			);
			setEscalationID(res.escalation_tk);
			setCustomerServices(res.services);
			if (res.status === 5) {
				setDisabledFields((prevState) => prevState.concat(["status"]));
			} else if (res.status === 0) {
				setDisabledFields([]);
				setDisableFields(false);
			}
			if (
				res.process_display === "Prorroga de pago" &&
				res.status === 0
			) {
				setDelayPaymentWizard(true);
				setDisabledFields((prevState) =>
					prevState.concat(["typifySubcategory", "status"])
				);
			}
			if (res.process_display === "Factibilidad") {
				setDisabledFields((prevState) =>
					prevState.concat(["typifySubcategory", "status"])
				);
			}
		});
	};

	const getServices = (rut) => {
		let list = [];
		const formData = {
			customer__rut: rut,
			operator: props.operator,
			fields: "number,commune",
		};
		if (props.operator == 0 || props.operator == null) {
			Modal.error({
				title: "Error",
				content: "Por favor seleccione un operador válido",
			});
			return;
		}
		HttpRequest.endpoint = "matrix/services/";
		HttpRequest.get(formData).then((res) => {
			res.forEach((element) => {
				list.push(
					<Option key={element["number"]} value={element["number"]}>
						{element["number"]}
					</Option>
				);
				if (
					element["commune"] === "Arica" ||
					element["commune"] === "Santiago"
				) {
					form.setFieldsValue({
						city: element["commune"] === "Arica" ? 0 : 1,
					});
				}
			});
			setServiceList(list);
			if (list.length === 1) {
				form.setFieldsValue({ services: [res[0].number] });
				setCustomerServices([res[0].number]);
			}
		});
	};

	const getAgents = () => {
		let list = [];
		HttpRequest.endpoint = "user/data/information/";
		HttpRequest.get({ fields: "ID,name" }).then((res) => {
			res.forEach((element) => {
				list.push(
					<Option value={element["ID"]} key={element["ID"]}>
						{element["name"]}
					</Option>
				);
			});
			setAgentList(list);
			setAgents(res);
		});
	};

	const searchUser = (value) => {
		if (value != null && value.length > 0) {
			setCustomerList([]);
			setLoadingUsers(true);
			HttpRequest.endpoint = "matrix/customer_filter/";
			const formData = { criterion: criterion, search: value };
			HttpRequest.get(formData).then((res) => {
				if (res && Array.isArray(res)) {
					setCustomerList(res);
					if (criterion === "service" && res.length === 1) {
						form.setFieldsValue({
							customer_rut: res[0].id,
							services: undefined,
						});
						setOpenOptions(false);
						//onchange
						getServices(res[0].id);
						setTyping(false);
						setCustomerRut([res[0].id]);
					}
				}
			});
		}
	};

	const onKeyPress = (e) => {
		const specialCharRegex = new RegExp("^[0-9]*$");
		const pressedKey = String.fromCharCode(
			!e.charCode ? e.which : e.charCode
		);
		if (!specialCharRegex.test(pressedKey)) {
			e.preventDefault();
			return false;
		}
	};

	const submitTypify = (
		values,
		escalationData = null,
		escalationPhotos = null
	) => {
		if (props.id) {
			if (!can(typifications.edit)) {
				return;
			}
		} else {
			if (!can(typifications.create)) {
				return;
			}
		}
		const formData = new FormData();
		if (
			values["customer"] == 3 &&
			(values["services"] == null ||
				values["services"].length == 0 ||
				values["customer_rut"] == null)
		) {
			Modal.error({
				title: "Error",
				content: "Debe seleccionar un cliente y al menos un servicio",
			});
			setEscalationButtonLoading(false);
			return;
		}
		if (values.customer === 4 && values["services"].length == 0) {
			Modal.error({
				title: "Error",
				content: "Debe seleccionar al menos un servicio",
			});
			setEscalationButtonLoading(false);
			return;
		}
		if (categoryValues.length === 0) {
			Modal.error({
				title: "Error",
				content: "Debe seleccionar una subcategoría",
			});
			setEscalationButtonLoading(false);
			return;
		}
		if (values["liveagent"] != undefined)
			formData.append("liveagent", values["liveagent"]);
		if (!props.id && values["customer"] == 3) {
			formData.append("services", JSON.stringify(values["services"]));
			formData.append("rut", JSON.stringify([values["customer_rut"]]));
		}
		if (!props.id && values.customer === 4) {
			formData.append("services", JSON.stringify(values["services"]));
			formData.append("rut", JSON.stringify(multipleClients));
		}
		props.id
			? formData.append("customer_type", customerType)
			: formData.append("customer_type", values["customer"]);
		formData.append("channel", values["channel"]);
		formData.append("type", values["type"]);
		formData.append("city", values["city"]);
		formData.append("status", values["status"]);

		if (props.conversationID) formData.append(props.conversationChannel === 4 ? "conversation_whatsapp" : "conversation_email", props.conversationID);
		if (props.id) {
			formData.append("agent", values["agent"]);
		}
		formData.append("category", JSON.stringify(categoryValues));
		if (escalationData) {
			formData.append("escalate", true);
			formData.append("escalation_data", JSON.stringify(escalationData));
			formData.append("contact_channel", values["channelContact"]);
		}
		setTipifyLoading(true);
		HttpRequest.endpoint = "tickets/typify/";
		setLoading(true);
		props.id
			? HttpRequest.patch(props.id, formData).then((res) => {
				setTipifyLoading(false);
				setLoading(false);
				if (res["ID"]) {
					setShowEscalation(false);
					autoCloseModal(
						escalationData
							? "Se ha editado la tipificación y se ha escalado con éxito"
							: "Se ha modificado la tipificación exitosamente"
					);
					if (res.status === 1 || res.status === 5) {
						setDisableFields(true);
					}
					if (escalationData) {
						setEscalationID(res.escalation_tk);
					}
					if (res.status === 5) {
						setDisabledFields((prevState) =>
							prevState.concat(["status"])
						);
						props.setModal(true);
					} else if (res.status === 0) {
						setDisabledFields([]);
						setDisableFields(false);
					}
					if (
						res.process_display === "Prorroga de pago" &&
						res.status === 0
					) {
						setDelayPaymentWizard(true);
						setDisabledFields((prevState) =>
							prevState.concat([
								"typifySubcategory",
								"status",
							])
						);
					}
					if (res.process_display === "Factibilidad") {
						setDisabledFields((prevState) =>
							prevState.concat([
								"typifySubcategory",
								"status",
							])
						);
					}
				} else if (res["escalation status"]) {
					Modal.error({
						title: "Error",
						content:
							"No puede cerrar la tipificación mientras el escalamiento asociado se encuentre abierto",
					});
				} else {
					setEscalationButtonLoading(false);
					setLoading(false);
					setTipifyLoading(false);
					Modal.error({
						title: "Error",
						content:
							"Ha ocurrido un error al modificar la tipificación",
					});
				}
			})
			: HttpRequest.post(formData).then((res) => {
				setLoading(false);
				if (res["ID"]) {
					if (props.conversationID) {
						HttpRequest.endpoint = "communications/conversation/"
						const formData = new FormData();
						formData.append("typify", res["ID"]);
						if (values["customer"] == 3) {
							formData.append("services", JSON.stringify(values["services"]));
							formData.append("rut", values["customer_rut"]);
						}
						HttpRequest.patch(props.conversationID, formData).then((res) => {
							if (res["ID"]) {
								autoCloseModal("Se ha creado y asignado el tickket correctamente");
								props.closeModal();
								props.refreshConversation();
							}
						})
					}
					setId(res["ID"]);
					props.updateRows && props.updateRows();
					if (props.startAgain) {
						props.startAgain();
						form.setFieldsValue({
							services: undefined,
							typifySubcategory: undefined,
						});
						setCategoriesValues([]);
						setCategoriesList([]);
					}
					if (escalationPhotos) {
						uploadEscalationPhotos(
							escalationPhotos,
							res.escalation_tk
						);
					} else {
						setTipifyLoading(false);
						setEscalationButtonLoading(false);
						setShowEscalation(false);
						autoCloseModal(
							escalationData
								? "Se ha creado la tipificación y se ha escalado con éxito"
								: "Se ha creado la tipificación exitosamente"
						);
					}
				} else {
					setEscalationButtonLoading(false);
					setLoading(false);
					setTipifyLoading(false);
					Modal.error({
						title: "Error",
						content:
							"Ha ocurrido un error al crear la tipificación",
					});
				}
			});
	};

	const uploadEscalationPhotos = (photoArray, escalationID) => {
		Promise.all(
			photoArray.map((photoObj) => {
				HttpRequest.endpoint = "escalation_ti/escalation_photo/";
				const formData = new FormData();
				formData.append("escalation", escalationID);
				formData.append("image", photoObj.originFileObj);
				return HttpRequest.post(formData);
			})
		).then((allRes) => {
			let error = 0;
			allRes.forEach((res) => {
				if (!res || !res.ID) {
					error++;
				}
			});
			setEscalationButtonLoading(false);
			setLoading(false);
			setTipifyLoading(false);
			setShowEscalation(false);
			if (error === 0) {
				autoCloseModal(
					"Se ha creado la tipificación y se ha escalado con éxito"
				);
			} else {
				Modal.error({
					title: "Error",
					content:
						"Ha ocurrido un error con las fotos del escalamiento. Sin embargo, el escalamiento y la tipificación se crearon con éxito. Si desea intentar agregar las fotos nuevamente, puede hacerlo desde la vista de detalle del escalamiento",
				});
			}
		});
	};

	const receiveEscalation = (escalationData, escalationPhotos) => {
		setEscalationButtonLoading(true);
		const values = form.getFieldsValue();
		submitTypify(values, escalationData, escalationPhotos);
	};

	const getFilters = (values, filtros) => {
		setFilterLoading(true);
		fetch(Config.API_URL + "/matrix/services-filters-dict/", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `JWT ${localStorage.getItem("authorization")}`,
			},
			body: JSON.stringify({
				criterion: values.typeFilter.value,
				filters: filtros,
				unpaid_ballot: values.unpaid_ballot ? true : false,
				excludes: [],
				includes: [],
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				setFilterLoading(false);
				if (res && typeof res === "object") {
					const list = [];
					for (let key in res) {
						list.push(
							<Option key={key} value={key}>
								{key}
							</Option>
						);
					}
					setServiceList(list);
					form.setFieldsValue({
						services: Object.keys(res),
					});
					setMultipleClients(Object.values(res));
					setFilterServicesList(res);
					setShowFilterServices(false);
				} else {
					errorModal(
						"Ha ocurrido un error consultando los servicios según los filtros"
					);
				}
			});
	};

	const handleMultipleServices = (services) => {
		const rutList = [];
		services.forEach((serv) => {
			if (serv in filterServicesList) {
				rutList.push(filterServicesList[serv]);
			}
		});
		setMultipleClients(rutList);
	};

	useEffect(() => {
		if (props.id != null) {
			getTypifyData(props.id);
		}
		getAgents();
	}, []);

	useEffect(() => {
		if (form.getFieldValue("customer_rut") != null) {
			getServices(form.getFieldValue("customer_rut")["key"]);
		}
		form.setFieldsValue({
			services: undefined,
			typifySubcategory: undefined,
		});
		setCategoriesValues([]);
		//getTypifyType()
	}, [props.operator]);

	useEffect(() => {
		if (!props.id && customerRut && typing) {
			const delay = setTimeout(() => {
				searchUser(customerRut[0]);
			}, 1500);
			return () => clearTimeout(delay);
		}
	}, [customerRut]);

	return (
		<Panel>
			<PanelBody>
				<Tabs centered>
					<Tabs.TabPane tab={`${props.finanzasView ? "" : 'Tipificación'}`} key="tipify">
						<Form
							form={form}
							onFinish={(values) => {
								props.receiveTipification
									? props.receiveTipification(values)
									: submitTypify(values);
							}}
						>
							<div
								style={{
									width: "100%",
									display: "flex",
									marginTop: "25px",
								}}
							>
								<div style={{ width: "30%", display: "flex" }}>
									{props.receiveTipification == null &&
										!props.hideSmallButtons && (
											<Tooltip title="Comentarios">
												<Button
													shape="circle"
													style={{
														marginRight: "10px",
													}}
													icon={<MessageOutlined />}
													onClick={() => {
														if (
															can(
																tipifyComments.view
															)
														) {
															setVisible(true);
															setDrawerContent(
																"commentary"
															);
														}
													}}
												/>
											</Tooltip>
										)}
									{props.id && !props.hideSmallButtons && (
										<div style={{ display: "flex" }}>
											<Tooltip title="Historial">
												<Button
													shape="circle"
													style={{
														marginRight: "10px",
													}}
													icon={<HistoryOutlined />}
													onClick={() => {
														setVisible(true);
														setDrawerContent(
															"history"
														);
													}}
												/>
											</Tooltip>
											<Tooltip title="Reconectar">
												<Button
													shape="circle"
													style={{
														marginRight: "10px",
													}}
													icon={<SolutionOutlined />}
													onClick={() => {
														if (
															can(recontact.view)
														) {
															setVisible(true);
															setDrawerContent(
																"reconnect"
															);
														}
													}}
												/>
											</Tooltip>

											<Tooltip title="Comunicacion">
												<Button
													shape="circle"
													style={{
														marginRight: "10px",
													}}
													icon={<CommentOutlined />}
													onClick={() => {
														if (
															can(recontact.view)
														) {
															setVisible(true);
															setDrawerContent(
																"communication"
															);
														}
													}}
												/>
											</Tooltip>

										</div>
									)}
									<Drawer
										title="Basic Drawer"
										placement="right"
										width={
											drawerContent == "commentary"
												? "380px"
												: drawerContent == "history"
													? "580px"
													: "400px"
										}
										closable={false}
										onClose={() => setVisible(false)}
										visible={visible}
									>
										{drawerContent == "commentary" && (
											<TypifyComments
												hideButtons={
													props.hideButtons ||
													disableFields
												}
												id={chatId}
											/>
										)}
										{drawerContent == "history" && (
											<TypifyHistory id={id} />
										)}
										{drawerContent == "reconnect" && (
											<TypifyReconnect
												hideButtons={
													props.hideButtons ||
													disableFields
												}
												id={id}
											/>
										)}

										{drawerContent == "communication" && (
											<TypifyCommunication
												hideButtons={
													props.hideButtons ||
													disableFields
												}
												id={id}
											/>
										)}
									</Drawer>
								</div>
								{props.id != null && !props.hideButtons && (
									<div className="typify-top-buttons">
										{!disabledFields.includes("status") && (
											<>
												<Button
													htmlType="submit"
													type="primary"
													className="typify-top-save-buttons"
													loading={loading}
												>
													Guardar
												</Button>
												<Button
													type="primary"
													loading={loading}
													onClick={() => {
														form.submit();
														props.closeTab(
															props.tabID
														);
													}}
													className="typify-top-save-buttons"
												>
													Guardar y salir
												</Button>
											</>
										)}
										{!disableFields && (
											<Button
												type="primary"
												loading={loading}
												onClick={() => {
													form.setFieldsValue({
														status: 1,
													});
													form.submit();
												}}
												className="typify-top-save-buttons"
											>
												Guardar y cerrar
											</Button>
										)}
										<Button
											type="primary"
											loading={loading}
											onClick={() => {
												props.closeTab(props.tabID);
											}}
											className="typify-top-save-buttons"
										>
											Salir
										</Button>
										{customerType === 3 && !props.tabs && (
											<>
												<Button
													onClick={() =>
														setCustomerModal(true)
													}
												>
													Cliente
												</Button>
												<Button
													onClick={() =>
														setServiceModal(true)
													}
												>
													Servicio
												</Button>
											</>
										)}
									</div>
								)}
							</div>
							{props.id == null &&
								props.receiveTipification == null &&
								!hideRutSearch && (
									<div>
										<div className="radio-group-div">
											<Form.Item>
												<Radio.Group
													className="radio-group"
													onChange={(e) =>
														setCriterion(
															e.target.value
														)
													}
													defaultValue={"service"}
												>
													<Radio value={"rut"}>
														Rut
													</Radio>
													<Radio value={"name"}>
														Nombre
													</Radio>
													<Radio
														value={
															"composite_address"
														}
													>
														Dirección
													</Radio>
													<Radio value={"service"}>
														Servicio
													</Radio>
												</Radio.Group>
											</Form.Item>
										</div>
									</div>
								)}
							{props.id == null &&
								props.receiveTipification == null && (
									<Row className="new-typify-row">
										<Form.Item
											label="Tipo:"
											name="customer"
											initialValue={3}
											className="new-typify-row-item"
										>
											<Select
												defaultValue={3}
												onChange={(value) => {
													//searchUser(" ");
													setOpenOptions(false);
													setCustomer(
														value == 3 || value == 4
													);
													form.setFieldsValue({
														customer_rut: undefined,
														services: undefined,
													});
													if (value === 4) {
														setShowFilterServices(
															true
														);
														setHideRutSearch(true);
													} else {
														setHideRutSearch(false);
													}
												}}
											>
												{clientTypeOpc}
											</Select>
										</Form.Item>
										{hideRutSearch ? (
											<Button
												onClick={() =>
													setShowFilterServices(true)
												}
												type="primary"
											>
												Buscar múltiples clientes
											</Button>
										) : (
											<div className="new-typify-row-item">
												<Form.Item
													label="Cliente:"
													name="customer_rut"
													className="new-typify-row-item-input"
												>
													{criterion == "service" ? (
														<Select
															open={openOptions}
															onClick={() =>
																setOpenOptions(
																	true
																)
															}
															className="typify-rut-field"
															onKeyPress={
																onKeyPress
															}
															placeholder="Ingrese el número de servicio"
															disabled={!customer}
															showSearch
															notFoundContent={
																loadingUsers ? (
																	<Spin size="small" />
																) : null
															}
															filterOption={false}
															onSearch={(val) => {
																setTyping(true);
																setCustomerRut([
																	val,
																]);
															}}
															onChange={(
																option
															) => {
																getServices(
																	option
																);
																setTyping(
																	false
																);
																setCustomerRut([
																	option,
																]);
																setOpenOptions(
																	false
																);
																form.setFieldsValue(
																	{
																		services:
																			undefined,
																	}
																);
															}}
														>
															{customerList.map(
																(element) => (
																	<Option
																		key={
																			element[
																			"id"
																			]
																		}
																		value={
																			element.id
																		}
																	>
																		{
																			element[
																			"text"
																			]
																		}
																	</Option>
																)
															)}
														</Select>
													) : (
														<Select
															className="typify-rut-field"
															placeholder={
																criterion ==
																	"rut"
																	? "Ingrese el rut"
																	: criterion ==
																		"composite_address"
																		? "Ingrese la dirección"
																		: "Ingrese el nombre del cliente"
															}
															disabled={!customer}
															showSearch
															notFoundContent={
																loadingUsers ? (
																	<Spin size="small" />
																) : null
															}
															filterOption={false}
															onSearch={(val) => {
																setTyping(true);
																setCustomerRut([
																	val,
																]);
															}}
															onChange={(
																option
															) => {
																getServices(
																	option
																);
																setTyping(
																	false
																);
																setCustomerRut([
																	option,
																]);
																setOpenOptions(
																	false
																);
																form.setFieldsValue(
																	{
																		services:
																			undefined,
																	}
																);
															}}
														>
															{customerList.map(
																(element) => (
																	<Option
																		key={
																			element[
																			"id"
																			]
																		}
																		value={
																			element.id
																		}
																	>
																		{
																			element[
																			"text"
																			]
																		}
																	</Option>
																)
															)}
														</Select>
													)}
												</Form.Item>
												<Button
													shape="circle"
													icon={<SearchOutlined />}
													onClick={() =>
														setCustomerModal(true)
													}
												/>
											</div>
										)}
										<div className="new-typify-row-item">
											<Form.Item
												label="Servicios"
												name="services"
												className="new-typify-row-item-input"
											>
												<Select
													maxTagCount={4}
													placeholder="Servicio"
													disabled={!customer}
													mode="multiple"
													onChange={(e) => {
														setCustomerServices(e);
														handleMultipleServices(
															e
														);
													}}
												>
													{serviceList}
												</Select>
											</Form.Item>
											{!hideRutSearch && (
												<Button
													shape="circle"
													icon={<SearchOutlined />}
													onClick={() =>
														setServiceModal(true)
													}
												/>
											)}
										</div>
									</Row>
								)}
							<Row className="typify-row">
								<Col span={9}>
									<Form.Item
										label="Categoría:"
										name="typifySubcategory"
									>
										<Select
											mode="multiple"
											className="width-fix-typify"
											placeholder="Subcategorías"
											onChange={(vals) =>
												setCategoriesValues(vals)
											}
											value={categoryValues}
											disabled={props.id}
										>
											{categoriesList.map((cat) => (
												<Select.Option
													value={cat.value}
													key={cat.value}
												>
													{cat.label}
												</Select.Option>
											))}
										</Select>
										{!props.id && (
											<Popover
												content={
													<CategoryForm
														setCategoriesList={
															setCategoriesList
														}
														currentValues={
															categoryValues
														}
														setCurrentValues={
															setCategoriesValues
														}
													/>
												}
												title="Title"
												trigger="click"
											>
												<Button>+</Button>
											</Popover>
										)}
									</Form.Item>
									{props.receiveTipification == null && (
										<Form.Item
											label="Tipo:"
											name="type"
											initialValue={0}
										>
											<Select
												disabled={
													props.hideButtons ||
													disableFields ||
													disabledFields.includes(
														"type"
													)
												}
												defaultValue={0}
											>
												{channelTypeOpc}
											</Select>
										</Form.Item>
									)}
									{props.receiveTipification == null && (
										<Form.Item
											label="Canal:"
											name="channel"
											initialValue={props.conversationChannel ?? 0}
										>
											<Select
												disabled={
													props.hideButtons ||
													disableFields ||
													disabledFields.includes(
														"channel"
													)
												}
												defaultValue={props.conversationChannel}
											>
												{channelOpc}
											</Select>
										</Form.Item>
									)}
									{props.id && (
										<Form.Item name="SLA" label="SLA">
											<div
												style={{
													backgroundColor: SlaColor,
													padding: "5px",
												}}
											>
												<Moment fromNow ago locale="en">
													{SLA}
												</Moment>{" "}
												abierto
											</div>
										</Form.Item>
									)}
								</Col>
								<Col span={14}>
									{props.receiveTipification == null && (
										<Form.Item
											label="Estado:"
											name="status"
											initialValue={0}
										>
											<Select
												disabled={
													props.hideButtons ||
													disabledFields.includes(
														"status"
													)
												}
												defaultValue={0}
											>
												{stateOpc}
											</Select>
										</Form.Item>
									)}
									{props.receiveTipification == null && (
										<Form.Item
											label="Ciudad:"
											name="city"
											initialValue={1}
										>
											<Select
												disabled={
													props.hideButtons ||
													disableFields ||
													disabledFields.includes(
														"city"
													)
												}
												defaultValue={1}
											>
												{cityOpc}
											</Select>
										</Form.Item>
									)}
									{props.id && (
										<Form.Item name="agent" label="Agente">
											<Select
												disabled={
													props.hideButtons ||
													disableFields ||
													disabledFields.includes(
														"agent"
													)
												}
												placeholder="Seleccione un técnico"
											>
												{agentList}
											</Select>
										</Form.Item>
									)}
									{props.receiveTipification == null && (
										<Form.Item
											label="Canal de contacto:"
											name="channelContact"
											initialValue={props.conversationChannel ?? 0}
										>
											<Select
												disabled={
													props.hideButtons ||
													disableFields ||
													disabledFields.includes(
														"channelContact"
													)
												}
												defaultValue={props.conversationChannel}
											>
												{channelContact}
											</Select>
										</Form.Item>
									)}

								</Col>
							</Row>
							<div className="center-buttons">
								{props.id == null && (
									<Button
										htmlType="submit"
										type="primary"
										loading={tipifyLoading}
									>
										{props.receiveTipification != null
											? "Siguiente"
											: "Guardar"}
									</Button>
								)}
								{
									props.finanzasView
										? (<></>)
										: (<>
											{customerRut &&
												customerServices &&
												!escalationID &&
												!disableFields &&
												customerType !== 4 && (
													<Button
														onClick={() =>
															setShowEscalation(true)
														}
													>
														Escalar a TI
													</Button>
												)}
											{canWithoutMessage(qc.inspection.create) &&
												customerType != 4 &&
												props.id && (
													<Button
														onClick={() =>
															setShowInspection(true)
														}
													>
														Crear inspección
													</Button>
												)}
										</>)
								}

							</div>
						</Form>
						<Modal
							visible={showEscalation}
							footer={null}
							onCancel={() => setShowEscalation(false)}
							title="Escalar"
						>
							<Escalation
								loading={escalationButtonLoading}
								submit={receiveEscalation}
							/>
						</Modal>
					</Tabs.TabPane>
					{customerRut && customerType == 3 && !props.hideTabs && (
						<Tabs.TabPane tab="Cliente" key="client">
							<CustomerInfoModal notModal rut={customerRut[0]} />
						</Tabs.TabPane>
					)}
					{customerRut && customerType == 3 && !props.hideTabs && (
						<Tabs.TabPane tab="Servicios" key="services">
							<ServiceInfoModal notModal rut={customerRut[0]} />
						</Tabs.TabPane>
					)}
					{props.id && escalationID && agents && !props.hideTabs && (
						<Tabs.TabPane tab="Escalamiento" key="escalation">
							<EscalationDetails
								tipifyID={props.id}
								escalationID={escalationID}
								agents={agents}
							/>
						</Tabs.TabPane>
					)}
					{props.id && !props.hideTabs && (
						<Tabs.TabPane tab="Conversaciones" key="conversations">
							<Tabs centered>
								<Tabs.TabPane tab="Whatsapp" key="whatsapp">
									<Conversation
										ticket={true}
										typifyInfo={{ "ID": props.id, "customer": customerRut, "services": customerServices, "type": customerType, "chat": chatId }}
										hidecustomer={true} hideTypify={true} messagesEndpoint="communications/whatsapp_message/" type="whatsapp" id={whatsappConversation}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab="Correo" key="email">
									<Conversation
										ticket={true}
										typifyInfo={{ "ID": props.id, "customer": customerRut, "services": customerServices, "type": customerType, "chat": chatId }}
										hidecustomer={true} hideTypify={true} messagesEndpoint="communications/email_message/" type="email" id={emailConversation}
									/>
								</Tabs.TabPane>
							</Tabs>
						</Tabs.TabPane>
					)}
				</Tabs>
				{customerModal && customerType !== 4 && (
					<CustomerInfoModal
						visible={customerModal}
						setVisible={setCustomerModal}
						rut={customerRut[0]}
					/>
				)}
				{serviceModal && customerType !== 4 && (
					<ServiceInfoModal
						visible={serviceModal}
						setVisible={setServiceModal}
						rut={customerRut[0]}
					/>
				)}
				{delayPaymentWizard && customerType !== 4 && (
					<PaymentDelay
						reset={getTypifyData}
						setVisible={setDelayPaymentWizard}
						tipifyID={props.id}
						rut={customerRut[0]}
						services={customerServices}
					/>
				)}
				{showInspection && customerType !== 4 && (
					<Modal
						width={1100}
						visible={showInspection}
						footer={null}
						onCancel={() => setShowInspection(false)}
						title="Crear inspección"
					>
						<InspeccionesForm
							closeModal={() => setShowInspection(false)}
							tipifyID={props.id}
						/>
					</Modal>
				)}
				{showFilterServices && (
					<Modal
						width={600}
						visible={showFilterServices}
						footer={null}
						onCancel={() => setShowFilterServices(false)}
						title="Multiples clientes"
					>
						<MassiveFilters
							loading={filterLoading}
							getFilters={getFilters}
							filtros={[]}
							tipify
							closeModal={() => setShowFilterServices(false)}
						/>
					</Modal>
				)}
			</PanelBody>
		</Panel>
	);
};

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(Typify);
