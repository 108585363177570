import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import EscalationConfigForm from './EscalationConfigForm';
import { escalationTests } from '../../config/permissions';

function EscalationTests() {

    const endpoints = {
        rows: 'escalation_ti/test/datatables/',
        columns: 'escalation_ti/test/datatables_struct/',
        delete: 'escalation_ti/test/'
    }
    const formEndpoint = 'escalation_ti/test';
    return (
        <>
            <FullTable
                modalContent={<EscalationConfigForm test permissions={escalationTests} endpoint={formEndpoint} />}
                endpoints={endpoints}
                tableName='prueba'
                permissions={escalationTests}
            />
        </>
    )
}

export default EscalationTests;