import { Badge, Popover, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import ACTIONS from "./../../../redux/creator";
import store from "./../../../redux/store";
import { BellOutlined, CompassOutlined, LoadingOutlined } from '@ant-design/icons';
import Notifications from './notifications2';
import HttpRequest from '../../../services/HttpRequest'
import styled from 'styled-components';
import { connect } from "react-redux";

function NotificationsIcon({ user }) {

	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(true)
	const MINUTE_MS = 5000;
	const [current, setCurrent] = useState()
	const [newCurrent, setNewCurrent] = useState(null)
	const currentStorage = localStorage.getItem('curretNotifications')

	const getNotifications = () => {
		HttpRequest.endpoint = "communications/notification/recent_notifications";
		HttpRequest.get().then((res) => {

			var newList = res?.data?.map((e) => {
				if (e?.agent === user?.ID || e?.agent === null) {
					return (

						{
							agent: e.agent,
							data: { type: e.data.type, conversation_ID: e.data.conversation_ID },
							message: e.message,
							pk: e.pk,
							public: false,
						}

					)
				}

			})
			setNotifications(newList)
			setCurrent(res.total)
			setLoading(false)
			store.dispatch(ACTIONS.setNotifications(res));
			localStorage.setItem('notifications', JSON.stringify(newList))
			store.dispatch(ACTIONS.setNotifications(localStorage.getItem('notifications')));
		})
	}

	useEffect(() => {
		const interval = setInterval(() => {
			getNotifications();
		}, MINUTE_MS);
		return () => clearInterval(interval);

	}, [])


	useEffect(() => {
		if (current != newCurrent) {
			if (newCurrent === null) {
				setNewCurrent(current)
				localStorage.setItem('curretNotifications', current)
			} else if (newCurrent >= 0) {
				setNewCurrent(current - currentStorage)
			}

		}
	}, [current])



	return (
		<div>
			{
				loading
					? (<>
						<CusttomSpin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
					</>)

					: (<>
						<Popover content={
							<Notifications
								style={{ height: "200px", overflowY: "scroll" }}
								notifications={notifications}
							/>
						} placement='bottomRight' trigger="click">

							<Badge count={newCurrent ? Math.abs(newCurrent) : 0} onClick={() => setNewCurrent(0)}>
								<BellOutlined style={{ color: "white", fontSize: "30px", marginLeft: "10px", cursor: "pointer" }} />
							</Badge>

						</Popover>
					</>)
			}



		</div >
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps)(NotificationsIcon)



const CusttomSpin = styled(Spin)`&&&{
  color: #fff;
	margin-left: 10px;
		svg{
			width: 15px;
		}
}`