import React, { useState, useEffect } from 'react';
import { Select, Spin, Radio } from 'antd';
import HttpRequest from '../../services/HttpRequest';

function RutField(props) {
    const [rut, setRut] = useState(null);
    const [typing, setTyping] = useState(null);
    const [clients, setClients] = useState(null);
    const [loadingClients, setLoadingClients] = useState(false);
    const [criterion, setCriterion] = useState("service");
    const [value, setValue] = useState(props.defaultValue ? props.defaultValue : undefined);
    const [loading, setLoading] = useState(true);

    const getClients = (value, customer) => {
        if (value != null && value.length > 0) {
            setLoadingClients(true);
            HttpRequest.endpoint = "matrix/customer_filter/";
            const formData = { criterion: customer ? 'rut' : criterion, search: value }
            HttpRequest.get(formData).then((res) => {
                if (res && Array.isArray(res)) {
                    const clientList = []
                    res.forEach(client => clientList.push(<Select.Option value={client.id} key={client.id}>{client.text}</Select.Option>))
                    setLoadingClients(false);
                    setClients(clientList)
                    setLoading(false)
                }
            })
        }
    }
    const onKeyPress = (e) => {
        const specialCharRegex = new RegExp("^[0-9]*$");
        const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!specialCharRegex.test(pressedKey)) {
            e.preventDefault();
            return false;
        }
    }

    useEffect(() => {
        if (rut && typing) {
            const delay = setTimeout(() => {
                getClients(rut)
            }, 1000)
            return () => clearTimeout(delay)
        }
    }, [rut])

    useEffect(() => {
        if (props.defaultValue) {
            getClients(props.defaultValue, true)
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <>
            {!loading ?
                <div>
                    {!props.hideCriterion && <div className="radio-group-div">
                        <Radio.Group className="radio-group rut-field-margin" onChange={(e) => setCriterion(e.target.value)} defaultValue={"service"}>
                            <Radio value={"rut"}>Rut</Radio>
                            <Radio value={"name"}>Nombre</Radio>
                            <Radio value={"composite_address"}>Dirección</Radio>
                            <Radio value={"service"}>Servicio</Radio>
                        </Radio.Group>
                    </div>}
                    <Select value={value} onClick={props.onClick} className={props.className || 'rut-field-width'} defaultValue={props.defaultValue} onChange={e => { setValue(e); props.onChange(e) }} placeholder={props.placeholder} onKeyPress={criterion === 'service' && onKeyPress} filterOption={false} notFoundContent={loadingClients ? <Spin size="small" /> : null} showSearch onSearch={val => { setTyping(true); setRut(val) }}>
                        {clients}
                    </Select>
                </div>
                : <Spin className='center-spin' />
            }
        </>
    )
}

export default RutField;
