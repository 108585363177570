import { getTimeProps } from "antd/lib/date-picker/generatePicker";

class Config {
    static DOMAIN_API = process.env.REACT_APP_IRIS_BACKEND;
    static API_URL = `${this.DOMAIN_API}/api/v1`;
    static API_WEBHOOKS_URL = `${process.env.REACT_APP_WEBHOOKS_BACKEND}/api/v1`;
    static WEBHOOKS_TOKEN = process.env.REACT_APP_WEBHOOKS_TOKEN;
    static TITLE = "Iris";
}

export default Config;