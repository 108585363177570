import React, { Fragment, useEffect, useState } from "react";
import SearchTables from "../TypificationsTables/SearchTables";
import ConversationTab from "./ConversationTab";
import ConversationTables from "./ConversationTables";
import { useParams } from 'react-router-dom';


function ConversationSearchTables(props) {
    const endpoint = props.tables
    const { id, pk } = useParams();
    const [idNotification, setIdNotification] = useState()

    useEffect(() => {
        if (pk && id) {
            localStorage.setItem('idNotification', id)
            setIdNotification(id)
        } else {
            localStorage.setItem('idNotification', null)
        }

    }, [id])

    return (

        <SearchTables component={
            <ConversationTab
                type={props.type}
                endpoints={props.endpoints}
                messagesEndpoint={props.messagesEndpoint}
                id={idNotification}
            />}
            endpoint={endpoint} />
    );
}

export default ConversationSearchTables;
