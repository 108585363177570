import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import ACTIONS from "./../../redux/creator";
import store from "./../../redux/store";
import HttpRequest from './../../services/HttpRequest';
import './operators.css';

const OperatorSelector = (props) => {
    const [companyData, setCompanyData] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('company') || 1)

    useEffect(() => {
        if (!localStorage.getItem('operator')) {
            localStorage.setItem('operator', '2')
        } else {
            store.dispatch(ACTIONS.setOperator(Number(localStorage.getItem('operator'))));
        }
        if (!localStorage.getItem('company')) {
            localStorage.setItem('company', '1')
        } else {
            store.dispatch(ACTIONS.setCompany(Number(localStorage.getItem('company'))));
        }
    }, [])

    useEffect(() => {
        HttpRequest.endpoint = 'operators/operators/company_list/';
        HttpRequest.get().then(res => {
            if (res && Array.isArray(res)) {
                setCompanyData(res)
            }
        })
    }, [])

    return companyData.length > 0 && <div className="operator-selector">
        <div className="icon">
            <i className="fas fa-satellite-dish"></i>
        </div>
        <Input
            type="select"
            onChange={(e) => {
                setSelectedCompany(e.target.value)
                store.dispatch(ACTIONS.setCompany(Number(e.target.value)));
                localStorage.setItem("company", e.target.value);
            }}
            defaultValue={localStorage.getItem('company') || '1'}
        >
            {companyData.map(company => <option key={company.ID} value={company.ID}>{company.name}</option>)}
        </Input>
        <Input
            type="select"
            onChange={(e) => {
                store.dispatch(ACTIONS.setOperator(Number(e.target.value)));
                localStorage.setItem("operator", e.target.value);
            }}
            defaultValue={localStorage.getItem('operator') || '2'}
            className='operator-selector-input'
        >
            {companyData.filter(company => Number(company.ID) === Number(selectedCompany))[0].operators.map(operator => <option key={operator.ID} value={operator.ID}>{operator.name}</option>)}
        </Input>
    </div>
};

OperatorSelector.defaultProps = {};

OperatorSelector.propTypes = {};

export default OperatorSelector;
