import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Modal, Button, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import HttpRequest from "../../services/HttpRequest.js";
import { can, validOperator } from '../../helpers/helpers';
import autoCloseModal from '../../components/modal/AutoCloseModal.jsx';

function CategoryForm(props) {
    const [formLoading, setFormLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [parentInfo, setParentInfo] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState(false);

    const submitForm = e => {
        if (validOperator()) {
            const formData = new FormData();
            Object.keys(e).forEach(key => {
                if (e[key] !== undefined && e[key] !== null) {
                    formData.append(key, e[key])
                }
            })
            HttpRequest.endpoint = props.endpoint;
            if (editable) {
                setButtonLoading(true);
                HttpRequest.patch(props.categoryInfo.ID, formData).then(res => {
                    setButtonLoading(false);
                    if (res.ID) {
                        props.updateRows()
                        autoCloseModal('Se ha editado la categoría con éxito')
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error editando la categoría'
                        })
                    }
                })
            } else {
                if (can(props.permissions.create)) {
                    setButtonLoading(true);
                    HttpRequest.post(formData).then(res => {
                        setButtonLoading(false);
                        if (res.ID) {
                            props.updateRows()
                            autoCloseModal('Se ha creado la categoría con éxito')
                        } else if (Array.isArray(res.classification) && res.classification[0].includes("is not a valid choice.")) {
                            Modal.error({
                                title: 'Error',
                                content: 'No se puede crear problema de cuarto nivel en escalamientos'
                            })
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error creando la categoría'
                            })
                        }
                    })
                }
            }
        }
    }
    const getParentInfo = () => {
        HttpRequest.endpoint = props.endpoint;
        return HttpRequest.get(null, props.categoryInfo.parent);
    }
    const deleteCategory = () => {
        setDisabledButtons(true);
        setButtonLoading(true);
        HttpRequest.endpoint = props.endpoint;
        HttpRequest.delete(props.categoryInfo.ID).then(res => {
            setButtonLoading(false);
            if (res['Error delete']) {
                Modal.error({
                    title: 'Error',
                    content: 'Esta categoría o alguna categoría hija se encuentra en uso en alguna tipificación. No fue posible eliminar.'
                })
                setDisabledButtons(false)
            } else if (res && Object.keys(res).length === 0) {
                autoCloseModal('Se ha eliminado con éxito esta categoría')
                props.updateRows()
                props.setDeletedNodeID(props.categoryInfo.ID);
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error al eliminar.'
                })
            }
        })
    }
    const handleEdit = () => {
        if (can(props.permissions.edit)) {
            setEditable(true);
            if (props.categoryInfo.classification === 1) {
                setParentInfo({ name: 'Ninguna' })
                setFormLoading(false);
            } else {
                getParentInfo().then(res => {
                    setParentInfo(res);
                    setFormLoading(false);
                })
            }
        }
    }
    const handleNewChild = () => {
        if (can(props.permissions.create) && can(props.permissions.edit)) {
            setFormLoading(false);
        }
    }
    const handleDelete = () => {
        if (can(props.permissions.delete)) {
            Modal.confirm({
                title: '¿Seguro que deseas eliminar esta categoría?',
                icon: <ExclamationCircleOutlined />,
                content: 'Se eliminarán todas las categorías hijas de esta categoría',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    deleteCategory();
                }
            });
        }
    }
    const initialValues = {
        name: props.categoryInfo && editable ? props.categoryInfo.name : null,
        sla: props.categoryInfo && editable ? props.categoryInfo.sla : 1,
        classification: props.categoryInfo ? editable ? props.categoryInfo.classification : props.categoryInfo.classification + 1 : 1,
        parent: props.categoryInfo ? editable ? props.categoryInfo.parent : props.categoryInfo.ID : null,
    }

    useEffect(() => {
        if (!props.categoryInfo) {
            setFormLoading(false);
        }
    }, [])

    return (
        <>
            {props.categoryInfo && formLoading &&
                <>
                    <h1 className='form-title'>Seleccione una acción</h1>
                    <div className='center-btns'>
                        <Button type='primary' disabled={disabledButtons} onClick={handleEdit}>Editar</Button>
                        <Button type='secondary' disabled={disabledButtons} onClick={handleNewChild}>Añadir hijo</Button>
                        <Button type='primary' danger disabled={disabledButtons} loading={buttonLoading} onClick={handleDelete}>Eliminar</Button>
                    </div>
                </>
            }
            {!formLoading &&
                <>
                    <Form name='create-customer' onFinish={submitForm} initialValues={initialValues}>
                        <Form.Item label='Nombre' name='name' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label='SLA' name='sla' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <InputNumber min={1} max={24} />
                        </Form.Item>
                        <Form.Item className='hidden-category' label='Clasificación' name='classification'>
                            <Select disabled>
                                <Select.Option value={1}>Tipo</Select.Option>
                                <Select.Option value={2}>Categoría</Select.Option>
                                <Select.Option value={3}>Subcategoría</Select.Option>
                                <Select.Option value={4}>Segunda subcategoría</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='hidden-category' label='Clasificación superior' name='parent'>
                            <Select disabled>
                                <Select.Option value={initialValues.parent}>{editable ? parentInfo.name : props.categoryInfo ? props.categoryInfo.name : 'Ninguna'}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button block size='large' htmlType='submit' type='primary' loading={buttonLoading}>{props.categoryInfo && editable ? 'Guardar' : 'Crear'}</Button>
                    </Form>
                </>
            }
        </>
    )
}

export default CategoryForm;