import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import { Button, Select, Form } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import "../../pages/leads/leads.css";
import { connect } from "react-redux";
import errorModal from "./../../components/modal/errorModal";

const CategoryForm = (props) => {
	const [form] = Form.useForm();
	const [typifyOpc, setTypifyOpc] = useState({
		type: [],
		category: [],
		subCategory: [],
		secondSub: [],
	});

	const getCategoryLabel = (catID, isSecondSub) => {
		let arrayToSearch = typifyOpc.subCategory;
		let label = "";
		if (isSecondSub) {
			arrayToSearch = typifyOpc.secondSub;
		}
		arrayToSearch.forEach((option) => {
			if (option && option.props && option.props.value === catID) {
				label = option.props.children;
			}
		});
		return label;
	};

	const getTypifyType = () => {
		let list = [];
		let formData = {};
		HttpRequest.endpoint = "tickets/category/";
		formData = {
			operator: props.operator,
			classification: 1,
			fields: "ID,name",
		};
		HttpRequest.get(formData).then((res) => {
			res.forEach((element) => {
				list.push(
					<Select.Option key={element["ID"]} value={element["ID"]}>
						{element["name"]}
					</Select.Option>
				);
			});
			setTypifyOpc((prevState) => ({
				...prevState,
				type: list,
			}));
		});
	};

	const getTypifyCategories = (classification, parent) => {
		let list = [];
		HttpRequest.endpoint = "tickets/category/";
		const formData = {
			operator: props.operator,
			parent: parent,
			fields: "ID,name",
		};
		HttpRequest.get(formData).then((res) => {
			res.forEach((element) => {
				list.push(
					<Select.Option key={element["ID"]} value={element["ID"]}>
						{element["name"]}
					</Select.Option>
				);
			});
			setTypifyOpc((prevState) => ({
				...prevState,
				[classification]: list,
			}));
		});
	};

	const addCategory = (values) => {
		let category;
		let newValue;
		if (values.secondSub) {
			category = {
				label: getCategoryLabel(values.secondSub, true),
				value: values.secondSub,
			};
			newValue = values.secondSub;
		} else if (values.subCategory) {
			category = {
				label: getCategoryLabel(values.subCategory, false),
				value: values.subCategory,
			};
			newValue = values.subCategory;
		} else {
			return errorModal(
				"Debe seleccionar una subcategoría o segunda subcategoría"
			);
		}
		props.setCategoriesList((prevCategoriesList) => {
			const arrayCopy = [...prevCategoriesList];
			arrayCopy.push(category);
			return arrayCopy;
		});
		const allValues = [...props.currentValues];
		allValues.push(newValue);
		props.setCurrentValues(allValues);
	};

	useEffect(() => {
		getTypifyType();
	}, [props.operator]);

	return (
		<div className="direction-form">
			<Form
				onFinish={addCategory}
				form={form}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 18 }}
			>
				<Form.Item name="type" label="Tipo">
					<Select
						className="direction-select"
						placeholder="Tipo"
						onChange={(value) => {
							setTypifyOpc((prevState) => ({
								...prevState,
								category: [],
								subCategory: [],
								secondSub: [],
							}));
							form.setFieldsValue({
								category: null,
								subCategory: null,
								secondSub: null,
							});
							getTypifyCategories("category", value);
						}}
					>
						{typifyOpc.type}
					</Select>
				</Form.Item>
				<Form.Item name="category" label="Categoría">
					<Select
						className="direction-select"
						placeholder="Categoría"
						onChange={(value) => {
							setTypifyOpc((prevState) => ({
								...prevState,
								subCategory: [],
								secondSub: [],
							}));
							form.setFieldsValue({
								subCategory: null,
								secondSub: null,
							});
							getTypifyCategories("subCategory", value);
						}}
					>
						{typifyOpc.category}
					</Select>
				</Form.Item>
				<Form.Item name="subCategory" label="SubCategoría">
					<Select
						className="direction-select"
						placeholder="SubCategoría"
						onChange={(value) => {
							setTypifyOpc((prevState) => ({
								...prevState,
								secondSub: [],
							}));
							form.setFieldsValue({ secondSub: null });
							getTypifyCategories("secondSub", value);
						}}
					>
						{typifyOpc.subCategory}
					</Select>
				</Form.Item>
				<Form.Item name="secondSub" label="Segunda Sub">
					<Select
						className="direction-select"
						placeholder="Segunda Subcategoría"
					>
						{typifyOpc.secondSub}
					</Select>
				</Form.Item>
				<Button htmlType="submit" className="center-btn" type="primary">
					Añadir
				</Button>
			</Form>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		operator: state.operator,
	};
}

export default connect(mapStateToProps)(CategoryForm);
