import React, { useState, useEffect } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { Tabs, Tag } from 'antd';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { can } from '../../helpers/helpers';
import { financeEscalation } from '../../config/permissions';
import FinanceEscalationForm from './FinanceEscalationForm';
import FinanceEscalationFull from './FinanceEscalationFull';

function FinanceEscalationTab(props) {
    const [tabID, setTabID] = useState(1);
    const [tabList, setTabList] = useState([]);
    const [tableKey, setTableKey] = useState(0);

    const endpoints = {
        columns: 'escalation_finance/escalation/' + props.tableID + '/datatables_struct/',
        rows: 'escalation_finance/escalation/' + props.tableID + '/datatables/',
        download: 'escalation_finance/escalation/' + props.tableID + '/download_data/',
    }

    const customRendering = (text, value, id) => {
        switch (value) {
            case "created":
                return <Moment fromNow locale="es">{text}</Moment>;
            case "custom_change":
                return <div style={{ display: 'inline', color: 'rgb(24, 144, 255)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => createTab(id)}>{id}</div>
            case "updated":
                return <Moment fromNow locale="es">{text}</Moment>;
            case "services":
                return text.map(serv => <Tag key={id}>{serv}</Tag>)
            default:
                break;
        }
    }
    const createTab = escID => {
        if (can(financeEscalation.view)) {
            setTabID(prevTabID => {
                setTabList(prevTabList => {
                    let isNew = true;
                    prevTabList.forEach(tab => {
                        if (tab.props.tab === escID) {
                            isNew = false;
                        }
                    })
                    if (isNew) {
                        return prevTabList.concat([
                            <Tabs.TabPane tab={escID} key={prevTabID}>
                                <FinanceEscalationFull id={escID} closeTab={deleteTab} tabID={prevTabID} />
                            </Tabs.TabPane>
                        ])
                    } else {
                        return prevTabList
                    }
                })
                return prevTabID + 1;
            })
        }
    }
    const deleteTab = tabID => {
        setTabList(prevTabList => prevTabList.filter(tab => Number(tab.key) !== tabID))
    }

    useEffect(() => {
        setTabList([])
    }, [props.operator])

    return (
        <>
            <Tabs className='typify-tab' type='card' size='small' onChange={e => { if (e.includes('table')) { setTableKey(tableKey + 1) } }}>
                <Tabs.TabPane tab='Tabla' key='table'>
                    {props.tableID && <FullTable modalWidth={600} buttons={[]} permissions={financeEscalation} customRendering={{ renderFunction: customRendering, keys: ['ID', 'updated', 'services'] }} key={tableKey} modalContent={<FinanceEscalationForm />} endpoints={endpoints} tableName='escalamiento' />}
                </Tabs.TabPane>
                {tabList}
            </Tabs>
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(FinanceEscalationTab);