import "react-phone-number-input/style.css";
import React, { useState, useEffect, useRef } from "react";
import { Button, Select, Modal, Collapse, Form, Input } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import { endPointCreateMassives, endPointSlackChannel, endPointTemplateText, endPointTemplateCall, endPointTemplateWhatsapp } from "../../services/endpoints.jsx";
import store from "../../redux/store.js";
import MassiveFilters from "./filtersForm.jsx"
import EmailForm from "./emailForm.jsx"
import ChannelForm from "./channelsForm.jsx";
import { can, canWithoutMessage } from "../../helpers/helpers.js";
import { eventOptions, massive } from "../../config/permissions.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';

const MassiveCreateModal = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [channel, setChannel] = useState();
    const [channelList, setChannelList] = useState([]);
    const [filterType, setFilterType] = useState();
    const [id, setId] = useState(props.id);
    const [unpaid, setUnpaid] = useState(false);
    const [callEvent, setCallEvent] = useState();
    const [textEvent, setTextEvent] = useState();
    const [emailEvent, setEmailEvent] = useState();
    const [whatsappEvent, setWhatsappEvent] = useState();
    const [name, setName] = useState("");
    const [filtros, setFiltros] = useState([]);
    const formRef = useRef();
    const [resetChannel, setResetChannel] = useState(0)

    const getSlackChannels = nextCursor => {
        HttpRequest.endpoint = endPointSlackChannel;
        const operator = store.getState().operator;
        const filters = { operator: operator };
        if (nextCursor) {
            filters.cursor = nextCursor
            setChannelList(prevChannels => prevChannels.concat([<Select.Option value={null} key={'none'}>Ninguno</Select.Option>]))
        }
        HttpRequest.get(filters).then(res => {
            if (res.ok) {
                setChannelList(prevChannels => prevChannels.concat(res.channels.map(chan => <Select.Option key={chan.id} value={chan.id}>{chan.name}</Select.Option>)))
            }
            if (res.response_metadata && res.response_metadata.next_cursor) {
                getSlackChannels(res.response_metadata.next_cursor)
            } else {
                if (props.id) {
                    getData(props.id);
                } else {
                    setShowForm(true)
                }
            }
        })
    }
    const getData = massiveId => {
        HttpRequest.endpoint = endPointCreateMassives + massiveId;
        HttpRequest.get().then((res) => {
            setName(res["name"]);
            setChannel(res["slack_channel"]);
            setUnpaid(res["unpaid_ballot"]);
            setFilterType(res["type_filters"]);
            setFiltros(res["filters"]);
            setTextEvent(res["text_event"]);
            setEmailEvent(res["email_event"]);
            setWhatsappEvent(res["whatsapp_event"]);
            setCallEvent(res["call_event"]);
            setShowForm(true);
        })
    }

    useEffect(() => {
        getSlackChannels()
    }, []);


    const createMassive = (values) => {
        let op = store.getState().operator;
        if (!op || op == 0) {
            Modal.error({ title: "Error", content: "Por favor seleccione un operador válido", });
            return;
        }
        const formData = new FormData();
        formData.append("name", values["name"]);
        if (values["slackChannel"]["value"] != null) {
            formData.append("slack_channel", values["slackChannel"]["value"]);
        }
        HttpRequest.endpoint = endPointCreateMassives;
        id ? can(massive.edit) && HttpRequest.patch(id, formData).then((res) => {
            if (res["ID"]) {
                setId(id);
                autoCloseModal("Se ha modificado el masivo exitosamente")
                props.updateRows()
            } else if (res.name === "There is already a massive with that name") {
                Modal.error({ title: "Error", content: 'Ya existe un masivo con este nombre' })
            } else {
                Modal.error({ title: "Error", content: "Ha ocurrido un error al modificar el masivo" })
            }
        })
            : can(massive.create) && HttpRequest.post(formData).then((res) => {
                if (res["ID"]) {
                    setId(res["ID"])
                    autoCloseModal("Se ha creado el masivo exitosamente")
                    props.updateRows()
                } else if (res.name === "There is already a massive with that name") {
                    Modal.error({ title: "Error", content: 'Ya existe un masivo con este nombre' })
                } else {
                    Modal.error({ title: "Error", content: "Ha ocurrido un error al crear el masivo" })
                }
            });
    };

    return (
        <>
            {showForm && (
                <Collapse accordion defaultActiveKey={['massive']}>
                    <Collapse.Panel key="massive" header="Masivo">
                        <Form onFinish={createMassive} ref={formRef} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                            <Form.Item label="Nombre" name="name" rules={[{ required: true }]} initialValue={id ? name : null}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Canal de Slack" name="slackChannel" initialValue={{ value: channel ?? null }}>
                                <Select showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Seleccione el canal" labelInValue defaultValue={{ value: channel ?? null, }}
                                    style={{ width: "100%", }} placeholder="Plantillas"
                                    onChange={(option) => setChannel(option.key)}>
                                    {channelList}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Guardar y añadir canal</Button>
                            </Form.Item>
                        </Form>
                    </Collapse.Panel>
                    <Collapse.Panel key="filters" header="Filtros">
                        <MassiveFilters updateRows={props.updateRows} editing={props.id ? true : false} id={id} unpaid={unpaid} filterType={filterType} filtros={filtros} />
                    </Collapse.Panel>
                    {canWithoutMessage(eventOptions.text.view) && <Collapse.Panel key="textChannel" header="Mensajes de Texto">
                        <ChannelForm
                            id={id}
                            event={textEvent}
                            endpoint={endPointTemplateText}
                            type="text"
                            editing={props.id ? true : false}
                            permissions={{
                                create: massive.create,
                                edit: massive.edit,
                                delete: massive.delete,
                            }}
                            reset={resetChannel}
                            setResetChannel={setResetChannel}
                            updateRows={props.updateRows}
                        />
                    </Collapse.Panel>}
                    {canWithoutMessage(eventOptions.call.view) && <Collapse.Panel key="callChannel" header="Llamadas">
                        <ChannelForm
                            id={id}
                            event={callEvent}
                            endpoint={endPointTemplateCall}
                            type="call"
                            editing={props.id ? true : false}
                            permissions={{
                                create: massive.create,
                                edit: massive.edit,
                                delete: massive.delete,
                            }}
                            setResetChannel={setResetChannel}
                            reset={resetChannel}
                            updateRows={props.updateRows}
                        />
                    </Collapse.Panel>}
                    {canWithoutMessage(eventOptions.whatsapp.view) && <Collapse.Panel key="whatsappChannel" header="Whatsapp">
                        <ChannelForm
                            id={id}
                            event={whatsappEvent}
                            endpoint={endPointTemplateWhatsapp}
                            type="whatsapp"
                            editing={props.id ? true : false}
                            permissions={{
                                create: massive.create,
                                edit: massive.edit,
                                delete: massive.delete,
                            }}
                            setResetChannel={setResetChannel}
                            reset={resetChannel}
                            updateRows={props.updateRows}
                        />
                    </Collapse.Panel>}
                    {canWithoutMessage(eventOptions.email.view) && <Collapse.Panel key="emailChannel" header="Correo">
                        <EmailForm
                            editing={props.id ? true : false}
                            permissions={{
                                create: massive.create,
                                edit: massive.edit,
                                delete: massive.delete,
                            }}
                            id={id}
                            event={emailEvent}
                            updateRows={props.updateRows}
                            reset={resetChannel}
                            setResetChannel={setResetChannel}
                        />
                    </Collapse.Panel>}
                </Collapse>
            )}
        </>
    );
};

export default MassiveCreateModal;
