import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import WizardConfigurations from './WizardConfigurations';

function WizardConfigurationsTable(props) {
    const endpoints = {
        columns: 'tickets/process/datatables_struct/',
        rows: 'tickets/process/datatables/',
    };

    return (
        <>
            <FullTable modalContent={<WizardConfigurations />} endpoints={endpoints} tableName='configuración' buttons={[]} />
        </>
    )
}

export default WizardConfigurationsTable;