import React, { useState, useEffect } from 'react';
import ExtraTipifyInfo from './ExtraTipifyInfo';
import PaymentAmount from './PaymentAmount';

function PaymentAmountWiz(props) {
    //props.startAgain y props.categories
    const [step, setStep] = useState(1);
    const [rut, setRut] = useState(null);
    const [services, setServices] = useState(null);
    const [channel, setChannel] = useState(null);

    const receiveExtraData = data => {
        console.log(data)
        setChannel(data.channel)
        setRut(data.customer_rut.value);
        setServices(data.services)
        setStep(2)
    }

    useEffect(() => {
        console.log(props.process)
    }, [])

    const steps = {
        1: <ExtraTipifyInfo nextStep={receiveExtraData} requiredRut requiredServices />,
        2: <PaymentAmount updateRows={props.updateRows} process={props.process} channel={channel} categories={props.categories} rut={rut} services={services} startAgain={props.startAgain} />
    }

    return (
        <>
            {steps[step]}
        </>
    )
}

export default PaymentAmountWiz;