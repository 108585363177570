import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { drives } from '../../config/permissions';
import DefinitionTableForm from './DefinitionTableForm';

function DrivesDefinitionTable() {
    const endpoints = {
        columns: "drives/definition/datatables_struct/",
        rows: "drives/definition/datatables/",
        delete: `drives/definition/`,
    }

    return (
        <>
            <FullTable
                modalContent={<DefinitionTableForm />}
                endpoints={endpoints}
                tableName='drive'
                permissions={drives.definition}
                modalWidth={800}
            />
        </>
    )
}

export default DrivesDefinitionTable;