import React, { useEffect } from 'react';
import { Select } from 'antd';

function FinanceSolutionResultField(props) {

    return (
        <>
            <Select className='dynamic-field-item' placeholder='Seleccionar prueba' disabled={props.disabled} defaultValue={props.initial} onChange={e => props.addSolution({ id: e, index: props.index })} placeholder='Seleccionar solución'>
                {props.solutions.map(sol => <Select.Option key={sol.ID} value={sol.ID}>{sol.name}</Select.Option>)}
            </Select>
        </>
    )
}

export default FinanceSolutionResultField;