import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import TextTable from './TextTable';

function TextSearchTables() {
    const endpoint = 'communications/text_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<TextTable />} />
        </>
    )
}

export default TextSearchTables;