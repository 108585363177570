import React, { useEffect, useState } from "react";
import { Tabs, Table, Modal } from "antd";
import HttpRequest from "../../services/HttpRequest";
import Moment from "react-moment";

const CustomerInfoModal = (props) => {


    const [personalInfo, setPersonalInfo] = useState();
    const [paymentInfo, setPaymentInfo] = useState();
    const [payorrutInfo, setPayorrutInfo] = useState();
    const [invoiceInfo, setInvoiceInfo] = useState();
    const { TabPane } = Tabs;

    const personalFields = {
        name: "Nombre", kind_name: "Tipo", email: "Correo", phone: "Teléfono",
        composite_address: "Dirección", commune: "Comuna", notes: "Nota", rut: "RUT"
    }

    const infoColumns = [
        { title: 'Campo', dataIndex: 'field', key: 'field' },
        { title: 'Valor', dataIndex: 'value', key: 'value' },
    ]

    const paymentsColumns = [
        { title: 'Fecha de pago', dataIndex: 'paid_on', key: 'paid_on' },
        { title: 'Fecha de Depósito', dataIndex: 'deposited_on', key: 'deposited_on' },
        { title: 'Cantidad', dataIndex: 'amount', key: 'amount' },
        { title: '¿Manual?', dataIndex: 'manual', key: 'manual' },
        { title: 'Tipo', dataIndex: 'kind', key: 'kind' },
        { title: '¿Despejado?', dataIndex: 'cleared', key: 'cleared' },
        { title: 'Comentario', dataIndex: 'comment', key: 'comment' },
    ]

    const payorrutColumns = [
        { title: 'Rut', dataIndex: 'rut', key: 'rut' },
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
    ]

    const invoicesColumns = [
        { title: 'Tipo', dataIndex: 'kind', key: 'kind' },
        { title: 'Folio', dataIndex: 'folio', key: 'folio' },
        { title: 'Fecha de Pago', dataIndex: 'paid_on', key: 'paid_on' },
        { title: 'Total', dataIndex: 'total', key: 'total' },
        { title: 'Estado', dataIndex: 'status', key: 'status' },
        { title: 'Comentario', dataIndex: 'comment', key: 'comment' },
    ]

    useEffect(() => {
        if (props.rut != null) {
            getCustomerData(props.rut)
            !props.hideTabs && getPayments(props.rut)
            !props.hideTabs && getPayorruts(props.rut)
            !props.hideTabs && getInvoices(props.rut)
        }
    }, [props.rut])

    const getCustomerData = rut => {
        HttpRequest.endpoint = "matrix/customers/"
        let param = { rut: rut }
        let personalData = [];
        HttpRequest.get(param).then((res) => {
            Object.keys(res[0]).map((key) => {

                if (Object.keys(personalFields).includes(key)) {
                    personalData.push({ key: key, field: personalFields[key], value: res[0][key] })
                }
            })
            setPersonalInfo(personalData);
        })
            .catch(err => {
                console.log(err)
            })
    }

    const getPayments = (rut) => {
        HttpRequest.endpoint = "matrix/payments/";
        let filters = { customer__rut: rut, fields: 'transfer_id,paid_on,deposited_on,amount,manual,kind,cleared,comment' }
        HttpRequest.get(filters).then((res) => {
            let paymentData = res.map((payment) => ({
                key: payment["transfer_id"],
                paid_on: payment["paid_on"] == null ? "" : <Moment fromNow locale="es">{payment["paid_on"]}</Moment>,
                deposited_on: payment["deposited_on"] == null ? "" : <Moment fromNow locale="es">{payment["deposited_on"]}</Moment>,
                amount: payment['amount'],
                manual: payment['manual'] ? "Sí" : "No",
                kind: payment['kind'],
                cleared: payment['cleared'] ? "Sí" : "No",
                comment: payment['comment'],
            }))
            setPaymentInfo(paymentData)
        })
    }

    const getPayorruts = (rut) => {
        let payorrutsData = [];
        HttpRequest.endpoint = "matrix/payorruts/";
        let filters = { customer__rut: rut, fields: 'rut,name' }
        HttpRequest.get(filters).then((res) => {
            res.forEach(element => {
                payorrutsData.push({ rut: element["rut"], name: element["name"] })
            });
            setPayorrutInfo(payorrutsData);
        })
    }

    const getInvoices = (rut) => {
        let invoicesData = [];
        HttpRequest.endpoint = "matrix/invoices/";
        let filters = { customer__rut: rut, fields: 'folio,kind,paid_on,total,status,comment' }
        HttpRequest.get(filters).then((res) => {
            res.forEach(element => {
                invoicesData.push({
                    key: element["folio"],
                    kind: element["kind"],
                    folio: element["folio"],
                    paid_on: element["paid_on"] == null ? "" : <Moment fromNow locale="es">{element["paid_on"]}</Moment>,
                    total: element["total"],
                    status: element["status"],
                    comment: element["comment"]
                })
            });
            setInvoiceInfo(invoicesData);
        })
    }

    return (
        <>
            {props.notModal ?
                <Tabs centered type="card">
                    <TabPane tab="Personal" key="1">
                        <Table columns={infoColumns} dataSource={personalInfo} pagination={false} size="middle" />
                    </TabPane>
                    {!props.hideTabs &&
                        <>
                            <TabPane tab="Pagos" key="2">
                                <Table columns={paymentsColumns} dataSource={paymentInfo} pagination={false} size="middle" />
                            </TabPane>
                            <TabPane tab="Pagadores" key="3">
                                <Table columns={payorrutColumns} dataSource={payorrutInfo} pagination={false} size="middle" />
                            </TabPane>
                            <TabPane tab="Facturas" key="4">
                                <Table columns={invoicesColumns} dataSource={invoiceInfo} pagination={false} size="middle" />
                            </TabPane>
                        </>
                    }
                </Tabs>
                :
                <Modal footer={null} visible={props.visible} width="65%" onCancel={() => props.setVisible(false)}>
                    <Tabs type="card">
                        <TabPane tab="Personal" key="1">
                            <Table columns={infoColumns} dataSource={personalInfo} pagination={false} size="middle" />
                        </TabPane>
                        {!props.hideTabs &&
                            <>
                                <TabPane tab="Pagos" key="2">
                                    <Table columns={paymentsColumns} dataSource={paymentInfo} pagination={false} size="middle" />
                                </TabPane>
                                <TabPane tab="Pagadores" key="3">
                                    <Table columns={payorrutColumns} dataSource={payorrutInfo} pagination={false} size="middle" />
                                </TabPane>
                                <TabPane tab="Facturas" key="4">
                                    <Table columns={invoicesColumns} dataSource={invoiceInfo} pagination={false} size="middle" />
                                </TabPane>
                            </>
                        }
                    </Tabs>
                </Modal>}
        </>
    )

}

export default CustomerInfoModal;